import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgTrainersIcon = ({ className = '' }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <defs>
        <path
          id="trainers_svg__a"
          d="M8.237 9.041l.015.08.03.078 3.375 6.79-.27-.113-1.172 2.761 1.841.782 1.172-2.762-.508-.216 3.47-7.246.004-.012a3.7 3.7 0 011.384.824l.003-.007.088.095c.068.069.133.14.196.214l.02.027 4.87 5.372c.702.775.69 1.96-.03 2.72l-3.278 3.458a1.661 1.661 0 01-2.251.15l-.048-.041L17 24H7.034l-.155-2.028-.075.068A1.663 1.663 0 014.669 22l-.116-.111-3.287-3.468a2 2 0 01-.148-2.576l.109-.133 4.84-5.414.068-.085A3.444 3.444 0 018.118 9.06l.119-.019zm-1.902 5.752l-2.127 2.292 2.5 2.632-.373-4.924zm11.345.013l-.361 4.878 2.473-2.602-2.112-2.276zM15.043 9l-2.766 5.73L9.43 9h5.614zM12 0c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="trainers_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#trainers_svg__a" />
        </mask>
        <use fill={theme.colors.white} xlinkHref="#trainers_svg__a" />
        <g fill={theme.colors.white} mask="url(#trainers_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgTrainersIcon;

import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgSuccess = ({ className = '', color }: IconProps) => {
  const theme = useTheme();
  const fill = color ? theme.colors[color] : '#FFF';

  return (
    <Svg className={className} viewBox="0 0 24 24">
      <defs>
        <path
          id="success_svg__a"
          d="M12 21.6c-5.294 0-9.6-4.307-9.6-9.6 0-5.294 4.306-9.6 9.6-9.6 5.293 0 9.6 4.306 9.6 9.6 0 5.293-4.307 9.6-9.6 9.6zM0 12c0 6.628 5.372 12 12 12 6.626 0 12-5.372 12-12S18.626 0 12 0C5.372 0 0 5.372 0 12zm17-2.4L10.847 16l-.001-.001V16L7 12l1.538-1.6 2.308 2.4L15.462 8 17 9.6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={0.496}>
        <mask id="success_svg__b" fill={fill}>
          <use xlinkHref="#success_svg__a" />
        </mask>
        <use fill={fill} xlinkHref="#success_svg__a" />
        <g fill={fill} mask="url(#success_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgSuccess;

import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { rem } from 'ui/helpers';

export type Props = {
  image?: string,
  children: ReactNode,
};

const Wrapper = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${rem(150)} ${rem(100)} ${rem(120)} ${rem(30)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20%;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url(${({ image }) => `${image}?size=800x800&mask=full-background-image-gradient-720-v1`});
    z-index: -1;
  }
`;

const WorkoutSummaryLayout = ({ image, children }: Props) => (
  <Wrapper image={image}>
    { children }
  </Wrapper>
);

export default WorkoutSummaryLayout;

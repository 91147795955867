import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useAppState } from 'state';
import useLogger from 'app/hooks/use-logger';
import useConfig from 'app/on-tv/config-provider';
import ClassCard from 'ui/components/molecules/class-card';
import Typography from 'ui/components/atoms/typography';
import ClassGrid from 'ui/components/layouts/class-grid-page';
import {
  TrainerDetails as TRAINER_QUERY,
  TrainerDetailsNoAuth as TRAINER_NO_AUTH_QUERY,
} from 'app/on-tv/pages/trainer/trainer-details.gql';
import useRoutes, { url } from 'utils/use-routes';
import {
  LessonStatus,
  TrainerDetailsQuery,
  TrainerDetailsQueryVariables,
  TrainerDetailsNoAuthQuery,
  TrainerDetailsNoAuthQueryVariables,
} from 'app/on-tv/types/graphql';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import FilterBar, { useDurationFilter } from 'ui/components/molecules/filter-bar';
import InstagramLogo from 'ui/components/atoms/icons/instagram';
import useDelay from 'utils/use-delay';
import transformLessonData from 'app/on-tv/utils/transform-lesson-data';

import { LessonDurationRange } from 'types/graphql';
import { rem } from 'ui/helpers';

type ComponentProps = {
  slug: string,
};

type Props = RouteComponentProps<ComponentProps>;

type LessonItem = {
  id: number,
  mainImageUrl?: string,
  duration: string,
  name: string,
  locked?: boolean,
  completed: boolean,
  equipment: boolean,
  favourited?: boolean
};

const Title = styled(Typography)`
  display: block;
  text-transform: uppercase;
`;

const InstagramHandleWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(50)};
`;

const Icon = styled(InstagramLogo)`
  width: ${rem(28)};
  margin-left: ${rem(15)};
`;

const TrainerAuthPage = ({ match: { params } }: Props) => {
  const { slug } = params;
  const { config } = useConfig();
  const userId = useAppState((state) => state.auth.userId);
  const { routes } = useRoutes();
  const logger = useLogger('on-tv:trainer');

  const { durationRange, showAllDurations, labelFromLessonDuration, onFilter } = useDurationFilter();

  useDismissEvent();

  const { loading, error, data } = useQuery<TrainerDetailsQuery, TrainerDetailsQueryVariables>(TRAINER_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      lessonConditions: showAllDurations
        ? lessonsQueryVariables.lessonConditions
        : {
          ...lessonsQueryVariables.lessonConditions,
          durationRange: [durationRange],
        },
      lessonFirst: config.MAX_LESSONS_IN_GRID,
      slug,
      userId,
    },
    onError: (e) => logger.error('TrainerDetailsQuery error', { error: e, slug }),
  });

  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  const lessons = useMemo(() => transformLessonData(data?.trainerBySlug?.lessons?.edges), [data]);

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.trainerBySlug || !slug || !userId) {
    logger.error('An error occured loading a trainer', { slug });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const { firstname, lastname, bio, avatarImage: { url: avatarUrl }, instagramHandle } = data.trainerBySlug;

  return (
    <ClassGrid
      pageTitle={<Title variant="paragon">{`${firstname} ${lastname}`}</Title>}
      pageTitleUpperCased
      subtitle={instagramHandle ? (
        <InstagramHandleWrapper>
          <Typography variant="pica">{instagramHandle}</Typography>
          <Icon />
        </InstagramHandleWrapper>
      ) : null}
      gridTitle="Classes"
      description={bio}
      featureImage={avatarUrl}
      selectedFilter={durationRange}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({ duration, route: routes.TRAINER, params: { slug } })}
          autofocus={!lessons.length}
          resetScroll
        />
      )}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            favourited={lesson.favourited}
            autofocus={index === 0}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

const TrainerNoAuthPage = ({ match: { params } }: Props) => {
  const { slug } = params;
  const { config } = useConfig();
  const { routes } = useRoutes();
  const logger = useLogger('on-tv:trainer');

  const { durationRange, showAllDurations, labelFromLessonDuration, onFilter } = useDurationFilter();

  useDismissEvent();

  const {
    loading,
    error,
    data,
  } = useQuery<TrainerDetailsNoAuthQuery, TrainerDetailsNoAuthQueryVariables>(TRAINER_NO_AUTH_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      lessonConditions: showAllDurations
        ? {}
        : {
          durationRange: [durationRange],
        },
      lessonFirst: config.MAX_LESSONS_IN_GRID,
      slug,
    },
    onError: (e) => logger.error('TrainerDetailsQuery error', { error: e, slug }),
  });

  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  const lessons = useMemo(() => transformLessonData(data?.trainerBySlug?.lessons?.edges), [data]);

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.trainerBySlug || !slug) {
    logger.error('An error occured loading a trainer', { slug });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const { firstname, lastname, bio, avatarImage: { url: avatarUrl }, instagramHandle } = data.trainerBySlug;

  return (
    <ClassGrid
      pageTitle={<Title variant="paragon">{`${firstname} ${lastname}`}</Title>}
      pageTitleUpperCased
      subtitle={instagramHandle ? (
        <InstagramHandleWrapper>
          <Typography variant="pica">{instagramHandle}</Typography>
          <Icon />
        </InstagramHandleWrapper>
      ) : null}
      gridTitle="Classes"
      description={bio}
      featureImage={avatarUrl}
      selectedFilter={durationRange}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({ duration, route: routes.TRAINER, params: { slug } })}
          autofocus={!lessons.length}
          resetScroll
        />
      )}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            favourited={lesson.favourited}
            autofocus={index === 0}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

const TrainerPage = (props: Props) => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <TrainerAuthPage {...props} /> : <TrainerNoAuthPage {...props} />;
};

TrainerPage.menu = true;

export default TrainerPage;

import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const ChromecastIcon = ({ className = '' }: IconProps) => (
  <Svg className={className} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="white"
      d="M8.0004 21C8.0004 16.5888 4.4112 12.9996 0 12.9996V11.0004C5.5236 11.0004 9.9996 15.4764 9.9996 21H8.0004ZM0 18.9996C1.1052 18.9996 2.0004 19.8948 2.0004 21H0V18.9996ZM6 21C6 17.6856 3.3144 15 0 15V17.0004C2.2056 17.0004 3.9996 18.7944 3.9996 21H6ZM3.6 9.42598C7.2732 10.6968 10.152 13.6644 11.286 17.4H20.4V6.59998H3.6V9.42598ZM0 3V8.7468H2.0004V5.0004H21.9996V18.9996H11.8128V21H24V3H0Z"
    />
  </Svg>
);

export default ChromecastIcon;

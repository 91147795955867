import React from 'react';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import FullPageTwoAnswer from 'ui/components/layouts/full-page-two-answer';
import closeApp from 'app/on-tv/utils/close-app';

export { useLeaveFiitModal } from 'app/on-tv/organisms/leave-fiit-modal/hooks';
type LeaveFiitScreenProps = {
  dismiss: () => void,
};

const LeaveFiitScreen = ({ dismiss }: LeaveFiitScreenProps) => {
  useDismissEvent(dismiss, true);

  const quitOnClick = () => {
    closeApp();
  };

  return (
    <FullPageTwoAnswer
      question="Are you sure you want to leave Fiit?"
      optionOne="No"
      optionOneClick={dismiss}
      optionTwo="Yes"
      optionTwoClick={quitOnClick}
      autofocusButton="one"
    />
  );
};

export default LeaveFiitScreen;

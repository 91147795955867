import useConfig from 'config';

const shakaNotSupportedApps: string[] = ['comcast', 'shaw', 'rogers', 'virgin', 'rdk'];

const useVideoPlayerSupport = () => {
  const { config } = useConfig();

  // Opt out of using shaka, as we want apps to use it if they can
  const shouldUseShaka = !shakaNotSupportedApps.includes(config.APP_TYPE);

  return {
    isShakaSupported: shouldUseShaka,
    shouldUseDashFallback: !shouldUseShaka,
  };
};

export default useVideoPlayerSupport;

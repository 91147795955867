import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import useConfig from 'app/on-tv/config-provider';
import LeaveFiitModal, { useLeaveFiitModal } from 'app/on-tv/organisms/leave-fiit-modal';
import {
  BrowseQuery,
  BrowseQueryVariables,
  BrowseNoAuthQuery,
  BrowseNoAuthQueryVariables,
  CollectionOrder,
  TrainingPlanStatus,
  WorkoutState,
} from 'app/on-tv/types/graphql';

import {
  Browse as BROWSE_PAGE_QUERY,
  BrowseNoAuth as BROWSE_PAGE_NO_AUTH_QUERY,
} from 'app/on-tv/pages/browse/browse.gql';
import { useAppState } from 'state';
import useLogger from 'app/hooks/use-logger';

import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

import ClassCard from 'ui/components/molecules/class-card';
import { lessonsToRailNodes, collectionToStudioRailNodes, getHeroCardContent } from 'app/on-tv/pages/browse/helpers';

import useRoutes, { url } from 'utils/use-routes';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';
import { RouteComponentProps } from 'react-router';
import TrainingPlanCard from 'ui/components/molecules/training-plan-card';
import ScrollingBrowse from 'ui/components/molecules/scrolling-browse';
import closeApp from 'app/on-tv/utils/close-app';

const BrowseAuthPage = ({ userId }: { userId: number }) => {
  const { config } = useConfig();
  const { routes } = useRoutes();
  const logger = useLogger('on-tv:browse-page');

  const {
    showLeaveFiitModal,
    dismissLeaveFiitModal,
  } = useLeaveFiitModal(config);

  const {
    loading,
    error,
    data,
  } = useQuery<BrowseQuery, BrowseQueryVariables>(BROWSE_PAGE_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      recommendedLessonsFirst: 5,
      recommendedPlansFirst: 5,
      userId,
      trendingRailLength: config.BROWSE_PAGE_TRENDING_RAIL_LENGTH,
      newReleasesRailLength: 10,
      collectionRailsSlug: config.BROWSE_PAGE_COLLECTION_RAILS_SLUG,
      collectionRailsCount: 5,
      collectionFirst: 15,
      collectionOffset: 0,
      collectionOrderBy: CollectionOrder.UPDATED_AT,
      collectionSlug: config.BROWSE_PAGE_COLLECTION_BROWSE_COLLECTIONS_SLUG,
    },
    onCompleted: (result) => {
      if (!result?.railsCollectionList) {
        logger.warn(`No collection list found for browse page rails: ${config.BROWSE_PAGE_COLLECTION_RAILS_SLUG}`);
      }
      if (!result?.railsCollectionList?.collections.edges.length) {
        logger.warn(`No collections in list for browse page rails: ${config.BROWSE_PAGE_COLLECTION_RAILS_SLUG}`);
      }
    },
    onError: (e) => logger.error('Browse graphQL error', { error: e }),
  });

  const activeTrainingPlan = data?.user?.activeTrainingPlan;
  const activeTrainingPlanWeek = activeTrainingPlan?.byWeek
    .find((week) => week.weekNumber === activeTrainingPlan?.currentWeek);

  const activeTrainingPlanLessons = activeTrainingPlanWeek?.schedule
    .filter(({ workout }) => !(workout?.state === WorkoutState.COMPLETED)) || [];

  const activeTrainingPlanRail = activeTrainingPlanLessons
    .map(({ id, lesson }, i) => lessonsToRailNodes(routes.LESSON_DETAILS, false)({ node: lesson, scheduleId: id }, i));

  const activePlanWeekEnd = activeTrainingPlanWeek?.periodEnd;
  const activePlanWeek = activeTrainingPlan?.currentWeek;
  const activePlanDuration = activeTrainingPlan?.byWeek.length;
  const activePlanName = activeTrainingPlan?.trainingPlanTemplate.trainingPlan.name;
  const activePlanImage = activeTrainingPlan?.trainingPlanTemplate.trainingPlan.images?.cover;
  const showCompletedPlanWeekHero = Boolean(
    activeTrainingPlan && activeTrainingPlanWeek && activeTrainingPlanLessons.length === 0,
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error onDismiss="action" onDismissAction={closeApp} />;
  }

  if (showLeaveFiitModal) {
    return <LeaveFiitModal dismiss={dismissLeaveFiitModal} />;
  }

  const studioRailData = data.railsCollectionList?.collections.edges || [];
  const studioRail = studioRailData.map(collectionToStudioRailNodes(routes.COLLECTION));
  const trendingRail = data.trendingRail.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const newReleasesRail = data.newReleasesRail.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const recommendedLessons = data?.user?.recommendedLessons?.edges?.map(
    lessonsToRailNodes(routes.LESSON_DETAILS)
  ) || [];

  const browseCollectionsRail = data.collectionsRail?.collections?.edges.map(({ node }) => (
    <ClassCard
      key={node.id}
      backgroundImage={node.image?.url}
      size="small"
      name={node.name}
      to={url({ route: routes.COLLECTION, params: { slug: node.slug } })}
    />
  )) || [];

  const trainingPlans = data.user?.trainingPlanRail.edges?.map(({ node }, i) => (
    <TrainingPlanCard
      key={i}
      name={node.name}
      durationWeeks={parseInt(node.durationWeeks, 10)}
      backgroundImage={node.images?.cover?.url}
      to={url({ route: routes.TRAINING_PLAN, params: { slug: node.slug } })}
      locked={!node.permissions.start}
      equipment={node.equipment}
      inTesting={node.status === TrainingPlanStatus.TESTING}
    />
  )) || [];

  return (
    <>
      <ScrollingBrowse
        activeTrainingPlanLessons={activeTrainingPlanRail}
        recommendedLessons={recommendedLessons}
        studioRail={studioRail}
        browseCollectionsRail={browseCollectionsRail}
        trendingRail={trendingRail}
        newReleasesRail={newReleasesRail}
        showCompletedPlanWeekHero={showCompletedPlanWeekHero}
        activePlanWeekEnd={activePlanWeekEnd}
        activePlanWeek={activePlanWeek}
        activePlanDuration={activePlanDuration}
        activePlanName={activePlanName}
        activePlanImage={activePlanImage}
        trainingPlans={trainingPlans}
        heroCardContent={getHeroCardContent(
          routes,
          data.auth.features.promoteUpgrade.value,
        )}
      />
    </>
  );
};

export type LessonItem = {
  id: number,
  mainImageUrl?: string,
  duration: string,
  trainerName: string,
  name: string,
  locked?: boolean,
  completed: boolean
};

export type Item = {
  id: string,
  mainImageUrl: string,
  name: string,
  slug: string
};

const BrowseNoAuthPage = () => {
  const { config } = useConfig();
  const { routes } = useRoutes();
  const logger = useLogger('on-tv:browse-page');

  const {
    showLeaveFiitModal,
    dismissLeaveFiitModal,
  } = useLeaveFiitModal(config);

  const {
    loading,
    error,
    data,
  } = useQuery<BrowseNoAuthQuery, BrowseNoAuthQueryVariables>(BROWSE_PAGE_NO_AUTH_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      trendingRailLength: config.BROWSE_PAGE_TRENDING_RAIL_LENGTH,
      newReleasesRailLength: 10,
      collectionRailsSlug: config.BROWSE_PAGE_COLLECTION_RAILS_SLUG,
      collectionRailsCount: 5,
      collectionFirst: 15,
      collectionOffset: 0,
      collectionOrderBy: CollectionOrder.UPDATED_AT,
      collectionSlug: config.BROWSE_PAGE_COLLECTION_BROWSE_COLLECTIONS_SLUG,
      trainingPlansFirst: 5,
    },
    onCompleted: (result) => {
      if (!result?.railsCollectionList) {
        logger.warn(`No collection list found for browse page rails: ${config.BROWSE_PAGE_COLLECTION_RAILS_SLUG}`);
      }
      if (!result?.railsCollectionList?.collections.edges.length) {
        logger.warn(`No collections in list for browse page rails: ${config.BROWSE_PAGE_COLLECTION_RAILS_SLUG}`);
      }
    },
    onError: (e) => logger.error('Browse graphQL error', { error: e }),
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error onDismiss="action" onDismissAction={closeApp} />;
  }

  if (showLeaveFiitModal) {
    return <LeaveFiitModal dismiss={dismissLeaveFiitModal} />;
  }

  const studioRailData = data.railsCollectionList?.collections.edges || [];
  const studioRail = studioRailData.map(collectionToStudioRailNodes(routes.COLLECTION));
  const trendingRail = data.trendingRail.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const newReleasesRail = data.newReleasesRail.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];

  const browseCollectionsRail = data.collectionsRail?.collections?.edges.map(({ node }) => (
    <ClassCard
      key={node.id}
      backgroundImage={node.image?.url}
      size="small"
      name={node.name}
      to={url({ route: routes.COLLECTION, params: { slug: node.slug } })}
    />
  )) || [];

  const trainingPlans = data.trainingPlanRail.edges?.map(({ node }, i) => (
    <TrainingPlanCard
      key={i}
      name={node.name}
      durationWeeks={parseInt(node.durationWeeks, 10)}
      backgroundImage={node.images?.cover?.url}
      to={url({ route: routes.TRAINING_PLAN, params: { slug: node.slug } })}
      locked
      equipment={node.equipment}
      inTesting={node.status === TrainingPlanStatus.TESTING}
    />
  )) || [];

  return (
    <>
      <ScrollingBrowse
        activeTrainingPlanLessons={[]}
        recommendedLessons={[]}
        studioRail={studioRail}
        browseCollectionsRail={browseCollectionsRail}
        trendingRail={trendingRail}
        newReleasesRail={newReleasesRail}
        trainingPlans={trainingPlans}
        heroCardContent={getHeroCardContent(
          routes,
          true,
        )}
      />
    </>
  );
};

const BrowsePage = (props: RouteComponentProps) => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <BrowseAuthPage userId={userId} {...props} /> : <BrowseNoAuthPage />;
};

BrowsePage.menu = true;

export default BrowsePage;

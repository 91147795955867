import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFilter = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 24 22" className={className}>
    <path
      d="M0 0l10.8 3.7V22L0 18.5V0zm24 0v18.3L13.2 22V3.5L24 0z"
    />
  </Svg>
);

export default SvgFilter;

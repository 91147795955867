import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';

type Props = {
  children: ReactNode;
  className?: string;
};

const Container = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
    position: relative;
  }

  & > *:not(:first-child)::before {
    content: '';
    width: 2px;
    height: ${rem(30)};
    background-color: grey;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SummaryMetricList = ({ children, className = '' }: Props) => (
  <Container className={className}>
    {children}
  </Container>
);

export default SummaryMetricList;

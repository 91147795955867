import { useQuery } from '@apollo/react-hooks';
import {
  OnTvLessonInstanceQuery,
  OnTvLessonInstanceQueryVariables,
  OnTvPartyQuery,
  OnTvPartyQueryVariables,
} from 'app/on-tv/types/graphql';
import {
  OnTvLessonInstance as LESSON_INSTANCE_QUERY,
  OnTvParty as PARTY_QUERY,
} from 'app/on-tv/pages/lesson-instance/query.gql';
import useLogger from 'app/hooks/use-logger';

export const useLessonInstanceQuery = (
  userId: number,
  lessonInstanceId?: string,
  partyId?: string,
) => {
  const logger = useLogger('on-tv:lesson-instance:hooks');

  const {
    data: lessonInstanceData,
    loading: lessonInstanceLoading,
    error: lessonInstanceError,
  } = useQuery<OnTvLessonInstanceQuery, OnTvLessonInstanceQueryVariables>(LESSON_INSTANCE_QUERY, {
    skip: !lessonInstanceId,
    variables: {
      userId,
      lessonInstanceId: lessonInstanceId as string,
    },
    onError: (e) => logger.error('OnTvLessonInstanceQuery error', { error: e }),
  });

  const {
    data: partyData,
    loading: partyLoading,
    error: partyError,
    subscribeToMore,
  } = useQuery<OnTvPartyQuery, OnTvPartyQueryVariables>(PARTY_QUERY, {
    skip: !partyId,
    variables: {
      userId,
      partyId: partyId as string,
    },
    onError: (e) => logger.error('OnTvPartyQuery error', { error: e }),
  });

  const loading = partyLoading || lessonInstanceLoading;
  const error = partyError || lessonInstanceError;
  const userLobbyEntry = partyData?.partyById?.lobbyEntries.edges[0];
  const trackerConnected = userLobbyEntry?.node.trackerConnected;
  const equipmentConnected = userLobbyEntry?.node.equipmentConnected;

  return {
    data: {
      lessonInstance: partyData?.partyById?.lessonInstance || lessonInstanceData?.lessonInstance,
      partyLessonMediaId: partyData?.partyById?.lessonMedia.id,
      user: partyData?.partyById?.user || lessonInstanceData?.user,
      trackerConnected,
      equipmentConnected,
    },
    loading,
    error,
    subscribeToMore,
  };
};

import React, { useCallback, useState, useEffect } from 'react';

import Casting from 'ui/components/layouts/casting';
import ClassDetailsBasicInformation from 'ui/components/molecules/class-details-basic-information';
import { LessonDurationRange } from 'types/graphql';
import useLogger from 'app/hooks/use-logger';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { CastingSplashLessonQuery, CastingSplashLessonQueryVariables } from 'app/on-tv/types/graphql';
import { CastingSplashLesson as LESSON_QUERY } from 'app/on-tv/pages/casting-splash/lesson-info.gql';
import { RouteComponentProps } from 'react-router-dom';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useRoutes from 'utils/use-routes';
import useCastMessageListener from 'app/on-tv/hooks/use-cast-message-listener';
import { CreatePinMutation as CREATE_REMOTE_APP_PIN } from 'app/on-tv/pages/pin/create-pin.gql';
import useWebSocket from 'utils/use-web-socket';
import useConfig from 'app/on-tv/config-provider';
import useServices from 'services';
import { useAppState, useDispatch } from 'state';
import { login } from 'actions/auth';
import { Message } from 'reconnecting-websocket';
import useSendChromecastMessage from 'app/on-tv/utils/send-chromecast-message';
import PartyHandler from 'app/on-tv/pages/casting-splash/party-handler';
import ErrorBoundary from 'ui/components/utils/error-boundary';

export type Trainers = Array<{
  firstname: string,
}>;

export type Props = {
  name: string,
  trainers: Trainers,
  duration?: LessonDurationRange,
  backgroundImage?: string,
};

const CastingSplashScreen = (props: RouteComponentProps<{ id: string }>) => {
  const id = parseInt(props.match.params.id, 10);
  const logger = useLogger('casting-splash-page');
  const { routes, redirect } = useRoutes();
  const { config } = useConfig();
  const userId = useAppState((state) => state.auth.userId);
  const [connectionId, setConnectionId] = useState('');
  const [connectionFailed, setConnectionFailed] = useState(false);
  const services = useServices();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { sendChromecastMessage } = useSendChromecastMessage();

  useCastMessageListener('LESSON_ID', (data: any) => {
    redirect({
      route: routes.CAST_SPLASH,
      params: { id: data.lessonId },
      replaceStack: true,
    });
  });

  const { loading, error, data } = useQuery<CastingSplashLessonQuery, CastingSplashLessonQueryVariables>(LESSON_QUERY, {
    skip: !id,
    variables: { id },
    onError: (e) => logger.error('Casting Lesson graphQL error', { error: e }),
  });

  useEffect(() => {
    async function getPin() {
      try {
        const { data: pinData } = await client.mutate({
          mutation: CREATE_REMOTE_APP_PIN,
          variables: {
            clientId: config.AUTH_CLIENT_ID,
            connectionId,
          },
        });
        sendChromecastMessage({ type: 'AUTH_PIN', data: { pin: pinData.createRemoteAppPin.pin } });
      } catch (err) {
        logger.error(err, { connectionId });
      }
    }

    if (connectionId !== '' && !Number.isNaN(id)) {
      getPin();
    }

    return () => undefined;
  }, [client, connectionId, config.REMOTE_LOGIN_PIN_BUFFER, config.AUTH_CLIENT_ID, logger, sendChromecastMessage, id]);

  const onWebsocketMessage = useCallback(
    async (socketData: { message: string, code: string, connectionId: string }) => {
      switch (socketData.message) {
        case 'CONNECTION_CREATED': {
          setConnectionId(socketData.connectionId);
          break;
        }
        case 'PIN_VERIFIED': {
          try {
            const tokens = await services.auth.loginWithCode({
              code: socketData.code,
            });
            dispatch(login(tokens));
            sendChromecastMessage({ type: 'AUTH_PIN_SUCCESS' });
          } catch (err) {
            logger.error(err);
          }
          break;
        }
        default: {
          logger.warn('Unknown message from web socket');
        }
      }
    },
    [dispatch, services.auth, logger, sendChromecastMessage],
  );

  const onWebsocketOpen = useCallback((sendMessage: (message: Message) => void) => {
    sendMessage(JSON.stringify({
      action: 'REQUEST_CONNECT',
    }));
  }, []);

  const onRetriesExceeded = useCallback(() => {
    setConnectionFailed(true);
    setConnectionId('');
    logger.warn('Too many errors from websocket');
  }, [setConnectionFailed, setConnectionId, logger]);

  useWebSocket({
    url: config.REMOTE_LOGIN_WEBSOCKET_URL,
    maxRetries: 5,
    onMessage: onWebsocketMessage,
    onOpen: onWebsocketOpen,
    onRetriesExceeded,
  });

  if (!id || loading) {
    return <LoadingScreen />;
  }

  const lesson = data?.lessonById;

  if (error || !lesson) {
    return (
      <ErrorOverlay
        error={error || new Error('Lesson not found.')}
        showBackButton={false}
        message="Something went wrong, please try casting again."
      />
    );
  }

  if (connectionFailed) {
    return (
      <ErrorOverlay
        error={new Error('An error occured while connecting to phone.')}
        showBackButton={false}
        message="Something went wrong, please try casting again."
      />
    );
  }

  return (
    <ErrorBoundary errorText="Something went wrong, please try casting again.">
      <Casting
        backgroundUrl={lesson.mainImage?.url}
        footerText="It&apos;s all set here. Start the class on your mobile."
      >
        <ClassDetailsBasicInformation
          name={lesson.name}
          trainers={lesson.trainers}
          duration={lesson.durationRange}
        />
        {userId && <PartyHandler userId={userId} lessonId={lesson.id} />}
      </Casting>
    </ErrorBoundary>
  );
};

export default CastingSplashScreen;

import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import useConfig from 'app/on-tv/config-provider';

import { rem } from 'ui/helpers';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';
import { BulletedList, ListItem } from 'ui/components/atoms/bulleted-list';
import { useAmazonBilling } from 'app/on-tv/utils/android';
import { useAppState, useDispatch } from 'state';
import useServices from 'services';
import { logout, refreshTokens } from 'actions/auth';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useLogger from 'app/hooks/use-logger';
import fetch from 'utils/fetch';
import urlSearchParams from 'utils/url-search-params';
import PurchaseCard from 'ui/components/molecules/purchase-card';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';

import {
  allSubscriptionPlans as ALL_SUBSCRIPTION_PLANS,
} from 'app/on-tv/organisms/premium-upsell/subscription-plans.gql';

import {
  SubscriptionPlanOrder,
  AllSubscriptionPlansQuery,
  AllSubscriptionPlansQueryVariables,
  PaymentProvider,
} from 'app/on-tv/types/graphql';

import { SpatialNavParent } from 'utils/spatial-nav';

export type Props = {
  showFullscreen?: boolean,
  navigateButton: ReactNode,
};

const Wrapper = styled.div<{ image: string }>`
  display: flex;
  flex-direction: row;
  padding: ${rem(120)} ${rem(150)} ${rem(50)};
  height: 100vh;
  width: 100vh;
  flex: 1 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url(${({ image }) => image});
    z-index: -1;
  }
`;

const UpsellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmazonUpsellButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(100)};
`;

const LogoHolder = styled.div`
  margin-bottom: ${rem(100)};
`;

const Headline = styled(Typography)`
  width: ${rem(1000)};
  margin-bottom: ${rem(7)};
`;

const InfoList = styled(BulletedList)`
  margin-left: ${rem(30)};
`;

const FreeTrialLink = styled.div`
  margin-top: ${rem(30)};
`;

const StyledLink = styled(Typography)`
  margin: 0 0.25rem;
`;

const PaddedMessage = styled(Typography)`
  margin-bottom: ${rem(30)};
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(72)};
  width: ${rem(500)};
`;

const regex = new RegExp('(\\D*)([0-9,.]+)(\\D*)');

const PremiumUpsell = () => {
  const { config } = useConfig();
  const dispatch = useDispatch();
  const services = useServices();
  const history = useHistory();
  const client = useApolloClient();
  const { billingAvailable, startPaymentFlow, getSkuProductData } = useAmazonBilling();
  const logger = useLogger('on-tv:premium-upsell');

  // We explicitly set these to prevent redundant updating and rerendering
  const userId = useAppState((state) => state.auth.userId);
  const refreshToken = useAppState((state) => state.auth.refreshToken);
  const authorization = useAppState((state) => state.auth.authorization);

  const onTokenRefresh = useCallback((payload) => dispatch(refreshTokens(payload)), [dispatch]);
  const onTokenRefreshError = useCallback(() => dispatch(logout()), [dispatch]);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [receiptVerificationError, setReceiptVerificationError] = useState(false);
  const [iapProductSkus, setIapProductSkus] = useState<any[]>([]);
  const [amazonSkus, setAmazonSkus] = useState<any[]>([]);

  useEffect(() => {
    const iapProductDataHandler = (e: CustomEvent) => {
      const { productData = null } = e?.detail || {};
      if (!productData) {
        logger.warn('IAP returned no product data for amazon billing');
        return;
      }
      logger.debug('Product data received from IAP', { productData });
      const skus = Object.values(productData);
      setIapProductSkus(skus);
    };

    const iapSuccessEventHandler = async (e: CustomEvent) => {
      logger.info('IAP was successful');
      const { detail } = e;
      setPaymentLoading(true);

      try {
        const { receiptId, amazonUserId } = detail;

        if (!receiptId || !amazonUserId) {
          logger.error('Either receipt or amazon user id was not returned from iap', { receiptId, amazonUserId });
          setPaymentLoading(false);
          return null;
        }

        logger.debug('Triggering RVS for amazon', { receiptId, amazonUserId });

        const receiptUrl = config.AMAZON_RVS_HANDLER_URL;

        const params = urlSearchParams({
          receipt_id: receiptId,
          amazon_user_id: amazonUserId,
          user_id: userId,
        });

        const { body } = await fetch(receiptUrl, {
          body: params,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            authorization,
          },
          method: 'POST',
        });

        logger.debug('Completed RVS Flow for Amazon', { receiptId, amazonUserId });

        if (!refreshToken) {
          // edge-case to keep strict typing on auth.refreshToken
          setPaymentLoading(false);
          onTokenRefreshError();
          return null;
        }

        // Delay token refresh 1s to allow sub to update
        setTimeout(() => {
          services.auth.refreshToken({ refreshToken })
            .then(async (response) => {
              logger.debug('refreshed tokens for a successful amazon billing receipt');
              onTokenRefresh(response);
              await client.resetStore();
              history.goBack();
            })
            .catch((err) => {
              // TODO: What do we want to show on error?
              logger.error('Could not refresh users token after successful amazon billing payment', { err });
              setPaymentLoading(false);
              onTokenRefreshError();
            });
        }, 1000);

        return body;
      } catch (err) {
        logger.error(err);
        setPaymentLoading(false);
        setReceiptVerificationError(true);
        return null;
      }
    };

    const iapErrorEventHandler = async (e: CustomEvent) => {
      logger.info('IAP was not successful');
      const { detail } = e;
      logger.warn('Amazon IAP issue - either the user cancelled or IAP errored', { detail });
      setPaymentLoading(false);
      // Do not breach amazon ux guidelines by showing an error here
      return null;
    };

    const androidIapProviderNotSupportedEventHandler = async (e: CustomEvent) => {
      const { detail } = e;
      logger.error('Android Provider Not Supported IAP error', { detail });
      return null;
    };

    const iapProductDataErrorHandler = async (e: CustomEvent) => {
      const { detail } = e;
      logger.error('IAP Product Data error', { detail });
      return null;
    };

    window.addEventListener('AmazonIapPaymentSuccess', iapSuccessEventHandler);
    window.addEventListener('AmazonIapPaymentError', iapErrorEventHandler);
    window.addEventListener('AndroidIapProviderNotSupported', androidIapProviderNotSupportedEventHandler);
    window.addEventListener('AmazonIapProductData', iapProductDataHandler);
    window.addEventListener('AmazonIapProductDataError', iapProductDataErrorHandler);
    return () => {
      window.removeEventListener('AmazonIapPaymentSuccess', iapSuccessEventHandler);
      window.removeEventListener('AmazonIapPaymentError', iapErrorEventHandler);
      window.removeEventListener('AndroidIapProviderNotSupported', androidIapProviderNotSupportedEventHandler);
      window.removeEventListener('AmazonIapProductData', iapProductDataHandler);
      window.removeEventListener('AmazonIapProductDataError', iapProductDataErrorHandler);
    };
  }, [
    config,
    userId,
    authorization,
    refreshToken,
    onTokenRefresh,
    onTokenRefreshError,
    services.auth,
    client,
    history,
    logger,
  ]);

  // Async fetch triggering ProductData listener
  useEffect(() => {
    if (amazonSkus.length) {
      // billing available and subscription plans available
      getSkuProductData(amazonSkus);
    }
  }, [getSkuProductData, amazonSkus]);

  const { data, loading } = useQuery<AllSubscriptionPlansQuery, AllSubscriptionPlansQueryVariables>(
    ALL_SUBSCRIPTION_PLANS, {
      variables: {
        length: 20,
        orderBy: SubscriptionPlanOrder.CYCLE,
        subscriptionPlanConditions: {
          amazonActive: [true],
          paymentProvider: PaymentProvider.AMAZON,
        },
      },
      skip: !billingAvailable,
      onCompleted: (completedData) => {
        if (completedData?.allSubscriptionPlans?.totalCount === 0) {
          logger.warn('API Returned no sub plans for amazon billing');
        }
        logger.debug('Product data received from API', { completedData });

        const amazonSkuList = completedData?.allSubscriptionPlans?.edges?.map(({ node }) => node.amazonSku) || [];
        setAmazonSkus(amazonSkuList);
      },
      onError: (err) => logger.error('Failed to get sub plans for amazon billing', { err }),
    },
  );

  if (loading || paymentLoading) {
    return <LoadingScreen />;
  }

  if (receiptVerificationError) {
    const message = 'You’re so close, but there was an issue finalising your subscription. ' +
    'Please contact support via chat on help.fiit.tv and we’ll fix it quickly for you.';
    return (
      <ErrorOverlay
        error={receiptVerificationError}
        onDismiss="back"
        message={message}
      />
    );
  }

  const verifiedSkus = data?.allSubscriptionPlans?.edges
    // filter skus by iap
    ?.filter(({ node }: any) => iapProductSkus?.some((item) => item.sku === node.amazonSku))
    // add pricing from iap, split into currency symbol and number for further processing
    ?.map(({ node }: any) => {
      const { price } = iapProductSkus.find((item) => item.sku === node.amazonSku);
      if (!price) {
        logger.warn('Failed to find sku', { iapProductSkus, node });
      }
      const [, prefix, value, suffix] = price.match(regex);
      if (!value) {
        logger.warn('Failed to parse currency', { price, node });
      }
      return {
        ...node,
        price: { numeric: Number(value.replace(',', '.')), prefix, suffix },
      };
    })
    // filter where no pricing information
    ?.filter(({ price: { numeric, suffix, prefix } }: any) => numeric && (suffix || prefix));

  const amazonUpsellButtons = verifiedSkus?.map((subPlan, i) => (
    <PurchaseCard
      dataTest={`purchase-card-${subPlan.name}`}
      key={i}
      autofocus={i === 0} // priority is the top plan
      name={subPlan.name}
      price={subPlan.price}
      cycle={subPlan.cycle}
      onClick={() => startPaymentFlow(subPlan.amazonSku)}
    />
  )) || [];

  return (
    <Wrapper image={
      billingAvailable
        ? 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/13151206/BG-Blur.png'
        : 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/14153333/BG.png'
      }
    >
      <SpatialNavParent layout="horizontal">
        <UpsellWrapper data-test="premium-upsell-content">
          <LogoHolder><Logo height="60px" /></LogoHolder>
          <Headline variant="paragon">{billingAvailable ? 'Start your free trial' : 'Unlock Fiit Now'}</Headline>
          <PaddedMessage color="beckersKnop">Choose a membership. Cancel anytime.</PaddedMessage>
          <InfoList color="beckersKnop">
            <ListItem>600+ workouts with world-class trainers</ListItem>
            <ListItem>HIIT, strength training, yoga, Pilates &amp; more</ListItem>
            <ListItem>Bodyweight and equipment workouts</ListItem>
            <ListItem>Suitable for entry level to advanced</ListItem>
            <ListItem>Includes mobile app to take Fiit on the go</ListItem>
            <ListItem>Customer support 7 days a week</ListItem>
          </InfoList>
          <FreeTrialLink>
            <Typography color="beckersKnop">Got a question? Visit</Typography>
            <StyledLink variant="pica">{config.TV_BASE_LINK}</StyledLink>
          </FreeTrialLink>
          <StyledButton
            onClick={() => history.goBack()}
            iconOnly
            label="Back"
            labelPosition="right"
            icon={<ChevronLeft />}
            autofocus={!amazonUpsellButtons.length}
          />
        </UpsellWrapper>
        <AmazonUpsellButtonWrapper data-test="premium-upsell-button-wrapper">
          <SpatialNavParent layout="vertical">
            {amazonUpsellButtons}
          </SpatialNavParent>
        </AmazonUpsellButtonWrapper>
      </SpatialNavParent>
    </Wrapper>
  );
};

export default PremiumUpsell;

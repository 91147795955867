import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import useRoutes from 'utils/use-routes';
import {
  FinishWorkoutMutation,
  FinishWorkoutMutationVariables,
  Lesson,
  WorkoutOutcome,
  WorkoutState,
} from 'app/on-tv/types/graphql';
import { removeStoredWorkout } from 'actions/locally-stored-workout';
import { FinishWorkout as FINISH_WORKOUT } from 'app/on-tv/pages/lesson-instance/finish-workout.gql';
import { useDispatch } from 'state';
import { FormattedStatsEvent } from 'app/on-tv/pages/lesson-instance';
import { analytics } from 'utils/analytics';
import useConfig from 'app/on-tv/config-provider';

type FinishWorkoutArgs = {
  workoutId: number,
  currentTime?: number,
  duration?: number,
  lastStatsEvent?: FormattedStatsEvent,
};

const getOutcome = (currentTime: number, duration: number) => {
  if (!currentTime || !duration) {
    return WorkoutOutcome.CANCELLED;
  }

  const COMPLETION_PERCENTAGE = 0.92;
  const QUIT_PERCENTAGE = 0.15;

  const percentCompleted = currentTime / duration;
  if (percentCompleted > COMPLETION_PERCENTAGE) {
    return WorkoutOutcome.COMPLETED;
  }
  if (percentCompleted > QUIT_PERCENTAGE) {
    return WorkoutOutcome.QUIT;
  }
  return WorkoutOutcome.CANCELLED;
};

type UseOnCompletedWorkoutArgs = {
  workoutId: number,
  userId: number,
  shouldNavigate: boolean,
  lastStatsEvent?: FormattedStatsEvent,
};

export const useOnCompletedWorkout = ({
  workoutId,
  userId,
  shouldNavigate = false,
  lastStatsEvent,
}: UseOnCompletedWorkoutArgs) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { routes, redirect } = useRoutes();
  const { config } = useConfig();

  const onCompleted = (outcome?: WorkoutState, lesson?: Pick<Lesson, 'id'|'name'>) => {
    dispatch(removeStoredWorkout(userId));
    analytics.track('WorkoutFinished', {
      lesson: lesson?.id,
      lessonName: lesson?.name,
    });

    if (shouldNavigate) {
      const route = config.APP_TYPE === 'chromecast' ? routes.CAST_SUMMARY : routes.WORKOUT_SUMMARY;
      if (outcome === WorkoutState.CANCELLED || !route) {
        history.goBack();
      } else {
        // This is fine until remote sessions supports more than one person. Still works just depends on UX
        redirect({
          route,
          params: { id: workoutId },
          replaceStack: true,
          ...(lastStatsEvent ? { state: { stats: lastStatsEvent } } : null),
        });
      }
    }
  };

  return {
    onCompleted,
  };
};

export const useFinishWorkout = (
  userId: number,
  args: FinishWorkoutArgs,
  shouldNavigate: boolean = false,
) => {
  const { workoutId, currentTime = 0, duration = 0, lastStatsEvent } = args;

  const { onCompleted } = useOnCompletedWorkout({ workoutId, userId, shouldNavigate, lastStatsEvent });

  const [finishWorkoutMutation, { error }] = useMutation<FinishWorkoutMutation, FinishWorkoutMutationVariables>(
    FINISH_WORKOUT,
    {
      onCompleted: ({ finishWorkoutById }) => {
        onCompleted(finishWorkoutById?.state, finishWorkoutById?.lesson);
      },
    },
  );

  const finishWorkout = useCallback(() => {
    finishWorkoutMutation({
      variables: {
        id: workoutId,
        relatedLessonsFirst: 5,
        userId,
        workoutState: [WorkoutState.COMPLETED],
        input: {
          outcome: getOutcome(currentTime, duration),
          clientMetricsSummary: {
            time: {
              workout: {
                sum: 1,
              },
            },
          },
        },
      },
    });
  }, [currentTime, duration, finishWorkoutMutation, workoutId, userId]);

  return {
    finishWorkout,
    error,
  };
};

export default useFinishWorkout;

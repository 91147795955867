import React, { useEffect } from 'react';
import Typography from 'ui/components/atoms/typography';
import styled from 'styled-components';
import {
  Image,
} from 'app/on-tv/types/graphql';

import useRoutes from 'utils/use-routes';

type Props = {
  backgroundImage?: Pick<Image, 'url'> | null
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  z-index: 3;
`;

const TextCentered = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TrainingPlanStartedContent = ({ backgroundImage }: Props) => (
  <Wrapper
    style={{
      backgroundImage: backgroundImage?.url ? `url(${backgroundImage.url}` : '',
    }}
  >
    <TextCentered variant="paragon">Training Plan Started</TextCentered>
  </Wrapper>
);

const TrainingPlanStarted = ({ backgroundImage }: Props) => {
  const { routes, push } = useRoutes();
  useEffect(() => {
    setTimeout(() => push({ route: routes.ACTIVE_TRAINING_PLAN, replaceStack: true }), 3000);
  }, [push, routes.ACTIVE_TRAINING_PLAN]);

  return (
    <TrainingPlanStartedContent backgroundImage={backgroundImage} />
  );
};

export default TrainingPlanStarted;

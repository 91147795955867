import React, { useRef } from 'react';
import styled from 'styled-components';
import RailCard from 'ui/components/atoms/rail-card';
import { useSpatialTarget } from 'utils/spatial-nav';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

export type Props = {
  name: string,
  price: Price,
  cycle: number,
  onClick?: () => void,
  focused?: boolean,
  autofocus?: boolean,
  promote?: boolean,
  dataTest?: string,
  headerText?: string,
  headerHighlight?: boolean,
  smallVMargin?: boolean,
  focusDisabled?: boolean,
  roundDp?: number,
  description?: string,
};

const PurchaseCard = styled(RailCard)<{smallVMargin?: boolean}>`
  margin: ${({ smallVMargin }) => (smallVMargin ? `${rem(10)} ${rem(17.5)}` : `${rem(10)}`)};
  flex-grow: 0;
  opacity: ${({ focus }) => (focus ? '1' : '0.8')};
`;

const CardContent = styled.button<{focused: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border-radius: 0.067rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${rem(30)} ${rem(30)};
  color: ${({ theme, focused }) => (focused ? theme.colors.fiitBlue : theme.colors.black)};
  background-color: ${({ theme }) => theme.colors.white};
`;

const PromoteWrapper = styled.span<{focused: boolean}>`
  background-color: ${({ theme }) => theme.colors.fiitBlue};
  padding: ${rem(20)};
  line-height: 0;
  position: absolute;
  transform: ${({ focused }) => (focused ? 'translate(110%, -100%)' : 'translate(106%, -88%)')};
`;

const PromoteText = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  opacity: 80%;
`;

const Title = styled(Typography)`
  opacity: 80%;
`;

const Description = styled(Typography)`
  opacity: 50%;
`;

const PriceLine = styled.span`
  margin-top: ${rem(20)};
`;

type BuildDescProps = {
  cycle: number,
  price: Price,
};

const buildFullDescription = ({ price: { prefix, suffix, numeric }, cycle }: BuildDescProps) => {
  const length = cycle > 1 ? `${cycle} months` : 'month';
  return `Pay ${prefix}${numeric}${suffix} every ${length}`;
};

type Price = {
  numeric: number,
  suffix: string,
  prefix: string,
};

type BuildMonthlyProps = {
  cycle: number,
  price: Price,
  roundDp: number,
};

const buildMonthlyAmount = ({ price: { prefix, suffix, numeric }, cycle, roundDp } : BuildMonthlyProps) => {
  const monthlyCost = (numeric / cycle).toFixed(roundDp);
  return `${prefix}${monthlyCost}${suffix}`;
};

const Purchase = ({
  name,
  price,
  cycle,
  promote = false,
  autofocus = false,
  dataTest,
  onClick,
  headerText,
  headerHighlight = false,
  smallVMargin = false,
  focusDisabled = false,
  roundDp = 2, // default 2dp to match Amazon
  description,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
    disabled: focusDisabled,
  });

  return (
    <PurchaseCard
      size="upsell"
      focus={focused}
      boxSize="upsell"
      data-test={dataTest}
      headerText={headerText}
      headerHighlight={headerHighlight}
      smallVMargin={smallVMargin}
    >
      <CardContent
        focused={focused}
        ref={elRef}
        onClick={onClick}
        tabIndex={0}
      >
        {promote && (
          <PromoteWrapper focused={focused}>
            <PromoteText variant="pica">Best Value</PromoteText>
          </PromoteWrapper>
        )}
        <Title variant="double-pica">{name}</Title>
        <PriceLine>
          <Typography
            data-test="purchase-card-per-month"
            variant="paragon"
          >
            {buildMonthlyAmount({ price, cycle, roundDp })}
          </Typography>
          <Typography variant="pica">/month</Typography>
        </PriceLine>
        <Description
          data-test="purchase-card-description"
          size="s"
        >
          {description || buildFullDescription({ price, cycle })}
        </Description>
      </CardContent>
    </PurchaseCard>
  );
};

export default Purchase;

import React from 'react';
import Button from 'ui/components/atoms/button';
import Play from 'ui/components/atoms/icons/play';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import useRoutes from 'utils/use-routes';
import DebugLayout from 'ui/components/layouts/debug';
import { rem } from 'ui/helpers';

const PaddedButton = styled(Button)`
  margin-bottom: 20px;
`;

const Title = styled(Typography)`
  margin-bottom: ${rem(50)};
`;

const DebugPage = () => {
  const { routes, push } = useRoutes();

  return (
    <DebugLayout>
      <Title>Debug Page</Title>
      <PaddedButton
        label="Config"
        variant="cta"
        autofocus
        onClick={() => push({ route: routes.DEBUG_CONFIG })}
        icon={<Play />}
      />
    </DebugLayout>
  );
};

DebugPage.menu = true;

export default DebugPage;

import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import ordinalIndicator from 'ordinal/indicator';
import styled from 'styled-components';

import { useAppState } from 'state';
import Typography from 'ui/components/atoms/typography';
import WorkoutSummaryLayout from 'ui/components/layouts/workout-summary';
import SummaryMetricList from 'ui/components/atoms/summary-metric-list';
import PostClassMetric from 'ui/components/atoms/workout-metric';
import NthIcon from 'ui/components/atoms/icons/nth-icon';
import {
  WorkoutByIdQuery,
  WorkoutByIdQueryVariables,
  WorkoutState,
  UserTrainingPlanState,
  LessonStatus,
} from 'app/on-tv/types/graphql';
import { WorkoutById as WORKOUT_QUERY } from 'app/on-tv/pages/workout-summary/workout-by-id.gql';
import Idle from 'app/pages/idle-or-error';
import Rail from 'ui/components/molecules/rail';
import ClassCard from 'ui/components/molecules/class-card';
import { rem } from 'ui/helpers';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import { trainerNames } from 'ui/components/utils/trainer-names';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import useLogger from 'app/hooks/use-logger';
import useRoutes, { url } from 'utils/use-routes';
import FavouriteButton from 'ui/components/molecules/favourite-button';
import TrainingPlanSummary from 'app/on-tv/organisms/training-plan-summary';
import SvgKcal from 'ui/components/atoms/icons/kcal';
import SvgBpm from 'ui/components/atoms/icons/bpm';
import SvgFiitPoints from 'ui/components/atoms/icons/points';
import SvgWeightIcon from 'ui/components/atoms/icons/weight';
import { FormattedStatsEvent } from 'app/on-tv/pages/lesson-instance';
import { hasStatsDiscrepancy, roundStatMetricForDisplay } from 'utils/stats';

type ComponentProps = {
  id: number,
  lastStatsPacket?: FormattedStatsEvent,
};

export type Props = RouteComponentProps<{ id: string }>;

const StyledMetricList = styled(SummaryMetricList)`
  margin: ${rem(80)} 0;
`;

const FavouriteButtonContainer = styled.div`
  max-width: 32%;
  margin-top: 3rem;
`;

const StyledIcon = styled.div`
  width: 1rem;
  height: 1rem;
`;

const WorkoutSummary = ({ id, lastStatsPacket }: ComponentProps) => {
  const userId = useAppState((state) => state.auth.userId);
  const logger = useLogger('on-tv:workout-summary');
  const { loading, error, data } = useQuery<WorkoutByIdQuery, WorkoutByIdQueryVariables>(WORKOUT_QUERY, {
    variables: {
      id,
      relatedLessonsFirst: 6,
      userId,
      workoutState: [WorkoutState.COMPLETED],
    },
    onError: (e) => logger.error('WorkoutByIdQuery', { error: e, workoutId: id }),
    onCompleted: ({ workoutById }) => {
      if (!lastStatsPacket || !workoutById?.summary) {
        return;
      }

      const { kcal, reps, score } = workoutById?.summary;
      const formattedSummary = {
        kcal: kcal?.sum,
        reps: reps?.sum,
        score: score?.sum,
      };

      const hasMinorDiscrepancy = hasStatsDiscrepancy(formattedSummary, lastStatsPacket, true);
      const hasMajorDiscrepancy = hasStatsDiscrepancy(formattedSummary, lastStatsPacket);

      if (hasMajorDiscrepancy) {
        logger.error('Major discrepancy between in class and summary stats', {
          inClass: lastStatsPacket,
          summary: formattedSummary,
        });
      } else if (hasMinorDiscrepancy) {
        logger.warn('Minor discrepancy between in class and summary stats', {
          inClass: lastStatsPacket,
          summary: formattedSummary,
        });
      }
    },
  });

  useDismissEvent();
  const { routes } = useRoutes();

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !data || !data.workoutById) {
    if (!routes.BROWSE?.acceptedPaths) {
      logger.error('Attempted to redirect to undefined route BROWSE.');
    }

    return <Idle redirectUrl={routes.BROWSE?.acceptedPaths[0]} />;
  }

  const { position, lesson, summary, userTrainingPlanSchedule } = data.workoutById;

  if (
    userId &&
    userTrainingPlanSchedule &&
    userTrainingPlanSchedule.userTrainingPlan.state === UserTrainingPlanState.COMPLETE
  ) {
    return (
      <TrainingPlanSummary trainingPlanData={userTrainingPlanSchedule.userTrainingPlan} userId={userId} />
    );
  }

  // sometimes the API returns the same lesson as the one just completed, until this is fixed
  // here [JS-4364] we return an extra class and filter out the already completed class if it's there.
  const relatedLessons = lesson.relatedLessons?.edges?.filter(({ node }) => node.id !== lesson.id);

  return (
    <WorkoutSummaryLayout image={lesson?.mainImage?.url}>
      <Typography
        color="white"
        variant="paragon"
        as="h1"
      >
        {lesson.name}
      </Typography>
      <Typography as="h2" variant="body-copy" weight="bold">{trainerNames(lesson.trainers, 'with')}</Typography>
      {userId && lesson.id && (
        <FavouriteButtonContainer>
          <FavouriteButton
            userId={userId}
            lessonId={lesson.id}
            favourited={lesson.favouritedByUser}
            autofocus={!lesson.favouritedByUser}
            showLabel
          />
        </FavouriteButtonContainer>
      )}
      <StyledMetricList>
        {summary?.score?.sum && (
          <PostClassMetric
            name="FIIT POINTS"
            value={roundStatMetricForDisplay('POINTS', summary.score.sum)}
            icon={<SvgFiitPoints color="white" />}
          />
        )}
        {summary?.reps?.sum && (
          <PostClassMetric
            name="REPS"
            value={roundStatMetricForDisplay('REPS', summary.reps.sum)}
            icon={<SvgWeightIcon color="white" />}
          />
        )}
        {/* Time is racey due to new stats system requiring a time value, TODO: Fix me */}
        {/* {summary?.time?.sum && (
          <PostClassMetric
            name="MINUTES"
            value={roundStatMetricForDisplay('MINUTES', summary.time.sum / 60)}
            icon={<SvgWatch color="white" />}
          />
        )} */}
        {summary?.kcal?.sum && (
          <PostClassMetric
            name="KCAL"
            value={roundStatMetricForDisplay('KCAL', summary.kcal.sum)}
            icon={<StyledIcon as={SvgKcal} color="white" />}
          />
        )}
        {summary?.bpm?.avg && (
          <PostClassMetric
            name="AVG BPM"
            value={roundStatMetricForDisplay('BPM', summary.bpm.avg)}
            icon={<StyledIcon as={SvgBpm} color="white" />}
          />
        )}
        {position && (
          <PostClassMetric
            name="Class"
            value={<>{position}<sup>{ordinalIndicator(position)}</sup></>}
            icon={<NthIcon color="white" />}
          />
        )}
      </StyledMetricList>
      {relatedLessons?.length && (
        <Rail title="Try next">
          {relatedLessons.map(({ node }, i) => (
            <ClassCard
              autofocus={lesson.favouritedByUser && i === 0}
              key={i}
              size="large"
              backgroundImage={node.mainImage?.url}
              duration={node.durationRange}
              trainer={trainerNames(node.trainers)}
              name={node.name}
              to={url({ route: routes.LESSON_DETAILS, params: { id: node.id } })}
              locked={!node.permissionsWithReasons.start.value}
              completed={!!node.workouts.totalCount}
              kettlebells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL')}
              dumbbells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS')}
              inTesting={node.status === LessonStatus.TESTING}
            />
          ))}
        </Rail>
      )}
    </WorkoutSummaryLayout>
  );
};

const WorkoutSummaryPage = ({ match: { params }, location: { state } }: Props) => {
  const workoutId = parseInt(params.id, 10);

  const { routes } = useRoutes();
  const logger = useLogger('on-tv:workout-summary:page');

  if (!workoutId) {
    if (!routes.BROWSE?.acceptedPaths) {
      logger.error('Attempted to redirect to undefined route BROWSE.');
    }

    return <Idle redirectUrl={routes.BROWSE?.acceptedPaths[0]} />;
  }

  return <WorkoutSummary id={workoutId} lastStatsPacket={state?.stats} />;
};

WorkoutSummaryPage.menu = true;

export default WorkoutSummaryPage;

import React, { useRef } from 'react';
import styled from 'styled-components';
import { Image, LessonDifficulty } from 'types/graphql';
import { rem } from 'ui/helpers';
import { format } from 'date-fns';
import { useSpatialTarget } from 'utils/spatial-nav';
import { Link } from 'react-router-dom';

import Typography from 'ui/components/atoms/typography';
import WatchIcon from 'ui/components/atoms/icons/watch';
import KcalIcon from 'ui/components/atoms/icons/kcal';
import BpmIcon from 'ui/components/atoms/icons/bpm';
import ClassIcon from 'ui/components/atoms/icons/classes';
import CardFocus from 'ui/components/atoms/card-focus';
import TestingBadge from 'ui/components/atoms/testing-badge';
import { roundStatMetricForDisplay } from 'utils/stats';

type MetricsProps = {
  timeInSeconds?: number | null,
  kcal?: number | null,
  avgBpm?: number | null,
  planClassesTaken?: number,
  noOfClasses?: number,
};

type Props = {
  heading: string,
  name: string,
  level: LessonDifficulty,
  startedAt: string,
  backgroundImage?: Pick<Image, 'url' | 'hexColour'> | null,
  planClassesTaken?: number,
  autofocus?: boolean,
  onFocus?: (target: HTMLElement | null) => void,
  to?: string,
  showMetrics?: boolean,
  currentWeek?: number,
  noOfWeeks?: number,
  noOfClasses?: number,
  inTesting?: boolean,
} & MetricsProps;

const Wrapper = styled(CardFocus)`
  margin-right: ${rem(40)};
  margin-top: ${rem(80)};
  height: ${rem(370)};
  max-height: ${rem(370)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: baseline;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  border-radius: ${rem(2)};
  padding: ${({ theme }) => theme.spacing.l};
  position: relative;
`;

const DateWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
`;

const CurrentPlan = styled(Typography)`
  margin-bottom: 0.5rem;
  opacity: 0.75;
`;

const TransparentTypography = styled(Typography)<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity || 0.5};
`;

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  position: absolute;
  bottom: 0.5rem;

  & :first-child {
    padding-left: 0;
  }
`;

const IconWrapper = styled.div`
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.3rem;
`;

const StyledIcon = styled.div<{ width?: string, height?: string }>`
  height: ${({ height }) => (height ? `${height}rem` : '1rem')};
  width: ${({ width }) => (width ? `${width}rem` : '1rem')};
`;

const Divider = styled.hr<{ small?: boolean }>`
  flex: 1;
  margin: auto;
  border: 1px solid currentColor;
  height: ${({ small }) => (small ? '0.25rem' : '1rem')};
`;

const CardLink = styled(Link)`
  display: block;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const IconWithTextContainer = styled.div`
  margin: 0 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }
`;

const StyledTypography = styled(Typography)`
  margin: 0.1rem 0 0 0.2rem;
`;

const StyledTestingBadge = styled(TestingBadge)`
  top: 0.5rem;
  right: 0.5rem;
`;

const LEVEL_MAP: Record<LessonDifficulty, string> = {
  [LessonDifficulty.ENTRY_LEVEL]: 'Entry Level',
  [LessonDifficulty.BEGINNER]: 'Beginner',
  [LessonDifficulty.INTERMEDIATE]: 'Intermediate',
  [LessonDifficulty.ADVANCED]: 'Advanced',
  [LessonDifficulty.ALL_LEVELS]: 'All levels',
};

const Metrics = ({ timeInSeconds, kcal, avgBpm, planClassesTaken, noOfClasses } : MetricsProps) => (
  <MetricsContainer>
    {!!timeInSeconds && (
      <>
        <MetricWrapper>
          <IconWrapper>
            <StyledIcon as={WatchIcon} />
          </IconWrapper>
          <Typography weight="bold">{roundStatMetricForDisplay('MINUTES', timeInSeconds / 60)}</Typography>
          <TransparentTypography variant="pica">Minutes</TransparentTypography>
        </MetricWrapper>
        <Divider />
      </>
    )}
    {kcal && (
      <>
        <MetricWrapper>
          <IconWrapper>
            <StyledIcon color="white" as={KcalIcon} />
          </IconWrapper>
          <Typography weight="bold">{roundStatMetricForDisplay('KCAL', kcal)}</Typography>
          <TransparentTypography variant="pica">Kcal</TransparentTypography>
        </MetricWrapper>
        <Divider />
      </>
    )}
    {avgBpm && (
      <>
        <MetricWrapper>
          <IconWrapper>
            <StyledIcon color="white" as={BpmIcon} />
          </IconWrapper>
          <Typography weight="bold">{roundStatMetricForDisplay('BPM', avgBpm)}</Typography>
          <TransparentTypography variant="pica">Avg BPM</TransparentTypography>
        </MetricWrapper>
        <Divider />
      </>
    )}
    {!!planClassesTaken && !!noOfClasses && (
      <MetricWrapper>
        <IconWrapper>
          <StyledIcon as={ClassIcon} />
        </IconWrapper>
        <Typography weight="bold">{planClassesTaken}/{noOfClasses}</Typography>
        <TransparentTypography variant="pica">Classes</TransparentTypography>
      </MetricWrapper>
    )}
  </MetricsContainer>
);

const HeroTrainingPlanCard = ({
  name,
  level,
  startedAt,
  backgroundImage,
  timeInSeconds,
  kcal,
  avgBpm,
  planClassesTaken,
  autofocus = false,
  to,
  onFocus: onItemFocused,
  showMetrics = false,
  currentWeek,
  noOfWeeks,
  noOfClasses,
  heading,
  inTesting,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
    onFocus: (el) => {
      if (focused && onItemFocused) {
        onItemFocused(el);
      }
    },
    disabled: !to,
  });

  const HeroCard = (
    <Wrapper focus={focused} data-test="training-plan-hero">
      {inTesting && <StyledTestingBadge />}
      <Content
        ref={elRef}
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage.url})`
            : 'none',
          backgroundColor: backgroundImage?.hexColour || '',
        }}
      >
        <CurrentPlan weight="bold">{heading}</CurrentPlan>
        <Typography variant="paragon">{name}</Typography>
        <Typography variant="pica">{LEVEL_MAP[level]}</Typography>
        {showMetrics ? (
          <Metrics
            timeInSeconds={timeInSeconds}
            kcal={kcal}
            avgBpm={avgBpm}
            planClassesTaken={planClassesTaken}
            noOfClasses={noOfClasses}
          />
        ) : (
          <FlexContainer>
            <IconWithTextContainer>
              <StyledIcon as={WatchIcon} width="0.5" height="0.5" />
              <StyledTypography variant="pica" size="xxs">Weeks {currentWeek}/{noOfWeeks}</StyledTypography>
            </IconWithTextContainer>
            <Divider small />
            <IconWithTextContainer>
              <StyledIcon as={ClassIcon} width="0.5" height="0.5" />
              <StyledTypography variant="pica" size="xxs">Classes {planClassesTaken}/{noOfClasses}</StyledTypography>
            </IconWithTextContainer>
          </FlexContainer>
        )}
        <DateWrapper>
          <TransparentTypography opacity={0.8}>Started on: </TransparentTypography>
          <Typography variant="pica">{format(new Date(startedAt), 'dd EEE LLL yyyy')}</Typography>
        </DateWrapper>
      </Content>
    </Wrapper>
  );

  if (!to) {
    return HeroCard;
  }

  return (
    <CardLink ref={elRef} to={to}>
      {HeroCard}
    </CardLink>
  );
};

export default HeroTrainingPlanCard;

import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAppState } from 'state';
import {
  TrainingPlans as TRAINING_PLANS,
  TrainingPlansNoAuth as TRAINING_PLANS_NO_AUTH,
} from 'app/on-tv/pages/training-plans/training-plans.gql';
import styled from 'styled-components';
import HeroCarousel from 'ui/components/molecules/hero-carousel';
import ActiveHeroCard from 'ui/components/molecules/hero-training-plan-card';
import { SpatialNavParent } from 'utils/spatial-nav';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import {
  TrainingPlansQuery,
  TrainingPlansQueryVariables,
  TrainingPlansNoAuthQuery,
  TrainingPlansNoAuthQueryVariables,
  Image,
  Workout,
  UserTrainingPlanSchedule,
  WorkoutState,
  UserFitnessGoal,
  TrainingPlanStatus,
} from 'app/on-tv/types/graphql';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import TrainingPlanCard from 'ui/components/molecules/training-plan-card';
import Rail from 'ui/components/molecules/rail';

import useLogger from 'app/hooks/use-logger';
import useRoutes, { url } from 'utils/use-routes';
import { RouteConfig } from 'app/on-tv/routes';

import HeroCard from 'ui/components/molecules/hero-training-plan-details-card';
import { usePageScroll } from 'utils/use-page-scroll';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
`;

const StyledRail = styled(Rail)`
  padding-top: ${({ theme }) => theme.spacing.l};
`;

type RecommendedEdgesToHeroSlides = {
  node: {
    name: string,
    slug: string,
    tagline?: string | null,
    durationWeeks: string,
    equipment: boolean,
    images: {
      cover: Pick<Image, 'url' | 'hexColour'> | null,
    } | null,
    status: TrainingPlanStatus,
  },
};

const recommendedEdgesToHeroSlides = (route?: RouteConfig) => (
  ({ node: { tagline, durationWeeks, equipment, images, slug, name, status } }:
  RecommendedEdgesToHeroSlides, i: number) => (
    <HeroCard
      key={i}
      name={name}
      title="Most recommended to you"
      description={tagline}
      durationWeeks={parseInt(durationWeeks, 10)}
      equipment={equipment ? [] : null}
      backgroundImage={images?.cover}
      to={url({ route, params: { slug } })}
      autofocus={i === 0}
      inTesting={status === TrainingPlanStatus.TESTING}
    />
  )
);

type Schedule = Pick<UserTrainingPlanSchedule, 'id'> & { workout: Pick<Workout, 'id' | 'state'> | null };

const getClassesTaken = (schedule: Schedule[]) => (
  schedule.reduce((acc: number, { workout }) => (workout?.state === WorkoutState.COMPLETED ? acc + 1 : acc), 0)
);

type TrainingPlanAuthProps = {
  userId: number,
};

const TrainingPlansAuthPage = ({ userId }: TrainingPlanAuthProps) => {
  const logger = useLogger('on-tv:training-plans-page');
  const { routes } = useRoutes();
  const { loading, error, data } = useQuery<TrainingPlansQuery, TrainingPlansQueryVariables>(TRAINING_PLANS, {
    variables: {
      ...lessonsQueryVariables,
      userId,
    },
    onError: (e) => logger.error('TrainingPlans graphQL error', { error: e }),
  });

  useDismissEvent();

  const pageScroll = usePageScroll();
  const onFocus = useCallback((element: HTMLElement | null) => {
    if (element) {
      pageScroll(element.offsetTop);
    }
  }, [pageScroll]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const activeTrainingPlan = data?.user?.activeTrainingPlan;

  const trainingPlanRails = [
    { title: 'Lose Weight', plans: data?.loseWeight, goal: UserFitnessGoal.LOSE_WEIGHT },
    { title: 'Build Muscle', plans: data?.buildMuscle, goal: UserFitnessGoal.BUILD_MUSCLE },
    { title: 'Flexibility', plans: data?.flexibility, goal: UserFitnessGoal.FLEXIBILITY },
    { title: 'General', plans: data?.general, goal: UserFitnessGoal.GENERAL },
    { title: 'Postnatal', plans: data?.postnatal, goal: UserFitnessGoal.POSTNATAL },
  ];

  // order the rails by having the rail matching the UserFitnessGoal first
  const trainingGoal = data?.user?.trainingGoal?.id || UserFitnessGoal.GENERAL;
  trainingPlanRails.sort(({ goal }) => (goal === trainingGoal ? -1 : 0));

  const recommendedHeroCards = data?.user?.recommendedTrainingPlans?.edges?.map(
    recommendedEdgesToHeroSlides(routes.TRAINING_PLAN)
  ) || [];

  return (
    <SpatialNavParent layout="vertical">
      <Wrapper>
        {!activeTrainingPlan ? (
          <HeroCarousel onFocus={onFocus}>
            {recommendedHeroCards}
          </HeroCarousel>
        ) : (
          <ActiveHeroCard
            heading="Your current plan"
            autofocus
            to={url({ route: routes.ACTIVE_TRAINING_PLAN })}
            name={activeTrainingPlan.trainingPlanTemplate.trainingPlan.name}
            startedAt={activeTrainingPlan.startedAt}
            level={activeTrainingPlan.trainingPlanTemplate.level}
            backgroundImage={activeTrainingPlan.trainingPlanTemplate.trainingPlan.images?.cover}
            noOfWeeks={activeTrainingPlan.byWeek.length}
            currentWeek={activeTrainingPlan.currentWeek || 1}
            planClassesTaken={activeTrainingPlan.byWeek.reduce((acc: number, { schedule }) => (
              acc + getClassesTaken(schedule)), 0)}
            noOfClasses={activeTrainingPlan.byWeek.reduce((acc: number, { schedule }) => acc + schedule.length, 0)}
            onFocus={onFocus}
          />
        )}
        {trainingPlanRails.map((rail) => {
          const railCards = rail.plans?.edges?.map(({ node }) => (
            <TrainingPlanCard
              key={node.id}
              backgroundImage={node.images?.cover?.url}
              name={node.name}
              durationWeeks={parseInt(node.durationWeeks, 10)}
              equipment={node.equipment}
              locked={!node.permissions.start}
              to={url({ route: routes.TRAINING_PLAN, params: { slug: node.slug } })}
              inTesting={node.status === TrainingPlanStatus.TESTING}
            />
          )) || [];
          return (
            <StyledRail
              onFocus={onFocus}
              title={rail.title}
              key={rail.title}
            >
              { railCards}
            </StyledRail>
          );
        })}
      </Wrapper>
    </SpatialNavParent>
  );
};

const TrainingPlansNoAuthPage = () => {
  const logger = useLogger('on-tv:training-plans-page');
  const { routes } = useRoutes();
  const {
    loading,
    error,
    data,
  } = useQuery<TrainingPlansNoAuthQuery, TrainingPlansNoAuthQueryVariables>(TRAINING_PLANS_NO_AUTH, {
    onError: (e) => logger.error('TrainingPlans graphQL error', { error: e }),
  });

  useDismissEvent();

  const pageScroll = usePageScroll();
  const onFocus = useCallback((element: HTMLElement | null) => {
    if (element) {
      pageScroll(element.offsetTop);
    }
  }, [pageScroll]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const trainingPlanRails = [
    { title: 'Lose Weight', plans: data?.loseWeight, goal: UserFitnessGoal.LOSE_WEIGHT },
    { title: 'Build Muscle', plans: data?.buildMuscle, goal: UserFitnessGoal.BUILD_MUSCLE },
    { title: 'Flexibility', plans: data?.flexibility, goal: UserFitnessGoal.FLEXIBILITY },
    { title: 'General', plans: data?.general, goal: UserFitnessGoal.GENERAL },
    { title: 'Postnatal', plans: data?.postnatal, goal: UserFitnessGoal.POSTNATAL },
  ];

  return (
    <SpatialNavParent layout="vertical">
      <Wrapper>
        {trainingPlanRails.map((rail, railIdx) => {
          const railCards = rail.plans?.edges?.map(({ node }, cardIdx) => (
            <TrainingPlanCard
              key={node.id}
              backgroundImage={node.images?.cover?.url}
              name={node.name}
              durationWeeks={parseInt(node.durationWeeks, 10)}
              equipment={node.equipment}
              locked
              to={url({ route: routes.TRAINING_PLAN, params: { slug: node.slug } })}
              inTesting={node.status === TrainingPlanStatus.TESTING}
              autofocus={railIdx === 0 && cardIdx === 0}
            />
          )) || [];
          return (
            <StyledRail
              onFocus={onFocus}
              title={rail.title}
              key={rail.title}
            >
              { railCards}
            </StyledRail>
          );
        })}
      </Wrapper>
    </SpatialNavParent>
  );
};

const TrainingPlansPage = () => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <TrainingPlansAuthPage userId={userId} /> : <TrainingPlansNoAuthPage />;
};

TrainingPlansPage.menu = true;
export default TrainingPlansPage;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AnimatedPinCountdown from 'ui/components/atoms/animated-icons/pin-countdown';
import Typography from 'ui/components/atoms/typography';

type Props = {
  pinChangeDelay: number,
  className?: string,
  pin: string,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled(Typography)`
  opacity: 0.8;
`;

const AnimatedPinCountdownContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const TextContainer = styled.div`
  display: block;
  line-height: 0.8rem;
`;

const getReadableTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  const minuteString = minutes < 10 ? `0${minutes}` : minutes;
  const secondString = seconds < 10 ? `0${seconds}` : seconds;
  return `${minuteString}:${secondString}`;
};

const PinCountdown = ({ pin, pinChangeDelay, className = '' }: Props) => {
  const [timeLeft, setTimeLeft] = useState(pinChangeDelay);
  const [restartAnimation, setRestartAnimation] = useState(false);

  useEffect(() => {
    setTimeLeft(pinChangeDelay);
    const interval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [pin, pinChangeDelay]);

  useEffect(() => {
    if (timeLeft === 0) {
      setRestartAnimation(true);
      return;
    }

    setRestartAnimation(false);
  }, [timeLeft]);

  return (
    <Wrapper className={className}>
      <AnimatedPinCountdownContainer>
        <AnimatedPinCountdown restart={restartAnimation} />
      </AnimatedPinCountdownContainer>
      <TextContainer>
        <Typography color="beckersKnop">Refreshes in</Typography>
        <br />
        <Time size="s" variant="pica">{getReadableTime(timeLeft)}</Time>
      </TextContainer>
    </Wrapper>
  );
};

export default PinCountdown;

import styled from 'styled-components';
import { ThemeColors } from 'ui/theme';

export const NumberedList = styled.ol<{ color?: ThemeColors }>`
  list-style: decimal;
  color: ${({ color, theme }) => (color ? theme.colors[color] : 'inherit')};
`;

export const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

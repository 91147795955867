import { LessonDifficulty, UserActivityLevel } from 'types/graphql';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';

export const ACTIVITY_LEVEL_TO_PLAN_LEVEL: Record<UserActivityLevel, LessonDifficulty> = {
  [UserActivityLevel.VERY_LOW]: LessonDifficulty.ENTRY_LEVEL,
  [UserActivityLevel.LOW]: LessonDifficulty.BEGINNER,
  [UserActivityLevel.MEDIUM]: LessonDifficulty.INTERMEDIATE,
  [UserActivityLevel.HIGH]: LessonDifficulty.ADVANCED,
  [UserActivityLevel.VERY_HIGH]: LessonDifficulty.ADVANCED,
};

type Template = {
  schedule: Array<{
    lesson: {
      equipmentLevel: Array<{
        equipment: { shortDisplay: string },
      }>,
    },
  }>,
};

export const getPlanEquipment = (template: Template) => {
  const equipmentFromLessons = template.schedule.map(({ lesson }) => (
    lesson.equipmentLevel?.map(({ equipment: eq }) => eq.shortDisplay) || []
  ));

  // array.flat() isn't supported on the sky box so using lodash flatten instead
  return uniq(flatten(equipmentFromLessons));
};

import React, { ReactNode } from 'react';
import styled from 'styled-components';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Typography from 'ui/components/atoms/typography';
import ChromecastIcon from 'ui/components/atoms/icons/chromecast';

type Props = {
  backgroundUrl?: string,
  children: ReactNode,
  footerText: string,
};

const Holder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Overlay = styled(FullPageOverlay)`
  z-index: 1;
`;

const CastInstructions = styled(Typography)`
  position: absolute;
  bottom: 5vh;
`;

const StyledChromecastIcon = styled(ChromecastIcon)`
  vertical-align: -20%;
  margin-right: 0.5rem;
`;

const Casting = ({ backgroundUrl, children, footerText }: Props) => (
  <Overlay layout="vertical" backgroundImage={backgroundUrl}>
    <Holder>
      {children}
    </Holder>
    <CastInstructions color="white" variant="pica">
      <StyledChromecastIcon />
      {footerText}
    </CastInstructions>
  </Overlay>
);

export default Casting;

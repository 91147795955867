import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import Button from 'ui/components/atoms/button';
import HeroCard from 'ui/components/molecules/hero-training-plan-details-card';
import Play from 'ui/components/atoms/icons/play';
import Typography from 'ui/components/atoms/typography';
import Rail from 'ui/components/molecules/rail';
import { Image, LessonDifficulty } from 'types/graphql';
import FilterBar from 'ui/components/molecules/filter-bar';
import { SpatialNavParent } from 'utils/spatial-nav';
import Scale from 'ui/components/atoms/icons/scale';
import toTitleCase from 'ui/components/utils/title-case';
import { rem } from 'ui/helpers';
import useRoutes from 'utils/use-routes';
import SvgPadlock from 'ui/components/atoms/icons/padlock';
import ExpandableDescription from 'ui/components/atoms/expandable-description';
import { usePageScroll } from 'utils/use-page-scroll';

type Props = {
  name: string,
  backgroundImage?: Pick<Image, 'url' | 'hexColour'> | null,
  durationWeeks: number,
  equipment: string[] | null,
  noOfClasses: number,
  heroDescription?: string | null,
  planDescription?: string | null,
  lessons: ReactNode[],
  levels: Array<{ level: LessonDifficulty }>
  onFilter: (value: string) => void,
  selectedFilter: LessonDifficulty,
  startTrainingPlan: () => void,
  canStartPlan: boolean,
  hasActivePlan: boolean,
  inTesting?: boolean,
};

const Wrapper = styled.div`
  flex: 1;
  margin: ${rem(80)} 0;
`;

const HeroContainer = styled.div`
  margin: 0 ${rem(40)} ${rem(40)} 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledRail = styled(Rail)`
  margin: 1rem 0;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const FilterBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`;

const FilterBarTitle = styled(Typography)`
  padding: 0 1rem 0 0.5rem;
`;

const ScaleIcon = styled(Scale)`
  margin-bottom: 0.2rem;
`;

const StyledExpandableDescription = styled(ExpandableDescription)`
  max-width: 60%;
`;

const TrainingPlanDetails = ({
  name,
  backgroundImage,
  durationWeeks,
  equipment,
  noOfClasses,
  heroDescription,
  planDescription,
  lessons,
  levels,
  onFilter,
  selectedFilter,
  startTrainingPlan,
  canStartPlan,
  hasActivePlan,
  inTesting,
}: Props) => {
  const { routes, redirect } = useRoutes();

  const pageScroll = usePageScroll();
  const onFocus = useCallback((element: HTMLElement | null, reset?: boolean) => {
    if (element) {
      pageScroll(reset ? 0 : element.offsetTop);
    }
  }, [pageScroll]);

  const filterEnums = { ...LessonDifficulty };
  Object.values(LessonDifficulty).forEach((difficulty: LessonDifficulty) => {
    if (!levels.find(({ level }) => level === difficulty)) {
      delete filterEnums[difficulty];
    }
  });

  return (
    <Wrapper>
      <div>
        <HeroContainer>
          <HeroCard
            name={name}
            backgroundImage={backgroundImage}
            durationWeeks={durationWeeks}
            noOfClasses={noOfClasses}
            equipment={equipment}
            description={heroDescription}
            inTesting={inTesting}
          />
        </HeroContainer>
        <SpatialNavParent layout="horizontal" onFocus={(el) => onFocus(el, true)}>
          <ButtonContainer>
            {canStartPlan ? (
              <StyledButton
                label="Start Plan"
                icon={<Play />}
                autofocus={!hasActivePlan}
                onClick={startTrainingPlan}
                disabled={hasActivePlan}
              />
            ) : (
              <StyledButton
                label="Unlock Fiit"
                autofocus
                icon={<SvgPadlock />}
                onClick={() => redirect({ route: routes.PREMIUM_UPSELL })}
              />
            )}
            <Button
              label="Back to plans"
              autofocus={hasActivePlan}
              onClick={() => redirect({ route: routes.TRAINING_PLANS })}
            />
          </ButtonContainer>
        </SpatialNavParent>
      </div>
      {planDescription && (
        <StyledExpandableDescription description={planDescription} title="What to expect" onFocus={onFocus} />
      )}
      <FilterBarContainer>
        <ScaleIcon />
        <FilterBarTitle variant="pica" color="beckersKnop">Training plan level</FilterBarTitle>
        <FilterBar
          enums={filterEnums}
          disableAllFilter
          labelFromEnum={toTitleCase}
          selected={selectedFilter}
          onSelect={onFilter}
        />
      </FilterBarContainer>
      <Typography variant="double-pica">Coming up in week one</Typography>
      <StyledRail>
        {lessons}
      </StyledRail>
      {canStartPlan ? (
        <StyledButton
          label="Start Plan"
          icon={<Play />}
          onClick={startTrainingPlan}
          disabled={hasActivePlan}
        />
      ) : (
        <StyledButton
          label="Unlock Fiit"
          icon={<SvgPadlock />}
          onClick={() => redirect({ route: routes.PREMIUM_UPSELL })}
        />
      )}
    </Wrapper>
  );
};

export default TrainingPlanDetails;

import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgPremiumIcon = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <defs>
      <path
        id="premium_svg__a"
        d="M0 4l6.464 2.586L12 4l5.541 2.586L24 4l-2.769 16H2.769z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="premium_svg__b" fill="currentColor">
        <use xlinkHref="#premium_svg__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#premium_svg__a" />
      <g fill="currentColor" mask="url(#premium_svg__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </Svg>
);

export default SvgPremiumIcon;

import React, { ReactNode, SetStateAction, Dispatch, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Image, LessonDifficulty } from 'types/graphql';

import HeroTrainingPlanCard from 'ui/components/molecules/hero-training-plan-card';
import TabBar from 'ui/components/molecules/tab-bar';
import TrainingPlanMetrics from 'ui/components/molecules/training-plan-metrics';
import Button from 'ui/components/atoms/button';
import Rail from 'ui/components/molecules/rail';
import Typography from 'ui/components/atoms/typography';
import FullPageTwoAnswer from 'ui/components/layouts/full-page-two-answer';
import { rem } from 'ui/helpers';
import { usePageScroll } from 'utils/use-page-scroll';

const Wrapper = styled.div`
  flex: 1;
  margin: ${rem(80)} 0;
`;

const HeroContainer = styled.div`
  margin-bottom: 2rem;
`;

const Container = styled.div`
  margin-bottom: 1rem;
`;

const TabBarContainer = styled.div`
  margin: 0 ${rem(45)} 1rem ${rem(5)};
`;

const DescriptionContainer = styled.div`
  max-width: 70%;
  margin-bottom: 2rem;
`;

const StyledRail = styled(Rail)`
  margin-bottom: 2rem;
`;

type Props = {
  name: string,
  level: LessonDifficulty,
  currentWeek: number,
  selectedWeek: number,
  durationWeeks: number,
  startedAt: string,
  weekStartedAt: string,
  weekEndsAt: string,
  kcal?: number,
  avgBpm?: number,
  planClassesTaken?: number,
  timeInSeconds?: number | null,
  backgroundImage?: Pick<Image, 'url'> | null,
  classesLeft: number,
  description?: string | null,
  lessons: ReactNode[],
  toggleWeek: Dispatch<SetStateAction<number | null>>,
  cancelTrainingPlan: () => void,
  noOfClasses: number,
  inTesting?: boolean,
};

const ActiveTrainingPlan = ({
  name,
  level,
  currentWeek,
  selectedWeek,
  durationWeeks,
  startedAt,
  weekStartedAt,
  weekEndsAt,
  kcal,
  avgBpm,
  planClassesTaken,
  timeInSeconds,
  backgroundImage,
  classesLeft,
  description,
  lessons,
  toggleWeek,
  cancelTrainingPlan,
  noOfClasses,
  inTesting,
}: Props) => {
  const [showEndPlanModal, setShowEndPlanModal] = useState(false);

  const pageScroll = usePageScroll();
  const onFocus = useCallback((element: HTMLElement | null) => {
    if (element) {
      pageScroll(element.offsetTop);
    }
  }, [pageScroll]);

  const toggleModal = useCallback(() => {
    setShowEndPlanModal((prev) => !prev);
  }, []);

  const tabs = Array.from({ length: durationWeeks }).map((_, i) => ({
    selected: i === selectedWeek - 1,
    name: `Week ${i + 1}`,
  }));

  if (showEndPlanModal) {
    return (
      <FullPageTwoAnswer
        question="Are you sure?"
        optionOne="Continue with current plan"
        optionTwo="End training plan"
        optionOneClick={toggleModal}
        optionTwoClick={cancelTrainingPlan}
        autofocusButton="one"
        useSetWidthButton={false}
        description="If you end the plan now and want to re-join later, you'll have
          to go back to the beginning of the plan."
      />
    );
  }

  return (
    <Wrapper>
      <HeroContainer>
        <HeroTrainingPlanCard
          heading="Your current plan"
          name={name}
          level={level}
          startedAt={startedAt}
          kcal={kcal}
          avgBpm={avgBpm}
          planClassesTaken={planClassesTaken}
          timeInSeconds={timeInSeconds}
          backgroundImage={backgroundImage}
          showMetrics
          noOfClasses={noOfClasses}
          inTesting={inTesting}
        />
      </HeroContainer>
      <TabBarContainer>
        <TabBar
          tabs={tabs}
          trainingPlanCurrentWeek={currentWeek}
          startedAt={weekStartedAt}
          endsAt={weekEndsAt}
          toggleSelectedTab={(i) => toggleWeek(i + 1)}
          autofocus
        />
      </TabBarContainer>
      <Container>
        <TrainingPlanMetrics
          endsAt={weekEndsAt}
          classesLeft={classesLeft}
        />
      </Container>
      <StyledRail data-test="lesson-rail" onFocus={onFocus}>
        {lessons}
      </StyledRail>
      {description && (
        <DescriptionContainer>
          <Typography>{description}</Typography>
        </DescriptionContainer>
      )}
      <Button
        label="End Training Plan"
        onClick={toggleModal}
      />
    </Wrapper>
  );
};

export default ActiveTrainingPlan;

import React, { useState } from 'react';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import useTimeout from 'utils/use-timeout';
import useConfig from 'config';
import useLogger from 'app/hooks/use-logger';

type Props = {
  timeout?: number,
};

const BufferingScreen = ({ timeout }: Props) => {
  const { config } = useConfig();
  const [timedOut, setTimedOut] = useState(false);
  const logger = useLogger('buffering-page');
  useTimeout(() => {
    setTimedOut(true);
  }, (timeout || config.VIDEO_BUFFERING_TIMEOUT) * 1000);

  if (timedOut) {
    logger.warn('Buffering of video timed out, showing error page');
    return (
      <ErrorOverlay error onDismiss="back" />
    );
  }

  return <LoadingOverlay />;
};

export default BufferingScreen;

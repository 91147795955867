import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Switch, MemoryRouter, BrowserRouter, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider, GlobalStyles } from 'ui';
import ErrorBoundary from 'ui/components/utils/error-boundary';

import createApolloClient, { WebsocketClientContext } from 'utils/apollo-client';
import { LoginRoute, ProtectedRoute } from 'app/on-tv/utils/auth-routes';
import routes from 'app/on-tv/routes';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

import useServices, { ServicesProvider } from 'services';

import PageContainer from 'app/on-tv/organisms/page-container';

import { StateProvider, useAppState, useDispatch } from 'state';
import useConfig, { ConfigProvider } from 'app/on-tv/config-provider';
import hideInitialLoadLogo from 'utils/hide-initial-load-logo';
import waitForFont from 'utils/wait-for-font';

import * as Sentry from '@sentry/browser';

import useLogger from 'app/hooks/use-logger';
import { resolvePersistedErrors } from 'utils/logging';
import { logout, refreshTokens } from 'actions/auth';
import { RouteContext } from 'utils/use-routes';
import useInstallation from 'utils/use-installation';
import { SpatialNavProvider } from 'utils/spatial-nav';
import { setNetworkStatus } from 'actions/network-status';
import '@fiit/scroll-behavior-polyfill';
import setupWebSocketMocks from 'app/on-tv/utils/setup-ws-mocks';
import { useCustomKeyboardInit } from 'utils/use-custom-keyboard';
import { LocationDescriptor } from 'history';
import { Analytics, analytics } from 'utils/analytics';
import { ChromecastMessage } from 'app/on-tv/utils/chromecast';

declare global {
  interface Window {
    cast: any;
    analytics: Analytics,
    $badger?: {
      shutdown: () => void,
      dismissLoadingScreen: (args?: { [key: string]: string | number | boolean }) => void,
      errorMetricsHandler: (
        text: string,
        visible: boolean,
        code?: string,
        args?: { [key: string]: string | number | boolean },
      ) => void,
      appActionMetricsHandler: (action: string, args?: { [key: string]: string | number | boolean }) => void,
    };
    tizen?: {
      tvinputdevice: {
        registerKeyBatch: (keys: string[], onSuccess: () => void, onError: (err: any) => void) => void,
      },
    };
  }
}

// If we deep linked into a specific page we need to still land on that page in the memory router
const pageLoadUrl = window.location.pathname;

const Router = ({ appType, children, initialEntries, initialIndex }: any) => (appType === 'web'
  ? <BrowserRouter>{children}</BrowserRouter>
  : (
    <MemoryRouter key={initialEntries.key} initialEntries={initialEntries.paths} initialIndex={initialIndex}>
      {children}
    </MemoryRouter>
  )
);

const PageWrapper = () => {
  const dispatch = useDispatch();
  const { config } = useConfig();
  const services = useServices();
  const logger = useLogger('on-tv:index');

  useCustomKeyboardInit();

  // We explicitly set these to prevent redundant updating and rerendering
  const userId = useAppState((state) => state.auth.userId);
  const refreshToken = useAppState((state) => state.auth.refreshToken);
  const authInitialized = useAppState((state) => state.auth.initialized);
  const chromecastCallbacks = useAppState((state) => state.chromecast.callbacks);

  const setTizenKeys = config.APP_TYPE === 'samsung';

  const onTokenRefresh = useCallback((payload) => dispatch(refreshTokens(payload)), [dispatch]);
  const onTokenRefreshError = useCallback(() => dispatch(logout()), [dispatch]);

  const notLoggedInModeEnabled = useAppState((state) => state.flag.notLoggedInModeEnabled);

  useEffect(() => {
    resolvePersistedErrors(true);
  }, []);

  useEffect(() => {
    if (!setTizenKeys) {
      return () => null;
    }
    const onTizenKeySuccess = () => logger.debug('Registered tizen keys');
    const onTizenKeyError = (err: any) => logger.warn('Failed to register tizen keys', err);
    const interval = setInterval(() => {
      if (window.tizen) {
        window.tizen.tvinputdevice.registerKeyBatch(
          ['MediaPause', 'MediaPlay', 'MediaStop'],
          onTizenKeySuccess,
          onTizenKeyError,
        );
        clearInterval(interval);
      }
    }, config.TIZEN_KEY_RETRY_MS);

    return () => {
      clearInterval(interval);
    };
  }, [config.TIZEN_KEY_RETRY_MS, logger, setTizenKeys]);

  useEffect(() => {
    const updateNetworkStatus = () => {
      const isOnline = navigator.onLine;
      dispatch(setNetworkStatus({ isOnline }));

      if (isOnline) {
        resolvePersistedErrors();
      } else {
        logger.info('window.offline event triggered, network connection lost');
      }
    };

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);
    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, [dispatch, logger]);

  useEffect(() => {
    if (!window.cast?.framework) {
      return;
    }

    const context = window.cast.framework.CastReceiverContext.getInstance();

    const receiverOptions = {
      disableIdleTimeout: true,
    };

    context.addCustomMessageListener(config.CHROMECAST_CHANNEL, ({ data: { type, data } }: ChromecastMessage) => {
      logger.info('Message received', { type, data });

      setTimeout(() => {
        if (Object.keys(chromecastCallbacks).includes(type)) {
          logger.info('Callback triggered for message', { type, data });
          chromecastCallbacks[type](data);
        }
      }, config.CHROMECAST_MESSAGE_DELAY_MS);
    });

    try {
      context.start(receiverOptions);
    } catch (err) {
      logger.info('Failed to start context');
    }
  }, [logger, config.CHROMECAST_CHANNEL, config.CHROMECAST_MESSAGE_DELAY_MS, chromecastCallbacks]);

  const { client, wsClient } = useMemo(() => createApolloClient({
    authService: services.auth,
    uri: config.WORKOUT_API_URL,
    subscriptionUri: config.SUBSCRIPTION_API_URL,
    clientId: config.AUTH_CLIENT_ID,
    version: config.VERSION,
    debug: config.CONNECT_APOLLO_DEV_TOOLS,
    subscriptionsMinimumTokenTTLSeconds: config.SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS,
    onTokenRefresh,
    onTokenRefreshError,
  }), [
    config.CONNECT_APOLLO_DEV_TOOLS,
    config.AUTH_CLIENT_ID,
    config.VERSION,
    config.WORKOUT_API_URL,
    config.SUBSCRIPTION_API_URL,
    config.SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS,
    onTokenRefresh,
    onTokenRefreshError,
    services.auth,
  ]);

  useEffect(() => {
    if (userId) {
      analytics.identify(userId.toString());
    }
  }, [userId]);

  useEffect(() => {
    if (authInitialized) {
      logger.debug('checking for font and removing initial loading logo');
      waitForFont(GlobalStyles.fontFamily)
        .finally(() => {
          hideInitialLoadLogo(config.INITIAL_LOAD_LOGO_ID, config.INITIAL_LOAD_TRANSITION_SECONDS);
        });
    }
  }, [
    authInitialized,
    config.INITIAL_LOAD_LOGO_ID,
    config.INITIAL_LOAD_TRANSITION_SECONDS,
    logger,
  ]);

  useInstallation({ client, platform: config.APP_TYPE });

  useEffect(() => {
    if (authInitialized || !refreshToken) {
      return;
    }

    services.auth.refreshToken({ refreshToken })
      .then((response) => {
        logger.debug('refreshed tokens for initial page load');
        onTokenRefresh(response);
      })
      .catch(() => {
        logger.debug('failed to refresh tokens on initial page load - discard them and proceed to "login" route');
        onTokenRefreshError();
      });
  }, [
    authInitialized,
    services.auth,
    onTokenRefresh,
    onTokenRefreshError,
    refreshToken,
    config.INITIAL_LOAD_LOGO_ID,
    config.INITIAL_LOAD_TRANSITION_SECONDS,
    logger,
  ]);

  // Hack navigation resetting for stack based navigation resetting
  const [initialEntries, setInitialEntries] = useState<{
    key: number,
    paths: LocationDescriptor[]
  }>({
    key: 0,
    paths: pageLoadUrl === '/' ? ['/'] : ['/', pageLoadUrl],
  });
  const initialIndex = initialEntries.paths.length - 1;

  const replaceNavigationStack = useCallback((pathname: string, search?: string, state?: any) => {
    setInitialEntries({
      paths: pathname === '/' ? ['/'] : ['/', { pathname, search, state }],
      key: initialEntries.key + 1, // Changing the key forces the memory router to be re-rendered and reset
    });
  }, [initialEntries, setInitialEntries]);

  const pushNavigationStack = useCallback((pathname: string, search?: string, state?: any) => {
    setInitialEntries({
      paths: [...initialEntries.paths, { pathname, search, state }],
      key: initialEntries.key + 1,
    });
  }, [initialEntries, setInitialEntries]);

  // setup connect device websocket mocks (only used for test)
  useEffect(() => {
    if (!userId || !config.WS_MOCKING_ENABLED) {
      return;
    }
    // @ts-ignore (processReceivedData is a private method but can still be accessed + this is for testing only)
    const processReceivedData = (data: string) => wsClient.processReceivedData(data);
    setupWebSocketMocks(processReceivedData, userId);
  }, [wsClient, userId, config.WS_MOCKING_ENABLED]);

  if (!authInitialized) {
    return null;
  }

  const getRouteType = (unauthenticated: Array<boolean | null>) => {
    if (unauthenticated.includes(true) && unauthenticated.includes(false) && notLoggedInModeEnabled) {
      if (userId) {
        return ProtectedRoute;
      }
      return Route;
    }
    if (unauthenticated.includes(false)) {
      return ProtectedRoute;
    }
    if (unauthenticated.includes(true)) {
      return LoginRoute;
    }
    return Route;
  };

  return (
    <ApolloProvider client={client}>
      <WebsocketClientContext.Provider value={{ client: wsClient }}>
        <RouteContext.Provider value={{ routes, replaceNavigationStack, pushNavigationStack }}>
          <Router appType={config.APP_TYPE} initialEntries={initialEntries} initialIndex={initialIndex}>
            <PageContainer>
              <Switch>
                {
                  Object.values(routes).map(({
                    acceptedPaths,
                    component: Component,
                    exact,
                    unauthenticated,
                  }, i: number) => {
                    const RouteType = (() => getRouteType(unauthenticated))();

                    return (
                      <RouteType
                        key={`${acceptedPaths[0]}.${i * 2}`}
                        component={Component}
                        exact={exact}
                        path={acceptedPaths}
                      />
                    );
                  })
                }
                <Route component={() => {
                  logger.error('Invalid route');
                  return <ErrorOverlay error onDismiss="back" />;
                }}
                />
              </Switch>
            </PageContainer>
          </Router>
        </RouteContext.Provider>
      </WebsocketClientContext.Provider>
    </ApolloProvider>
  );
};

const AppWithConfig = () => {
  const { config } = useConfig();

  useEffect(() => {
    if (config.SENTRY_DSN) {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
      });
    }
  }, [config.SENTRY_DSN, config.SENTRY_ENVIRONMENT]);

  return (
    <SpatialNavProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <ServicesProvider config={config}>
            <>
              <GlobalStyles />
              <StateProvider>
                <PageWrapper />
              </StateProvider>
            </>
          </ServicesProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </SpatialNavProvider>
  );
};

const App = () => (
  <ConfigProvider>
    <AppWithConfig />
  </ConfigProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

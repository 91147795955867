import React, { useCallback, useState, useEffect } from 'react';

import useServices from 'services';
import { useDispatch } from 'state';
import { login } from 'actions/auth';

import useRoutes from 'utils/use-routes';

import logger from 'utils/logging';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingOverlay from 'ui/components/molecules/loading-screen';

const AuthenticatePage = () => {
  const services = useServices();
  const dispatch = useDispatch();
  const { routes, redirect } = useRoutes();
  const [error, setError] = useState<any>();

  const onLoad = useCallback(async () => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code) {
      const errString = 'Invalid link';
      logger.error(errString);
      setError(new Error(errString));
      return;
    }
    try {
      const tokens = await services.auth.loginWithCode({
        code,
      });
      dispatch(login(tokens));
      redirect({ route: routes.BROWSE, replaceStack: true });
    } catch (err) {
      logger.error(err);
      setError(err);
    }
  }, [dispatch, redirect, routes.BROWSE, services.auth]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  return <LoadingOverlay text="Logging you into Fiit" />;
};

AuthenticatePage.menu = false;

export default AuthenticatePage;

/* eslint-disable max-len */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import memoize from 'lodash/memoize';

import UpNext from 'ui/components/atoms/up-next';
import InClassLayout from 'ui/components/layouts/in-class';
import VideoPlayer from 'ui/components/atoms/video-player';
import CurrentExercise from 'ui/components/molecules/current-exercise';
import LiveExercise from 'ui/components/molecules/live-exercise';
import VideoProgressBar from 'ui/components/molecules/video-progress-display';
import AnimatedCountdown from 'ui/components/molecules/animated-countdown-wrapper';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import PoweredBy from 'ui/components/molecules/powered-by';
import HoldScreen from 'ui/components/organisms/hold-screen';

import {
  findCurrentActivity,
  findNextActivity,
  mapActivities,
  MappedActivity,
  MappedCircuit,
  mapSections,
} from 'ui/components/molecules/current-exercise/utils';

import Idle from 'app/pages/idle-or-error';
import LoadingScreen from 'ui/components/molecules/loading-screen';

import { useAppState, useDispatch } from 'state';
import { prepareStream as prepareStreamAction, timeChange as timeChangeAction } from 'actions/video';
import { setCanDisplay } from 'actions/screen-saver';
import useConfig from 'config';

import logger from 'utils/logging';
import useRoutes from 'utils/use-routes';

import {
  Lesson,
  LessonInstance,
  LessonInstanceType,
  ExerciseDisplay,
  LessonMedia,
  Image,
  StudioType,
  TimelineCircuit,
  CumulativeMetricShowState,
  ActivityMetrics,
  LessonInstancePlaybackType,
} from 'types/graphql';

import {
  LessonTimelineFragmentFragment as OnTvLessonTimelineFragmentFragment,
} from 'app/on-tv/types/graphql';

import {
  LessonTimelineFragmentFragment as InStudioLessonTimelineFragmentFragment,
} from 'app/in-studio/types/graphql';

import Leaderboard from 'ui/components/molecules/leaderboard';
import { LessonInstanceStatus } from 'state/lesson-instance';

import { PrepareStream as PREPARE_STREAM_MUTATION } from 'graphql/mutations/prepare-stream.gql';
import {
  PublishCurrentTime as PUBLISH_CURRENT_TIME_MUTATION,
} from 'app/on-tv/pages/lesson-instance/publish-lesson-instance-current-time.gql';
import InClassAnimation from 'ui/components/atoms/animated-icons/in-class';
import SvgFiitPoints from 'ui/components/atoms/icons/points';
import InClassCounter from 'ui/components/molecules/in-class-counter';
import WeightIcon from 'ui/components/atoms/icons/weight';
import InClassMetric from 'ui/components/molecules/in-class-metric';
import SvgKcal from 'ui/components/atoms/icons/kcal';
import BPMCounter from 'ui/components/molecules/bpm-counter';
import SvgBpm from 'ui/components/atoms/icons/bpm';
import SvgBikeKcal from 'ui/components/atoms/icons/bike-kcal';
import SvgBikeRpm from 'ui/components/atoms/icons/bike-rpm';
import SvgBikeTotalScore from 'ui/components/atoms/icons/bike-total-score';
import { IconProps } from 'ui/components/atoms/icons/types';
import { StatMetric, roundStatMetricForDisplay } from 'utils/stats';

import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

const SHOW_NEXT_EXERCISE_DURATION_SECONDS = 10;
const HIDE_NEXT_EXERCISE_DURATION_SECONDS = 1;

// More info - https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide
const AUTOPLAY_POLICY_ERROR = 'NotAllowedError';

type PickedLessonMedia = Pick<LessonMedia,
'id' |
'hlsUrl' |
'dashUrl' |
'dashUuid' |
'commercial' |
'status'>;

type LessonInstanceProp = (
  Pick<LessonInstance, 'id' | 'workoutStartTime' | 'type' | 'playbackType' >
  & { lesson: (
    & { mainImage: (
      & Pick<Image, 'id' | 'url' > | null
    )}
    & { mediaOptions: (
      & PickedLessonMedia[]
    )}
    & Pick<Lesson, 'duration' | 'name' | 'exerciseDisplay' | 'defaultMediaOption' >
    & (OnTvLessonTimelineFragmentFragment | InStudioLessonTimelineFragmentFragment)
  )}
);

export type Props = {
  lessonInstance: LessonInstanceProp,
  lessonInstanceStatus: LessonInstanceStatus,
  userId: number | null,
  playing?: boolean,
  onSessionEnd: () => void,
  onBuffering?: (isBuffering: boolean) => void,
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>,
  monitor?: boolean,
  onPlayFailed?: () => void,
  syncToLeaderboard?: boolean,
  seek?: number,
  isInParty?: boolean,
  customLessonMediaId?: string,
  workoutStatsData?: {
    bpm?: number | null,
    kc?: number | null,
    pt?: number | null,
    ar?: number | null,
    tr?: number | null,
    iar?: number | null,
    itr?: number | null,
    brp?: number | null,
    bkc?: number | null,
    bts?: number | null,
    tsp?: number | null,
    tts?: number | null,
    tkc?: number | null,
  },
  studioType?: StudioType,
  userProfileImage?: string | null,
  trackerConnected?: boolean,
  equipmentConnected?: boolean,
  userHeartRateRange?: {
    min: number,
    max: number,
  } | null,
  syncVideoSeconds?: number,
};

type InLiveSessionProps = {
  lessonInstance: LessonInstanceProp,
  mappedTimeline: MappedActivity[],
  onTimeChange: (currentTime: number) => void,
  lessonInstanceStatus: LessonInstanceStatus,
  onSessionEnd: () => void,
};

type InVirtualSessionProps = Props & {
  mappedTimeline: MappedActivity[],
  onTimeChange: (currentTime: number) => void,
};

type LeaderboardProps = {
  lessonInstanceId: string,
  allowDetailed: boolean,
};

type SessionLiveExerciseProps = {
  lessonInstanceId: string,
  timeline: MappedActivity[],
  circuits: MappedCircuit[],
};

type SessionCurrentExerciseProps = {
  lessonInstanceId: string,
  timeline: MappedActivity[],
};

type SessionNextExerciseProps = SessionCurrentExerciseProps & {
  playbackSpeed?: number,
};

type WrappedVideoProgressBarProps = {
  duration: number,
  timeline: (OnTvLessonTimelineFragmentFragment| InStudioLessonTimelineFragmentFragment)['timeline'],
  lessonInstanceId: string,
  isInParty?: boolean,
};

type SessionInClassAnimationProps = {
  lessonInstanceId: string,
  sections: MappedCircuit[],
  playbackSpeed: number,
};

type SessionHeartRateProps = {
  timeline: MappedActivity[],
  lessonInstanceId: string,
  currentBpm: number | null,
  userHeartRateRange?: {
    min: number,
    max: number,
  } | null
};

type SessionInClassMetricProps = {
  lessonInstanceId: string,
  timeline: MappedActivity[],
  icon: ({ className, color }: IconProps) => JSX.Element,
  metricCount: number | null,
  metricLabel: string,
  trackerConnected: boolean,
  equipmentConnected: boolean,
  metricName: keyof ActivityMetrics,
};

interface VideoSrc {
  dashUrl?: string | null;
  hlsUrl: string;
}
interface NonRelVideoSrc {
  dashUrl?: string | null;
  hlsUrl: string;
  cookie: string;
}

enum Runtimes {
  HOSTED = 'HOSTED',
  BUNDLED = 'BUNDLED',
}

const SessionLeaderboard = ({ lessonInstanceId, allowDetailed }: LeaderboardProps) => {
  const lessonInstance = useAppState((state) => state.lessonInstance[lessonInstanceId]);
  const connected = useAppState((state) => (state.lessonInstance[lessonInstanceId]?.connected || false));

  if (!lessonInstance) {
    return null;
  }

  const { leaderboard: { entries, totalUserCount, updatedAt } } = lessonInstance;

  return (
    <Leaderboard
      connected={connected}
      entries={entries}
      totalUserCount={totalUserCount}
      updatedAt={updatedAt}
      showCounter
      allowDetailed={allowDetailed}
    />
  );
};

const SessionCurrentExercise = ({ lessonInstanceId, timeline }: SessionCurrentExerciseProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  return <CurrentExercise currentTime={currentTime} timeline={timeline} />;
};

const SessionLiveExercise = ({ lessonInstanceId, timeline, circuits }: SessionLiveExerciseProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  return <LiveExercise currentTime={currentTime} timeline={timeline} circuits={circuits} />;
};

const SessionInClassAnimation = ({ lessonInstanceId, sections, playbackSpeed }: SessionInClassAnimationProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  const currentCircuit = sections.find(({ startTime, endTime }) => (
    currentTime >= startTime && currentTime < endTime
  ));

  if (!currentCircuit) {
    return null;
  }

  return <InClassAnimation circuit={currentCircuit} playbackSpeed={playbackSpeed} />;
};

const SessionNextExercise = ({ lessonInstanceId, timeline, playbackSpeed = 1 }: SessionNextExerciseProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  const next = findNextActivity(timeline, currentTime);

  const show = !!next &&
    next?.startTime - SHOW_NEXT_EXERCISE_DURATION_SECONDS <= currentTime &&
    next?.startTime - HIDE_NEXT_EXERCISE_DURATION_SECONDS > currentTime;

  const hide = !!next && next?.startTime - HIDE_NEXT_EXERCISE_DURATION_SECONDS <= currentTime;

  return next && (show || hide) ? <UpNext exercise={next.name} show={show} hide={hide} playbackSpeed={playbackSpeed} /> : null;
};

const SessionHeartRateZone = ({
  lessonInstanceId,
  timeline,
  userHeartRateRange,
  currentBpm,
}: SessionHeartRateProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  const currentActivity = findCurrentActivity(timeline, currentTime);
  const sortedBoundaries = useMemo(() => (
    currentActivity?.trackingConfig?.scoreZones?.boundaries.sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.start > b.start ? 1 : -1;
    }) || []
  ), [currentActivity]);

  return (
    <BPMCounter
      currentBpm={currentBpm}
      zoneStart={sortedBoundaries[0]?.start}
      userHeartRateRange={userHeartRateRange}
      disabled={
        [
          'WARMUP_SECTION',
          'TRANSITION_SECTION',
          'INTRO_SECTION',
          'COOLDOWN_SECTION',
        ].includes(currentActivity?.type || '')
      }
    />
  );
};

const ScoringInClassMetric = ({
  timeline,
  lessonInstanceId,
  metricCount,
  metricLabel,
  trackerConnected,
  equipmentConnected,
  icon,
  metricName,
}: SessionInClassMetricProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);
  const { metrics } = findCurrentActivity(timeline, currentTime) || { metrics: null };

  if (!metrics) {
    return null;
  }

  const disableTrack = (key: keyof ActivityMetrics) => (metrics[key] && typeof metrics[key] === 'object' ? {
    // @ts-ignore we have literally just checked if it is an object
    ...metrics[key],
    track: false,
  } : null);

  const noTrackerConnectedMetrics = ({
    reps: disableTrack('reps'),
    score: disableTrack('score'),
    kcal: disableTrack('kcal'),
    bpm: disableTrack('bpm'),
  });

  const noEquipmentConnectedMetrics = ({
    bikeTotalScore: disableTrack('bikeTotalScore'),
    bikeRpm: disableTrack('bikeRpm'),
    bikeKcal: disableTrack('bikeKcal'),
    treadTotalScore: disableTrack('treadTotalScore'),
    treadSpeed: disableTrack('treadSpeed'),
    treadKcal: disableTrack('treadKcal'),
  });

  const metricsWithTrackers = {
    ...metrics,
    ...(!trackerConnected ? noTrackerConnectedMetrics : null),
    ...(!equipmentConnected ? noEquipmentConnectedMetrics : null),
  };

  const getHidden = (met: ActivityMetrics, name: keyof ActivityMetrics) => {
    if (!met[name]) {
      return true;
    }
    if (met[name]?.hasOwnProperty('showInstantaneous')) {
      // @ts-ignore we are literally checking this on the line above typescript
      return !met[name]?.showInstantaneous;
    }
    if (met[name]?.hasOwnProperty('totalWorkout')) {
      // @ts-ignore we are literally checking this on the line above typescript
      return !met[name]?.totalWorkout === CumulativeMetricShowState.HIDDEN;
    }
    return true;
  };

  const isHidden = getHidden(metricsWithTrackers, metricName);

  if (isHidden) {
    return null;
  }

  const getDisabled = (met: ActivityMetrics, name: keyof ActivityMetrics) => {
    if (!met[name]) {
      return true;
    }
    if (met[name]?.hasOwnProperty('track')) {
      // @ts-ignore we are literally checking this on the line above typescript
      return !met[name]?.track;
    }
    return true;
  };

  const disabled = getDisabled(metricsWithTrackers, metricName);

  return (
    <InClassMetric
      disabled={disabled}
      metricCount={metricCount}
      metricLabel={metricLabel}
      metricIcon={icon}
    />
  );
};

const WrappedVideoProgressBar = ({ lessonInstanceId, duration, timeline, isInParty }: WrappedVideoProgressBarProps) => {
  const currentTime = useAppState((state) => state.lessonInstance[lessonInstanceId]?.localVideoTime || 0);

  const debouncedVideoTime = currentTime < 60
    ? Math.floor(currentTime / 3) * 3
    : Math.floor(currentTime / 30) * 30;

  const [publishCurrentTime] = useMutation(PUBLISH_CURRENT_TIME_MUTATION);

  useEffect(() => {
    if (lessonInstanceId && isInParty) {
      const input = {
        clientTime: new Date(),
        videoTime: Math.round(debouncedVideoTime),
      };

      publishCurrentTime({ variables: { id: lessonInstanceId, input } });
    }
  }, [lessonInstanceId, publishCurrentTime, debouncedVideoTime, isInParty]);

  return (
    <VideoProgressBar
      currentTime={currentTime}
      duration={duration}
      timeline={timeline}
    />
  );
};

const videoDomainRegex = /[^/]*(\/\/)?[^/]*/;
const relativeMediaUrl = (url: string) => url.replace(videoDomainRegex, '/media');

// Convert our cookie to a query string
const cookieParse = (cookie: string) => cookie.split('; ').join('&').replace(/CloudFront-/g, '');
const bundledMediaUrl = (url: string, cookie: string) => `${url}?${cookieParse(cookie)}`;

const relativeSrc = memoize(({ dashUrl, hlsUrl, ...others }: VideoSrc) => ({
  ...others,
  dashUrl: dashUrl && relativeMediaUrl(dashUrl),
  hlsUrl: relativeMediaUrl(hlsUrl),
}));

const bundledSrc = memoize(({ dashUrl, hlsUrl, cookie, ...others }: NonRelVideoSrc) => ({
  ...others,
  dashUrl: dashUrl && bundledMediaUrl(dashUrl, cookie),
  hlsUrl: bundledMediaUrl(hlsUrl, cookie),
}));

// if we have a tracker connected but no stats yet we want to show 0 instead of a -
const formatMetricValue = (trackerConnected: boolean, metricType: StatMetric, value?: number | null) => {
  if (value) {
    return roundStatMetricForDisplay(metricType, value);
  }

  if (trackerConnected && ['BPM', 'RPM', 'SPEED'].includes(metricType)) {
    // if the phone loses connection with the tracker it sends a value of null for instantaneous metrics
    // This should show as - instead of 0 in the UI
    return null;
  }

  return trackerConnected ? 0 : null;
};

const InVirtualSession = ({
  lessonInstanceStatus,
  lessonInstance,
  playing = false,
  onPlayFailed,
  userId,
  onSessionEnd,
  onBuffering,
  onTimeChange,
  videoRef,
  monitor,
  seek,
  isInParty = false,
  customLessonMediaId,
  workoutStatsData,
  studioType,
  userProfileImage,
  trackerConnected = false,
  equipmentConnected = false,
  userHeartRateRange,
  mappedTimeline,
  syncVideoSeconds,
}: InVirtualSessionProps) => {
  const dispatch = useDispatch();
  const { config } = useConfig();

  const mappedSections = mapSections(lessonInstance.lesson.timeline as TimelineCircuit[]);

  const mediaOption =
    lessonInstance.lesson.mediaOptions.find((option: PickedLessonMedia) => option.id === customLessonMediaId) ||
    lessonInstance.lesson.defaultMediaOption;

  const cookie = useAppState((state) => (
    mediaOption?.id
      ? state.video[mediaOption.id]?.cookie
      : null
  ));

  const [prepareStream, { error }] = useMutation(PREPARE_STREAM_MUTATION, {
    onCompleted({ prepareStream: prepareStreamPayload }) {
      if (!mediaOption?.id) {
        return;
      }

      dispatch(prepareStreamAction({
        ...prepareStreamPayload,
        lessonMediaId: mediaOption?.id,
      }));
    },
    onError: (e) => logger.error('PREPARE_STREAM_MUTATION error', { error: e }),
  });

  useEffect(() => {
    if (mediaOption) {
      const { dashUrl, id } = mediaOption;
      if (!dashUrl) {
        logger.error('No dash url found for media', { mediaOption });
      }
      const resourceType = dashUrl ? 'DASH' : 'HLS';

      prepareStream({ variables: { input: { userId, lessonMediaId: id, resourceType } } });
    }
  }, [lessonInstance, prepareStream, userId, mediaOption]);

  const onPlayFailedCallback = useCallback((playerError: Error) => {
    if (playerError.name === AUTOPLAY_POLICY_ERROR) {
      logger.debug('Autoplay policy error - show pause screen');
      dispatch(setCanDisplay(false));
    }

    if (onPlayFailed) {
      onPlayFailed();
    }
  }, [onPlayFailed, dispatch]);

  const { routes } = useRoutes();

  const { inClassAnimationsEnabled, recordingModeEnabled } = useAppState((state) => state.flag);

  if (!lessonInstance?.lesson?.defaultMediaOption) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Idle redirectUrl={routes.BROWSE?.acceptedPaths[0]} />;
  }

  if (!mediaOption) {
    logger.error('Media option not found', { lessonInstance, customLessonMediaId });
    return <Idle redirectUrl={routes.BROWSE?.acceptedPaths[0]} />;
  }

  const displayExerciseDetails = lessonInstance?.lesson?.exerciseDisplay !== ExerciseDisplay.NONE;

  const runtime = config.APP_TYPE === 'samsung' ? Runtimes.BUNDLED : Runtimes.HOSTED;

  const isAirbikeStudio = studioType === StudioType.AIRBIKE;
  const isTreadStudio = studioType === StudioType.TREAD;
  const isCardioStudio = studioType === StudioType.CARDIO;
  const hasRepsStudio = studioType && [StudioType.STRENGTH, StudioType.AIRBIKE, StudioType.TREAD].includes(studioType);
  const playbackSpeed = recordingModeEnabled ? config.VIDEO_INCREASED_PLAYBACK_SPEED : 1;
  const onTimeChangeInterval = 1000 / playbackSpeed;

  const allowDetailedLeaderboards = config.DETAILED_LEADERBOARDS_ENABLED;

  return (
    <>
      <InClassLayout
        pauseVisible={!playing}
        countdown={
          lessonInstanceStatus === LessonInstanceStatus.COUNTDOWN &&
          lessonInstance.workoutStartTime &&
          <AnimatedCountdown backgroundImage={lessonInstance.lesson.mainImage?.url} />
        }
        video={(
          cookie && (
            <VideoPlayer
              src={runtime === Runtimes.BUNDLED ? bundledSrc({ ...mediaOption, cookie }) : relativeSrc(mediaOption)}
              ref={videoRef}
              onPlayFailed={onPlayFailedCallback}
              playing={playing && lessonInstanceStatus === LessonInstanceStatus.IN_SESSION}
              seek={seek == null ? syncVideoSeconds : seek}
              onVideoEnd={onSessionEnd}
              onTimeChange={onTimeChange}
              onTimeChangeInterval={onTimeChangeInterval}
              onBuffering={onBuffering}
              monitor={monitor}
              monitorMeta={{
                playerName: 'on-tv',
                playerType: 'sky',
                videoId: mediaOption.id,
                videoTitle: lessonInstance?.lesson?.name,
                videoDuration: lessonInstance?.lesson?.duration,
                userId,
              }}
              playbackSpeed={playbackSpeed}
              isFullHeight
              // TODO need some error handlers
            />
          )
        )}
        upNext={(
          displayExerciseDetails && (
          <SessionNextExercise
            lessonInstanceId={lessonInstance.id}
            timeline={mappedTimeline}
            playbackSpeed={playbackSpeed}
          />
          )
        )}
        exercise={(
          displayExerciseDetails && (
          <SessionCurrentExercise
            lessonInstanceId={lessonInstance.id}
            timeline={mappedTimeline}
          />
          )
        )}
        animation={inClassAnimationsEnabled ? (
          <SessionInClassAnimation
            lessonInstanceId={lessonInstance.id}
            sections={mappedSections}
            playbackSpeed={playbackSpeed}
          />
        ) : null}
        leaderboard={(
          lessonInstance.type === LessonInstanceType.VIRTUAL_SYNC &&
          <SessionLeaderboard lessonInstanceId={lessonInstance.id} allowDetailed={allowDetailedLeaderboards} />
        )}
        progressBar={(
          <WrappedVideoProgressBar
            lessonInstanceId={lessonInstance.id}
            duration={lessonInstance?.lesson?.duration}
            timeline={lessonInstance?.lesson?.timeline}
            isInParty={isInParty}
          />
        )}
        heartRateZone={!hasRepsStudio && isInParty && trackerConnected ? (
          <SessionHeartRateZone
            lessonInstanceId={lessonInstance.id}
            timeline={mappedTimeline}
            userHeartRateRange={userHeartRateRange}
            currentBpm={formatMetricValue(trackerConnected, 'BPM', workoutStatsData?.bpm)}
          />
        ) : null}
        heartRate={(isInParty && (hasRepsStudio || !trackerConnected)) ? (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected, 'BPM', workoutStatsData?.bpm)}
            metricLabel="BPM"
            icon={SvgBpm}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="bpm"
          />
        ) : null}
        kcals={isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected, 'KCAL', workoutStatsData?.kc)}
            metricLabel="KCAL"
            icon={SvgKcal}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="kcal"
          />
        )}
        fiitPoints={isCardioStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected, 'POINTS', workoutStatsData?.pt)}
            metricLabel="FP"
            icon={SvgFiitPoints}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="score"
          />
        )}
        bikeRPM={isAirbikeStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(equipmentConnected, 'RPM', workoutStatsData?.brp)}
            metricLabel="RPM"
            icon={SvgBikeRpm}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="bikeRpm"
          />
        )}
        bikeTotalScore={isAirbikeStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected || equipmentConnected, 'TOTAL_SCORE', workoutStatsData?.bts)}
            metricLabel="BTS"
            icon={SvgBikeTotalScore}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="bikeTotalScore"
          />
        )}
        bikeKcals={isAirbikeStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(equipmentConnected, 'KCAL', workoutStatsData?.bkc)}
            metricLabel="BKC"
            icon={SvgBikeKcal}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="bikeKcal"
          />
        )}
        treadSpeed={isTreadStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(equipmentConnected, 'SPEED', workoutStatsData?.tsp)}
            metricLabel="KM/H"
            icon={SvgBikeRpm}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="treadSpeed"
          />
        )}
        treadTotalScore={isTreadStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(equipmentConnected, 'TOTAL_SCORE', workoutStatsData?.tts)}
            metricLabel="TTS"
            icon={SvgBikeTotalScore}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="treadTotalScore"
          />
        )}
        treadKcals={isTreadStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected, 'KCAL', workoutStatsData?.tkc)}
            metricLabel="TKC"
            icon={SvgBikeKcal}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="treadKcal"
          />
        )}
        activityReps={hasRepsStudio && isInParty && (
          <ScoringInClassMetric
            metricCount={formatMetricValue(trackerConnected, 'REPS', workoutStatsData?.iar || workoutStatsData?.ar)}
            metricLabel="REPS"
            icon={WeightIcon}
            timeline={mappedTimeline}
            lessonInstanceId={lessonInstance.id}
            trackerConnected={trackerConnected}
            equipmentConnected={equipmentConnected}
            metricName="reps"
          />
        )}
        totalReps={hasRepsStudio && isInParty && (
          <InClassCounter
            title="TOTAL REPS"
            value={formatMetricValue(trackerConnected, 'REPS', workoutStatsData?.itr || workoutStatsData?.tr)}
            profileImageUrl={userProfileImage}
          />
        )}
        hasVideo
      />
    </>
  );
};

const InLiveSession = ({ lessonInstanceStatus, lessonInstance, onTimeChange, mappedTimeline, onSessionEnd }: InLiveSessionProps) => {
  const { config } = useConfig();

  // Handle counter for class - 0.1 secs for smoothness
  const [counter, setCounter] = useState(0);
  const [startTime, setStartTime] = useState<number | null>(null);

  const updateCounter = useCallback((timestamp: number) => {
    if (lessonInstanceStatus !== LessonInstanceStatus.IN_SESSION) {
      return;
    }
    if (!startTime) {
      setStartTime(timestamp);
      return;
    }
    const elapsedTime = timestamp - startTime;
    const newCounter = Math.floor(elapsedTime / 100);
    if (newCounter > counter) {
      setCounter(newCounter);
      onTimeChange(Math.floor(newCounter / 10));
    } else {
      requestAnimationFrame(updateCounter);
    }
  }, [counter, lessonInstanceStatus, onTimeChange, startTime]);

  useEffect(() => {
    const frame = requestAnimationFrame(updateCounter);

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [lessonInstanceStatus, onTimeChange, updateCounter]);

  useEffect(() => {
    if ((counter / 10) > lessonInstance.lesson.duration) {
      onSessionEnd();
    }
  }, [onSessionEnd, lessonInstance.lesson.duration, counter]);

  const mappedCircuits = useMemo(() => {
    if (lessonInstance?.lesson?.timeline) {
      return mapSections(lessonInstance.lesson.timeline as TimelineCircuit[]);
    }
    return [];
  }, [lessonInstance]);

  const allowDetailedLeaderboards = config.DETAILED_LEADERBOARDS_ENABLED;

  return (
    <InClassLayout
      countdown={
        lessonInstanceStatus === LessonInstanceStatus.COUNTDOWN &&
        lessonInstance.workoutStartTime &&
        <AnimatedCountdown backgroundImage={lessonInstance.lesson.mainImage?.url} />
      }
      liveExercise={(
        <SessionLiveExercise
          lessonInstanceId={lessonInstance.id}
          timeline={mappedTimeline}
          circuits={mappedCircuits}
        />
      )}
      leaderboard={(
        lessonInstance.type === LessonInstanceType.VIRTUAL_SYNC &&
        <SessionLeaderboard lessonInstanceId={lessonInstance.id} allowDetailed={allowDetailedLeaderboards} />
      )}
      progressBar={(
        <WrappedVideoProgressBar
          lessonInstanceId={lessonInstance.id}
          duration={lessonInstance?.lesson?.duration}
          timeline={lessonInstance?.lesson?.timeline}
        />
      )}
      progressTop
      poweredBy={(
        <PoweredBy />
      )}
    />
  );
};

const InSession = (props: Props) => {
  const [isAuxWindowConnected, setIsAuxWindowConnected] = useState(false);
  const dispatch = useDispatch();

  const { lessonInstance, syncToLeaderboard } = props;
  const { playbackType, lesson, id } = lessonInstance;

  const mappedTimeline = mapActivities(lesson.timeline as TimelineCircuit[]);
  const onTimeChange = useCallback((currentTime: number) => {
    dispatch(timeChangeAction({
      instance: id,
      currentTime,
    }));
  }, [dispatch, id]);

  const syncVideoSeconds = useAppState((state) => (
    syncToLeaderboard
      ? state.lessonInstance[id]?.leaderboard?.videoSeconds
      : undefined
  ));

  const { isAuxiliaryWindowConnected } = useAuxiliaryWindow();

  useEffect(() => {
    const checkConnected = async () => {
      const connected = await isAuxiliaryWindowConnected();
      setIsAuxWindowConnected(connected);
    };
    checkConnected();
  });

  if (isAuxWindowConnected) {
    return (
      <HoldScreen />
    );
  }

  switch (playbackType) {
    case LessonInstancePlaybackType.VIRTUAL:
      return (
        <InVirtualSession
          {...props}
          mappedTimeline={mappedTimeline}
          onTimeChange={onTimeChange}
          syncVideoSeconds={syncVideoSeconds}
        />
      );
    case LessonInstancePlaybackType.LIVE:
      return (
        <InLiveSession
          {...props}
          mappedTimeline={mappedTimeline}
          onTimeChange={onTimeChange}
        />
      );
    // This should never be hit
    default:
      return <ErrorOverlay error onDismiss="back" />;
  }
};

export default InSession;

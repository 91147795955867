import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { LessonDifficulty } from 'types/graphql';
import {
  StartTrainingPlanMutation,
  StartTrainingPlanMutationVariables,
  TrainingPlanDetailsQuery,
  TrainingPlanDetailsQueryVariables,
  TrainingPlanDetailsNoAuthQuery,
  TrainingPlanDetailsNoAuthQueryVariables,
  Image,
  TemplateFragmentFragment,
  TemplateNoAuthFragmentFragment,
  UserActivityLevel,
  LessonStatus,
  TrainingPlanStatus,
} from 'app/on-tv/types/graphql';
import PlanDetails from 'ui/components/layouts/training-plan-details';
import ClassCard from 'ui/components/molecules/class-card';
import {
  TrainingPlanDetails as TRAINING_PLAN_DETAILS,
  TrainingPlanDetailsNoAuth as TRAINING_PLAN_DETAILS_NO_AUTH,
} from 'app/on-tv/pages/training-plan-details/training-plan-details.gql';
import {
  StartTrainingPlan as START_TRAINING_PLAN,
} from 'app/on-tv/pages/training-plan-details/start-training-plan.gql';
import { useAppState } from 'state';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';

import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useLogger from 'app/hooks/use-logger';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import useRoutes from 'utils/use-routes';
import { trainerNames } from 'ui/components/utils/trainer-names';
import TrainingPlanStarted from 'ui/components/molecules/training-plan-started';
import { ACTIVITY_LEVEL_TO_PLAN_LEVEL, getPlanEquipment } from 'app/on-tv/utils/training-plan-helpers';
import { analytics } from 'utils/analytics';

export type Props = RouteComponentProps<{ slug: string, level?: string }>;

const getTrainingPlanLevel = (
  levelFromQuery: string | null,
  activityLevel: UserActivityLevel | null,
): LessonDifficulty | null => {
  if (levelFromQuery) {
    return LessonDifficulty[levelFromQuery as keyof typeof LessonDifficulty];
  }
  if (activityLevel) {
    return ACTIVITY_LEVEL_TO_PLAN_LEVEL[activityLevel];
  }
  return null;
};

type PlanDetailsScreenProps = {
  slug: string,
  name: string,
  tagline?: string | null,
  description?: string | null,
  noOfClasses: number,
  userId: number,
  canStartPlan: boolean,
  images: {
    cover: Pick<Image, 'url' | 'hexColour'> | null,
  } | null
  template: TemplateFragmentFragment | TemplateNoAuthFragmentFragment,
  templates: Array<TemplateFragmentFragment | TemplateNoAuthFragmentFragment>,
  hasActivePlan: boolean,
  inTesting?: boolean,
};

const PlanDetailsScreen = ({
  slug,
  name,
  images,
  tagline,
  description,
  template,
  templates,
  noOfClasses,
  userId,
  canStartPlan,
  hasActivePlan,
  inTesting,
}: PlanDetailsScreenProps) => {
  const [showStartingPlanScreen, setShowStartingPlanScreen] = useState(false);
  const { routes, redirect } = useRoutes();

  const onFilter = useCallback((planLevel: string) => (
    redirect({
      route: routes.TRAINING_PLAN,
      params: { slug },
      queryParams: { level: planLevel },
      state: { preventScrollReset: true },
    })
  ), [slug, redirect, routes.TRAINING_PLAN]);

  const [startTrainingPlan] = useMutation<StartTrainingPlanMutation, StartTrainingPlanMutationVariables>(
    START_TRAINING_PLAN,
    {
      variables: {
        ...lessonsQueryVariables,
        userId,
        input: {
          userId,
          trainingPlanTemplateId: template.id,
        },
      },
      onCompleted: (() => {
        setShowStartingPlanScreen(true);
        analytics.track('TrainingPlanStarted', {
          trainingPlanTemplateId: template.id,
        });
      }),
    },
  );

  if (showStartingPlanScreen) {
    return <TrainingPlanStarted backgroundImage={images?.cover} />;
  }

  const equipment = getPlanEquipment(template);

  return (
    <PlanDetails
      canStartPlan={canStartPlan}
      hasActivePlan={hasActivePlan}
      startTrainingPlan={startTrainingPlan}
      name={name}
      backgroundImage={images?.cover}
      durationWeeks={template.byWeek.length}
      equipment={equipment}
      noOfClasses={noOfClasses}
      heroDescription={tagline}
      planDescription={description}
      inTesting={inTesting}
      lessons={template.byWeek[0]?.schedule.map(({ lesson }, i: number) => (
        <ClassCard
          key={i}
          backgroundImage={lesson.mainImage?.url}
          size="large"
          duration={lesson.durationRange}
          trainer={trainerNames(lesson.trainers)}
          name={lesson.name}
          kettlebells={!!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL')}
          dumbbells={!!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS')}
          // @ts-ignore yes this sometimes doesn't exist that's why there's a fallback
          favourited={lesson.favouritedByUser || false}
          // @ts-ignore yes this sometimes doesn't exist that's why there's a fallback
          locked={!lesson.permissionsWithReasons?.start.value || true}
          inTesting={lesson.status === LessonStatus.TESTING}
        />
      )) || []}
      levels={templates.map(({ level: templateLevel }) => ({ level: templateLevel }))}
      onFilter={onFilter}
      selectedFilter={template.level}
    />
  );
};

const TrainingPlanDetailsAuthPage = ({ match: { params: { slug, level } } }: Props) => {
  const userId = useAppState((state) => state.auth.userId);
  const levelFromUrl = useQueryParam().get('level') || level;
  const logger = useLogger('on-tv:training-plan-details-page');
  const { loading, error, data } = useQuery<TrainingPlanDetailsQuery, TrainingPlanDetailsQueryVariables>(
    TRAINING_PLAN_DETAILS,
    {
      variables: {
        slug,
        userId,
        noUserId: !userId,
      },
      onError: (e) => logger.error('TrainingPlanDetails graphQL error', { error: e }),
    },
  );

  useDismissEvent();

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.trainingPlanBySlug?.templates || !userId) {
    if (!userId) {
      logger.error('TrainingPlanDetails no userId');
    } else {
      logger.error('TrainingPlanDetails no training plan slug templates');
    }

    return <ErrorOverlay error onDismiss="back" />;
  }

  const {
    name,
    images,
    tagline,
    description,
    templates,
    permissions,
    status,
  } = data.trainingPlanBySlug;

  const targetPlanLevel = getTrainingPlanLevel(levelFromUrl || null, data.user?.activityLevel || null);
  const targetTemplate = targetPlanLevel
    ? templates.find(({ level: planLevel }) => planLevel === targetPlanLevel)
    : null;
  const template = targetTemplate || templates[0];

  const noOfClasses = template.byWeek.reduce(
    (acc, currentValue) => acc + (currentValue?.schedule?.length || 0), 0,
  );

  return (
    <PlanDetailsScreen
      name={name}
      images={images}
      tagline={tagline}
      description={description}
      templates={templates}
      noOfClasses={noOfClasses}
      template={template}
      slug={slug}
      userId={userId}
      canStartPlan={permissions.start}
      hasActivePlan={Boolean(data.user?.activeTrainingPlan)}
      inTesting={status === TrainingPlanStatus.TESTING}
    />
  );
};

const TrainingPlanDetailsNoAuthPage = ({ match: { params: { slug, level } } }: Props) => {
  const levelFromUrl = useQueryParam().get('level') || level;
  const logger = useLogger('on-tv:training-plan-details-page');
  const { loading, error, data } = useQuery<TrainingPlanDetailsNoAuthQuery, TrainingPlanDetailsNoAuthQueryVariables>(
    TRAINING_PLAN_DETAILS_NO_AUTH,
    {
      variables: {
        slug,
      },
      onError: (e) => logger.error('TrainingPlanDetails graphQL error', { error: e }),
    },
  );

  useDismissEvent();

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.trainingPlanBySlug?.templates) {
    logger.error('TrainingPlanDetails no training plan slug templates');
    return <ErrorOverlay error onDismiss="back" />;
  }

  const {
    name,
    images,
    tagline,
    description,
    templates,
    status,
  } = data.trainingPlanBySlug;

  const targetPlanLevel = getTrainingPlanLevel(levelFromUrl || null, null);
  const targetTemplate = targetPlanLevel
    ? templates.find(({ level: planLevel }) => planLevel === targetPlanLevel)
    : null;
  const template = targetTemplate || templates[0];

  const noOfClasses = template.byWeek.reduce(
    (acc, currentValue) => acc + (currentValue?.schedule?.length || 0), 0,
  );

  return (
    <PlanDetailsScreen
      name={name}
      images={images}
      tagline={tagline}
      description={description}
      templates={templates}
      noOfClasses={noOfClasses}
      template={template}
      slug={slug}
      canStartPlan={false}
      hasActivePlan={false}
      inTesting={status === TrainingPlanStatus.TESTING}
      userId={-1}
    />
  );
};

const TrainingPlanDetailsPage = (props: Props) => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <TrainingPlanDetailsAuthPage {...props} /> : <TrainingPlanDetailsNoAuthPage {...props} />;
};

TrainingPlanDetailsPage.menu = true;
export default TrainingPlanDetailsPage;

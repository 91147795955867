import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import useRoutes, { url } from 'utils/use-routes';
import useLogger from 'app/hooks/use-logger';

import Button from 'ui/components/atoms/button';
import ArrowLeft from 'ui/components/atoms/icons/arrow-left';
import Rail from 'ui/components/molecules/rail';
import HeroTrainingPlanCard from 'ui/components/molecules/hero-training-plan-card';
import TrainingPlanCard from 'ui/components/molecules/training-plan-card';

import {
  TrainingPlanSummary as TRAINING_PLAN_SUMMARY,
} from 'src/app/on-tv/organisms/training-plan-summary/training-plan-summary.gql';
import {
  MetricsSummaryFragmentFragment,
  TrainingPlanSummaryQuery,
  TrainingPlanSummaryQueryVariables,
  LessonDifficulty,
  TrainingPlan,
  TrainingPlanStatus,
} from 'app/on-tv/types/graphql';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  & > * {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto 1rem 0;
`;

type Props = {
  trainingPlanData: {
    startedAt: string,
    trainingPlanTemplate: {
      id: number,
      level: LessonDifficulty,
      trainingPlan: Pick<TrainingPlan, 'id' | 'name' |'images'>,
    }
    workoutSummary: {
      summary: MetricsSummaryFragmentFragment | null,
    } | null,
    schedule: Array<{ id: number }>
  },
  userId: number,
};

const TrainingPlanSummary = ({ trainingPlanData, userId } : Props) => {
  const logger = useLogger('on-tv:training-plan-summary-page');
  const { routes } = useRoutes();
  const { data } = useQuery<TrainingPlanSummaryQuery, TrainingPlanSummaryQueryVariables>(
    TRAINING_PLAN_SUMMARY,
    {
      variables: {
        userId,
        planCount: 10,
        planOffset: 0,
      },
      onError: (error) => logger.error('TrainingPlanSummary error', error),
    },
  );

  const { startedAt, trainingPlanTemplate, workoutSummary, schedule } = trainingPlanData;
  const { level, trainingPlan } = trainingPlanTemplate;

  return (
    <Wrapper>
      <HeroTrainingPlanCard
        heading="You smashed it!"
        name={trainingPlan.name}
        level={level}
        startedAt={startedAt}
        backgroundImage={trainingPlan.images?.cover}
        showMetrics
        timeInSeconds={workoutSummary?.summary?.time?.sum}
        kcal={workoutSummary?.summary?.kcal?.sum}
        avgBpm={workoutSummary?.summary?.bpm?.avg}
        noOfClasses={schedule.length}
        // training plans only complete when all classes are taken so just setting the same as noOfClasses
        planClassesTaken={schedule.length}
      />
      <StyledButton
        label="Back to browse"
        autofocus
        icon={<ArrowLeft />}
        to={url({ route: routes.BROWSE })}
      />
      <Rail title="Try next">
        {data?.userById?.recommendedTrainingPlans.edges?.map(({ node }) => (
          <TrainingPlanCard
            key={node.id}
            backgroundImage={node.images?.cover?.url}
            name={node.name}
            durationWeeks={parseInt(node.durationWeeks, 10)}
            equipment={node.equipment}
            locked={!node.permissions.start}
            to={url({ route: routes.TRAINING_PLAN, params: { slug: node.slug } })}
            inTesting={node.status === TrainingPlanStatus.TESTING}
          />
        )) || []}
      </Rail>
    </Wrapper>
  );
};

export default TrainingPlanSummary;

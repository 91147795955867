import React from 'react';
import chunk from 'lodash/chunk';
import { useDispatch, useAppState } from 'state';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import DebugLayout from 'ui/components/layouts/debug';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import {
  setMatConnectivityFlowEnabled,
  setCkConnectivityFlowEnabled,
  setInClassAnimationsEnabled,
  setDynamicHeroTransitionEnabled,
  setSignUpFlowEnabled,
  setClassDetailsExtraEnabled,
  setInClassTransitionsEnabled,
  setRecordingModeEnabled,
  setNotLoggedInModeEnabled,
} from 'actions/flag';
import { setEnabled as setScreenSaverEnabled } from 'actions/screen-saver';
import Button from 'ui/components/atoms/button';
import { SpatialNavParent } from 'utils/spatial-nav';

type Config = {
  value: boolean,
  action: (payload: boolean) => any,
  spatialId: string,
  name: string,
};

type UpdateStateProps = {
  value: boolean,
  action: (payload: boolean) => any,
};

const Title = styled(Typography)`
  margin-bottom: ${rem(50)};
`;

const Buttons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PaddedButton = styled(Button)`
  margin: 0 10px 10px;
`;

const BUTTONS_PER_ROW = 3;

const ConfigDebugPage = () => {
  const dispatch = useDispatch();

  const {
    matConnectivityFlowEnabled,
    ckConnectivityFlowEnabled,
    inClassAnimationsEnabled,
    inClassTransitionsEnabled,
    dynamicHeroTransitionEnabled,
    signUpFlowEnabled,
    classDetailsExtraEnabled,
    recordingModeEnabled,
    notLoggedInModeEnabled,
  } = useAppState((state) => state.flag);
  const { enabled: screenSaverEnabled } = useAppState((state) => state.screenSaver);

  const formatEnabled = (val: boolean) => (val ? 'enabled' : 'disabled');

  useDismissEvent();

  const updateState = ({ action, value }: UpdateStateProps) => {
    dispatch(action(value));
  };

  const configs: Config[] = [
    {
      value: matConnectivityFlowEnabled,
      action: setMatConnectivityFlowEnabled,
      spatialId: 'mat-connectivity-button',
      name: 'Mat Connectivity',
    },
    {
      value: ckConnectivityFlowEnabled,
      action: setCkConnectivityFlowEnabled,
      spatialId: 'ck-connectivity-button',
      name: 'CK Connectivity',
    },
    {
      value: inClassAnimationsEnabled,
      action: setInClassAnimationsEnabled,
      spatialId: 'in-class-animations-button',
      name: 'In Class Animations',
    },
    {
      value: inClassTransitionsEnabled,
      action: setInClassTransitionsEnabled,
      spatialId: 'in-class-transitions-button',
      name: 'In Class Transitions (Up Next/Track Info)',
    },
    {
      value: dynamicHeroTransitionEnabled,
      action: setDynamicHeroTransitionEnabled,
      spatialId: 'dynamic-hero-transition-button',
      name: 'Dynamic Hero Transition',
    },
    {
      value: signUpFlowEnabled,
      action: setSignUpFlowEnabled,
      spatialId: 'sign-up-flow-button',
      name: 'Sign Up Flow',
    },
    {
      value: classDetailsExtraEnabled,
      action: setClassDetailsExtraEnabled,
      spatialId: 'class-details-extra-button',
      name: 'Class Details Extra',
    },
    {
      value: screenSaverEnabled,
      action: setScreenSaverEnabled,
      spatialId: 'screen-saver-button',
      name: 'Screen Saver',
    },
    {
      value: recordingModeEnabled,
      action: setRecordingModeEnabled,
      spatialId: 'recording-mode-button',
      name: 'Recording Mode',
    },
    {
      value: notLoggedInModeEnabled,
      action: setNotLoggedInModeEnabled,
      spatialId: 'not-logged-in-mode-button',
      name: 'Not Logged In Mode',
    },
  ];

  const buttons = configs.map(({ value, action, spatialId, name }) => (
    <PaddedButton
      spatialId={spatialId}
      onClick={() => updateState({ action, value: !value })}
      label={`${name}: ${formatEnabled(value)}`}
      key={name}
    />
  ));

  // Chunk into rows of 3
  const rowsOfButtons = chunk(buttons, BUTTONS_PER_ROW);

  const renderedButtons = rowsOfButtons.map((row, i) => (
    <SpatialNavParent layout="horizontal" key={`row_${i}`}>
      <Buttons>
        {row}
      </Buttons>
    </SpatialNavParent>
  ));

  return (
    <DebugLayout>
      <Title>Config Debug Page</Title>
      {renderedButtons}
    </DebugLayout>
  );
};

ConfigDebugPage.menu = true;

export default ConfigDebugPage;

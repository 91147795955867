import { useCallback } from 'react';
import useConfig from 'app/on-tv/config-provider';
import { ChromecastMessage } from 'app/on-tv/utils/chromecast';
import useLogger from 'app/hooks/use-logger';

const useSendChromecastMessage = () => {
  const { config } = useConfig();
  const logger = useLogger('on-tv:send-chromecast-message');

  const sendChromecastMessage = useCallback(({ type, data }: ChromecastMessage['data']) => {
    if (!window.cast?.framework) {
      logger.warn('Tried to send chromecast messages with no casting or framework');
      return;
    }

    const context = window.cast.framework.CastReceiverContext.getInstance();

    if (context) {
      context.sendCustomMessage(config.CHROMECAST_CHANNEL, undefined, { type, data });
      logger.info('Sent message to chromecast', { type, data });
    } else {
      logger.warn('No session to send message to', { type, data });
    }
  }, [config, logger]);
  return {
    sendChromecastMessage,
  };
};

export default useSendChromecastMessage;

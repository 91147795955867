import React, { useState, useCallback, SyntheticEvent } from 'react';

import styled from 'styled-components';

import Button from 'ui/components/atoms/button';
import Cross from 'ui/components/atoms/icons/cross';
import Play from 'ui/components/atoms/icons/play';
import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';
import { trainerNames } from 'ui/components/utils/trainer-names';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import useKeyEvent, { Key } from 'utils/use-key-event';
import FullPageTwoAnswer from 'ui/components/layouts/full-page-two-answer';
import { SpatialNavParent } from 'utils/spatial-nav';
import useConfig from 'app/on-tv/config-provider';
import useDismissEvent from 'app/hooks/use-dismiss-event';

type Trainers = Array<{
  firstname: string,
}>;

type Props = {
  onComplete: () => void,
  trainers?: Trainers,
  lessonName?: string,
  onPlay: () => void,
  onPause: () => void,
  playing: boolean,
};

type PauseScreenProps = {
  playOnClick?: (event: SyntheticEvent) => void,
  quitOnClick?: (event: SyntheticEvent) => void,
  trainers?: Trainers,
  lessonName?: string,
};

type QuitScreenDialogProps = {
  returnOnClick?: (event: SyntheticEvent) => void,
  quitOnClick?: (event: SyntheticEvent) => void,
};

const ControlButton = styled(Button)`
  margin: ${rem(100)};
`;

const StyledFullPageOverlay = styled(FullPageOverlay)`
  z-index: 2;
`;

const LessonDetailsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: ${({ theme }) => theme.misc.safeScreenPaddingVertical}rem;
  left: ${({ theme }) => theme.misc.safeScreenPaddingHorizontal + 0.5}rem;
`;

const LessonDetailsText = styled(Typography)<{ opacity: number }>`
  opacity: ${({ opacity }) => (opacity == null ? 1 : opacity)};
`;

const CrossIcon = styled(Cross)`
  width: ${rem(100)};
  height: ${rem(100)};
`;

const PauseScreenDialog = (props: PauseScreenProps) => {
  const { playOnClick, quitOnClick, trainers, lessonName } = props;

  return (
    <StyledFullPageOverlay transparent>
      <>
        <LessonDetailsContainer>
          <LessonDetailsText variant="paragon" opacity={0.7} data-test="lesson-name">{lessonName}</LessonDetailsText>
          {trainers && trainers.length > 0 && (
            <Typography>
              with <LessonDetailsText weight="bold" opacity={0.5}>{trainerNames(trainers)}</LessonDetailsText>
            </Typography>
          )}
        </LessonDetailsContainer>
        <SpatialNavParent layout="horizontal">
          <ControlButton
            icon={<Play />}
            label="Resume"
            labelPosition="below"
            iconOnly
            size="large"
            onClick={playOnClick}
            autofocus
          />
          <ControlButton
            icon={<Cross />}
            label="Leave Class"
            labelPosition="below"
            iconOnly
            size="large"
            onClick={quitOnClick}
          />
        </SpatialNavParent>
      </>
    </StyledFullPageOverlay>
  );
};

const QuitScreenDialog = ({ returnOnClick, quitOnClick }: QuitScreenDialogProps) => (
  <FullPageTwoAnswer
    icon={CrossIcon}
    transparent
    question="Are you sure you want to leave?"
    optionOne="No"
    optionOneClick={returnOnClick}
    optionTwo="Yes"
    optionTwoClick={quitOnClick}
    autofocusButton="one"
  />
);

const PauseScreen = ({ playing, onComplete, trainers, lessonName, onPlay, onPause }: Props) => {
  const [quitVisible, setQuitVisible] = useState(false);
  const { config } = useConfig();
  useDismissEvent(() => {
    if (quitVisible) {
      onComplete();
    }
  });

  const togglePause = useCallback(() => {
    if (quitVisible) {
      return;
    }

    if (playing) {
      if (onPause) {
        onPause();
      }
      return;
    }

    if (onPlay) {
      onPlay();
    }
  }, [quitVisible, playing, onPlay, onPause]);

  const pause = useCallback(() => {
    if (quitVisible || !playing) {
      return;
    }

    if (onPause) {
      onPause();
    }
  }, [quitVisible, playing, onPause]);

  const play = useCallback(() => {
    if (!quitVisible && playing) {
      return;
    }

    if (onPlay) {
      onPlay();
    }
  }, [quitVisible, playing, onPlay]);

  const toggleConfirmQuit = useCallback(() => {
    if (playing) {
      pause();
      setQuitVisible(true);
      return;
    }

    play();
    setQuitVisible(false);
  }, [pause, play, playing]);

  useKeyEvent({
    key: [Key.MediaPause],
    handler: pause,
  });

  useKeyEvent({
    key: [Key.MediaPlay],
    handler: play,
  });

  useKeyEvent({
    key: [' ', config.PLATFORM_RCU_BACK_BUTTON, Key.MediaPlayPause, Key.Enter, Key.F8],
    handler: togglePause,
  });

  useKeyEvent({
    key: [Key.MediaStop, config.PLATFORM_RCU_BACK_BUTTON, Key.Escape],
    handler: toggleConfirmQuit,
  });

  if (quitVisible) {
    return <QuitScreenDialog returnOnClick={toggleConfirmQuit} quitOnClick={onComplete} />;
  }

  if (!playing) {
    return (
      <PauseScreenDialog
        playOnClick={togglePause}
        quitOnClick={onComplete}
        trainers={trainers}
        lessonName={lessonName}
      />
    );
  }

  return null;
};

export default PauseScreen;

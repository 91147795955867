import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgInfo = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <defs>
      <path id="info_svg__a" d="M12 21.6c-5.294 0-9.6-4.307-9.6-9.6 0-5.294 4.306-9.6 9.6-9.6 5.293 0 9.6 4.306 9.6 9.6 0 5.293-4.307 9.6-9.6 9.6zM0 12c0 6.628 5.372 12 12 12 6.626 0 12-5.372 12-12S18.626 0 12 0C5.372 0 0 5.372 0 12zm10.8-1.019c0-.589.538-1.067 1.2-1.067.662 0 1.2.478 1.2 1.067v6.786c0 .588-.538 1.066-1.2 1.066-.662 0-1.2-.478-1.2-1.066V10.98zM12 8.114c-.662 0-1.2-.537-1.2-1.2 0-.662.538-1.2 1.2-1.2.662 0 1.2.538 1.2 1.2 0 .663-.538 1.2-1.2 1.2z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="info_svg__b" fill="#fff">
        <use xlinkHref="#info_svg__a" />
      </mask>
      <use fill="#000" transform="matrix(1 0 0 -1 0 24)" xlinkHref="#info_svg__a" />
      <g fill="#FFF" mask="url(#info_svg__b)">
        <path d="M0 0H24V24H0z" />
      </g>
    </g>
  </Svg>
);

export default SvgInfo;

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Message } from 'reconnecting-websocket';

import useConfig from 'app/on-tv/config-provider';

import { useDispatch, useAppState } from 'state';
import { useApolloClient } from '@apollo/react-hooks';
import useServices from 'services';
import { login } from 'actions/auth';
import { RouteComponentProps } from 'react-router-dom';

import { CreatePinMutation as CREATE_REMOTE_APP_PIN } from 'app/on-tv/pages/pin/create-pin.gql';

import LoginPage from 'ui/components/layouts/login-page';
import Typography from 'ui/components/atoms/typography';
import { NumberedList, ListItem } from 'ui/components/atoms/numbered-list';
import Button from 'ui/components/atoms/button';
import RemoteLoginPin from 'ui/components/atoms/remote-login-pin';
import useWebSocket from 'utils/use-web-socket';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

import useLogger from 'app/hooks/use-logger';
import useRoutes from 'utils/use-routes';

import { rem } from 'ui/helpers';
import Divider from 'ui/components/atoms/divider';
import { useHistory } from 'react-router';
import PinCountdown from 'ui/components/atoms/pin-countdown';
import { SpatialNavParent, useSetFocus } from 'utils/spatial-nav';

const PinWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PinWithDividerContainer = styled.div`
  display: inline-block;
`;

const StyledPinCountdown = styled(PinCountdown)`
  margin-top: 1.25rem;
  margin-left: 1rem;
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${({ theme }) => theme.spacing.l};
  margin-bottom: ${rem(50)};
`;

const StyledLink = styled(Typography)`
  margin: 0 0.25rem;
`;

const List = styled(NumberedList)`
  margin: ${rem(50)} 0;
  width: ${rem(900)};
`;

const Pin = styled(RemoteLoginPin)`
  margin-bottom: ${rem(40)};
`;

const StyledButton = styled(Button)<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const timeFromNow = (expiresAt: string) => {
  const date1 = new Date(expiresAt).getTime();
  const now = new Date().getTime();
  return Math.abs(date1 - now);
};

const getBackgroundImage = (platform: string, isSignUp: boolean = true) => {
  switch (platform) {
    case 'sky':
      return 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/25143718/sky-login-pin-scaled.jpg';
    case 'amazon':
      return isSignUp
        ? 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/25143803/amazon-signup-pin-scaled.jpg'
        : 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/25145214/amazon-login-pin-scaled.jpg';
    default:
      return 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/09/01103508/loginbg.jpg';
  }
};

const PinPage = ({ match } : RouteComponentProps) => {
  const isSignUp = match.path.includes('signup');
  const { routes } = useRoutes();
  const history = useHistory();
  const logger = useLogger('on-tv:login-pin');

  useDismissEvent();

  const { config } = useConfig();
  const dispatch = useDispatch();
  const [connectionId, setConnectionId] = useState('');
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [createPinError, setCreatePinError] = useState(false);
  const [loginWithCodeError, setLoginWithCodeError] = useState(false);
  const [pinErrorRetry, setPinErrorRetry] = useState(0);
  const services = useServices();
  const setFocus = useSetFocus();
  const [pinInfo, setPinInfo] = useState<{ pin: string, pinDelay: number | null }>({
    pin: '......',
    pinDelay: null,
  });
  const showSignupButton = useAppState((state) => state.flag.signUpFlowEnabled);

  const client = useApolloClient();

  useEffect(() => {
    let id: number;

    async function tick() {
      try {
        const { data } = await client.mutate({
          mutation: CREATE_REMOTE_APP_PIN,
          variables: {
            clientId: config.AUTH_CLIENT_ID,
            connectionId,
          },
        });
        const delay = (timeFromNow(data.createRemoteAppPin.expiresAt) - config.REMOTE_LOGIN_PIN_BUFFER * 1000);
        setPinInfo({
          pin: data.createRemoteAppPin.pin,
          pinDelay: delay,
        });
        id = setTimeout(tick, delay);
      } catch (error) {
        logger.error(error, { connectionId });
        setCreatePinError(error);
      }
    }
    if (connectionId !== '') {
      id = setTimeout(tick, 0);
      return () => clearTimeout(id);
    }

    return () => undefined;
  }, [client, connectionId, config.REMOTE_LOGIN_PIN_BUFFER, config.AUTH_CLIENT_ID, setCreatePinError, logger]);

  const onWebsocketMessage = useCallback(
    async (socketData: { message: string, code: string, connectionId: string }) => {
      if (socketData.message === 'CONNECTION_CREATED') {
        setConnectionId(socketData.connectionId);
      }

      if (socketData.message === 'PIN_VERIFIED') {
        try {
          const tokens = await services.auth.loginWithCode({
            code: socketData.code,
          });

          dispatch(login(tokens));
        } catch (error) {
          logger.error(error);
          setLoginWithCodeError(error);
        }
      }
    },
    [dispatch, services.auth, setLoginWithCodeError, logger],
  );

  const onWebsocketOpen = useCallback((sendMessage: (message: Message) => void) => {
    sendMessage(JSON.stringify({
      action: 'REQUEST_CONNECT',
    }));
  }, []);

  const onRetriesExceeded = useCallback(() => {
    setConnectionFailed(true);
  }, [setConnectionFailed]);

  useWebSocket({
    url: config.REMOTE_LOGIN_WEBSOCKET_URL,
    maxRetries: 5,
    onMessage: onWebsocketMessage,
    onOpen: onWebsocketOpen,
    onRetriesExceeded,
    genericErrorRetry: pinErrorRetry,
  });

  const { pin, pinDelay } = pinInfo;

  const clearErrors = () => {
    setConnectionFailed(false);
    setCreatePinError(false);
    setLoginWithCodeError(false);
  };

  const backAction = useCallback(() => {
    clearErrors();
    setConnectionId('');
    setPinErrorRetry(pinErrorRetry + 1);
    setFocus('PAGE_CONTENT');
  }, [pinErrorRetry, setFocus]);

  return (
    <LoginPage
      image={getBackgroundImage(config.APP_TYPE, isSignUp)}
      footer={(
        <>
          <Typography color="beckersKnop">Need help? Visit</Typography>
          <StyledLink variant="pica">help.fiit.tv</StyledLink>
        </>
      )}
      headerText={isSignUp ? 'Sign up with pin' : 'Log in with pin'}
    >
      <List color="beckersKnop">
        <ListItem>
          <Typography>Grab your mobile or computer</Typography>
        </ListItem>
        <ListItem>
          <Typography>Visit</Typography>
          <StyledLink variant="pica" color="white">{config.TV_BASE_LINK}/{isSignUp ? 'SIGNUP' : 'LOGIN'}</StyledLink>
          <Typography>in your web browser</Typography>
        </ListItem>
        <ListItem>
          <Typography>Follow the steps and enter the code below</Typography>
        </ListItem>
      </List>

      <PinWrapper>
        <PinWithDividerContainer>
          <Pin pin={pin} />
          { routes.LOGIN_BY_PASSWORD && <StyledDivider title="or" /> }
          {isSignUp ? (
            <StyledButton
              widthPercentage={100}
              to={{ pathname: routes.LOGIN_BY_PIN?.acceptedPaths[0], state: history.location.state }}
              variant="cta"
              label="Login"
              autofocus
            />
          ) : (
            <ButtonRow>
              <SpatialNavParent layout="horizontal">
                <StyledButton
                  widthPercentage={showSignupButton ? 53 : 100}
                  to={{ pathname: routes.LOGIN_BY_PASSWORD?.acceptedPaths[0], state: history.location.state }}
                  variant="cta"
                  label="Log in with password"
                  autofocus
                />
                {showSignupButton && (
                  <StyledButton
                    widthPercentage={45}
                    label="Sign up"
                    variant="cta"
                    to={{ pathname: routes.SIGNUP?.acceptedPaths[0], state: history.location.state }}
                  />
                )}
              </SpatialNavParent>
            </ButtonRow>
          )}
        </PinWithDividerContainer>
        {pinDelay && !createPinError && (
          <StyledPinCountdown pin={pin} pinChangeDelay={Math.round(pinDelay / 1000) - 1} />
        )}
      </PinWrapper>

      { (createPinError || loginWithCodeError || connectionFailed) &&
        <ErrorOverlay error onDismiss="action" onDismissAction={backAction} /> }

    </LoginPage>
  );
};

PinPage.menu = false;

export default PinPage;

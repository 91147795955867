import React, { useCallback, useEffect, useState } from 'react';
import ConnectDeviceModal from 'app/on-tv/organisms/connect-device-modal';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useLogger from 'app/hooks/use-logger';
import useRoutes from 'utils/use-routes';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import { onSubscriptionEvent } from 'app/on-tv/pages/connect-device/helpers';

import { ConnectDevice as CONNECT_DEVICE_QUERY } from 'app/on-tv/pages/connect-device/queries.gql';
import {
  PatchParty as PATCH_PARTY,
  PatchLessonInstance as PATCH_LESSON_INSTANCE,
  ReopenParty as REOPEN_PARTY,
} from 'app/on-tv/pages/connect-device/mutations.gql';
import { StartWorkout as START_WORKOUT_MUTATION } from 'src/graphql/mutations/start-workout.gql';
import {
  LobbyEntryUpdated as LOBBY_ENTRY_UPDATED_SUB,
  LobbyJoined as LOBBY_JOINED_SUB,
} from 'app/on-tv/pages/connect-device/subscriptions.gql';
import {
  ConnectDeviceQuery,
  ConnectDeviceQueryVariables,
  PatchLessonInstanceMutation,
  PatchLessonInstanceMutationVariables,
  StartWorkoutMutation,
  StartWorkoutMutationVariables,
  LobbyEntryUpdatedSubscription,
  LobbyJoinedSubscription,
  LessonInstanceType,
  PartyStatus,
  PatchPartyMutation,
  PatchPartyMutationVariables,
  ReopenPartyMutation,
  ReopenPartyMutationVariables,
} from 'app/on-tv/types/graphql';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import { LessonInstanceLobbyStatus, LessonInstancePlayState, StudioType } from 'types/graphql';
import { useHistory } from 'react-router';
import { useAppState } from 'state';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import ConnectDeviceGuide from 'app/on-tv/organisms/connect-device-guide';

export enum ConnectionFlowStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTION_STARTED = 'CONNECTION_STARTED',
  TRACKER_ONLY_CONNECTED = 'TRACKER_ONLY_CONNECTED',
  EQUIPMENT_ONLY_CONNECTED = 'EQUIPMENT_ONLY_CONNECTED',
  CONNECTED = 'CONNECTED',
}

const getConnectionFlowStatus = (
  isConnectedKit: boolean,
  startedConnectionFlow: boolean,
  trackerConnected: boolean,
  equipmentConnected: boolean,
): ConnectionFlowStatus => {
  const isFullyConnected = isConnectedKit ? trackerConnected && equipmentConnected : trackerConnected;
  const isTrackerOnlyConnected = isConnectedKit && !equipmentConnected && trackerConnected;
  const isEquipmentOnlyConnected = isConnectedKit && !trackerConnected && equipmentConnected;

  if (isFullyConnected) {
    return ConnectionFlowStatus.CONNECTED;
  }
  if (isTrackerOnlyConnected) {
    return ConnectionFlowStatus.TRACKER_ONLY_CONNECTED;
  }
  if (isEquipmentOnlyConnected) {
    return ConnectionFlowStatus.EQUIPMENT_ONLY_CONNECTED;
  }
  if (startedConnectionFlow) {
    return ConnectionFlowStatus.CONNECTION_STARTED;
  }
  return ConnectionFlowStatus.NOT_CONNECTED;
};

const getImageUrl = (status: ConnectionFlowStatus): string | null => {
  switch (status) {
    case ConnectionFlowStatus.CONNECTION_STARTED:
    case ConnectionFlowStatus.TRACKER_ONLY_CONNECTED:
    case ConnectionFlowStatus.EQUIPMENT_ONLY_CONNECTED:
      return 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/11140025/tv-2.png';
    case ConnectionFlowStatus.CONNECTED:
      return 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/02142447/connected-device-tracker.png';
    default:
      return null;
  }
};

type PageProps = {
  lessonMediaId: string,
  userId: number,
  partyId: string,
  lessonInstanceId: string,
  firstName?: string | null,
  lastName?: string | null,
  profileImageUrl?: string | null,
  lobbyEntries: {
    edges: Array<{
      node?: {
        id: string,
        trackerConnected: boolean,
        equipmentConnected: boolean,
        leftAt?: string | null,
      }
    }>
  },
  lessonInstanceType: LessonInstanceType,
  studioId: StudioType,
};

const Page = ({
  firstName,
  lastName,
  profileImageUrl,
  lessonInstanceId,
  lobbyEntries,
  userId,
  lessonMediaId,
  lessonInstanceType,
  partyId,
  studioId,
}: PageProps) => {
  const { routes, redirect } = useRoutes();
  const history = useHistory();
  const scheduleId = useQueryParam().get('scheduleId');
  const [showConnectGuide, setShowConnectGuide] = useState(false);
  const logger = useLogger('on-tv:connect-device-page');

  const toggleConnectGuide = () => (setShowConnectGuide((prev) => !prev));

  const [
    patchParty,
    { error: patchPartyError },
  ] = useMutation<PatchPartyMutation, PatchPartyMutationVariables>(PATCH_PARTY, {
    variables: {
      id: partyId,
      input: {
        status: PartyStatus.CLOSED,
      },
    },
    onError: (error) => logger.error('PatchPartyMutation error', { error }),
  });

  const [
    patchLessonInstance,
    { loading: patchLessonInstanceLoading, error: patchLessonInstanceError },
  ] = useMutation<PatchLessonInstanceMutation, PatchLessonInstanceMutationVariables>(PATCH_LESSON_INSTANCE, {
    onError: (e) => logger.error('PatchLessonInstanceMutation error', { error: e }),
  });

  const [reopenParty, { error: reopenPartyError }] = useMutation<ReopenPartyMutation, ReopenPartyMutationVariables>(
    REOPEN_PARTY,
    {
      variables: {
        id: partyId,
      },
      onError: (error) => logger.error('ReopenPartyMutation error', { error }),
    },
  );

  const [
    startStreamableWorkout,
    { loading: streamableLoading, error: streamableError },
  ] = useMutation<StartWorkoutMutation, StartWorkoutMutationVariables>(
    START_WORKOUT_MUTATION,
    {
      variables: {
        userId,
        lessonMediaId,
        lessonInstanceId,
      },
      onError: (error) => {
        // if a user has cancelled their plan via the mobile app they may end up here with a scheduleId
        // for the old plan which will throw an error so lets catch this and retry without the id
        if (error.message.toLowerCase().includes('schedule entry')) {
          return startStreamableWorkout();
        }
        return logger.error('StartWorkoutMutation error', { error });
      },
    },
  );

  const onGoBack = useCallback(async () => {
    if (showConnectGuide) {
      toggleConnectGuide();
      return;
    }

    if (lessonInstanceType === LessonInstanceType.INDIVIDUAL) {
      await patchLessonInstance({
        variables: {
          id: lessonInstanceId,
          input: {
            cancelled: true,
            lobbyStatus: LessonInstanceLobbyStatus.CLOSED,
          },
        },
      });
    } else {
      await patchParty();
    }

    history.goBack();
  }, [showConnectGuide, lessonInstanceType, history, patchLessonInstance, lessonInstanceId, patchParty]);

  const onTryAgain = useCallback(reopenParty, [reopenParty]);

  useDismissEvent(onGoBack, true);

  if (streamableLoading || patchLessonInstanceLoading) {
    return <LoadingOverlay />;
  }

  const error = streamableError || patchLessonInstanceError || patchPartyError || reopenPartyError;
  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const isConnectedKit = [StudioType.AIRBIKE, StudioType.TREAD].includes(studioId);

  if (showConnectGuide) {
    return (
      <ConnectDeviceGuide onBackClick={toggleConnectGuide} isConnectedKit={isConnectedKit} />
    );
  }

  const startClassClick = async () => {
    await patchLessonInstance({
      variables: {
        id: lessonInstanceId,
        input: {
          playState: LessonInstancePlayState.PLAYING,
          lobbyStatus: LessonInstanceLobbyStatus.CLOSED,
        },
      },
    });
    redirect({ route: routes.PARTY, params: { partyId } });
  };

  const continueWithoutStatsClick = async () => {
    // Start workout before updating the lesson instance so connected mobile clients can exit flow in response
    await startStreamableWorkout({
      variables: {
        userId,
        lessonMediaId,
        lessonInstanceId,
        ...(scheduleId ? { scheduleId: parseInt(scheduleId, 10) } : null),
      },
    });
    await startClassClick();
  };

  // As we update based on subscription events we don't requery so we don't remove lobbyEntries
  // that have been left so we filter them out here
  const lobbyEntryEdges = lobbyEntries?.edges?.filter(({ node }) => !node?.leftAt) || [];
  const startedConnectionFlow = lobbyEntryEdges.length > 0;

  // this isn't necessary for mvp as there is only 1 possible lobby entry
  const deviceConnected = startedConnectionFlow &&
    lobbyEntryEdges.every(({ node }) => node?.trackerConnected);

  // this isn't necessary for mvp as there is only 1 possible lobby entry
  const equipmentConnected = startedConnectionFlow &&
    lobbyEntryEdges.every(({ node }) => node?.equipmentConnected);

  const connectionFlowStatus = getConnectionFlowStatus(
    isConnectedKit,
    startedConnectionFlow,
    deviceConnected,
    equipmentConnected,
  );

  return (
    <ConnectDeviceModal
      firstName={firstName}
      lastName={lastName}
      profileImageUrl={profileImageUrl}
      connectionFlowStatus={connectionFlowStatus}
      imageUrl={getImageUrl(connectionFlowStatus)}
      startClassClick={startClassClick}
      continueWithoutStatsClick={continueWithoutStatsClick}
      onGoBack={onGoBack}
      onTryAgain={onTryAgain}
      onGuideButtonClick={toggleConnectGuide}
    />
  );
};

type Props = {
  match: {
    params: {
      partyId: string
    }
  }
};

const ConnectDevicePage = ({ match: { params: { partyId } } }: Props) => {
  const userId = useAppState((state) => state.auth.userId);
  const logger = useLogger('on-tv:connect-device-page');

  const {
    data,
    loading,
    error,
    subscribeToMore,
  } = useQuery<ConnectDeviceQuery, ConnectDeviceQueryVariables>(
    CONNECT_DEVICE_QUERY, {
      variables: {
        partyId,
      },
      onError: (e) => logger.error('Connect device graphQL error', { error: e }),
    },
  );

  useEffect(() => {
    subscribeToMore(
      {
        document: LOBBY_ENTRY_UPDATED_SUB,
        variables: { partyId },
        updateQuery: (
          prev, {
            subscriptionData,
          } : { subscriptionData: { data: LobbyEntryUpdatedSubscription } },
        ): ConnectDeviceQuery => {
          if (!subscriptionData.data) {
            return prev;
          }

          return onSubscriptionEvent(prev, subscriptionData.data.lobbyEntryUpdated);
        },
      },
    );

    subscribeToMore({
      document: LOBBY_JOINED_SUB,
      variables: { partyId },
      updateQuery: (
        prev, {
          subscriptionData,
        } : { subscriptionData: { data: LobbyJoinedSubscription } },
      ): ConnectDeviceQuery => {
        if (!subscriptionData.data) {
          return prev;
        }

        return onSubscriptionEvent(prev, subscriptionData.data.lobbyEntryCreated);
      },
    });
  }, [partyId, subscribeToMore]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !data?.partyById) {
    return <ErrorOverlay error onDismiss="back" />;
  }
  if (!userId) {
    logger.error('Connect device page no userId');
    return <ErrorOverlay error onDismiss="back" />;
  }

  const { hostUser, lessonInstance, lessonMedia, lobbyEntries } = data.partyById;
  return (
    <Page
      lessonMediaId={lessonMedia.id}
      lessonInstanceId={lessonInstance.id}
      lessonInstanceType={lessonInstance.type}
      firstName={hostUser.firstname}
      lastName={hostUser.lastname}
      profileImageUrl={hostUser.displayImageUrl}
      partyId={partyId}
      lobbyEntries={lobbyEntries}
      userId={userId}
      studioId={lessonInstance.lesson.studio.id}
    />
  );
};

ConnectDevicePage.menu = false;
export default ConnectDevicePage;

import React, { SyntheticEvent, useRef, useState } from 'react';

import styled from 'styled-components';
import { useAppState } from 'state';

import { rem } from 'ui/helpers';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';

import Play from 'ui/components/atoms/icons/play';
import Schedule from 'ui/components/atoms/icons/schedule';
import KCal from 'ui/components/atoms/icons/kcal';
import Points from 'ui/components/atoms/icons/points';

import ClassDetailsBasicInformation from 'ui/components/molecules/class-details-basic-information';
import ClassDetailsLabels from 'ui/components/molecules/class-details-labels';
import ClassDetailsTags from 'ui/components/molecules/class-details-tags';
import { LessonDurationRange, MuscleGroup, LessonStartPermission, LessonStartPermissionReason } from 'types/graphql';
import Padlock from 'ui/components/atoms/icons/padlock';
import enumToDisplay from 'utils/enum-display';
import { width as menuWidth } from 'ui/components/molecules/side-menu';
import { SpatialNavParent } from 'utils/spatial-nav';
import FavouriteButton from 'ui/components/molecules/favourite-button';
import TestingBadge from 'ui/components/atoms/testing-badge';
import Description from 'ui/components/atoms/expandable-description';
import ClassDetailsExtraInformation from 'ui/components/molecules/class-details-extra-information';
import { TransformedLessons } from 'app/on-tv/utils/transform-lesson-data';
import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import { usePageScroll } from 'utils/use-page-scroll';
import useKeyEvent from 'utils/use-key-event';
import useConfig from 'config';

export type Trainers = Array<{
  firstname: string,
  lastname: string,
  slug: string,
  avatarImage: {
    url: string,
  },
}>;

export type Props = {
  image?: string,
  name: string,
  trainers: Trainers,
  review?: number | null,
  startOnClick?: (event: SyntheticEvent) => void,
  scheduleOnClick?: (event: SyntheticEvent) => void,
  favouriteOnClick?: (event: SyntheticEvent) => void,
  studio: string,
  duration: LessonDurationRange,
  difficulty: string,
  equipment?: string,
  musicGenre?: string,
  muscles: Array<{
    group: MuscleGroup,
  }>,
  kcal?: string,
  avgPoints?: string,
  description?: string | null,
  permissions?: LessonStartPermission,
  autofocus?: boolean,
  favourited?: boolean,
  lessonId: number,
  userId?: number,
  inTesting?: boolean,
  similarClasses: TransformedLessons,
  setDismissFocusId: (id: string | null) => void,
  chooseMediaOption?: () => void,
  mappedTimeline: MappedCircuit[],
  noTitleTopPad?: boolean,
};

const leftMargin = '1rem';
const contentWidth = rem(1000);

const Wrapper = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: column;
  margin-left: ${leftMargin};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100vw - (${leftMargin} + ${contentWidth} + ${menuWidth}));
    height: calc(100vw - (${leftMargin} + ${contentWidth} + ${menuWidth}));
    background-image: url(${({ image }) => `${image}?size=800x800&mask=full-background-image-gradient-720-v1`});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    z-index: -1;
  }
`;

const Content = styled.div`
  width: ${contentWidth};
`;

const TitleSection = styled.div<{ noTopPad?: boolean }>`
  min-height: ${rem(200)};
  padding: ${({ noTopPad }) => (noTopPad ? `0 0 ${rem(50)}` : `${rem(150)} 0 ${rem(50)}`)};
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.black};
  position: sticky;
  top: 0;
`;

const LabelSection = styled.div`
  margin-top: ${rem(60)};
`;

const TagSection = styled.div`
  margin-top: ${rem(25)};
`;

const BreakdownSection = styled.div`
  margin-top: ${rem(40)};
`;

const KCalIcon = styled(KCal)`
  margin-right: ${rem(10)};
`;

const PointsIcon = styled(Points)`
  margin-right: ${rem(10)};
`;

const BreakdownItem = styled(Typography)`
  margin-right: ${rem(30)};
  border: 1px solid ${({ theme }) => theme.colors.beckersKnop};
  width: max-content;
  padding: ${rem(5)} ${rem(20)};
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const StyledTestingBadge = styled(TestingBadge)`
  top: 2rem;
`;

const StyledDescription = styled(Description)`
  min-height: 6rem;
  margin-bottom: 4rem;
  margin-left: 1px;
`;

const ScrollableSection = styled.div<{ enabled: boolean }>`
  position: relative;
  height: ${({ enabled }) => (enabled ? rem(480) : 'auto')};
  overflow-y: ${({ enabled }) => (enabled ? 'scroll' : 'visible')};
  margin-bottom: 3rem;
`;

const DescriptionContainer = styled.div<{ fixedHeight: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? rem(480) : 'auto')};
`;

const ClassDetailsLayout = ({
  image,
  name,
  trainers,
  review,
  startOnClick,
  scheduleOnClick,
  studio,
  duration,
  difficulty,
  equipment,
  musicGenre,
  muscles,
  kcal,
  avgPoints,
  description,
  permissions,
  autofocus = false,
  favourited = false,
  lessonId,
  userId,
  inTesting,
  similarClasses,
  setDismissFocusId,
  chooseMediaOption,
  mappedTimeline,
  noTitleTopPad,
}: Props) => {
  const [showExtraDetailsContent, setShowExtraDetailsContent] = useState(false);
  const titleSectionRef = useRef<HTMLDivElement | null>(null);
  const basicDetailsWrapper = useRef<HTMLDivElement | null>(null);
  const extraDetailsWrapper = useRef<HTMLDivElement | null>(null);
  const classDetailsExtraEnabled = useAppState((state) => state.flag.classDetailsExtraEnabled);
  const { config } = useConfig();

  const fixedHeight = ['web', 'fiit_studios'].includes(config.APP_TYPE) === false;

  const pageScroll = usePageScroll();

  const scrollDescription = (el: HTMLElement | null, reset?: boolean) => {
    if (basicDetailsWrapper.current) {
      basicDetailsWrapper.current.scroll({ behavior: 'smooth', top: reset ? 0 : el?.offsetTop || 0 });
    }
  };

  const scrollPage = (reset?: boolean) => {
    if (reset) {
      pageScroll(0);
    }
    if (extraDetailsWrapper.current && titleSectionRef.current) {
      pageScroll(extraDetailsWrapper.current.offsetTop - titleSectionRef.current.offsetHeight);
    }
  };

  // Debug tester - choose media option utils
  const mediaOptionChooser = () => {
    if (chooseMediaOption) {
      chooseMediaOption();
    }
  };

  useKeyEvent({
    key: config.CHOOSE_MEDIA_OPTION_BUTTON,
    handler: mediaOptionChooser,
  });

  const getStartButton = () => {
    const getLabel = () => {
      if (permissions?.blockedReasons?.includes(LessonStartPermissionReason.NOT_ON_PLAN)) {
        return 'Upgrade';
      }
      if (permissions?.blockedReasons?.includes(LessonStartPermissionReason.PREMIUM)) {
        return 'Unlock Fiit';
      }
      if (permissions?.value) {
        return 'Start class';
      }
      // catch all
      return 'Unlock Fiit';
    };

    return startOnClick ? (
      <Button
        icon={permissions?.value ? <Play /> : <Padlock />}
        label={getLabel()}
        onClick={startOnClick}
        autofocus={autofocus}
      />
    ) : null;
  };

  return (
    <Wrapper image={image}>
      {inTesting && <StyledTestingBadge />}
      <SpatialNavParent layout="vertical">
        <Content>
          <TitleSection ref={titleSectionRef} noTopPad={noTitleTopPad}>
            <ClassDetailsBasicInformation name={name} trainers={trainers} review={review} />
          </TitleSection>
          <ScrollableSection ref={basicDetailsWrapper} enabled={fixedHeight}>
            <SpatialNavParent
              id="INFO_SECTION"
              layout="horizontal"
              onFocus={(el) => {
                scrollDescription(el, true);
                setDismissFocusId(null);
                setShowExtraDetailsContent(false);
              }}
            >
              <ButtonSection>
                {scheduleOnClick && (
                  <Button
                    icon={<Schedule />}
                    label="Schedule class"
                    onClick={scheduleOnClick}
                    autofocus={!startOnClick && autofocus}
                  />
                )}
                {getStartButton()}
                {userId && (
                  <FavouriteButton
                    userId={userId}
                    lessonId={lessonId}
                    favourited={favourited}
                    shouldUpdateIconColor
                  />
                )}
              </ButtonSection>
            </SpatialNavParent>
            <LabelSection>
              <ClassDetailsLabels studio={studio} duration={duration} />
            </LabelSection>
            <TagSection>
              <ClassDetailsTags
                tags={[
                  enumToDisplay(difficulty),
                  equipment,
                  musicGenre,
                  muscles.map(({ group }) => enumToDisplay(group)).join(', '),
                ]}
              />
            </TagSection>
            {(kcal || avgPoints) && (
              <BreakdownSection>
                {
                  kcal && (
                    <BreakdownItem variant="pica" color="beckersKnop">
                      <KCalIcon color="beckersKnop" />{kcal} avg kcal
                    </BreakdownItem>
                  )
                }
                {
                  avgPoints && (
                    <BreakdownItem variant="pica" color="beckersKnop">
                      <PointsIcon color="beckersKnop" />{avgPoints} avg pts
                    </BreakdownItem>
                  )
                }
              </BreakdownSection>
            )}
            {description && (
              <DescriptionContainer fixedHeight={fixedHeight}>
                <StyledDescription
                  description={description}
                  onFocus={() => {
                    setShowExtraDetailsContent(false);
                    setDismissFocusId(null);
                  }}
                  onExpand={scrollDescription}
                  disableTransition
                  isExpandable={fixedHeight}
                />
              </DescriptionContainer>
            )}
          </ScrollableSection>
          {classDetailsExtraEnabled && (
            <div ref={extraDetailsWrapper}>
              <ClassDetailsExtraInformation
                similarClasses={similarClasses}
                trainers={trainers}
                setDismissFocusId={setDismissFocusId}
                showExtraDetailsContent={showExtraDetailsContent}
                mappedTimeline={mappedTimeline}
                onTabBarFocus={() => {
                  scrollPage();
                  setDismissFocusId('INFO_SECTION');
                  setShowExtraDetailsContent(true);
                }}
              />
            </div>
          )}
        </Content>
      </SpatialNavParent>
    </Wrapper>
  );
};

export default ClassDetailsLayout;

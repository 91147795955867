import React, { useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import RailCard from 'ui/components/atoms/rail-card';
import { Link } from 'react-router-dom';
import Padlock from 'ui/components/atoms/icons/padlock';
import Equipment from 'ui/components/atoms/icons/equipment';
import { useSpatialTarget } from 'utils/spatial-nav';
import CardBadge from 'ui/components/atoms/card-badge';
import { pluralise } from 'ui/components/utils/pluralise';
import TestingBadge from 'ui/components/atoms/testing-badge';

export type Props = {
  name: string,
  durationWeeks: number,
  backgroundImage?: string,
  trainer?: string,
  to: string,
  focused?: boolean,
  locked?: boolean,
  equipment?: boolean,
  setFocus?: (focus?: string) => void,
  dataTest?: string,
  autofocus?: boolean,
  inTesting?: boolean,
};

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 0.067rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 ${({ theme }) => theme.spacing.xxs};
  position: relative;
  text-align: center;
`;

const CardLink = styled(Link)`
  display: block;
`;

const PlanDuration = styled(Typography)<{ focused: boolean }>`
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  ${({ focused }) => (focused ? 'margin-left: 1.5rem' : '')};
`;

const PlanTitle = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BadgeContainer = styled(Typography)`
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
`;

const LockedContainer = styled(Typography)`
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
`;

const StyledPadlock = styled(Padlock)`
  width: 0.6rem;
`;

const StyledTestingBadge = styled(TestingBadge)`
  top: 0.5rem;
  right: 0.5rem;
`;

const TrainingPlanCard = ({
  name,
  durationWeeks,
  backgroundImage,
  to,
  locked = false,
  equipment = false,
  dataTest,
  autofocus = false,
  inTesting,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
  });

  const card = (
    <RailCard size="large" focus={focused} boxSize="large" box>
      {inTesting && <StyledTestingBadge />}
      <CardContent
        style={{ backgroundImage: `url(${backgroundImage}?size=852x480&mask=class-card-out-of-focus-gradient-v1)` }}
      >
        <PlanDuration
          focused={focused}
          uppercase
          size="xs"
          variant="pica"
        >
          {durationWeeks} {pluralise(durationWeeks, 'week', 'weeks')}
        </PlanDuration>
        {equipment && (
          <BadgeContainer>
            <CardBadge>
              <Equipment />
            </CardBadge>
          </BadgeContainer>
        )}
        <PlanTitle
          color="white"
          variant="paragon"
          data-test={dataTest}
          uppercase
          size="l"
        >
          { name }
        </PlanTitle>
        {locked && (
          <LockedContainer>
            <CardBadge outline={false}>
              <StyledPadlock />
            </CardBadge>
          </LockedContainer>
        )}
      </CardContent>
    </RailCard>
  );

  return (
    <CardLink ref={elRef} to={to} data-test="card-link">
      { card }
    </CardLink>
  );
};

export default TrainingPlanCard;

/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentClass } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppState } from 'state';

export type Props = {
  component: ((args: any) => (JSX.Element) | null) | ComponentClass<any>;
  exact?: boolean;
  path?: string[];
};

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const loggedIn = useAppState(({ auth }) => auth.loggedIn);

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (!loggedIn) {
            return <Redirect to={{ pathname: '/login', state: { returnRoute: props.location } }} />;
          }

          return <Component {...props} />;
        }
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }: Props) => {
  const loggedIn = useAppState(({ auth }) => auth.loggedIn);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn) {
          const path = props.location?.state?.returnRoute || { pathname: '/browse' };
          const fromSignUpRoute = props.location.pathname.includes('signup');

          return fromSignUpRoute
            ? <Redirect to={{ pathname: '/premium', search: `?fromSignUpRoute=${fromSignUpRoute}` }} />
            : <Redirect to={path} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export {
  ProtectedRoute,
  LoginRoute,
};

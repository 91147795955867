type User = {
  handle?: string,
  firstname?: string | null,
  lastname?: string | null,
};

type Options = {
  full?: boolean,
};

const formatName = ({ firstname, lastname }: User, { full = false }: Options = {}) => {
  if (firstname) {
    return lastname ? `${firstname} ${full ? lastname : lastname[0]}` : firstname;
  }

  return '';
};

export default formatName;

import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { useAppState } from 'state';
import { ScreenSaverImage } from 'app/on-tv/organisms/screen-saver';

type Props = {
  imageReel: ScreenSaverImage[],
};

const REEL_TRANSITION_TIME_SECONDS = 30;

const zoom = keyframes`
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.5, 1.5);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
`;

const Wrapper = styled.div<{show: boolean}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const BackgroundImage = styled.img`
  width: 100%;
  animation: ${zoom} ${REEL_TRANSITION_TIME_SECONDS}s linear, ${fadeIn} 2s linear;
  animation-fill-mode: forwards;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${rem(96)};
  left: ${rem(165)};
  opacity: 0.8;
`;

const Title = styled(Typography)`
  animation: ${slideInFromLeft} 1.5s ease-out;
  animation-fill-mode: forwards;
`;

const Subtitle = styled(Typography)`
  opacity: 0;
  animation: ${fadeIn} 1.5s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
`;

const ScreenSaver = ({ imageReel }: Props) => {
  const { idleTime: showAfter } = useAppState((state) => state.screenSaver);
  // Show the screen saver after a set amount of idle time
  // Listening to any keydown events to reset the current idle time
  const [showScreenSaver, setShowScreenSaver] = useState(false);
  const [imageReelIndex, setImageReelIndex] = useState(0);
  const { canDisplay } = useAppState((state) => state.screenSaver);
  const shouldPreventKeydownPropagation = useRef(showScreenSaver);

  useEffect(() => {
    if (!canDisplay) {
      return () => {};
    }

    let timeout: number;

    const initTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        shouldPreventKeydownPropagation.current = true;
        setShowScreenSaver(true);
      }, showAfter * 1000);
    };

    const resetTimer = (e: Event) => {
      if (shouldPreventKeydownPropagation.current) {
        e.preventDefault();
        e.stopPropagation();
      }

      shouldPreventKeydownPropagation.current = false;
      setShowScreenSaver(false);
      initTimer();
    };

    // Reset the timer on keydown/click
    document.addEventListener('keydown', resetTimer, true);
    document.addEventListener('click', resetTimer, true);

    initTimer();

    return () => {
      document.removeEventListener('keydown', resetTimer, true);
      document.removeEventListener('click', resetTimer, true);
      clearTimeout(timeout);
    };
  }, [canDisplay, showAfter]);

  useEffect(() => {
    if (!showScreenSaver) {
      return () => {};
    }

    const imageChangeInterval = setInterval(() => (
      setImageReelIndex((prev) => (prev < imageReel.length - 1 ? prev + 1 : 0))
    ), REEL_TRANSITION_TIME_SECONDS * 1000);

    return () => clearInterval(imageChangeInterval);
  }, [imageReel.length, showScreenSaver]);

  const { url, title, subText } = imageReel[imageReelIndex];

  return (
    <Wrapper show={showScreenSaver} tabIndex={0}>
      <BackgroundImage
        src={`${url}?size=1920x1080`}
        key={`bg-${imageReelIndex}`}
      />
      <TextContainer>
        <Title key={`title-${imageReelIndex}`} variant="paragon">{title}</Title>
        <Subtitle key={`subtitle-${imageReelIndex}`}>{subText}</Subtitle>
      </TextContainer>
    </Wrapper>
  );
};

export default ScreenSaver;

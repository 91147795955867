import React, { useEffect, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import {
  StartWorkoutMutation,
  StartWorkoutMutationVariables,
  WorkoutFinishingLoaderQuery,
  WorkoutFinishingLoaderQueryVariables,
} from 'app/on-tv/types/graphql';
import useLogger from 'app/hooks/use-logger';
import useRoutes from 'utils/use-routes';
import useConfig from 'app/on-tv/config-provider';

import useFinishWorkout from 'app/on-tv/hooks/use-finish-workout';
import { StartWorkout as START_WORKOUT_MUTATION } from 'src/graphql/mutations/start-workout.gql';
import {
  WorkoutFinishingLoader as WORKOUT_FININSHING_LOADER,
} from 'src/app/on-tv/organisms/workout-finishing-loader/workout-finishing-loader.gql';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import FullPageModal from 'ui/components/layouts/full-page-modal';
import Typography from 'ui/components/atoms/typography';
import BaseButton from 'ui/components/atoms/button';
import { rem } from 'ui/helpers';

import { useDispatch } from 'state';
import { endStream } from 'actions/video';
import { FormattedStatsEvent } from 'app/on-tv/pages/lesson-instance';
import handleExternalLink from 'utils/external-link';
import storage from 'utils/local-storage-fallback';
import { getFutureDate } from 'utils/dates';

type WorkoutFinishingLoaderProps = {
  userId: number,
  errorOverlayDismissAction: () => void,
  lessonMediaId: string,
  finishingWorkoutInfo: {
    currentTime?: number,
    duration?: number,
    lastStatsEvent?: FormattedStatsEvent,
  },
  workoutId: number,
};

const Header = styled(Typography)`
  padding: ${rem(10)};
`;

const Subheader = styled(Typography)`
  padding: ${rem(10)};
`;

const Button = styled(BaseButton)`
  margin: ${rem(10)};
  width: ${rem(400)};
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const WorkoutFinishing = ({
  userId,
  lessonMediaId,
  errorOverlayDismissAction,
  workoutId,
  finishingWorkoutInfo,
}: WorkoutFinishingLoaderProps) => {
  const dispatch = useDispatch();

  const { finishWorkout, error: finishWorkoutError } = useFinishWorkout(userId, {
    ...finishingWorkoutInfo,
    workoutId,
  }, true);

  useEffect(() => {
    finishWorkout();
    dispatch(endStream({ lessonMediaId }));
  }, [dispatch, finishWorkout, lessonMediaId]);

  if (finishWorkoutError) {
    return <ErrorOverlay error={finishWorkoutError} onDismiss="action" onDismissAction={errorOverlayDismissAction} />;
  }

  return (
    <LoadingOverlay />
  );
};

type Props = {
  userId: number,
  workoutId?: number,
  lessonMediaId: string,
  lessonInstanceId: string,
  finishingWorkoutInfo: {
    duration?: number,
    currentTime?: number,
    lastStatsEvent?: FormattedStatsEvent,
  },
};

const FEEDBACK_EXPIRATION_KEY = 'web_app_beta_typeform_submission_expiration';
const FEEDBACK_DISMISS_COUNT_KEY = 'web_app_beta_modal_dismiss_count';

const checkIfFeedbackFormShouldBeShown = (isWebApp: boolean, feedbackFormUrl: string, maxDismissalCount: number) => {
  const expirationDate = storage.getItem(FEEDBACK_EXPIRATION_KEY);
  const dismissalCount = storage.getItem(FEEDBACK_DISMISS_COUNT_KEY) || 0;

  if (!expirationDate && isWebApp) {
    return true;
  }

  const hasSurpassedDismissalCap = Number(dismissalCount) > maxDismissalCount;
  if (!expirationDate || !feedbackFormUrl || hasSurpassedDismissalCap) {
    return false;
  }

  const expirationDateObject = new Date(JSON.parse(expirationDate));
  const currentDate = new Date();
  const isExpired = expirationDateObject < currentDate;

  if (isExpired && isWebApp) {
    return true;
  }

  return false;
};

const WorkoutFinishingLoader = ({
  userId,
  workoutId: defaultWorkoutId,
  lessonMediaId,
  lessonInstanceId,
  finishingWorkoutInfo,
}: Props) => {
  const { routes, redirect } = useRoutes();
  const { config } = useConfig();
  const logger = useLogger('on-tv:workout-finishing-screen');
  const isWebApp = config.APP_TYPE === 'web';
  const feedbackFormUrl = config.WEB_APP_BETA_FEEDBACK_TYPEFORM_URL;
  const shouldInitiallyShowFeedbackModal = useMemo(
    () => checkIfFeedbackFormShouldBeShown(isWebApp, feedbackFormUrl, config.BETA_FEEDBACK_MODAL_MAX_DISMISSAL_COUNT),
    [isWebApp, feedbackFormUrl, config.BETA_FEEDBACK_MODAL_MAX_DISMISSAL_COUNT],
  );
  const [shouldShowFeedbackModal, setShouldShowFeedbackModal] = useState(shouldInitiallyShowFeedbackModal);

  const onDismiss = () => {
    setShouldShowFeedbackModal(false);

    const currentDismissCount = storage.getItem(FEEDBACK_DISMISS_COUNT_KEY) || 0;
    const newDismissCount = Number(currentDismissCount) + 1;
    storage.setItem(FEEDBACK_DISMISS_COUNT_KEY, JSON.stringify(newDismissCount));
  };

  const handleTakeSurvey = () => {
    handleExternalLink(feedbackFormUrl);

    setShouldShowFeedbackModal(false);
    // set a date in advance for the user to retake the survey
    const expirationDate = getFutureDate(config.BETA_FEEDBACK_SURVEY_RETAKE_DAYS || 30);
    storage.setItem(FEEDBACK_EXPIRATION_KEY, JSON.stringify(expirationDate));
    // reset the dismissal count
    storage.setItem(FEEDBACK_DISMISS_COUNT_KEY, JSON.stringify(0));
  };

  const [
    loadLessonInstance,
    lessonInstanceInfo,
  ] = useLazyQuery<WorkoutFinishingLoaderQuery, WorkoutFinishingLoaderQueryVariables>(
    WORKOUT_FININSHING_LOADER,
    {
      variables: { lessonInstanceId, userId },
      onError: (e) => logger.error('WorkoutFinishingLoaderQuery error', { error: e }),
    },
  );
  const lessonInstance = lessonInstanceInfo.data?.lessonInstance;
  const lessonInstanceWorkout = lessonInstance?.workouts.edges?.find(({ node }) => node.owner?.id === userId);

  const [startStreamableWorkout, startedWorkoutInfo] = useMutation<StartWorkoutMutation, StartWorkoutMutationVariables>(
    START_WORKOUT_MUTATION,
    {
      variables: {
        userId,
        lessonMediaId,
        lessonInstanceId,
      },
    },
  );
  const startedWorkout = startedWorkoutInfo.data?.startStreamableWorkout.workout;

  const workoutId = defaultWorkoutId || lessonInstanceWorkout?.node.id || startedWorkout?.id;
  const error = lessonInstanceInfo.error || startedWorkoutInfo.error;

  const errorOverlayDismissAction = useCallback(() => {
    if (workoutId) {
      return redirect({ route: routes.WORKOUT_SUMMARY, params: { id: workoutId }, replaceStack: true });
    }

    return redirect({ route: routes.BROWSE, replaceStack: true });
  }, [redirect, routes, workoutId]);

  useEffect(() => {
    // if we're in a loading/error state we are handling this so can return
    if (lessonInstanceInfo.loading || startedWorkoutInfo.loading || error) {
      return;
    }

    // if we have a workoutId already there's no need to continue
    if (workoutId) {
      return;
    }

    // if we haven't already, requery the lessonInstance to make sure we haven't missed a subscription
    // update with the workoutId
    if (!lessonInstanceInfo.called) {
      loadLessonInstance();
      return;
    }

    // finally if we still don't have a workoutId then lets create one
    startStreamableWorkout();
  }, [
    userId,
    startStreamableWorkout,
    workoutId,
    loadLessonInstance,
    startedWorkoutInfo.loading,
    lessonInstanceInfo.loading,
    lessonInstanceInfo.called,
    error,
  ]);

  if (lessonInstanceInfo.loading || startedWorkoutInfo.loading || !workoutId) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="action" onDismissAction={errorOverlayDismissAction} />;
  }

  if (shouldShowFeedbackModal) {
    return (
      <FullPageModal>
        <ModalContentContainer>
          <Header variant="double-pica">Fiit need your help!</Header>
          <Subheader><strong>App Version: </strong>{config.VERSION}</Subheader>
          {/* eslint-disable-next-line max-len */}
          <Subheader>To make this web app the best experience possible for you, we would love to hear your feedback.</Subheader>
          <Button
            label="Take short survey"
            variant="cta"
            autofocus
            onClick={handleTakeSurvey}
          />
          <Button
            label="Dismiss"
            onClick={onDismiss}
          />
        </ModalContentContainer>
      </FullPageModal>
    );
  }

  return (
    <WorkoutFinishing
      userId={userId}
      errorOverlayDismissAction={errorOverlayDismissAction}
      lessonMediaId={lessonMediaId}
      workoutId={workoutId}
      finishingWorkoutInfo={finishingWorkoutInfo}
    />
  );
};

export default WorkoutFinishingLoader;

/* eslint-disable consistent-return */
import ApolloClient from 'apollo-client';
import { Observable, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { getPersistedState } from 'state/auth';
import { AuthService, TokenPayload } from 'services/auth';
import { createLogger } from 'utils/logging';
import createCache from 'utils/apollo-client/cache';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import React from 'react';

const logger = createLogger({ label: 'apollo-client' });

type GetClientArgs = {
  authService: AuthService;
  uri: string;
  debug: boolean;
  clientId: string;
  version: string;
  subscriptionsMinimumTokenTTLSeconds: number;
  onTokenRefresh: (payload: TokenPayload) => void;
  onTokenRefreshError: (error: Error) => void;
  subscriptionUri: string;
  virtualStudioId?: string | null;
  contentPackagePermissionsEnabled?: boolean;
};

type AuthHeaders = {
  authorization: string | null;
};
type RefreshRequest = Promise<AuthHeaders>;

type GetAuthHeadersArgs = {
  refreshToken: string | null,
  authorization: string | null,
};

interface Definition {
  kind: string;
  operation?: string;
}

type WebsocketClientContextType = {
  client?: SubscriptionClient;
};

export const WebsocketClientContext = React.createContext<WebsocketClientContextType>({});

export default ({
  authService,
  uri,
  subscriptionUri,
  debug,
  clientId,
  version,
  subscriptionsMinimumTokenTTLSeconds,
  virtualStudioId,
  contentPackagePermissionsEnabled,
  onTokenRefresh,
  onTokenRefreshError,
}: GetClientArgs) => {
  const getAuthHeaders = async ({ refreshToken, authorization }: GetAuthHeadersArgs): RefreshRequest => {
    // If we have an auth header stored, use it
    if (authorization) {
      return { authorization };
    }

    // If there's no auth header, we need to get one - but if we don't have a refresh token then we're fucked
    if (!refreshToken) {
      onTokenRefreshError(new Error('Cannot obtain new authorization without a refresh token'));

      return { authorization: null };
    }

    return authService.refreshToken({ refreshToken })
      .then((response) => {
        logger.debug('refreshing tokens');

        // Store the new tokens
        onTokenRefresh(response);

        // Retrieve the new tokens in the right format (we could also reformat here but :shrug:)
        // This also requires that onTokenRefresh complete it's side effect before proceeding
        const { authorization: newAuthorization } = getPersistedState();

        return { authorization: newAuthorization };
      })
      .catch((refreshError) => {
        logger.error(refreshError);

        onTokenRefreshError(refreshError);

        return { authorization: null };
      });
  };

  const getContext = async ({ headers = {}, ...context }) => {
    const { authorization, refreshToken } = getPersistedState();
    const authHeaders = await getAuthHeaders({ authorization, refreshToken });
    return {
      ...context,
      headers: {
        ...headers,
        ...authHeaders,
      },
    };
  };

  // Create the link to fetch from the api
  const apiLink = new HttpLink({
    uri,
    headers: {
      'X-Fiit-AppClientId': clientId,
      'X-Fiit-VirtualStudioId': virtualStudioId,
      'X-Fiit-AppVersion': version,
      ...(contentPackagePermissionsEnabled ? { 'X-Fiit-ContentPackagePermissionsEnabled': true } : null),
    },
    credentials: 'include',
  });

  // Create an auth link which reads from persisted auth state
  const authLink = setContext((_, context) => getContext(context));

  // Create a link to catch auth errors and refetch tokens when possible
  const refreshTokenLink = onError(({ operation, graphQLErrors = [], forward }) => {
    const { refreshToken } = getPersistedState();
    const authError = graphQLErrors.find(({ name }) => name === 'UnauthorizedError');

    if (authError && refreshToken) {
      return new Observable((observer) => {
        getAuthHeaders({ refreshToken, authorization: null })
          .then(async () => {
            const oldContext = operation.getContext();
            const newContext = await getContext(oldContext);
            operation.setContext(newContext);

            // Retry the request
            forward(operation).subscribe(observer);
          })
          .catch((refreshError: Error) => {
            observer.error(refreshError);
          });
      });
    }
  });

  logger.debug('creating client');

  const wsClient = new SubscriptionClient(subscriptionUri, {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const { refreshToken, authorization, expires } = getPersistedState();

      // No guarantee with have the expiry - if we don't just assume we need a new one
      const expiresInSeconds = expires ? differenceInSeconds(expires, new Date()) : 0;

      // If the token has less than X seconds left before it expires - force refresh it before creating a subscription
      const forceRefresh = expiresInSeconds < subscriptionsMinimumTokenTTLSeconds;

      const headers = await getAuthHeaders({ refreshToken, authorization: forceRefresh ? null : authorization });

      return headers;
    },
  });

  wsClient.onReconnecting(() => {
    const { refreshToken } = getPersistedState();

    if (!refreshToken) {
      wsClient.close();
    }
  });

  // create link to websocket
  const wsLink = new WebSocketLink(wsClient);

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink, // TODO changed this - test again
    authLink.concat(refreshTokenLink).concat(apiLink),
  );

  const client = new ApolloClient({
    cache: createCache(),
    connectToDevTools: debug,
    link,
    name: clientId,
    version,
  });

  return {
    client,
    wsClient,
  };
};

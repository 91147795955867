import { createLogger } from 'utils/logging';

const logger = createLogger({ label: 'fonts' });
const waitForFont = (fontName: string, timeout: number = 3000) => new Promise((resolve) => {
  if (!document?.fonts?.addEventListener) {
    resolve(true);
  }

  const testString = `1em ${fontName}`;

  if (document.fonts.check(testString)) {
    resolve(true);
    return;
  }

  // Held as re-assignable variable to allow cancel in two places
  let fallbackTimeout: number;

  const handler = () => {
    if (document.fonts.check(testString)) {
      resolve(true);
      clearTimeout(fallbackTimeout);
      document.fonts.removeEventListener('loadingdone', handler);
    }
  };

  document.fonts.addEventListener('loadingdone', handler);

  fallbackTimeout = setTimeout(() => {
    resolve(false);
    document.fonts.removeEventListener('loadingdone', handler);
    logger.warn(`Backup font loading timeout hit at ${timeout}ms`);
  }, timeout);
});

export default waitForFont;

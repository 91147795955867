import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgInstagramLogo = ({ className = '' }: IconProps) => (
  <Svg className={className} viewBox="0 0 24 24">
    <defs>
      <path id="dhi4rq9nra" d="M16.446 1C20.06 1 23 3.94 23 7.554v8.892C23 20.06 20.06 23 16.446 23H7.554C3.94 23 1 20.06 1 16.446V7.554C1 3.94 3.94 1 7.554 1zm0 2.213H7.554c-2.397 0-4.34 1.944-4.34 4.34v8.893c0 2.397 1.943 4.34 4.34 4.34h8.892c2.397 0 4.34-1.943 4.34-4.34V7.554c0-2.397-1.943-4.34-4.34-4.34zM12 6.31c3.137 0 5.69 2.552 5.69 5.69 0 3.137-2.553 5.69-5.69 5.69-3.137 0-5.69-2.553-5.69-5.69 0-3.137 2.553-5.69 5.69-5.69zm0 2.213c-1.92 0-3.477 1.557-3.477 3.477S10.08 15.477 12 15.477 15.477 13.92 15.477 12 13.92 8.523 12 8.523zm5.701-3.534c.753 0 1.363.61 1.363 1.364 0 .753-.61 1.363-1.363 1.363s-1.363-.61-1.363-1.363.61-1.364 1.363-1.364z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="if2pep94cb" fill="#fff">
        <use xlinkHref="#dhi4rq9nra" />
      </mask>
      <use fill="#FFF" xlinkHref="#dhi4rq9nra" />
      <g fill="#FFF" mask="url(#if2pep94cb)">
        <path d="M0 0H24V24H0z" />
      </g>
    </g>
  </Svg>
);

export default SvgInstagramLogo;

import {
  Lesson,
  LessonCardFragmentFragment,
  LessonCardNoAuthFragmentFragment,
  LessonDurationRange,
  LessonStatus,
} from 'app/on-tv/types/graphql';
import { trainerNames } from 'ui/components/utils/trainer-names';

type Input = Array<{ node: Pick<Lesson, 'id'> & LessonCardFragmentFragment | LessonCardNoAuthFragmentFragment }>;

export type TransformedLessons = Array<{
  id: number,
  locked: boolean,
  mainImageUrl?: string,
  duration: LessonDurationRange,
  name: string,
  trainerName: string,
  completed: boolean,
  kettlebells: boolean,
  dumbbells: boolean,
  favourited: boolean,
  status: LessonStatus,
}>;

const transformLessonData = (lessonsFromQuery?: Input | null): TransformedLessons => (
  lessonsFromQuery?.map(({ node }) => node)
    .map((lesson) => ({
      id: lesson.id,
      // @ts-ignore this has optional chaining
      locked: !lesson.permissionsWithReasons?.start.value,
      mainImageUrl: lesson.mainImage?.url,
      duration: lesson.durationRange,
      name: lesson.name,
      trainerName: trainerNames(lesson.trainers),
      // @ts-ignore this has optional chaining
      completed: !!lesson.workouts?.totalCount,
      kettlebells: !!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL'),
      dumbbells: !!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS'),
      // @ts-ignore this has a fallback
      favourited: lesson.favouritedByUser || false,
      status: lesson.status,
    })) || []
);

export default transformLessonData;

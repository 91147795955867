import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useRouteMatch } from 'react-router-dom';

import routes, { RouteConfig } from 'app/on-tv/routes';
import useConfig from 'app/on-tv/config-provider';
import SideMenuContent, { MenuItem, width as menuWidth } from 'ui/components/molecules/side-menu';
import BrowserIcon from 'ui/components/atoms/icons/browse';
import FavouriteIcon from 'ui/components/atoms/icons/favourite';
import FilterIcon from 'ui/components/atoms/icons/filter';
import PremiumIcon from 'ui/components/atoms/icons/premium';
import TrainingPlansIcon from 'ui/components/atoms/icons/training-plans';
import TrainersIcon from 'ui/components/atoms/icons/trainers';
import ExclaimIcon from 'ui/components/atoms/icons/exclaim';
import InfoIcon from 'ui/components/atoms/icons/info';
import ScheduleIcon from 'ui/components/atoms/icons/schedule';
import ScreenSaver from 'app/on-tv/organisms/screen-saver';

import { UserProfile as USER_PROFILE_QUERY } from 'app/on-tv/graphql/queries/user-profile-query.gql';

import { UserProfileQuery, UserProfileQueryVariables } from 'app/on-tv/types/graphql';
import { useAppState } from 'state';
import useRoutes from 'utils/use-routes';
import styled from 'styled-components';
import { SpatialNavParent, useSetFocus, useFocusedState } from 'utils/spatial-nav';
import ResumeWorkoutModal from 'app/on-tv/organisms/resume-workout-modal';
import { useResumeWorkoutModal } from 'app/on-tv/organisms/resume-workout-modal/hooks';
import { PageContext } from 'utils/use-page-scroll';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import useDelayOnBack from 'utils/use-delay-on-back';
import { analytics } from 'utils/analytics';
import handleExternalLink from 'utils/external-link';

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

type MenuContainerProps = {
  onClick?: () => void,
  children?: ReactNode,
  className?: string,
};

const MenuContainer = styled.div`
  flex-shrink: 0;
  width: ${menuWidth};
  z-index: 2;
`;

const addPathToList = (arr: string[], { acceptedPaths }: { acceptedPaths?: string[] }) => {
  if (acceptedPaths !== undefined) {
    arr.push(...acceptedPaths);
  }
  return arr;
};
const routeConfig = Object.entries(routes).map(([path, config]) => ({ path, ...config }));
const menuPathsConfig = {
  exact: routeConfig
    .filter(({ menu, exact }) => !!menu && exact)
    .reduce(addPathToList, [] as string[]),
  partial: routeConfig
    .filter(({ menu, exact }) => !!menu && !exact)
    .reduce(addPathToList, [] as string[]),
};

const itemSelected = (acceptedRoutes = routeConfig, currentUrl: string) => {
  const paramRegex = /\/:[A-z]*/;
  // Strip out the params, we only care about the main part matching.
  return acceptedRoutes
    .map((route) => ({
      ...route,
      acceptedPaths: route.acceptedPaths.map((path) => path.replace(paramRegex, '')),
    }))
    .find(({ acceptedPaths }: typeof routeConfig[0]) => (
      acceptedPaths.find((path) => currentUrl.includes(path))
    ));
};

const SideMenu = () => {
  const exactMatch = useRouteMatch({ path: menuPathsConfig.exact, exact: true });
  const partialMatch = useRouteMatch({ path: menuPathsConfig.partial, exact: false });
  const showMenu = !!(exactMatch || partialMatch);
  const setFocus = useSetFocus();
  const focused = useFocusedState();
  const { config } = useConfig();
  const { redirect } = useRoutes();

  const clickToggleMenu = useCallback(() => {
    if (focused) {
      setFocus('PAGE_CONTENT');
    } else {
      setFocus();
    }
  }, [focused, setFocus]);

  const currentUrl = exactMatch?.url || partialMatch?.url || '';

  const loggedIn = useAppState((state) => state.auth.loggedIn);
  const { data } = useQuery<UserProfileQuery, UserProfileQueryVariables>(USER_PROFILE_QUERY, { skip: !loggedIn });

  const menuAction = (route: RouteConfig) => () => {
    redirect({ route, replaceStack: true });
    setFocus('PAGE_CONTENT');
  };

  const menuExternalAction = (item: string) => () => {
    if (item === 'FEEDBACK') {
      handleExternalLink(config.WEB_APP_BETA_FEEDBACK_TYPEFORM_URL);
    }
    if (item === 'UPDATES') {
      handleExternalLink('https://fiit.tv/get-started/web-app-updates');
    }
  };

  if (!showMenu) {
    return null;
  }

  const showPremiumUpsellItem = data?.auth?.features?.promoteUpgrade?.value;

  const showDebugItem = data?.auth?.user?.permissions?.debug?.value;

  const showFeedbackItem = config.WEB_APP_BETA_FEEDBACK_TYPEFORM_URL;

  const showFavourites = loggedIn;

  const isWeb = config.APP_TYPE === 'web';

  const menuItemsList: MenuItem[] = [
    {
      id: 'browse',
      label: 'Browse',
      icon: BrowserIcon,
      // If it's not one of the other pages, default to browse being selected
      selected: !itemSelected([
        routes.PROFILE,
        routes.FAVOURITES,
        routes.PREMIUM_UPSELL,
        routes.TRAINING_PLANS,
        routes.ACTIVE_TRAINING_PLAN,
        routes.TRAINING_PLAN,
        routes.TRAINERS,
        routes.TRAINER,
        routes.FILTER,
        routes.DEBUG,
      ], currentUrl),
      handleAction: menuAction(routes.BROWSE),
    },
    {
      id: 'filter',
      label: 'Filter',
      icon: FilterIcon,
      selected: !!itemSelected([routes.FILTER], currentUrl),
      handleAction: menuAction(routes.FILTER),
    },
    ...(showFavourites ? [{
      id: 'favourites',
      label: 'Favourites',
      icon: FavouriteIcon,
      selected: !!itemSelected([routes.FAVOURITES], currentUrl),
      handleAction: menuAction(routes.FAVOURITES),
    }] : []),
    ...(showPremiumUpsellItem ? [{
      id: 'subscribe',
      label: 'Subscribe',
      icon: PremiumIcon,
      selected: !!itemSelected([routes.PREMIUM_UPSELL], currentUrl),
      handleAction: menuAction(routes.PREMIUM_UPSELL),
    }] : []),
    {
      id: 'training-plans',
      label: 'Training Plans',
      icon: TrainingPlansIcon,
      selected: !!itemSelected([
        routes.TRAINING_PLANS,
        routes.TRAINING_PLAN,
        routes.ACTIVE_TRAINING_PLAN,
      ], currentUrl),
      handleAction: menuAction(routes.TRAINING_PLANS),
    },
    {
      id: 'trainers',
      label: 'Trainers',
      icon: TrainersIcon,
      selected: !!itemSelected([
        routes.TRAINER,
        routes.TRAINERS,
      ], currentUrl),
      handleAction: menuAction(routes.TRAINERS),
    },
    ...(isWeb && showFeedbackItem ? [{
      id: 'feedback',
      label: 'Feedback',
      icon: ScheduleIcon,
      selected: false,
      handleAction: menuExternalAction('FEEDBACK'),
    }] : []),
    ...(isWeb ? [{
      id: 'changelog',
      label: 'Updates',
      icon: InfoIcon,
      selected: false,
      handleAction: menuExternalAction('UPDATES'),
    }] : []),
    ...(showDebugItem ? [{
      id: 'debug',
      label: 'Debug',
      icon: ExclaimIcon,
      selected: !!itemSelected([routes.DEBUG], currentUrl),
      handleAction: menuAction(routes.DEBUG),
    }] : []),
  ];

  return (
    <MenuContainer
      data-test="menu-wrapper"
      onClick={clickToggleMenu}
    >
      <div data-test-state={focused ? 'open' : 'closed'}>
        <SideMenuContent
          items={menuItemsList}
          user={data?.auth?.user}
          expanded={focused}
          version={config.VERSION}
          autofocus={focused}
        />
      </div>
    </MenuContainer>
  );
};

const PageContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

type PageContainerProps = {
  children: JSX.Element,
};

const loginRoutes = ['/', '/login'];

const PageContainer = ({ children }: PageContainerProps) => {
  const userId = useAppState((state) => state.auth.userId);
  const pageContentRef = useRef<HTMLDivElement | null>(null);
  const delayed = useDelayOnBack();
  const location = useLocation();

  // https://segment.com/docs/connections/spec/identify recommends making an Identify call
  // Upon loading any pages that are accessible by a logged in user
  useEffect(() => {
    if (userId) {
      analytics.identify(userId.toString());
    }
  }, [userId, location]);

  useEffect(() => {
    if (pageContentRef.current && !location.state?.preventScrollReset) {
      pageContentRef.current.scrollTo(0, 0);
    }

    const route = itemSelected(routeConfig, location.pathname);

    if (route && !loginRoutes.includes(location.pathname)) {
      analytics.page(route.path, { path: location.pathname });
    }
  }, [location]);

  const scrollTo = useCallback((top: number) => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo({ top, behavior: 'smooth' });
    }
  }, []);

  // Check if we are allowing the screen saver on this platform
  const { enabled: enableScreenSaver } = useAppState((state) => state.screenSaver);

  const { showResumeWorkoutModal } = useResumeWorkoutModal();

  if (delayed) {
    return <LoadingScreen />;
  }

  if (showResumeWorkoutModal && userId) {
    return <ResumeWorkoutModal userId={userId} />;
  }

  return (
    <Wrapper>
      <SpatialNavParent layout="horizontal">
        <SpatialNavParent layout="vertical" forgetFocus>
          <SideMenu />
        </SpatialNavParent>
        {enableScreenSaver && (
          <ScreenSaver />
        )}
        <SpatialNavParent layout="vertical" id="PAGE_CONTENT">
          <PageContext.Provider value={scrollTo}>
            <PageContent ref={pageContentRef}>
              {children}
            </PageContent>
          </PageContext.Provider>
        </SpatialNavParent>
      </SpatialNavParent>
    </Wrapper>
  );
};

export default PageContainer;

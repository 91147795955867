import { useState, useEffect } from 'react';

const useDelay = (time: number) => {
  const initialValue = Boolean(time);
  const [delayed, setDelayed] = useState(initialValue);

  useEffect(() => {
    if (!time) {
      return () => null;
    }

    const timeoutId = setTimeout(() => {
      setDelayed(false);
    }, time);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [time]);

  return delayed;
};

export default useDelay;

import { Logging } from 'utils/logging';
import useLogger from 'app/hooks/use-logger';
import useConfig from 'app/on-tv/config-provider';
import { useCallback, useMemo } from 'react';

type IsBillingAvailable = (paymentProvider: string) => string;

type CheckIfBillingIsAvailableArgs = {
  paymentProvider: string,
  logger: Logging,
};

type DoStartPaymentFlowArgs = {
  paymentProvider: string,
  sku: string,
};

// TODO: Add link to android repo where this is defined for ref.
type StartPaymentFlow = (paymentProvider: string, sku: string) => void;

type GetProductData = (type: string, skus: string) => void;

type Android = {
  isBillingAvailable: IsBillingAvailable,
  startPaymentFlow: StartPaymentFlow,
  getProductData: GetProductData,
};

// Utilise TS interface merging for window.Android
declare global {
  interface Window {
    Android: Android;
  }
}

const checkIfBillingIsAvailable = ({ paymentProvider, logger }: CheckIfBillingIsAvailableArgs): boolean => {
  try {
    const response = window.Android.isBillingAvailable(paymentProvider);
    return JSON.parse(response).available;
  } catch (error) {
    logger.error('Android billing available check error', { error });
    return false;
  }
};

export const useAmazonBilling = () => {
  const logger = useLogger('on-tv:android-util');
  const { config } = useConfig();
  const paymentProvider = config.APP_TYPE.toUpperCase();

  const billingAvailable = useMemo(() => config.AMAZON_BILLING_ENABLED &&
    window?.Android && checkIfBillingIsAvailable({ paymentProvider, logger }), [config, paymentProvider, logger]);

  // Start the payment flow in android, only do this if billing is available and a sku is found
  const startPaymentFlow = (sku?: string | null) => {
    if (!billingAvailable || !sku) {
      return;
    }
    try {
      window.Android.startPaymentFlow(paymentProvider, sku);
    } catch (error) {
      logger.error('Android start payment flow check error', { error });
    }
  };

  // Get the product data from android, only do this if billing is available and skus are passed in
  const getSkuProductData = useCallback((skus: string[]) => {
    if (!billingAvailable || !skus.length) {
      return;
    }
    const skuString = skus.join(',');
    try {
      logger.debug('Calling APK to get product data', { paymentProvider, skus });
      window.Android.getProductData(paymentProvider, skuString);
    } catch (error) {
      logger.error('Android get product data error', { error });
    }
  }, [billingAvailable, paymentProvider, logger]);
  return {
    billingAvailable,
    startPaymentFlow,
    getSkuProductData,
  };
};

import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';

type Props = {
  pin: string,
  className?: string,
};

const lineOpacity = 0.5;
const lineWidth = '1px';
// As paragon is upper case, it doesn't use it's full line height - to centralise a single line can use an offset
const lineHeightOffset = 0.4;
const verticalPadding = 1;
// How far from the top of the text should the vertical rule start
const verticalRuleInset = 0.4;

// Using a before element to allow us to use opacity 0.5 on the border without rgba because want to use currentColor
const Wrapper = styled.div`
  position: relative;
  padding: ${verticalPadding}rem 1.4rem ${verticalPadding - lineHeightOffset}rem;
  display: inline-block;
  min-width: 24rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1000rem;
    border: ${lineWidth} solid currentColor;
    opacity: ${lineOpacity};
  }
`;

const Character = styled(Typography)`
  position: relative;
  display: inline-block;
  padding: 0 1rem;

  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: ${verticalRuleInset}rem;
    bottom: ${verticalRuleInset + lineHeightOffset}rem;
    width: ${lineWidth};
    background: currentColor;
    opacity: ${lineOpacity};
  }

  &:last-child::after {
    display: none;
  }
`;

const RemoteLoginPin = ({ pin, className = '' }: Props) => (
  <Wrapper className={className}>
    {
      pin.split('').map((char, index) => (
        <Character
          variant="paragon"
          // No stable unique key to use, have to resort to index
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          data-test={char === '.' ? 'pin-char-loading' : 'pin-char'}
        >
          { char }
        </Character>
      ))
    }
  </Wrapper>
);

export default RemoteLoginPin;

import React, { Dispatch, SetStateAction, useState, ElementType } from 'react';
import styled from 'styled-components';
import { SpatialNavParent } from 'utils/spatial-nav';
import { useQuery } from '@apollo/react-hooks';
import useLogger from 'app/hooks/use-logger';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useRoutes from 'utils/use-routes';
import useConfig from 'app/on-tv/config-provider';
import { useAppState } from 'state';

import {
  Filter as FILTER,
  GetFilterOptions as GET_FILTER_OPTIONS,
} from 'app/on-tv/pages/filter/filter.gql';
import {
  StudioType,
  EquipmentOrder,
  LessonDurationRange,
  LessonDifficulty,
  MuscleGroup,
  FilterQuery,
  FilterQueryVariables,
  GetFilterOptionsQuery,
  GetFilterOptionsQueryVariables,
  StudioOrder,
  LessonOrder,
  UserFitnessGoal,
} from 'app/on-tv/types/graphql';

import Button from 'ui/components/atoms/button';
import Typography from 'ui/components/atoms/typography';
import ArrowRight from 'ui/components/atoms/icons/arrow-right';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import WatchIcon from 'ui/components/atoms/icons/watch';
import ScaleIcon from 'ui/components/atoms/icons/scale';
import StudioIcon from 'ui/components/atoms/icons/studio';
import BodyIcon from 'ui/components/atoms/icons/body';
import MovingBodyIcon from 'ui/components/atoms/icons/moving-body';
import ClassesIcon from 'ui/components/atoms/icons/classes';
import TargetIcon from 'ui/components/atoms/icons/target';
import EquipmentIcon from 'ui/components/atoms/icons/weight';
import MusicIcon from 'ui/components/atoms/icons/music';
import PlusIcon from 'ui/components/atoms/icons/plus';

import { labelFromLessonDuration } from 'ui/components/atoms/class-length';

const Wrapper = styled.div`
  margin: 2rem;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const FilterButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

const FilterButton = styled(Button)`
  margin: 0.5rem;
`;

const SubmitButton = styled(Button)<{ isDisabled: boolean }>`
  margin-top: 2rem;
  ${({ isDisabled }) => isDisabled && 'opacity: 0.3;'}

  &:focus {
    ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}
  }
`;

const PageTitle = styled(Typography)`
  margin-bottom: 2rem;
`;

const FilterTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
`;

const FilterRowIcon = styled.div`
  margin-right: 0.5rem;
`;

const getEnumLabel = (val: string, isDurationRange: boolean = false) => {
  if (isDurationRange) {
    return labelFromLessonDuration(val);
  }

  const parsed = val.split('_').join(' ');
  const first = parsed.slice(0, 1);
  const rest = parsed.slice(1, parsed.length).toLowerCase();
  return `${first}${rest}`;
};

export type FilterValue = (
  Partial<StudioType> |
  Partial<StudioType> |
  Partial<LessonDurationRange> |
  Partial<LessonDifficulty> |
  Partial<MuscleGroup> |
  number |
  string
);

type FilterState = (
  StudioType |
  LessonDurationRange |
  LessonDifficulty |
  UserFitnessGoal |
  string |
  number
);

type FiltersArray = Array<{
  icon: ElementType,
  title: string,
  filters: Array<{
    selectFilter: () => void,
    label: string,
    selected: boolean,
    value: FilterValue,
  }>
}>;

type PageContentProps = {
  lessonCount?: number,
  loadingLessonCount: boolean,
  lessonTypeFilters: Array<{ id: number, displayName: string }>,
  bodyPartFilters: Array<{ id: number, displayName: string }>,
  recoverySportFilters: Array<{ id: number, displayName: string }>,
  musicFilters: Array<{ id: string, displayName: string }>,
  equipmentFilters: Array<{ id: string, shortDisplay: string }>,
  trainingGoalFilters: Array<{ id: UserFitnessGoal, displayName: string }>,
  studioFilters: StudioType[],
  muscleFilters: Array<{ id: number, group: MuscleGroup }>,
  setSelectedStudios: Dispatch<SetStateAction<StudioType[]>>,
  setSelectedDurations: Dispatch<SetStateAction<LessonDurationRange[]>>,
  setSelectedDifficulties: Dispatch<SetStateAction<LessonDifficulty[]>>,
  setSelectedMuscleIds: Dispatch<SetStateAction<number[]>>,
  setSelectedBodyPartIds: Dispatch<SetStateAction<number[]>>,
  setSelectedLessonTypeIds: Dispatch<SetStateAction<number[]>>,
  setSelectedRecoverySportIds: Dispatch<SetStateAction<number[]>>,
  setSelectedMusicGenreIds: Dispatch<SetStateAction<string[]>>,
  setSelectedEquipmentIds: Dispatch<SetStateAction<string[]>>,
  setSelectedTrainingGoals: Dispatch<SetStateAction<UserFitnessGoal[]>>,
  selectedStudios: StudioType[],
  selectedDurations: LessonDurationRange[],
  selectedDifficulties: LessonDifficulty[],
  selectedTrainingGoals: UserFitnessGoal[],
  selectedLessonTypeIds: number[],
  selectedRecoverySportIds: number[],
  selectedBodyPartIds: number[],
  selectedMuscleIds: number[],
  selectedMusicGenreIds: string[],
  selectedEquipmentIds: string[],
  showLessonCount: boolean,
};

const PageContent = ({
  lessonCount,
  loadingLessonCount,
  lessonTypeFilters,
  equipmentFilters,
  studioFilters,
  muscleFilters,
  musicFilters,
  recoverySportFilters,
  trainingGoalFilters,
  bodyPartFilters,
  setSelectedStudios,
  setSelectedTrainingGoals,
  setSelectedDurations,
  setSelectedDifficulties,
  setSelectedLessonTypeIds,
  setSelectedEquipmentIds,
  setSelectedBodyPartIds,
  setSelectedRecoverySportIds,
  setSelectedMuscleIds,
  setSelectedMusicGenreIds,
  selectedStudios,
  selectedMusicGenreIds,
  selectedLessonTypeIds,
  selectedBodyPartIds,
  selectedEquipmentIds,
  selectedRecoverySportIds,
  selectedTrainingGoals,
  selectedDurations,
  selectedDifficulties,
  selectedMuscleIds,
  showLessonCount,
} : PageContentProps) => {
  const { routes, push } = useRoutes();
  const logger = useLogger('on-tv:filter-page:content');
  const userId = useAppState((state) => state.auth.userId);

  const submitSearch = () => {
    push({
      route: routes.LESSONS,
      params: { lessonOrder: userId ? LessonOrder.RECOMMENDED : LessonOrder.WORKOUTS_COMPLETED },
      queryParams: {
        fromFilters: true,
        ...(selectedLessonTypeIds.length ? { lessonTypeId: selectedLessonTypeIds } : {}),
        ...(selectedEquipmentIds.length ? { equipmentId: selectedEquipmentIds } : {}),
        ...(selectedStudios.length ? { studioId: selectedStudios } : {}),
        ...(selectedDurations.length ? { durationRange: selectedDurations } : {}),
        ...(selectedDifficulties.length ? { difficulty: selectedDifficulties } : {}),
        ...(selectedMusicGenreIds.length ? { musicGenre: selectedMusicGenreIds } : {}),
        ...(selectedMuscleIds.length ? { muscleId: selectedMuscleIds } : {}),
        ...(selectedTrainingGoals.length ? { trainingGoalId: selectedTrainingGoals } : {}),
        ...(selectedBodyPartIds.length ? { bodyPartId: selectedBodyPartIds } : {}),
        ...(selectedRecoverySportIds.length ? { lessonRecoverySportId: selectedRecoverySportIds } : {}),
      },
    });
  };

  const toggleFilter = (prev: FilterState[], filterValue: any) => {
    if (prev.includes(filterValue)) {
      return prev.filter((value) => value !== filterValue);
    }
    return [...prev, filterValue];
  };

  const handleFilterClick = (filterValue: FilterValue, condition: string) => {
    switch (condition) {
      case 'studio':
        return setSelectedStudios((prev) => toggleFilter(prev, filterValue));
      case 'duration':
        return setSelectedDurations((prev) => toggleFilter(prev, filterValue));
      case 'difficulty':
        return setSelectedDifficulties((prev) => toggleFilter(prev, filterValue));
      case 'muscle':
        return setSelectedMuscleIds((prev) => toggleFilter(prev, filterValue));
      case 'body-part':
        return setSelectedBodyPartIds((prev) => toggleFilter(prev, filterValue));
      case 'lesson-type':
        return setSelectedLessonTypeIds((prev) => toggleFilter(prev, filterValue));
      case 'music-genre':
        return setSelectedMusicGenreIds((prev) => toggleFilter(prev, filterValue));
      case 'equipment':
        return setSelectedEquipmentIds((prev) => toggleFilter(prev, filterValue));
      case 'training-goal':
        return setSelectedTrainingGoals((prev) => toggleFilter(prev, filterValue));
      case 'recovery':
        return setSelectedRecoverySportIds((prev) => toggleFilter(prev, filterValue));
      default:
        logger.error('Invalid condition passed to handleFilterClick', { condition });
        return <ErrorOverlay error onDismiss="back" />;
    }
  };

  const filters: FiltersArray = [
    {
      icon: WatchIcon,
      title: 'Duration',
      filters: [
        ...Object.values(LessonDurationRange).map((value) => ({
          selectFilter: () => handleFilterClick(value, 'duration'),
          label: getEnumLabel(value, true),
          selected: selectedDurations.includes(value),
          value,
        })),
      ],
    },
    {
      icon: StudioIcon,
      title: 'Studio',
      filters: [
        ...studioFilters.map((value) => ({
          selectFilter: () => handleFilterClick(value, 'studio'),
          label: getEnumLabel(value),
          selected: selectedStudios.includes(value),
          value,
        })),
      ],
    },
    {
      icon: ScaleIcon,
      title: 'Class level',
      filters: [
        ...Object.values(LessonDifficulty).map((value) => ({
          selectFilter: () => handleFilterClick(value, 'difficulty'),
          label: getEnumLabel(value),
          selected: selectedDifficulties.includes(value),
          value,
        })),
      ],
    },
    {
      icon: ClassesIcon,
      title: 'Class type',
      filters: [
        ...Object.values(lessonTypeFilters).map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'lesson-type'),
          label: getEnumLabel(displayName),
          selected: selectedLessonTypeIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: BodyIcon,
      title: 'Target body part',
      filters: [
        ...bodyPartFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'body-part'),
          label: getEnumLabel(displayName),
          selected: selectedBodyPartIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: TargetIcon,
      title: 'Target area',
      filters: [
        ...muscleFilters.map(({ id, group }) => ({
          selectFilter: () => handleFilterClick(id, 'muscle'),
          label: getEnumLabel(group),
          selected: selectedMuscleIds.includes(id),
          value: group,
        })),
      ],
    },
    {
      icon: EquipmentIcon,
      title: 'Equipment',
      filters: [
        ...equipmentFilters.map(({ id, shortDisplay }) => ({
          selectFilter: () => handleFilterClick(id, 'equipment'),
          label: getEnumLabel(shortDisplay),
          selected: selectedEquipmentIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: MusicIcon,
      title: 'Music',
      filters: [
        ...musicFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'music-genre'),
          label: getEnumLabel(displayName),
          selected: selectedMusicGenreIds.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: MovingBodyIcon,
      title: 'Best for',
      filters: [
        ...trainingGoalFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'training-goal'),
          label: getEnumLabel(displayName),
          selected: selectedTrainingGoals.includes(id),
          value: id,
        })),
      ],
    },
    {
      icon: PlusIcon,
      title: 'Sports recovery',
      filters: [
        ...recoverySportFilters.map(({ id, displayName }) => ({
          selectFilter: () => handleFilterClick(id, 'recovery'),
          label: getEnumLabel(displayName),
          selected: selectedRecoverySportIds.includes(id),
          value: id,
        })),
      ],
    },
  ];

  const classesLabelCount = `(${loadingLessonCount ? '...' : lessonCount})`;
  const isDisabled = lessonCount === 0;
  return (
    <Wrapper>
      <PageTitle color="whiteOpaque" variant="pica">Filters</PageTitle>
      <SpatialNavParent layout="vertical">
        {filters.map(({ title, filters: rowFilters, icon }, filterRowIndex) => (
          <FilterSection key={title}>
            <FilterTitleContainer>
              <FilterRowIcon color="white" as={icon} />
              <Typography variant="double-pica" size="m">{title}</Typography>
            </FilterTitleContainer>
            <SpatialNavParent layout="grid" columns={4}>
              <FilterButtonContainer data-test={`filter-buttons-${title.split(' ').join('-').toLowerCase()}`}>
                {rowFilters.map(({ label, selected, selectFilter, value }, i: number) => (
                  <FilterButton
                    key={value}
                    label={label}
                    autofocus={filterRowIndex === 0 && i === 0}
                    onClick={selectFilter}
                    selected={selected}
                  />
                ))}
              </FilterButtonContainer>
            </SpatialNavParent>
          </FilterSection>
        ))}
        <SubmitButton
          label={`Show classes ${showLessonCount ? classesLabelCount : ''}`}
          icon={<ArrowRight color="white" />}
          isDisabled={isDisabled}
          onClick={isDisabled ? () => {} : submitSearch}
        />
      </SpatialNavParent>
    </Wrapper>
  );
};

const FilterPage = () => {
  const [selectedStudios, setSelectedStudios] = useState<StudioType[]>([]);
  const [selectedDurations, setSelectedDurations] = useState<LessonDurationRange[]>([]);
  const [selectedLessonTypeIds, setSelectedLessonTypeIds] = useState<number[]>([]);
  const [selectedDifficulties, setSelectedDifficulties] = useState<LessonDifficulty[]>([]);
  const [selectedMuscleIds, setSelectedMuscleIds] = useState<number[]>([]);
  const [selectedBodyPartIds, setSelectedBodyPartIds] = useState<number[]>([]);
  const [selectedRecoverySportIds, setSelectedRecoverySportIds] = useState<number[]>([]);
  const [selectedMusicGenreIds, setSelectedMusicGenreIds] = useState<string[]>([]);
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<string[]>([]);
  const [selectedTrainingGoals, setSelectedTrainingGoals] = useState<UserFitnessGoal[]>([]);
  const logger = useLogger('on-tv:filter-page');

  const { config } = useConfig();
  useDismissEvent();

  const { data, loading, error } = useQuery<FilterQuery, FilterQueryVariables>(FILTER, {
    variables: {
      lessonsMaxLength: config.MAX_LESSONS_IN_GRID,
      lessonCondition: {
        ...(selectedLessonTypeIds.length ? { lessonTypeId: selectedLessonTypeIds } : {}),
        ...(selectedStudios.length ? { studioId: selectedStudios } : {}),
        ...(selectedDurations.length ? { durationRange: selectedDurations } : {}),
        ...(selectedDifficulties.length ? { difficulty: selectedDifficulties } : {}),
        ...(selectedMuscleIds.length ? { muscleId: selectedMuscleIds } : {}),
        ...(selectedBodyPartIds.length ? { bodyPartId: selectedBodyPartIds } : {}),
        ...(selectedMusicGenreIds.length ? { musicGenre: selectedMusicGenreIds } : {}),
        ...(selectedEquipmentIds.length ? { equipmentId: selectedEquipmentIds } : {}),
        ...(selectedTrainingGoals.length ? { trainingGoalId: selectedTrainingGoals } : {}),
        ...(selectedRecoverySportIds.length ? { lessonRecoverySportId: selectedRecoverySportIds } : {}),
      },
    },
    onError: (e) => logger.error('FilterQuery error', { error: e }),
  });

  const {
    data: filterOptionsData,
    loading: filterOptionsLoading,
    error: filterOptionsError,
  } = useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GET_FILTER_OPTIONS, {
    variables: {
      studioCount: 10,
      studioOrderBy: StudioOrder.NATURAL,
      equipmentCount: 10,
      equipmentOrderBy: EquipmentOrder.VALUE,
    },
    onError: (e) => logger.error('GetFilterOptionsQuery graphQL error', { error: e }),
  });

  // We only want to show a loading screen when we don't have all studios, no need to show when
  // getting the updated lesson count
  if (filterOptionsLoading) {
    return <LoadingScreen />;
  }

  if (error || filterOptionsError || !filterOptionsData) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const {
    allMuscles,
    allStudios,
    allLessonTypes,
    allBodyParts,
    allMusicGenres,
    allEquipment,
    allTrainingGoals,
    allLessonRecoverySports,
  } = filterOptionsData;
  const studioFilters = allStudios?.edges?.map(({ node }) => node.id) || [];
  const equipmentFilters = allEquipment?.edges?.map(({ node: { id, shortDisplay } }) => ({ id, shortDisplay })) || [];
  const showLessonCount = Boolean(
    selectedRecoverySportIds.length ||
    selectedTrainingGoals.length ||
    selectedEquipmentIds.length ||
    selectedMusicGenreIds.length ||
    selectedMuscleIds.length ||
    selectedBodyPartIds.length ||
    selectedLessonTypeIds.length ||
    selectedDifficulties.length ||
    selectedDurations.length ||
    selectedStudios.length,
  );

  return (
    <PageContent
      lessonCount={Math.min(data?.allLessons.totalCount || 0, config.MAX_LESSONS_IN_GRID)}
      loadingLessonCount={loading}
      showLessonCount={showLessonCount}
      lessonTypeFilters={allLessonTypes}
      studioFilters={studioFilters}
      muscleFilters={allMuscles}
      musicFilters={allMusicGenres}
      recoverySportFilters={allLessonRecoverySports}
      trainingGoalFilters={allTrainingGoals}
      equipmentFilters={equipmentFilters}
      bodyPartFilters={allBodyParts}
      setSelectedRecoverySportIds={setSelectedRecoverySportIds}
      setSelectedTrainingGoals={setSelectedTrainingGoals}
      setSelectedLessonTypeIds={setSelectedLessonTypeIds}
      setSelectedEquipmentIds={setSelectedEquipmentIds}
      setSelectedStudios={setSelectedStudios}
      setSelectedDurations={setSelectedDurations}
      setSelectedDifficulties={setSelectedDifficulties}
      setSelectedMuscleIds={setSelectedMuscleIds}
      setSelectedBodyPartIds={setSelectedBodyPartIds}
      setSelectedMusicGenreIds={setSelectedMusicGenreIds}
      selectedStudios={selectedStudios}
      selectedRecoverySportIds={selectedRecoverySportIds}
      selectedTrainingGoals={selectedTrainingGoals}
      selectedLessonTypeIds={selectedLessonTypeIds}
      selectedEquipmentIds={selectedEquipmentIds}
      selectedDurations={selectedDurations}
      selectedDifficulties={selectedDifficulties}
      selectedMuscleIds={selectedMuscleIds}
      selectedBodyPartIds={selectedBodyPartIds}
      selectedMusicGenreIds={selectedMusicGenreIds}
    />
  );
};

FilterPage.menu = true;
export default FilterPage;

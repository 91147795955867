import React from 'react';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';

import animationData from './pin-countdown-animation.json';

export type Props = {
  restart: boolean,
};

const AnimatedPinCountdown = ({ restart } : Props) => (
  <LottieAnimation animationData={animationData} autoplay loop restart={restart} />
);

export default AnimatedPinCountdown;

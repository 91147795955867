import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgTrainingPlansIcon = ({ className = '' }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <defs>
        <path
          id="training-plans_svg__a"
          d="M17 2v15.002L12 22H1V2h16zm6 2v14.08L20.5 20 18 18.08V4h5zm-8 0H3v16h8v-4h4V4zm6 5h-1v8.095l.499.383.501-.384V9zm-7.56 4v1.745h-6V13h6zm-7.146 0v1.745h-1.74V13h1.74zm7.146-3.511v1.744h-6V9.49h6zm-7.146 0v1.744h-1.74V9.49h1.74zm7.146-3.49v1.745h-6V5.999h6zm-7.146 0v1.745h-1.74V5.999h1.74zM21 6h-1v1h1V6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="training-plans_svg__b" fill={theme.colors.white}>
          <use xlinkHref="#training-plans_svg__a" />
        </mask>
        <use fill={theme.colors.white} xlinkHref="#training-plans_svg__a" />
        <g fill={theme.colors.white} mask="url(#training-plans_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default SvgTrainingPlansIcon;

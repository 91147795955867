import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFilter = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <path d="M7.18643 1.71672L24 0V14.4086C23.9572 16.4138 22.1277 18.0013 19.8944 18.0013C17.6334 18.0013 15.7888 16.3764 15.7888 14.3711C15.7888 12.3658 17.6334 10.7409 19.8944 10.7409C21.0906 10.7409 22.2026 11.2005 22.9753 11.9793V5.1724L8.21116 6.61035C8.21116 6.61035 8.21116 16.2888 8.21116 16.3698C8.21116 18.3751 6.36659 20 4.10558 20C1.84457 20 0 18.3751 0 16.3698C0 14.3645 1.84457 12.7397 4.10558 12.7397C5.30191 12.7397 6.41381 13.1993 7.18643 13.9784V1.71672Z" fill={fill} />

    </Svg>
  );
};

export default SvgFilter;

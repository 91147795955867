import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 0.6rem;
  height: 0.6rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgFiitPoints = ({ className = '' }: IconProps) => (
  <Svg className={className} viewBox="0 0 24 24">
    <defs>
      <path id="prefix__a" d="M16 1v7h7v8h-7v7H8v-7.001L1 16V8l7-.001V1h8z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <use fill="#000" xlinkHref="#prefix__a" />
      <g fill="#FFF" mask="url(#prefix__b)">
        <path d="M0 0H24V24H0z" />
      </g>
    </g>
  </Svg>
);

export default SvgFiitPoints;

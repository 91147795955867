import { createLogger } from 'utils/logging';
import { LessonInstanceStatus } from 'state/lesson-instance';

const logger = createLogger({ label: 'actions:lesson-instance' });

export enum LessonInstanceActionTypes {
  UNKNOWN = 'LESSON_INSTANCE/UNKNOWN',
  INIT = 'LESSON_INSTANCE/INIT',
  JOIN_LOBBY = 'LESSON_INSTANCE/JOIN_LOBBY',
  LOBBY_UPDATE = 'LESSON_INSTANCE/LOBBY_UPDATE',
  START_WORKOUT_COUNTDOWN = 'LESSON_INSTANCE/START_WORKOUT_COUNTDOWN',
  START_WORKOUT_SESSION = 'LESSON_INSTANCE/START_WORKOUT_SESSION',
  LEADERBOARD_UPDATE = 'LESSON_INSTANCE/LEADERBOARD_UPDATE',
  END_WORKOUT_SESSION = 'LESSON_INSTANCE/END_WORKOUT_SESSION',
  SET_CONNECTED_STATUS = 'LESSON_INSTANCE/SET_CONNECTED_STATUS',
  SET_LESSON_INSTANCE_STATUS = 'LESSON_INSTANCE/SET_LESSON_INSTANCE_STATUS',
}

interface LessonInstanceActionArgs {
  lessonInstanceId: string;
}

export const init = (payload: LessonInstanceActionArgs) => ({
  type: LessonInstanceActionTypes.INIT,
  payload,
});

export type Init = typeof init;

export const joinLobby = (payload: LessonInstanceActionArgs) => ({
  type: LessonInstanceActionTypes.JOIN_LOBBY,
  payload,
});

export type JoinLobby = typeof joinLobby;

type Message = {
  [key: string]: any,
};

interface WebSocketMessageActionArgs extends LessonInstanceActionArgs {
  message: Message;
}

type User = {
  name: string,
  id: string,
  image?: string,
};

type LobbyUpdateMessageDetails = {
  entries: [{
    user: User,
  }],
};
// TODO should these be more strict / validatey?
const parseLobbyUpdateMessage = ({ data }: Message): LobbyUpdateMessageDetails => ({
  entries: data.users.map((user: User) => ({ user })),
});

const lobbyUpdate = ({ lessonInstanceId, message }: WebSocketMessageActionArgs) => ({
  type: LessonInstanceActionTypes.LOBBY_UPDATE,
  payload: {
    lessonInstanceId,
    ...parseLobbyUpdateMessage(message),
  },
});

export type LobbyUpdate = typeof lobbyUpdate;

// TODO not saving this at the moment - ask sync team, should we be using this one, or the lesson-instance one?
const parseStartWorkoutMessage = (message: Message) => ({
  workoutStartDelaySeconds: parseInt(message.data.workoutStartDelaySeconds, 10),
});

const startWorkoutCountdown = ({ lessonInstanceId, message }: WebSocketMessageActionArgs) => ({
  type: LessonInstanceActionTypes.START_WORKOUT_COUNTDOWN,
  payload: {
    lessonInstanceId,
    ...parseStartWorkoutMessage(message),
  },
});

export type StartWorkoutCountdown = typeof startWorkoutCountdown;

export const startWorkoutSession = (payload: LessonInstanceActionArgs) => ({
  type: LessonInstanceActionTypes.START_WORKOUT_SESSION,
  payload,
});

export type StartWorkoutSession = typeof startWorkoutSession;

type LeaderboardMessageEntry = {
  position: number,
  score: number,
  bpm?: number,
  connectedKitMotion?: number,
  user: {
    id: string,
    name: string,
    image?: string,
  },
};

const parseLeaderboardUpdateMessage = ({ time, data }: Message) => ({
  updatedAt: new Date(time),
  totalUserCount: parseInt(data.totalUserCount, 10),
  videoSeconds: data.videoSeconds,
  // TODO do we know what userImage will be
  entries: data.entries.map(({ userId, userName, userImage, ...rest }: any) => ({
    ...rest,
    user: {
      id: userId,
      name: userName,
      ...(userImage ? { image: userImage } : null),
    },
  })) as LeaderboardMessageEntry[],
});

const leaderboardUpdate = ({ lessonInstanceId, message }: WebSocketMessageActionArgs) => ({
  type: LessonInstanceActionTypes.LEADERBOARD_UPDATE,
  payload: {
    lessonInstanceId,
    ...parseLeaderboardUpdateMessage(message),
  },
});

export type LeaderboardUpdate = typeof leaderboardUpdate;

export const endWorkoutSession = (payload: LessonInstanceActionArgs) => ({
  type: LessonInstanceActionTypes.END_WORKOUT_SESSION,
  payload,
});

export type EndWorkoutSession = typeof endWorkoutSession;

const unknown = ({ lessonInstanceId, message }: WebSocketMessageActionArgs) => ({
  type: LessonInstanceActionTypes.UNKNOWN,
  payload: {
    lessonInstanceId,
    message,
  },
});

export type Unknown = typeof unknown;

export const webSocketMessage = ({ lessonInstanceId, message }: WebSocketMessageActionArgs) => {
  switch (message.action) {
    case 'LOBBY_UPDATE':
      return lobbyUpdate({ lessonInstanceId, message });
    case 'START_WORKOUT':
      return startWorkoutCountdown({ lessonInstanceId, message });
    case 'LEADERBOARD_UPDATE':
      return leaderboardUpdate({ lessonInstanceId, message });
    default:
      logger.warn(`Unknown websocket message received: ${JSON.stringify(message)}`);
      return unknown({ lessonInstanceId, message });
  }
};

export type WebSocketMessage = typeof webSocketMessage;

type SetConnectedStatusActionPayload = {
  lessonInstanceId: string,
  connected: boolean,
};

export const setConnectedStatus = (payload: SetConnectedStatusActionPayload) => ({
  type: LessonInstanceActionTypes.SET_CONNECTED_STATUS,
  payload,
});

export type SetConnectedStatus = typeof setConnectedStatus;

type SetLessonInstanceStatusActionPayload = {
  lessonInstanceId: string,
  status: LessonInstanceStatus,
};

export const setLessonInstanceStatus = (payload: SetLessonInstanceStatusActionPayload) => ({
  type: LessonInstanceActionTypes.SET_LESSON_INSTANCE_STATUS,
  payload,
});

export type SetLessonInstanceStatus = typeof setLessonInstanceStatus;

export default { init, webSocketMessage };

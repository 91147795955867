import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { Image } from 'types/graphql';
import Success from 'ui/components/atoms/icons/success';
import { useSpatialTarget } from 'utils/spatial-nav';
import CardFocus from 'ui/components/atoms/card-focus';
import { Link } from 'react-router-dom';
import { pluralise } from 'ui/components/utils/pluralise';
import differenceInHours from 'date-fns/differenceInHours';

type Props = {
  backgroundImage?: Pick<Image, 'url' | 'hexColour'> | null,
  currentWeek: number,
  weeksInPlan: number,
  completeBefore: string,
  name: string,
  autofocus?: boolean,
  to: string,
  onFocus: (target: HTMLElement | null) => void,
};

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  border-radius: ${rem(2)};
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;
  max-height: ${rem(210)};
`;

const PlanName = styled(Typography)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const Title = styled(Typography)``;
const SubTitle = styled(Typography)`
  margin-bottom: 0.5rem;
  opacity: 0.75;
`;

const IconContainer = styled.div`
  display: flex;
`;

const Dash = styled.div`
  padding-right: 0.8rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
`;

const Icon = styled(Success)`
  margin-right: 0.8rem;
  background-color: ${({ theme }) => theme.colors.whiteOpaque};
  border-radius: 1rem;
  padding: ${rem(2)};
`;

const CardWrapper = styled(CardFocus)`
  margin: ${rem(40)} ${rem(40)} 0 0;
`;

const CardLink = styled(Link)`
  display: block;
`;

const generateSubtitle = (completeBefore: string, currentWeek: number): string => {
  const now = new Date();
  const currentHoursLeft = differenceInHours(new Date(completeBefore), now);
  const currentDaysLeft = Math.floor(currentHoursLeft / 24);

  if (currentDaysLeft > 0) {
    return `Week ${currentWeek} starts in ${currentDaysLeft} ${pluralise(currentDaysLeft, 'day', 'days')}`;
  }
  if (currentHoursLeft > 0) {
    return `Week ${currentWeek} starts in ${currentHoursLeft} ${pluralise(currentHoursLeft, 'hour', 'hours')}`;
  }
  return `Week ${currentWeek} starts soon`;
};

const CompletedTrainingPlanHero = ({
  backgroundImage,
  currentWeek,
  weeksInPlan,
  completeBefore,
  autofocus = false,
  to,
  name,
  onFocus,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
  });

  useEffect(() => {
    if (focused) {
      onFocus(elRef.current);
    }
  }, [focused, onFocus]);

  const heroCard = (
    <CardWrapper focus={focused}>
      <HeroWrapper
        ref={elRef}
        style={{
          ...(backgroundImage && { backgroundImage: `url(${backgroundImage.url})` }),
          ...(backgroundImage?.hexColour && { backgroundColor: backgroundImage.hexColour }),
        }}
      >
        <PlanName variant="paragon">{name}</PlanName>
        <Title variant="double-pica">Week {currentWeek} completed great job!</Title>
        {currentWeek !== weeksInPlan && (
          <SubTitle
            variant="pica"
          >
            {generateSubtitle(completeBefore, currentWeek + 1)}
          </SubTitle>
        )}
        <IconContainer>
          {Array.from({ length: weeksInPlan }).map((_, index) => {
            if (index < currentWeek) {
              return (<Icon key={index} />);
            }
            return (<Dash key={index}>-</Dash>);
          })}
        </IconContainer>
      </HeroWrapper>
    </CardWrapper>
  );

  return (
    <CardLink ref={elRef} to={to} data-test="card-link">
      { heroCard }
    </CardLink>
  );
};

export default CompletedTrainingPlanHero;

import React, { useEffect, useRef, useState } from 'react';
import { rem } from 'ui/helpers';
import shaka from 'shaka-player';
import styled from 'styled-components';
import useLogger from 'app/hooks/use-logger';
import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import Button from 'ui/components/atoms/button';
import useConfig from 'app/on-tv/config-provider';

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
`;

const SkipButton = styled(Button)`
  position: absolute;
  bottom: ${rem(100)};
  left: ${rem(150)};
  z-index: 1;
`;

shaka.polyfill.installAll();
type ShakaError = Error & { code: number, category: number };

type Props = {
  setShowVideo: (value: boolean) => void,
};

const IntroVideo = ({ setShowVideo } : Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const player = useRef<any>(new shaka.Player());
  const [videoAttached, setVideoAttached] = useState(false);
  const { config } = useConfig();
  const logger = useLogger('on-tv:intro-video');

  // attach video element to player
  useEffect(() => {
    const video = videoRef.current;
    const currentPlayer = player.current;

    if (!currentPlayer || !video) {
      return () => {};
    }

    logger.debug('Attaching video element');

    currentPlayer.attach(video)
      .then(() => {
        logger.debug('Video attached');
        setVideoAttached(true);
      })
      .catch(() => {
        logger.error('Failed to attach video element');
        setShowVideo(false);
      });

    return () => {
      currentPlayer.detach()
        .then(() => logger.debug('Video element detached'))
        .catch(() => logger.error('Failed to attach video element'));
    };
  }, [setShowVideo, logger]);

  // load video into player
  useEffect(() => {
    const currentPlayer = player.current;

    if (!currentPlayer || !videoAttached) {
      return () => {};
    }

    logger.debug('Loading url');

    currentPlayer.load(
      'https://images-bucket.prod.fiit-static.net/app/uploads/2023/01/18150556/TV-intro-vid_Jan-2023_25mb.mp4',
    )
      .then(() => logger.debug('Loaded url'))
      .catch((e: ShakaError) => {
        // If the error is a LOAD_INTERRUPTED error we ignore as this just means the user has skipped/navigated away
        // from the video
        if (e.code === 7000) {
          return;
        }

        logger.error('error loading intro video, skipping to login splash', e);
        setShowVideo(false);
      });

    return () => {
      currentPlayer.unload()
        .then(() => () => logger.debug('Unloaded url'))
        .catch(() => logger.error('Failed to unload'));
    };
  }, [setShowVideo, videoAttached, logger]);

  // some of the skyboxes don't fire the ended event, even though th value of ended changes...
  // if we're on sky we manually watch for changed to the ended value
  const intervalRef = useRef(0);
  useEffect(() => {
    if (config.APP_TYPE === 'sky') {
      intervalRef.current = setInterval(() => {
        if (videoRef?.current?.ended) {
          setShowVideo(false);
        }
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [config.APP_TYPE, setShowVideo]);

  const onEnded = () => {
    // sky uses the interval above
    if (config.APP_TYPE === 'sky') {
      return;
    }

    setShowVideo(false);
  };

  return (
    <div>
      <StyledVideo
        autoPlay
        ref={videoRef}
        onEnded={onEnded}
      />
      <SkipButton
        label="Skip"
        labelPosition="left"
        icon={<ChevronRight />}
        autofocus
        onClick={() => setShowVideo(false)}
        iconOnly
      />
    </div>
  );
};

export default IntroVideo;

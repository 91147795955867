import React, { useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAppState } from 'state';
import { useHistory } from 'react-router-dom';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import styled from 'styled-components';
import useRoutes from 'utils/use-routes';
import { UserProfile as USER_PROFILE_QUERY } from 'app/on-tv/graphql/queries/user-profile-query.gql';
import { UserProfileQuery, UserProfileQueryVariables } from 'app/on-tv/types/graphql';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 25px;
`;

const DebugLayout = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const { redirect, routes } = useRoutes();
  const loggedIn = useAppState((state) => state.auth.loggedIn);
  const { data } = useQuery<UserProfileQuery, UserProfileQueryVariables>(USER_PROFILE_QUERY, { skip: !loggedIn });

  const show = data?.auth?.user?.permissions?.debug?.value;

  useEffect(() => {
    if (!show) {
      redirect({ route: routes.BROWSE, replaceStack: true });
    }
  }, [redirect, routes.BROWSE, show]);

  useDismissEvent();

  return show ? (
    <>
      <Wrapper>
        { children }
        <Button
          label="Go back"
          variant="cta"
          autofocus
          onClick={() => history.goBack()}
          icon={<ChevronLeft />}
        />
      </Wrapper>
    </>
  ) : null;
};

export default DebugLayout;

import React from 'react';
import styled from 'styled-components';

import Button from 'ui/components/atoms/button';
import FullPageModal from 'ui/components/layouts/full-page-modal';
import Play from 'ui/components/atoms/icons/play';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { SpatialNavParent } from 'utils/spatial-nav';

type Props = {
  mediaOptions: Array<{
    dashUuid?: string | null,
    dashUrl?: string | null,
    id: string,
    status: string,
  }>,
  onSelect: (id: string) => void,
};

const MediaOptionButton = styled(Button)`
  margin: ${rem(10)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  align-self: center;
  margin-bottom: ${rem(60)};
`;

const ChooseLessonMediaModal = ({ mediaOptions, onSelect }: Props) => {
  const options = mediaOptions.map((option, id) => (
    <MediaOptionButton
      onClick={() => onSelect(option.id)}
      label={option.id}
      autofocus={id === 0}
      key={option.id}
    />
  ));
  return (
    <FullPageModal icon={<Play />}>
      <Wrapper>
        <Title variant="double-pica">Media Options</Title>
        <SpatialNavParent layout="vertical">
          {options}
        </SpatialNavParent>
      </Wrapper>
    </FullPageModal>
  );
};

export default ChooseLessonMediaModal;

import React from 'react';

import animationData from 'ui/components/atoms/animated-icons/connect-device.json';
import LottieAnimation from 'ui/components/atoms/animated-icons/lottie-animation';

type Props = {
  className?: string,
};

const AnimatedConnectDevice = ({ className = '' }: Props) => (
  <LottieAnimation className={className} animationData={animationData} autoplay loop />
);

export default AnimatedConnectDevice;

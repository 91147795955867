/* eslint-disable max-len */
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import shaka from 'shaka-player';

import LoginPageLayout from 'ui/components/layouts/login-page';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';
import { rem } from 'ui/helpers';
import useRoutes from 'utils/use-routes';
import useConfig from 'app/on-tv/config-provider';
import { useHistory } from 'react-router';
import LeaveFiitScreen, { useLeaveFiitModal } from 'app/on-tv/organisms/leave-fiit-modal';
import QRCode from 'ui/components/atoms/qr-code';
import { BulletedList } from 'ui/components/atoms/bulleted-list';
import { ListItem } from 'ui/components/atoms/numbered-list';
import Divider from 'ui/components/atoms/divider';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import { SpatialNavParent } from 'utils/spatial-nav';
import IntroVideo from 'app/on-tv/pages/login-splash/intro-video';
import storage from 'utils/local-storage-fallback';
import { useAppState } from 'state';
import handleExternalLink from 'utils/external-link';

const StyledLink = styled(Typography)`
  margin: 0 0.25rem;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(102)};
  max-width: 65%;
  flex-direction: column;
`;

const Account = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? rem(width) : rem(440))};
`;

const AccountTypography = styled(Typography)`
  display: block;
  margin-bottom: ${rem(20)};
`;

const footer = (platform: string) => (platform !== 'web' ? (
  <>
    <Typography color="beckersKnop">Need help? Visit</Typography>
    <StyledLink variant="pica">help.fiit.tv</StyledLink>
  </>
) : null);

const StyledButton = styled(Button)<{ widthPercentage: number}>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;

const QRContainer = styled.div<{small: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0.2rem;
  border-radius: ${rem(2)};
  width: ${({ small }) => `${rem(small ? 100 : 150)}`};
  height: ${({ small }) => `${rem(small ? 140 : 190)}`};
  min-width: ${({ small }) => `${rem(small ? 100 : 150)}`};
  min-height: ${({ small }) => `${rem(small ? 140 : 190)}`};
  margin-right: 0.5rem;
`;

const StyledQRCode = styled(QRCode)`
  padding-bottom: 0.2rem;
`;

const StyledBulletedList = styled(BulletedList)`
  margin-left: 1rem;
  margin-top: 0.5rem;
`;

const StyledListItem = styled(ListItem)`
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentTypography = styled(Typography)`
  width: 90%;
  color: ${({ theme }) => theme.colors.beckersKnop};
  margin: ${({ theme }) => `0 0 ${theme.spacing.m}`};
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${({ theme }) => theme.spacing.l};
  margin: ${rem(30)} 0;
`;

shaka.polyfill.installAll();

const getBulletPoints = (platform: string, tvBaseLink: string): ReactNode[] => {
  switch (platform) {
    case 'sky':
      return [
        <StyledListItem key={0}>
          <Typography size="s">1,500 classes & over 40 training plans</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">HIIT, strength, spin, tread, yoga, pilates, breathwork, Barre & more</Typography>
        </StyledListItem>,
      ];
    case 'amazon':
      return [
        <StyledListItem key={0}>
          <Typography size="s">Take hundreds of workouts - Hiit, strength, yoga & more</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">Sign up below to start your free trial</Typography>
        </StyledListItem>,
        <StyledListItem key={2}>
          <Typography size="s">Offer to new customers only. Cancel any time.</Typography>
        </StyledListItem>,
      ];
    case 'comcast':
    case 'videotron':
    case 'rdk':
      return [
        <StyledListItem key={0}>
          <Typography size="s">Take hundreds of workouts — HIIT, strength, yoga & more</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">
            Go to
            <Typography color="white" variant="pica"> {tvBaseLink} </Typography>
            to get 30 days free
          </Typography>
        </StyledListItem>,
        <StyledListItem key={2}>
          <Typography size="s">Scan the QR code to get started</Typography>
        </StyledListItem>,
      ];
    case 'virgin':
      return [
        <StyledListItem key={0}>
          <Typography size="s">2000+ workouts, 40+ Training Plans and Monthly Challenges</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">
            Go to
            <Typography color="white" variant="pica"> {tvBaseLink} </Typography>
            or scan the QR code to get started
          </Typography>
        </StyledListItem>,
      ];
    case 'rogers':
    case 'shaw':
      return [
        <StyledListItem key={0}>
          <Typography size="s">Take hundreds of workouts — HIIT, strength, yoga & more</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">
            Go to
            <Typography color="white" variant="pica"> {tvBaseLink} </Typography>
            to get 14 days free
          </Typography>
        </StyledListItem>,
        <StyledListItem key={2}>
          <Typography size="s">Scan the QR code to get started</Typography>
        </StyledListItem>,
      ];
    case 'samsung':
      return [
        <StyledListItem key={0}>
          <Typography size="s">Take hundreds of workouts — HIIT, strength, yoga & more</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">
            Visit
            <Typography color="white" variant="pica"> {tvBaseLink} </Typography>
            to get 30 days free
          </Typography>
        </StyledListItem>,
        <StyledListItem key={2}>
          <Typography size="s">Scan the QR code to get started</Typography>
        </StyledListItem>,
      ];
    case 'android':
      return [
        <StyledListItem key={0}>
          <Typography size="s">This is FIIT on an Android TV!</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">Cool right?</Typography>
        </StyledListItem>,
      ];
    case 'lg':
      return [
        <StyledListItem key={0}>
          <Typography size="s">This is FIIT on an LG TV!</Typography>
        </StyledListItem>,
        <StyledListItem key={1}>
          <Typography size="s">Cool right?</Typography>
        </StyledListItem>,
      ];
    default:
      return [];
  }
};

const getContent = (platform: string) => (
  platform === 'web' ? (
    <>
      <ContentTypography as="p">Functional Fitness. HIIT. Strength. Circuits. Mobility. Stretching. Pilates. Yoga. Breathwork. Level up your performance with hundreds of on-demand classes, group workouts and training plans - from entry level to advanced.</ContentTypography>
      <ContentTypography as="p">Need help? Visit<StyledLink variant="pica"><a href="https://help.fiit.tv/">help.fiit.tv</a></StyledLink></ContentTypography>
    </>
  ) : null
);

const LoginSplash = () => {
  const { routes } = useRoutes();
  const history = useHistory();
  const { config } = useConfig();
  const [showVideo, setShowVideo] = useState(false);
  const hideIntroVideo = useQueryParam().get('hideIntroVideo');
  const showDefaultContent = !['amazon', 'web', 'sky'].includes(config.APP_TYPE);
  const signUpFlowEnabled = useAppState((state) => state.flag.signUpFlowEnabled);
  const notLoggedInModeEnabled = useAppState((state) => state.flag.notLoggedInModeEnabled);

  useEffect(() => {
    if (hideIntroVideo || config.HIDE_INTRO_VIDEO) {
      return;
    }

    // For MVP we are showing the video each time a user hits this page, this is here to avoid seeing this locally
    if (!config.SHOW_INTRO_VIDEO_ONCE) {
      setShowVideo(true);
      return;
    }

    const storedState = storage.getItem('introVideoPlayed');
    if (!storedState) {
      setShowVideo(true);
      storage.setItem('introVideoPlayed', 'true');
    }
  }, [config.SHOW_INTRO_VIDEO_ONCE, hideIntroVideo, config.HIDE_INTRO_VIDEO]);

  const {
    showLeaveFiitModal,
    dismissLeaveFiitModal,
  } = useLeaveFiitModal(config);

  if (showLeaveFiitModal) {
    return <LeaveFiitScreen dismiss={dismissLeaveFiitModal} />;
  }

  if (showVideo) {
    return <IntroVideo setShowVideo={setShowVideo} />;
  }

  const loginButtonPath = () => {
    switch (config.APP_TYPE) {
      case 'web':
        return routes.LOGIN_BY_MAGIC;
      case 'shaw':
        return routes.LOGIN_BY_PASSWORD;
      default:
        return routes.LOGIN_BY_PIN;
    }
  };

  const getBackground = (appType: string) => {
    switch (appType) {
      case 'shaw':
        return 'https://images-bucket.prod.fiit-static.net/app/uploads/2022/12/19111519/SHAW-updated-splash.png';
      case 'web':
      case 'amazon':
      case 'virgin':
      case 'sky':
      case 'rogers':
        return 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/12/17114911/big-fiit-14-days-splash.jpg';
      default:
        return 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/12/17114914/big-fiit-30-days-splash.jpg';
    }
  };

  const getSignupButtons = () => (
    notLoggedInModeEnabled ? (
      <Account width={600}>
        <SpatialNavParent layout="vertical">
          <ButtonContainer>
            <SpatialNavParent layout="horizontal">
              <StyledButton
                widthPercentage={48}
                label="Sign up"
                autofocus
                {...(config.APP_TYPE === 'web'
                  ? { onClick: () => handleExternalLink('https://getfiit.tv/web') }
                  : { to: { pathname: routes.SIGNUP?.acceptedPaths[0], state: history.location.state } })}
              />
              <StyledButton
                widthPercentage={48}
                label="Browse for free"
                to={{ pathname: routes.BROWSE?.acceptedPaths[0], state: history.location.state }}
              />
            </SpatialNavParent>
          </ButtonContainer>
          <StyledDivider title="or" />
          <ButtonContainer>
            <StyledButton
              widthPercentage={100}
              label="Log in"
              to={{ pathname: (loginButtonPath())?.acceptedPaths[0], state: history.location.state }}
            />
          </ButtonContainer>
        </SpatialNavParent>
      </Account>
    ) : (
      <Account width={800}>
        <ButtonContainer>
          <SpatialNavParent layout="horizontal">
            <StyledButton
              widthPercentage={48}
              label="Log in"
              to={{ pathname: (loginButtonPath())?.acceptedPaths[0], state: history.location.state }}
              autofocus
            />
            <StyledButton
              widthPercentage={48}
              label="Sign up"
              {...(config.APP_TYPE === 'web'
                ? { onClick: () => handleExternalLink('https://fiit.tv/pricing/') }
                : { to: { pathname: routes.SIGNUP?.acceptedPaths[0], state: history.location.state } })}
            />
          </SpatialNavParent>
        </ButtonContainer>
      </Account>
    )
  );

  const getNoSignupButtons = () => (
    notLoggedInModeEnabled ? (
      <Account width={800}>
        <AccountTypography variant="pica" color="beckersKnop">Already have an account?</AccountTypography>
        <ButtonContainer>
          <SpatialNavParent layout="horizontal">
            <StyledButton
              widthPercentage={48}
              label="Log in"
              to={{ pathname: (loginButtonPath())?.acceptedPaths[0], state: history.location.state }}
              autofocus
            />
            <StyledButton
              widthPercentage={48}
              label="Browse for free"
              to={{ pathname: routes.BROWSE?.acceptedPaths[0], state: history.location.state }}
            />
          </SpatialNavParent>
        </ButtonContainer>
      </Account>
    ) : (
      <Account>
        <AccountTypography variant="pica" color="beckersKnop">Already have an account?</AccountTypography>
        <StyledButton
          widthPercentage={100}
          label="Log in"
          to={{ pathname: (loginButtonPath())?.acceptedPaths[0], state: history.location.state }}
          autofocus
        />
      </Account>
    )
  );

  return (
    <LoginPageLayout
      image={
        getBackground(config.APP_TYPE)
      }
      footer={footer(config.APP_TYPE)}
      headerText="Bring the gym home"
    >
      <Details>
        {showDefaultContent && (
          <QRContainer small={config.APP_TYPE === 'rogers'}>
            <StyledQRCode url={config.TV_QR_LINK} />
            <Typography color="black" variant="pica">Scan</Typography>
          </QRContainer>
        )}
        <StyledBulletedList color="beckersKnop">
          {getBulletPoints(config.APP_TYPE, config.TV_BASE_LINK)}
        </StyledBulletedList>
        {getContent(config.APP_TYPE)}
      </Details>
      {!signUpFlowEnabled ? getNoSignupButtons() : getSignupButtons()}
    </LoginPageLayout>
  );
};

LoginSplash.menu = false;

export default LoginSplash;

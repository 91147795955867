import React, { useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import ProfileImage from 'ui/components/atoms/profile-image';
import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import { rem } from 'ui/helpers';
import Button from 'ui/components/atoms/button';
import { SpatialNavParent, useSpatialTarget } from 'utils/spatial-nav';
import formatName from 'app/on-tv/utils/format-name';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import { ConnectionFlowStatus } from 'app/on-tv/pages/connect-device';
import AnimatedConnectDevice from 'ui/components/atoms/animated-icons/connect-device';
import useConfig from 'app/on-tv/config-provider';

type Props = {
  imageUrl: string | null,
  startClassClick: () => void,
  continueWithoutStatsClick: () => void,
  profileImageUrl?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  connectionFlowStatus: ConnectionFlowStatus,
  onGoBack: () => void,
  onTryAgain: () => void,
  onGuideButtonClick: () => void,
};

const ProfileSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: ${rem(60)};
  left: ${rem(90)};
  z-index: 5;
`;

const StyledProfileImage = styled(ProfileImage)`
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
`;

const CenterImage = styled.img`
  width: auto;
  height: 100%;
  border-radius: 100%;
`;

const Description = styled(Typography)`
  max-width: 70%;
  margin: auto;
  padding-top: 1rem;
`;

const ButtonContainer = styled.div`
  margin: ${rem(35)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 ${rem(22)};
  display: inline-block;
  flex: 1;
  max-width: ${rem(450)};
`;

const StyledFullPageOverlay = styled(FullPageOverlay)`
  text-align: center;
  flex-grow: 1;
`;

const HelpFooter = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
`;

const NestedWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

const FocusableImageWrapper = styled.button<{ focused: boolean, disabled: boolean }>`
  height: ${rem(400)};
  width: ${rem(400)};
  border-radius: 100%;
  border: ${({ theme, focused, disabled }) => {
    if (disabled) {
      return 'none';
    }
    return focused ? `${rem(4)} solid ${theme.colors.fiitBlue}` : `${rem(2)} solid ${theme.colors.whiteOpaque}`;
  }};
`;

const BackButton = styled(Button)`
  position: absolute;
  left: ${rem(150)};
  bottom: ${rem(100)};
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${rem(600)};
  height: ${rem(550)};
  margin: auto;
`;

const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type FocusableImageProps = {
  imageUrl?: string | null,
  onClick: () => void,
  connectionFlowStatus: ConnectionFlowStatus,
};

const FocusableMedia = ({ imageUrl, onClick, connectionFlowStatus }: FocusableImageProps) => {
  const elRef = useRef(null);
  const disabled = connectionFlowStatus === ConnectionFlowStatus.CONNECTED;
  const { focused } = useSpatialTarget({ elRef, autofocus: !disabled, disabled });

  return (
    <FocusableImageWrapper
      ref={elRef}
      onClick={onClick}
      data-test="connection-img"
      focused={focused}
      disabled={disabled}
    >
      {connectionFlowStatus === ConnectionFlowStatus.NOT_CONNECTED ? (
        <AnimationContainer>
          <AnimatedConnectDevice />
        </AnimationContainer>
      ) : (
        <CenterImage src={`${imageUrl}?content-type=png`} />
      )}
    </FocusableImageWrapper>
  );
};

const getText = (connectionFlowStatus: ConnectionFlowStatus, appType: string, isTitle?: boolean) => {
  const device = appType === 'web' ? 'computer' : 'TV screen';
  switch (connectionFlowStatus) {
    default:
    case ConnectionFlowStatus.NOT_CONNECTED:
      return isTitle
        ? 'Connect fitness tracker to mobile'
        : `See your stats, and track your heart rate and reps live on your ${device}`;
    case ConnectionFlowStatus.CONNECTION_STARTED:
      return isTitle
        ? 'Follow the steps on your mobile'
        : '';
    case ConnectionFlowStatus.TRACKER_ONLY_CONNECTED:
      return isTitle
        ? 'Follow the steps on your mobile'
        : 'Or start with only your hr tracker connected';
    case ConnectionFlowStatus.EQUIPMENT_ONLY_CONNECTED:
      return isTitle
        ? 'Follow the steps on your mobile'
        : 'Or start with only your equipment connected';
    case ConnectionFlowStatus.CONNECTED:
      return isTitle
        ? 'Connected!'
        : 'You\'re all set to start the class.';
  }
};

const ConnectDeviceModal = ({
  imageUrl,
  profileImageUrl,
  startClassClick,
  continueWithoutStatsClick,
  firstName,
  lastName,
  connectionFlowStatus = ConnectionFlowStatus.NOT_CONNECTED,
  onGoBack,
  onTryAgain,
  onGuideButtonClick,
}: Props) => {
  const formattedName = formatName({ firstname: firstName, lastname: lastName });
  const { config } = useConfig();

  const canStartClass = [
    ConnectionFlowStatus.CONNECTED,
    ConnectionFlowStatus.TRACKER_ONLY_CONNECTED,
    ConnectionFlowStatus.EQUIPMENT_ONLY_CONNECTED,
  ].includes(connectionFlowStatus);

  return (
    <StyledFullPageOverlay>
      <SpatialNavParent layout="vertical">
        <ProfileSection>
          <StyledProfileImage imageUrl={profileImageUrl} />
          {formattedName && (
            <Typography weight="bold" size="s">{formattedName}.</Typography>
          )}
        </ProfileSection>
        <NestedWrapper>
          <Typography as="p" variant="double-pica">{getText(connectionFlowStatus, config.APP_TYPE, true)}</Typography>
          <Description as="p" variant="body-copy" color="beckersKnop">
            {getText(connectionFlowStatus, config.APP_TYPE)}
          </Description>
          <MediaContainer>
            <FocusableMedia
              imageUrl={imageUrl}
              connectionFlowStatus={connectionFlowStatus}
              onClick={onGuideButtonClick}
            />
          </MediaContainer>
          <SpatialNavParent layout="horizontal">
            <ButtonContainer>
              {canStartClass ? (
                <StyledButton
                  label="Start class"
                  onClick={startClassClick}
                  autofocus
                />
              ) : (
                <>
                  <StyledButton
                    label="Try to connect again"
                    onClick={onTryAgain}
                  />
                  <StyledButton
                    label="Continue without stats"
                    onClick={continueWithoutStatsClick}
                  />
                </>
              )}
            </ButtonContainer>
          </SpatialNavParent>
        </NestedWrapper>
        <BackButton
          label="Back"
          onClick={onGoBack}
          labelPosition="right"
          icon={<ChevronLeft />}
          iconOnly
        />
      </SpatialNavParent>
      <HelpFooter>
        <Typography>For help go to </Typography>
        <Typography variant="pica">help.fiit.tv</Typography>
      </HelpFooter>
    </StyledFullPageOverlay>
  );
};

export default ConnectDeviceModal;

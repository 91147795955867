import React from 'react';
import styled from 'styled-components';

import ArrowRight from 'ui/components/atoms/icons/arrow-right';
import { IconProps } from 'ui/components/atoms/icons/types';

const Svg = styled(ArrowRight)`
  transform: rotate(180deg);
`;

const ArrowLeft = (props: IconProps) => <Svg {...props} />;

export default ArrowLeft;

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useAppState } from 'state';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import ClassDetails from 'ui/components/layouts/class-details';
import AgreementModal, { Agreement, SUPPORTED_AGREEMENTS } from 'app/on-tv/pages/lesson/agreement-modal';
import ChooseLessonMediaModal from 'app/on-tv/pages/lesson/choose-lesson-media-modal';

import {
  LessonDetails as LESSON_QUERY,
  LessonDetailsNoAuth as LESSON_NO_AUTH_QUERY,
} from 'app/on-tv/pages/lesson/lesson-details.gql';
import { StartWorkout as START_WORKOUT_MUTATION } from 'src/graphql/mutations/start-workout.gql';
import { CreateParty as CREATE_PARTY_MUTATION } from 'app/on-tv/pages/lesson/create-party.gql';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

import useLogger from 'app/hooks/use-logger';
import useRoutes from 'utils/use-routes';
import useConfig from 'app/on-tv/config-provider';
import useDelay from 'utils/use-delay';

import {
  LessonDetailsQuery,
  LessonDetailsQueryVariables,
  LessonDetailsNoAuthQuery,
  LessonDetailsNoAuthQueryVariables,
  StartWorkoutMutation,
  StartWorkoutMutationVariables,
  LessonDurationRange,
  LessonDifficulty,
  MuscleGroup,
  CreatePartyMutation,
  CreatePartyMutationVariables,
  LessonStatus,
  TimelineCircuit,
  LessonStartPermission,
  LessonStartPermissionReason,
  StudioFamily,
} from 'app/on-tv/types/graphql';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import { useSetFocus } from 'utils/spatial-nav';
import transformLessonData, { TransformedLessons } from 'app/on-tv/utils/transform-lesson-data';
import { MappedCircuit, mapSections } from 'ui/components/molecules/current-exercise/utils';
import { analytics } from 'utils/analytics';

type ComponentProps = {
  id: string,
};

export type Props = RouteComponentProps<ComponentProps>;

type ClassDetailsScreenProps = {
  defaultMediaOption: {
    dashUrl?: string | null,
    id: string,
    commercial: boolean,
  },
  mediaOptions?: Array<{
    dashUuid?: string | null,
    dashUrl?: string | null,
    id: string,
    status: string,
    commercial: boolean,
  }>,
  name: string,
  trainers: Array<{
    firstname: string,
    lastname: string,
    slug: string,
    avatarImage: {
      url: string,
    },
  }>,
  mainImage?: string,
  studio: {
    id: string,
    family: StudioFamily,
  },
  durationRange: LessonDurationRange,
  difficulty: LessonDifficulty,
  equipment?: string,
  musicGenre?: string,
  muscles: Array<{
    group: MuscleGroup,
  }>,
  description?: string | null,
  userId: number,
  outstandingAgreements: Agreement[],
  permissions?: LessonStartPermission,
  favourited?: boolean,
  lessonId: number,
  inTesting?: boolean,
  similarClasses: TransformedLessons,
  isUserTester?: boolean,
  review?: number | null,
  mappedTimeline: MappedCircuit[],
  subConnectivityEnabled?: boolean,
};

const ClassDetailsScreen = ({
  mainImage,
  name,
  trainers,
  studio,
  durationRange,
  difficulty,
  equipment,
  musicGenre,
  muscles,
  description,
  defaultMediaOption,
  mediaOptions,
  outstandingAgreements,
  userId,
  permissions,
  favourited = false,
  lessonId,
  inTesting,
  similarClasses,
  isUserTester,
  review,
  mappedTimeline,
  subConnectivityEnabled = false,
}: ClassDetailsScreenProps) => {
  const { routes, push } = useRoutes();
  const { config } = useConfig();
  const scheduleId = useQueryParam().get('scheduleId');
  const dismissFocusId = useRef<string | null>(null);
  const setFocus = useSetFocus();
  const logger = useLogger('on-tv:lesson:class-details');

  const history = useHistory();

  const [overrideMediaId, setOverrideMediaId] = useState(null);

  const lessonMediaId = overrideMediaId || defaultMediaOption?.id;
  const hostInstallationId = useAppState((state) => state?.installation?.installationId);

  const [
    createParty,
    { loading: createPartyLoading, error: createPartyError },
  ] = useMutation<CreatePartyMutation, CreatePartyMutationVariables>(
    CREATE_PARTY_MUTATION,
    {
      variables: {
        input: {
          lessonMediaId,
          hostUserId: userId,
          hostInstallationId,
          ...(scheduleId ? { hostScheduleEntryId: parseInt(scheduleId, 10) } : {}),
        },
      },
      onCompleted: ({ createParty: { party: { id } } }) => {
        push({
          route: routes.CONNECT_DEVICE,
          params: { partyId: id },
          queryParams: { ...(scheduleId ? { scheduleId } : {}) },
        });
      },
      onError: (e) => logger.error('CreatePartyMutation error', e),
    },
  );

  const [startStreamableWorkout, { loading, error }] = useMutation<StartWorkoutMutation, StartWorkoutMutationVariables>(
    START_WORKOUT_MUTATION,
    {
      variables: {
        userId,
        lessonMediaId,
      }, // TODO device type / metadata etc
      onCompleted: ({ startStreamableWorkout: { workout } }) => {
        const lessonInstanceId = workout.lessonInstance.id;

        analytics.track('WorkoutStarted', {
          isPrivate: true,
          isScheduled: false,
          lessonId,
          lessonName: name,
          studioId: studio?.id,
        });

        if (routes.LESSON_INSTANCE) {
          // might be best to call this "Session/:remote-session-id"
          push({ route: routes.LESSON_INSTANCE, params: { lessonInstanceId } });
        }
      },
      onError: (e) => {
        // if a user has cancelled their plan via the mobile app they may end up here with a scheduleId
        // for the old plan which will throw an error so lets catch this and retry without the id
        if (e.message.toLowerCase().includes('schedule entry')) {
          return startStreamableWorkout();
        }

        return logger.error('StartWorkoutMutation error', { error: e });
      },
    },
  );

  const [starting, setStarting] = useState(false);
  const showAgreementsModal = starting && permissions?.value && outstandingAgreements.length !== 0;
  const [showChooseLessonMediaModal, setShowChooseLessonMediaModal] = useState(false);
  const matConnectivityFlowEnabled = useAppState((state) => state.flag.matConnectivityFlowEnabled);
  const ckConnectivityFlowEnabled = useAppState((state) => state.flag.ckConnectivityFlowEnabled);
  const deviceConnectivityFlowEnabled = studio.family === StudioFamily.CONNECTED_KIT
    ? ckConnectivityFlowEnabled
    : matConnectivityFlowEnabled;

  const onDismiss = useCallback(() => {
    setStarting(false);
  }, []);

  useEffect(() => {
    const startPermitted = (outstandingAgreements.length === 0 && permissions?.value);

    if (starting && startPermitted && userId && lessonMediaId) {
      if (deviceConnectivityFlowEnabled && subConnectivityEnabled) {
        createParty();
      } else {
        startStreamableWorkout({
          variables: {
            userId,
            lessonMediaId,
            ...(scheduleId ? { scheduleId: parseInt(scheduleId, 10) } : {}),
          },
        });
      }
    }
  }, [
    startStreamableWorkout,
    starting,
    permissions,
    outstandingAgreements.length,
    deviceConnectivityFlowEnabled,
    createParty,
    scheduleId,
    userId,
    lessonMediaId,
    subConnectivityEnabled,
  ]);

  const setDismissFocusId = (id: string | null) => {
    dismissFocusId.current = id;
  };

  useDismissEvent(useCallback(() => {
    if (showAgreementsModal) {
      onDismiss();
      return;
    }

    if (dismissFocusId.current) {
      setFocus(dismissFocusId.current);
      return;
    }

    history.goBack();
  }, [showAgreementsModal, history, onDismiss, setFocus]));

  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  const startClass = () => {
    if (!permissions?.value) {
      if (permissions?.blockedReasons?.includes(LessonStartPermissionReason.NOT_ON_PLAN)) {
        push({ route: routes.UNLIMITED_UPSELL });
        return;
      }
      push({ route: routes.PREMIUM_UPSELL });
      return;
    }
    setStarting(true);
  };

  const chooseMediaOption = useCallback(() => setShowChooseLessonMediaModal(true), []);

  const selectMediaOption = useCallback((id) => {
    setOverrideMediaId(id);
    setShowChooseLessonMediaModal(false);
    setStarting(true);
  }, []);

  if (loading || delayed || createPartyLoading) {
    return <LoadingOverlay />;
  }

  if (error || createPartyError) {
    return <ErrorOverlay error={error || createPartyError} onDismiss="back" />;
  }

  return (
    <>
      {showAgreementsModal && userId && (
        <AgreementModal
          // Hack otherwise it doesn't re-render when changing agreements - meaning autofocus doesn't trigger
          key={outstandingAgreements[0]?.id || 'agreements-modal'}
          {...outstandingAgreements[0]}
          onDismiss={onDismiss}
          userId={userId}
        />
      )}
      {showChooseLessonMediaModal && mediaOptions && (
        <ChooseLessonMediaModal
          mediaOptions={mediaOptions}
          onSelect={selectMediaOption}
        />
      )}
      <ClassDetails
        image={mainImage}
        name={name}
        trainers={trainers}
        studio={studio?.id}
        duration={durationRange}
        difficulty={difficulty}
        equipment={equipment}
        musicGenre={musicGenre}
        muscles={muscles}
        description={description}
        permissions={permissions}
        startOnClick={startClass}
        autofocus={!showAgreementsModal}
        favourited={favourited}
        lessonId={lessonId}
        userId={userId}
        inTesting={inTesting}
        similarClasses={similarClasses}
        setDismissFocusId={setDismissFocusId}
        {... (isUserTester ? { chooseMediaOption } : null)}
        review={review}
        mappedTimeline={mappedTimeline}
      />
    </>
  );
};

type LessonDetailsProps = {
  id: number,
  userId: number,
};

const LessonDetailsAuth = ({ id, userId }: LessonDetailsProps) => {
  const { config } = useConfig();
  const logger = useLogger('on-tv:lesson:details');

  const { loading, error, data } = useQuery<LessonDetailsQuery, LessonDetailsQueryVariables>(LESSON_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      id,
      userId,
      relatedLessonsFirst: 6,
    },
    onError: (e) => logger.error('LessonDetailsQuery error', { error: e, lessonId: id }),
  });

  const similarClasses = useMemo(() => transformLessonData(data?.lessonById?.relatedLessons?.edges), [data]);
  const mappedTimeline = useMemo(() => {
    if (data?.lessonById?.timeline) {
      return mapSections(data.lessonById.timeline as TimelineCircuit[]);
    }
    return [];
  }, [data]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !data?.lessonById) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const {
    mainImage,
    name,
    trainers,
    studio,
    durationRange,
    difficulty,
    equipmentLevel,
    musicGenre,
    muscles,
    description,
    defaultMediaOption,
    mediaOptions,
    neededAgreements,
    permissionsWithReasons,
    favouritedByUser: favourited,
    status,
    review,
  } = data.lessonById;

  const isUserTester = config.DEBUG_LESSON_MEDIA_ENABLED && data.user?.permissions?.debug?.value;

  const subConnectivityEnabled = data.auth?.permissions.useDevices;

  const outstandingAgreements = neededAgreements
    .filter(({ acceptedByUser, type }) => (
      !acceptedByUser && SUPPORTED_AGREEMENTS.includes(type)
    ));

  return (
    <>
      { defaultMediaOption && (
      <ClassDetailsScreen
        defaultMediaOption={defaultMediaOption}
        mainImage={mainImage?.url}
        name={name}
        trainers={trainers}
        studio={studio}
        durationRange={durationRange}
        difficulty={difficulty}
        equipment={equipmentLevel[0]?.equipment?.shortDisplay}
        musicGenre={musicGenre?.displayName}
        muscles={muscles}
        description={description}
        outstandingAgreements={outstandingAgreements}
        userId={userId}
        permissions={permissionsWithReasons.start}
        favourited={favourited}
        lessonId={id}
        inTesting={status === LessonStatus.TESTING}
        similarClasses={similarClasses}
        mediaOptions={mediaOptions}
        isUserTester={isUserTester}
        review={review.overall.avg}
        mappedTimeline={mappedTimeline}
        subConnectivityEnabled={subConnectivityEnabled}
      />
      ) }
    </>
  );
};

type LessonDetailsNoAuthProps = {
  lessonId: number,
};

const LessonDetailsNoAuth = ({ lessonId }: LessonDetailsNoAuthProps) => {
  const logger = useLogger('on-tv:lesson:details');
  const dismissFocusId = useRef<string | null>(null);
  const { routes, push } = useRoutes();
  const history = useHistory();
  const setFocus = useSetFocus();

  useDismissEvent(useCallback(() => {
    if (dismissFocusId.current) {
      setFocus(dismissFocusId.current);
      return;
    }

    history.goBack();
  }, [history, setFocus]));

  const {
    loading,
    error,
    data,
  } = useQuery<LessonDetailsNoAuthQuery, LessonDetailsNoAuthQueryVariables>(LESSON_NO_AUTH_QUERY, {
    variables: {
      id: lessonId,
      relatedLessonsFirst: 6,
    },
    onError: (e) => logger.error('LessonDetailsQuery error', { error: e, lessonId }),
  });

  const similarClasses = useMemo(() => transformLessonData(data?.lessonById?.relatedLessons?.edges), [data]);
  const mappedTimeline = useMemo(() => {
    if (data?.lessonById?.timeline) {
      return mapSections(data.lessonById.timeline as TimelineCircuit[]);
    }
    return [];
  }, [data]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !data?.lessonById) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const {
    mainImage,
    name,
    trainers,
    studio,
    durationRange,
    difficulty,
    equipmentLevel,
    musicGenre,
    muscles,
    description,
    status,
    review,
  } = data.lessonById;

  const permissions = {
    value: false,
  };

  const setDismissFocusId = (id: string | null) => {
    dismissFocusId.current = id;
  };

  const startClass = () => {
    push({ route: routes.PREMIUM_UPSELL });
  };

  return (
    <ClassDetails
      image={mainImage?.url}
      name={name}
      trainers={trainers}
      studio={studio?.id}
      duration={durationRange}
      difficulty={difficulty}
      equipment={equipmentLevel[0]?.equipment?.shortDisplay}
      musicGenre={musicGenre?.displayName}
      muscles={muscles}
      description={description}
      permissions={permissions}
      startOnClick={startClass}
      autofocus
      lessonId={lessonId}
      inTesting={status === LessonStatus.TESTING}
      similarClasses={similarClasses}
      setDismissFocusId={setDismissFocusId}
      review={review?.overall.avg}
      mappedTimeline={mappedTimeline}
    />
  );
};

const LessonPage = ({ match: { params } }: Props) => {
  const lessonId = parseInt(params.id, 10);
  const userId = useAppState((state) => state.auth.userId);

  if (!lessonId) {
    return <div>error</div>;
  }

  return userId ? <LessonDetailsAuth id={lessonId} userId={userId} /> : <LessonDetailsNoAuth lessonId={lessonId} />;
};

LessonPage.menu = true;

export default LessonPage;

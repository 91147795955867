import React, { useEffect, useState, useCallback, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import Button from 'ui/components/atoms/button';

import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import ProfileImage from 'ui/components/atoms/profile-image';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { UserProfileQuery, UserProfileQueryVariables } from 'app/on-tv/types/graphql';
import { UserProfile as USER_PROFILE_QUERY } from 'app/on-tv/graphql/queries/user-profile-query.gql';
import { useAppState, useDispatch } from 'state';
import { logout } from 'actions/auth';
import formatName from 'app/on-tv/utils/format-name';
import LogoutScreen from 'app/on-tv/organisms/logout-screen';
import { useSetFocus } from 'utils/spatial-nav';
import SvgLogout from 'ui/components/atoms/icons/logout';
import useRoutes from 'utils/use-routes';
import { WebsocketClientContext } from 'utils/apollo-client';

type ComponentProps = {
  slug: string,
};

export type Props = RouteComponentProps<ComponentProps>;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: relative;
`;

const Heading = styled(Typography)`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(20)};
`;

const Subheading = styled(Typography)`
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.beckersKnop};
  max-width: ${rem(1000)};
`;

const LargeProfileImage = styled(ProfileImage)`
  width: ${rem(150)};
  height: ${rem(150)};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.white};
`;

const StyledLink = styled(Typography)`
  margin: 0 0.25rem;
`;

const Footer = styled.div`
  margin-top: ${rem(50)};
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  width: 100%;
`;

const ContactCopyContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ProfilePage = () => {
  const client = useApolloClient();
  const { client: wsClient } = useContext(WebsocketClientContext);
  const dispatch = useDispatch();
  const setFocus = useSetFocus();
  const { redirect, routes } = useRoutes();

  const [logoutVisible, setLogoutVisible] = useState(false);
  const showLogout = useCallback(() => {
    setLogoutVisible(true);
  }, []);

  const cancelLogout = useCallback(() => {
    setFocus('PAGE_CONTENT');
    setLogoutVisible(false);
  }, [setFocus]);

  const logoutAction = useCallback(async () => {
    setLogoutVisible(false);
    await client.clearStore();
    await wsClient?.close();
    dispatch(logout());
    redirect({ route: routes.LOGIN, replaceStack: true, queryParams: { hideIntroVideo: true } });
  }, [wsClient, client, dispatch, redirect, routes.LOGIN]);

  const { data, refetch: refetchUser } = useQuery<UserProfileQuery, UserProfileQueryVariables>(USER_PROFILE_QUERY);

  const userId = useAppState((state) => state.auth.userId);

  useDismissEvent();

  useEffect(() => {
    refetchUser();
  }, [userId, refetchUser]);

  const user = data?.auth?.user;

  return (
    <>
      <Wrapper>
        <LargeProfileImage data-test="profile-image" imageUrl={user?.profileImageUrl} />
        <Heading variant="double-pica" weight="bold">{formatName(user || {}, { full: true }) || 'Hey there,'}</Heading>
        <Subheading>
          To personalise your profile, view and manage your subscription,
          log into the Fiit app on your phone or tablet.
          Plus enjoy all the extra features currently available on iOS and Android.
        </Subheading>
        <Button
          label="Log out"
          variant="cta"
          autofocus
          onClick={showLogout}
          icon={<SvgLogout />}
        />
        <Footer>
          <Button
            label="T&Cs and Privacy Policy"
            variant="cta"
            to={routes.LEGAL?.acceptedPaths[0]}
          />
          <ContactCopyContainer>
            <Typography color="beckersKnop">Got a question or need help? Contact</Typography>
            <StyledLink variant="pica">help.fiit.tv</StyledLink>
          </ContactCopyContainer>
        </Footer>
      </Wrapper>
      {logoutVisible && <LogoutScreen cancelLogout={cancelLogout} logoutAction={logoutAction} />}
    </>
  );
};

ProfilePage.menu = true;

export default ProfilePage;

import React, { useState, useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Typography from 'ui/components/atoms/typography';
import { useAppState } from 'state';
import {
  ScreenSaver as SCREEN_SAVER_QUERY,
} from 'app/on-tv/organisms/screen-saver/screen-saver-query.gql';
import {
  LessonDifficulty,
  ScreenSaverQuery,
  ScreenSaverQueryVariables,
  TrainingPlanSchedule,
  UserActivityLevel,
} from 'app/on-tv/types/graphql';
import useLogger from 'app/hooks/use-logger';
import ScreenSaver from 'ui/components/layouts/screen-saver';
import styled from 'styled-components';
import { ACTIVITY_LEVEL_TO_PLAN_LEVEL, getPlanEquipment } from 'app/on-tv/utils/training-plan-helpers';
import SvgWatch from 'ui/components/atoms/icons/watch';
import ClassesIcon from 'ui/components/atoms/icons/classes';
import SvgEquipment from 'ui/components/atoms/icons/equipment';
import SvgRating from 'ui/components/atoms/icons/rating';
import { trainerNames } from 'ui/components/utils/trainer-names';
import { rem } from 'ui/helpers';

export type ScreenSaverImage = {
  url?: string,
  title: string,
  subText: string | ReactNode,
};

const Divider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  height: 0.5rem;
  margin: 0 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ leftMargin?: boolean }>`
  height: 1rem;
  width: 1rem;
  margin: 0 0.3rem 0.3rem ${({ leftMargin }) => (leftMargin ? '0.3rem' : 0)};
`;

const StyledIcon = styled.div`
  width: 0.8rem;
  height: 0.8rem;
`;

const EquipmentIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${rem(2)} solid ${({ theme }) => theme.colors.white};
  border-radius: ${rem(3)};
  margin-right: 0.3rem;
`;

const defaultImages: ScreenSaverImage[] = [
  {
    url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/04/26130959/big-fiit-screen-saver-plan.jpg',
    title: 'Start a plan',
    subText: 'You tell us your goal. We tell you exactly how to get there. Easy.',
  },
  {
    url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/04/26131028/big-fiit-screen-saver-trial.jpg',
    title: 'FIIT GOES BIG',
    subText: 'Get moving with the ultimate interactive fitness experience – right here on the big screen',
  },
  {
    url: 'https://images-bucket.prod.fiit-static.net/app/uploads/2021/04/26161105/big-fiit-screen-saver-trainers.jpg',
    title: 'Meet all the trainers',
    subText: 'World-class personal trainers at home',
  },
];

type Lesson = {
  mainImage?: {
    url: string,
  } | null,
  name: string,
  trainers: Array<{
    firstname: string,
    lastname: string,
  }>,
  review: {
    overall: {
      avg?: number | null,
    },
  },
};

type Plan = {
  images: {
    cover?: {
      url: string,
    } | null
  } | null,
  name: string,
  durationWeeks: string,
  templates?: Array<{
    id: number,
    level: LessonDifficulty,
    byWeek: Array<{
      schedule: Array<Pick<TrainingPlanSchedule, 'id'>>,
    } | null>,
    schedule: Array<{
      id: number,
      lesson: {
        id: number,
        equipmentLevel: Array<{
          equipment: { shortDisplay: string },
        }>,
      },
    }>,
  }> | null,
};

const buildLessonScreenSaver = (lesson: Lesson): ScreenSaverImage => ({
  url: lesson?.mainImage?.url,
  title: lesson.name,
  subText: (
    <>
      <Typography>with </Typography><Typography weight="bold">{trainerNames(lesson.trainers)}</Typography>
      {lesson.review.overall.avg && (
        <>
          <br />
          <Row>
            <Typography variant="pica">{lesson.review.overall.avg.toFixed(2)}</Typography>
            <IconWrapper leftMargin>
              <SvgRating />
            </IconWrapper>
          </Row>
        </>
      )}
    </>
  ),
});

const buildPlanScreenSaver = (plan: Plan, userActivityLevel?: UserActivityLevel | null): ScreenSaverImage => {
  const predictedTemplate = plan.templates?.find(({ level }) => (
    userActivityLevel ? level === ACTIVITY_LEVEL_TO_PLAN_LEVEL[userActivityLevel] : true));

  const noOfClasses = predictedTemplate?.byWeek.reduce(
    (acc: number, currentValue: any) => acc + (currentValue?.schedule?.length || 0), 0,
  );

  const equipment = predictedTemplate ? getPlanEquipment(predictedTemplate) : [];

  return ({
    url: plan?.images?.cover?.url,
    title: plan.name,
    subText: (
      <Row>
        <IconWrapper>
          <StyledIcon as={SvgWatch} />
        </IconWrapper>
        <Typography variant="pica">{plan.durationWeeks} weeks</Typography>
        {noOfClasses && (
          <>
            <Divider />
            <IconWrapper>
              <StyledIcon as={ClassesIcon} />
            </IconWrapper>
            <Typography variant="pica">{noOfClasses} classes</Typography>
          </>
        )}
        {equipment.length > 0 && (
          <>
            <Divider />
            <EquipmentIconWrapper>
              <StyledIcon as={SvgEquipment} />
            </EquipmentIconWrapper>
            <Typography variant="pica">{equipment.join(', ')}</Typography>
          </>
        )}
      </Row>
    ),
  });
};

const ScreenSaverLoader = () => {
  const { loggedIn, userId } = useAppState((state) => state.auth);
  const [imageReel, setImageReel] = useState(defaultImages);
  const logger = useLogger('screen-saver');

  useQuery<ScreenSaverQuery, ScreenSaverQueryVariables>(SCREEN_SAVER_QUERY, {
    skip: !loggedIn || !userId,
    variables: {
      userId,
    },
    onError: (error) => logger.error('ScreensaverQuery error', { error }),
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const { user } = data;
      const recommendedLesson = user?.recommendedLessons.edges && user.recommendedLessons.edges[0].node;
      const recommendedPlan = user?.recommendedTrainingPlans?.edges && user?.recommendedTrainingPlans?.edges[0].node;
      const hasImages = recommendedLesson?.mainImage?.url && recommendedPlan?.images?.cover?.url;

      if (recommendedLesson && recommendedPlan && hasImages) {
        const personlisedPlanScreenSaver = buildPlanScreenSaver(recommendedPlan, user?.activityLevel);
        const personlisedLessonScreenSaver = buildLessonScreenSaver(recommendedLesson);
        setImageReel([personlisedPlanScreenSaver, personlisedLessonScreenSaver]);
      }
    },
  });

  useEffect(() => {
    if (!loggedIn) {
      setImageReel(defaultImages);
    }
  }, [loggedIn]);

  return <ScreenSaver imageReel={imageReel} />;
};

export default ScreenSaverLoader;

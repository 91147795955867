import {
  ConnectDeviceQuery,
} from 'app/on-tv/types/graphql';

type SubscriptionData = {
  lobbyEntry: {
    id: number,
    user: {
      id: number,
      handle: string,
    }
    trackerConnected?: boolean,
    equipmentConnected?: boolean,
    leftAt?: string | null,
  }
};

export const onSubscriptionEvent = (
  prev: ConnectDeviceQuery,
  subscriptionData: SubscriptionData,
): ConnectDeviceQuery => {
  if (!prev.partyById) {
    return prev;
  }

  const { lobbyEntry } = subscriptionData;
  const lobbyEntryEdges = [...prev.partyById?.lobbyEntries.edges || []];
  const lobbyEntryToUpdateIndex = lobbyEntryEdges.findIndex(({ node }) => (
    node.id === lobbyEntry.id
  ));

  if (lobbyEntryToUpdateIndex > -1) {
    if (lobbyEntry.trackerConnected) {
      lobbyEntryEdges[lobbyEntryToUpdateIndex].node.trackerConnected = lobbyEntry.trackerConnected;
    }
    if (lobbyEntry.equipmentConnected) {
      lobbyEntryEdges[lobbyEntryToUpdateIndex].node.equipmentConnected = lobbyEntry.equipmentConnected;
    }
  } else {
    lobbyEntryEdges.push({
      __typename: 'LobbyEntryEdge',
      node: {
        trackerConnected: false,
        equipmentConnected: false,
        ...subscriptionData.lobbyEntry,
      },
    });
  }

  return {
    ...prev,
    partyById: {
      __typename: 'Party',
      ...prev.partyById,
      lobbyEntries: {
        ...prev.partyById?.lobbyEntries,
        edges: lobbyEntryEdges,
      },
    },
  };
};

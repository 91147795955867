import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { rem } from 'ui/helpers';

import { ThemeSpacing } from 'ui/theme';
import Typography from 'ui/components/atoms/typography';
import LessonList from 'ui/components/molecules/lesson-list';
import EmptyState from 'ui/components/molecules/empty-state';
import GhostIcon from 'ui/components/atoms/icons/ghost';
import { SpatialNavParent } from 'utils/spatial-nav';
import { usePageScroll } from 'utils/use-page-scroll';

export type Props = {
  children: ReactNode[],
  className?: string,
  itemSpacing?: ThemeSpacing,
  pageTitle?: ReactNode,
  pageTitleUpperCased?: boolean,
  gridTitle?: string,
  onClick?: () => void,
  subtitle?: ReactNode
  columns?: number,
};

const Wrapper = styled.div`
  flex: 1;
  margin-left: ${rem(20)};
`;

const ContentGrid = styled(LessonList)`
  margin-top: ${rem(25)};
  position: relative;
  padding-bottom: ${rem(100)};
`;

const AboveHolder = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(80)};
`;

const Row = styled.div`
  padding-top: ${rem(100)};
  display: flex;
`;

const HeaderHolder = styled.div<{description?: string}>`
  ${({ description }) => (!description ? `height: ${rem(0)}` : null)};
  padding-bottom: ${rem(50)};
`;

const GridTitleHolder = styled.div`
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  background-color: ${({ theme }) => theme.colors.black};
  position: sticky;
  z-index: 1;
  top: -1px;
`;

const EmptyStateStyled = styled(EmptyState)`
  margin-top: ${rem(100)};
`;

const TrainerGrid = ({
  itemSpacing = 'm',
  pageTitle,
  gridTitle,
  children = [],
  className = '',
  subtitle,
  columns = 3,
}: Props) => {
  const pageScroll = usePageScroll();
  const onFocus = (element: HTMLElement | null) => {
    if (element) {
      pageScroll(element.offsetTop);
    }
  };

  return (
    <Wrapper className={className}>
      <AboveHolder>
        <Row>
          {
            (pageTitle) && (
              <HeaderHolder data-test="trainer-grid-header">
                <Typography variant="paragon" as="div">{pageTitle}</Typography>
                { subtitle }
              </HeaderHolder>
            )
          }
        </Row>
      </AboveHolder>
      <GridTitleHolder>
        <Typography variant="body-copy" weight="bold">{gridTitle}</Typography>
      </GridTitleHolder>
      {
        children.length ? (
          <SpatialNavParent layout="grid" columns={columns}>
            <ContentGrid
              className={className}
              itemSpacing={itemSpacing}
              variant="GRID"
              columns={columns}
              onItemFocused={onFocus}
            >
              {
                children
              }
            </ContentGrid>
          </SpatialNavParent>
        ) : (
          <EmptyStateStyled
            icon={<GhostIcon />}
            heading="No trainers available yet"
          />
        )
      }
    </Wrapper>
  );
};

export default TrainerGrid;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { useAppState } from 'state';

import useLogger from 'app/hooks/use-logger';

import useConfig from 'app/on-tv/config-provider';

import ClassCard from 'ui/components/molecules/class-card';
import ClassGrid from 'ui/components/layouts/class-grid-page';
import {
  CollectionDetails as COLLECTION_QUERY,
  CollectionDetailsNoAuth as COLLECTION_NO_AUTH_QUERY,
} from 'app/on-tv/pages/collection/collection-details.gql';
import useRoutes, { url } from 'utils/use-routes';
import FilterBar, { useDurationFilter } from 'ui/components/molecules/filter-bar';
import useDelay from 'utils/use-delay';

import { LessonDurationRange } from 'types/graphql';

import {
  CollectionDetailsQuery,
  CollectionDetailsQueryVariables,
  CollectionDetailsNoAuthQuery,
  CollectionDetailsNoAuthQueryVariables,
  CollectionOrder,
  LessonStatus,
} from 'app/on-tv/types/graphql';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import Typography from 'ui/components/atoms/typography';
import transformLessonData from 'app/on-tv/utils/transform-lesson-data';

type ComponentProps = {
  slug: string,
};

export type Props = RouteComponentProps<ComponentProps>;

export type LessonItem = {
  id: number,
  mainImageUrl?: string,
  duration: string,
  trainerName: string,
  name: string,
  locked?: boolean,
  completed: boolean,
  equipment?: boolean,
  favourited?: boolean,
};

const Title = styled(Typography)`
  display: block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const CollectionAuthPage = ({ match: { params } }: Props) => {
  const { slug } = params;
  const userId = useAppState((state) => state.auth.userId);

  const { config } = useConfig();

  const { durationRange, showAllDurations, labelFromLessonDuration, onFilter } = useDurationFilter();

  const { routes } = useRoutes();

  const logger = useLogger('on-tv:collection');

  const { loading, error, data } = useQuery<CollectionDetailsQuery, CollectionDetailsQueryVariables>(COLLECTION_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      lessonConditions: {
        ...lessonsQueryVariables.lessonConditions,
        ...(showAllDurations ? null : { durationRange: [durationRange] }),
      },
      lessonFirst: config.MAX_LESSONS_IN_GRID,
      slug,
      userId,
      collectionFirst: 15,
      collectionOrderBy: CollectionOrder.UPDATED_AT,
    },
    onError: (e) => logger.error('CollectionDetailsQuery error', { error: e, slug }),
  });
  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  useDismissEvent();

  const lessons = useMemo(() => transformLessonData(data?.collectionBySlug?.lessons?.edges), [data]);

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.collectionBySlug || !slug || !userId) {
    logger.error('An error occured loading a collection', { slug });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const relatedCollectionsRailCards = data.collectionBySlug?.relatedCollectionList?.collections?.edges.map((
    { node },
    i: number,
  ) => (
    <ClassCard
      key={node.id}
      backgroundImage={node.image?.url}
      size="small"
      name={node.name}
      to={url({ route: routes.COLLECTION, params: { slug: node.slug } })}
      autofocus={i === 0}
    />
  )) || [];

  const { name, description } = data.collectionBySlug;

  return (
    <ClassGrid
      pageTitle={<Title variant="double-pica">{name}</Title>}
      gridTitle="Classes"
      description={description}
      relatedCollectionsRailCards={relatedCollectionsRailCards}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({ duration, route: routes.COLLECTION, params: { slug } })}
          autofocus={lessons.length === 0}
          resetScroll
        />
      )}
      selectedFilter={durationRange}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            autofocus={!relatedCollectionsRailCards.length && index === 0}
            favourited={lesson.favourited}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};
const CollectionNoAuthPage = ({ match: { params } }: Props) => {
  const { slug } = params;

  const { config } = useConfig();

  const { durationRange, showAllDurations, labelFromLessonDuration, onFilter } = useDurationFilter();

  const { routes } = useRoutes();

  const logger = useLogger('on-tv:collection');

  const {
    loading,
    error,
    data,
  } = useQuery<CollectionDetailsNoAuthQuery, CollectionDetailsNoAuthQueryVariables>(COLLECTION_NO_AUTH_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      lessonConditions: {
        ...(showAllDurations ? null : { durationRange: [durationRange] }),
      },
      lessonFirst: config.MAX_LESSONS_IN_GRID,
      slug,
      collectionFirst: 15,
      collectionOrderBy: CollectionOrder.UPDATED_AT,
    },
    onError: (e) => logger.error('CollectionDetailsQuery error', { error: e, slug }),
  });
  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  useDismissEvent();

  const lessons = useMemo(() => transformLessonData(data?.collectionBySlug?.lessons?.edges), [data]);

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.collectionBySlug || !slug) {
    logger.error('An error occured loading a collection', { slug });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const relatedCollectionsRailCards = data.collectionBySlug?.relatedCollectionList?.collections?.edges.map((
    { node },
    i: number,
  ) => (
    <ClassCard
      key={node.id}
      backgroundImage={node.image?.url}
      size="small"
      name={node.name}
      to={url({ route: routes.COLLECTION, params: { slug: node.slug } })}
      autofocus={i === 0}
    />
  )) || [];

  const { name, description } = data.collectionBySlug;

  return (
    <ClassGrid
      pageTitle={<Title variant="double-pica">{name}</Title>}
      gridTitle="Classes"
      description={description}
      relatedCollectionsRailCards={relatedCollectionsRailCards}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({ duration, route: routes.COLLECTION, params: { slug } })}
          autofocus={lessons.length === 0}
          resetScroll
        />
      )}
      selectedFilter={durationRange}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            autofocus={!relatedCollectionsRailCards.length && index === 0}
            favourited={lesson.favourited}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

const CollectionPage = (props: Props) => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <CollectionAuthPage {...props} /> : <CollectionNoAuthPage {...props} />;
};

CollectionPage.menu = true;

export default CollectionPage;

import { useDispatch } from 'state';
import { addCallback as addChromecastCallback, ChromecastCallback } from 'actions/chromecast';

const useCastMessageListener = (messageType: string, callback: ChromecastCallback) => {
  const dispatch = useDispatch();

  dispatch(addChromecastCallback({ messageType, callback }));
};

export default useCastMessageListener;

import { useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';
import { useAppState, useDispatch } from 'state';
import { removeStoredWorkout } from 'actions/locally-stored-workout';
import { useHistory } from 'react-router';
import { useFinishWorkout } from 'app/on-tv/hooks/use-finish-workout';

const hasValidIncompleteWorkout = (timeOfWorkout: Date) => (
  differenceInMinutes(new Date(), timeOfWorkout) < 20
);

export const useResumeWorkoutModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const validRoute = !history.location.pathname.includes('lesson-instance') &&
    !history.location.pathname.includes('party');
  const isOnline = useAppState((state) => state.networkStatus.isOnline);
  const userId = useAppState((state) => state.auth.userId);
  const storedWorkout = useAppState((state) => state.locallyStoredWorkout);
  const hasValidWorkout = storedWorkout ? hasValidIncompleteWorkout(storedWorkout.timeOfWorkout) : false;

  const showResumeWorkoutModal = isOnline && userId && hasValidWorkout && validRoute;

  const mutationArgs = {
    workoutId: 0,
    ...storedWorkout,
  };

  // Have to declare this before the check for user id due to rules of hooks
  // Seeing as we're already deep in hooks, seems make the userID as 0 rather than a new comp to check it
  const { finishWorkout } = useFinishWorkout(userId || 0, mutationArgs);

  // clear down the stored workout if it is no longer valid
  useEffect(() => {
    if (!userId) {
      return;
    }

    if (storedWorkout && !hasValidWorkout && storedWorkout?.workoutId) {
      finishWorkout();
    }
    if ((storedWorkout && !hasValidWorkout) || !storedWorkout?.workoutId) {
      dispatch(removeStoredWorkout(userId));
    }
  }, [dispatch, storedWorkout, userId, hasValidWorkout, finishWorkout]);

  return { showResumeWorkoutModal };
};

import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import useServices from 'services';
import { useHistory } from 'react-router-dom';
import useRoutes from 'utils/use-routes';
import { useSetFocus } from 'utils/spatial-nav';
import { rem } from 'ui/helpers';

import LoginPage from 'ui/components/layouts/login-page';
import Typography from 'ui/components/atoms/typography';

import Button from 'ui/components/atoms/button';
import TextField from 'ui/components/atoms/text-field';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import Divider from 'ui/components/atoms/divider';
import { simpleEmailRegex } from 'utils/email-validation';
import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Logo from 'ui/components/atoms/logo';
import { NumberedList, ListItem } from 'ui/components/atoms/numbered-list';
import LoadingScreen from 'ui/components/molecules/loading-screen';

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(600)};
  margin-top: ${rem(40)};
`;

const LoginInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${({ theme }) => theme.spacing.l};
  margin: ${rem(30)} 0;
`;

const StyledButton = styled(Button)<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;

const Wrapper = styled.div<{ image: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${rem(120)} ${rem(150)} ${rem(50)};
  height: 100vh;
  width: 100vh;
  flex: 1;
  margin: auto;
  text-align: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url(${({ image }) => image});
    z-index: -1;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Headline = styled(Typography)`
  width: ${rem(480)};
  margin-bottom: ${rem(40)};
`;

const LogoHolder = styled.div`
  margin-bottom: ${rem(100)};
`;

const StyledNumberedList = styled(NumberedList)`
  margin-top: ${rem(60)};
`;

const StyledListItem = styled(ListItem)`
  text-align: initial;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${rem(260)};
`;

const defaultErrors = {
  email: '',
};

const LoginMagicPage = () => {
  const email = useRef<HTMLInputElement>(null);

  const services = useServices();
  const history = useHistory();
  const setFocus = useSetFocus();
  const { routes } = useRoutes();

  const [magicLinkSentTo, setMagicLinkSentTo] = useState('');
  const [{
    email: emailError,
  }, setErrors] = useState(defaultErrors);

  const [loading, setLoading] = useState(false);

  useDismissEvent();

  const onEmailKeyDown = useCallback((e) => {
    // 13 is enter, 9 is tab
    // This is because we are still natively using our own spatial nav
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      setFocus('login-magic-button');
    }
  }, [setFocus]);

  const onSubmitMagic = useCallback(async () => {
    try {
      setErrors(defaultErrors);
      if (email.current) {
        if (!simpleEmailRegex.test(email.current.value)) {
          setErrors({
            email: 'That email is invalid, please check and try again.',
          });
          return;
        }
        const emailValue = email.current.value;
        setLoading(true);
        const { message } = await services.auth.requestMagicLink({ email: emailValue });
        if (message === 'success') {
          setMagicLinkSentTo(emailValue);
          return;
        }
        throw new Error(message);
      }
    } catch (err) {
      setErrors({
        email: 'Failed to send magic link, please try again.',
      });
    } finally {
      setLoading(false);
    }
  }, [services]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (magicLinkSentTo) {
    return (
      <FullPageOverlay centered={false}>
        <Wrapper
          // eslint-disable-next-line max-len
          image="https://images-bucket.prod.fiit-static.net/app/uploads/2020/10/05090742/fhd-16-9-frame.png"
        >
          <MainWrapper>
            <LogoHolder><Logo height="60px" /></LogoHolder>
            <Headline variant="paragon">Check your inbox</Headline>
            <Typography variant="double-pica">We&apos;ve emailed you at</Typography>
            <Typography>{magicLinkSentTo}</Typography>
            <StyledNumberedList>
              <StyledListItem>Go to your emails</StyledListItem>
              <StyledListItem>Open the latest email from Fiit</StyledListItem>
              <StyledListItem>Tap &apos;Log in&apos;</StyledListItem>
            </StyledNumberedList>
          </MainWrapper>
        </Wrapper>
      </FullPageOverlay>
    );
  }

  return (
    <LoginPage
      image="https://images-bucket.prod.fiit-static.net/app/uploads/2020/09/01103508/loginbg.jpg"
      footer={(
        <>
          <Typography color="beckersKnop">Need help? Visit</Typography>
          <StyledLink variant="pica">help.fiit.tv</StyledLink>
        </>
      )}
    >
      <LoginForm>
        <LoginInputContainer>
          <StyledTextField
            id="email"
            label="Email"
            inputRef={email}
            autofocus
            errorMessage={emailError}
            onKeyDown={onEmailKeyDown}
          />
          <ButtonContainer>
            <StyledButton
              widthPercentage={100}
              spatialId="login-magic-button"
              onClick={onSubmitMagic}
              label="Send me a quick login link"
            />
          </ButtonContainer>
          <StyledDivider title="or" />
          <ButtonContainer>
            <StyledButton
              widthPercentage={100}
              spatialId="login-button"
              label="Log in with password"
              to={{ pathname: routes.LOGIN_BY_PASSWORD?.acceptedPaths[0], state: history.location.state }}
            />
          </ButtonContainer>
        </LoginInputContainer>
      </LoginForm>
    </LoginPage>
  );
};

LoginMagicPage.menu = false;

export default LoginMagicPage;

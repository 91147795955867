/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useMutation, useSubscription } from '@apollo/react-hooks';

import { useAppState, useDispatch } from 'state';
import InSession from 'app/pages/in-session';
import { init } from 'actions/lesson-instance';
import { setCanDisplay } from 'actions/screen-saver';
import useConfig from 'app/on-tv/config-provider';

import {
  LessonInstanceWorkoutCreatedSubscription,
  UpdatePlayStateMutation,
  UpdatePlayStateMutationVariables,
  WorkoutStatsEventSubscription,
  WorkoutStatsEventSubscriptionVariables,
  LessonInstanceWorkoutUpdatedSubscription,
  WorkoutState,
  OnTvPartyQuery,
  OnTvLessonInstanceFragmentFragment,
  OnTvWorkoutFragmentFragment,
  StudioType,
} from 'app/on-tv/types/graphql';

import {
  LessonInstanceWorkoutCreated as WORKOUT_CREATED,
  LessonInstanceWorkoutUpdated as WORKOUT_UPDATED,
  WorkoutStatsEvent as WORKOUT_STATS_EVENT_SUB,
} from 'app/on-tv/pages/lesson-instance/subscriptions.gql';

import DebugBar from 'app/on-tv/pages/lesson-instance/debug';
import PauseScreen from 'app/on-tv/pages/lesson-instance/pause-screen';
import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import BufferingOverlay from 'ui/components/molecules/buffering-screen';
import { LessonInstanceStatus } from 'state/lesson-instance';
import ClassInstanceLoadingScreen from 'ui/components/molecules/class-instance-loading-screen';

import useLogger from 'app/hooks/use-logger';
import { setStoredWorkout, removeStoredWorkout } from 'actions/locally-stored-workout';

import WorkoutFinishingScreen from 'app/on-tv/organisms/workout-finishing-loader/workout-finishing-loader';
import { UpdatePlayState as UPDATE_PLAY_STATE } from 'graphql/mutations/update-play-state.gql';
import { LessonInstancePlayState } from 'types/graphql';
import useRoutes from 'utils/use-routes';
import { useLessonInstanceQuery } from 'app/on-tv/pages/lesson-instance/hooks';
import { analytics } from 'utils/analytics';

export type Props = RouteComponentProps<{ lessonInstanceId?: string, partyId?: string }>;

type LessonInstanceProps = {
  userId: number,
  lessonInstance: OnTvLessonInstanceFragmentFragment & { workouts: OnTvWorkoutFragmentFragment },
  lessonMediaId: string,
  partyId?: string,
  studioType: StudioType,
  userProfileImage?: string | null,
  trackerConnected?: boolean,
  userHeartRateRange?: {
    min: number,
    max: number,
  } | null
};

export type FormattedStatsEvent = {
  score?: number | null,
  kcal?: number | null,
  reps?: number | null,
} | null;

type EquipmentDataEvent = {
  detail: {
    brp?: number,
    bsp?: number,
    bkc?: number,
    bwa?: number,
  }
};

declare global {
  interface WindowEventMap {
    EquipmentData: CustomEvent<EquipmentDataEvent>
  }
}

const formatLastStatsEvent = (event?: WorkoutStatsEventSubscription['workoutStatsEvent']): FormattedStatsEvent => {
  if (!event) {
    return null;
  }

  const { pt, kc, tr, itr } = event;
  return {
    score: pt,
    kcal: kc,
    reps: itr || tr,
  };
};

const LessonInstance = ({
  userId,
  lessonInstance,
  lessonMediaId,
  partyId,
  studioType,
  userProfileImage,
  trackerConnected,
  userHeartRateRange,
}: LessonInstanceProps) => {
  const { config } = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init({ lessonInstanceId: lessonInstance.id }));
    dispatch(setCanDisplay(false));
  }, [lessonInstance.id, dispatch]);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [playing, setPlaying] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [statsData, setStatsData] = useState({});
  const [seek] = useState(useAppState((state) => state.locallyStoredWorkout?.currentTime));
  const [finishingWorkoutInfo, setFinishingWorkoutInfo] = useState<{
    currentTime?: number,
    duration?: number,
    lastStatsEvent?: FormattedStatsEvent,
  } | null>(null);

  useEffect(() => {
    analytics.track('ContentStateChanged', {
      contentState: finishingWorkoutInfo ? 'FINISHED' : 'STARTED',
    });
  }, [finishingWorkoutInfo]);

  // When we move to remoteSessions - instead of using just the logged in user, we can end all the remotes if needed
  const workout = lessonInstance.workouts?.edges?.find(({ node }) => node.owner?.id === userId);
  const workoutId = workout?.node?.id;

  useEffect(() => {
    if (!workoutId || !config.ENABLE_WORKOUT_STORAGE) {
      return () => {};
    }

    const storeWorkout = () => dispatch(setStoredWorkout({
      workoutId,
      lessonMediaId,
      currentTime: videoRef.current?.currentTime,
      duration: videoRef.current?.duration,
      timeOfWorkout: new Date(),
      lessonId: lessonInstance.lesson.id,
      lessonInstanceId: lessonInstance.id,
      userId,
      partyId,
    }));

    storeWorkout();

    const interval = setInterval(storeWorkout, 30000);

    return () => clearInterval(interval);
  }, [
    workoutId,
    lessonMediaId,
    lessonInstance,
    userId,
    dispatch,
    initialLoading,
    partyId,
    config.ENABLE_WORKOUT_STORAGE,
  ]);

  const updateStats = useCallback((updates) => setStatsData({ ...statsData, ...updates }), [statsData]);

  useSubscription<WorkoutStatsEventSubscription, WorkoutStatsEventSubscriptionVariables>(
    WORKOUT_STATS_EVENT_SUB,
    {
      variables: { partyId },
      skip: !partyId,
      onSubscriptionData: ({ subscriptionData }) => {
        // eslint-disable-next-line no-console
        console.log('workoutStatsData', subscriptionData.data?.workoutStatsEvent);
        updateStats(subscriptionData.data?.workoutStatsEvent);
      },
    },
  );

  const [updatePlayState] = useMutation<UpdatePlayStateMutation, UpdatePlayStateMutationVariables>(UPDATE_PLAY_STATE, {
    variables: {
      lessonInstanceId: lessonInstance.id,
      playState: playing ? LessonInstancePlayState.PLAYING : LessonInstancePlayState.PAUSED,
    },
  });

  useEffect(() => {
    updatePlayState();
  }, [playing, updatePlayState]);

  const onUserPause = useCallback(() => {
    dispatch(setCanDisplay(true));
    setPlaying(false);
  }, [dispatch]);
  const onUserResume = useCallback(() => {
    dispatch(setCanDisplay(false));
    setPlaying(true);
  }, [dispatch]);
  const onPlayFailed = useCallback(() => {
    dispatch(setCanDisplay(true));
    setPlaying(false);
  }, [dispatch]);

  const handleScreenClick = () => {
    const shouldShowPauseScreen = playing && !initialLoading;
    if (shouldShowPauseScreen) {
      onUserPause();
    }
  };

  const onBuffering = useCallback((isBuffering: boolean) => {
    if (!isBuffering && initialLoading) {
      setInitialLoading(false);
      return;
    }
    if (!initialLoading) {
      setBuffering(isBuffering);
    }
  }, [initialLoading]);

  const onSessionEnd = useCallback(() => {
    setFinishingWorkoutInfo({
      currentTime: videoRef.current?.currentTime,
      duration: lessonInstance.lesson.duration,
      lastStatsEvent: formatLastStatsEvent(statsData),
    });
    dispatch(setCanDisplay(true));
  }, [lessonInstance, dispatch, statsData]);

  if (finishingWorkoutInfo) {
    return (
      <WorkoutFinishingScreen
        userId={userId}
        workoutId={workoutId}
        lessonInstanceId={lessonInstance.id}
        lessonMediaId={lessonMediaId}
        finishingWorkoutInfo={finishingWorkoutInfo}
      />
    );
  }
  return (
    <div role="main" onClick={handleScreenClick}>
      <InSession
        userId={userId}
        lessonInstance={lessonInstance}
        // For on TV we don't yet have a concept of status - Needs to be done based on remote sessions
        lessonInstanceStatus={LessonInstanceStatus.IN_SESSION}
        playing={playing}
        onSessionEnd={onSessionEnd}
        onBuffering={onBuffering}
        videoRef={videoRef}
        monitor={config.MONITOR_WORKOUT_VIDEO}
        onPlayFailed={onPlayFailed}
        seek={seek}
        isInParty={!!partyId}
        customLessonMediaId={lessonMediaId}
        workoutStatsData={statsData}
        studioType={studioType}
        userProfileImage={userProfileImage}
        trackerConnected={trackerConnected}
        userHeartRateRange={userHeartRateRange}
      />
      { config.ALLOW_IN_SESSION_DEBUG && <DebugBar videoRef={videoRef} /> }
      <PauseScreen
        onComplete={onSessionEnd}
        trainers={lessonInstance.lesson.trainers}
        lessonName={lessonInstance.lesson.name}
        playing={playing}
        onPause={onUserPause}
        onPlay={onUserResume}
      />
      { initialLoading && playing && (
        <ClassInstanceLoadingScreen
          name={lessonInstance.lesson.name}
          trainers={lessonInstance.lesson.trainers}
          backgroundImage={lessonInstance.lesson.mainImage?.url}
          duration={lessonInstance.lesson.durationRange}
        />
      ) }
      { buffering && <BufferingOverlay /> }
    </div>
  );
};

type LoaderProps = {
  lessonInstanceId?: string,
  userId: number,
  partyId?: string,
};
const LessonInstanceLoader = ({ lessonInstanceId, userId, partyId }: LoaderProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { routes, redirect } = useRoutes();
  const logger = useLogger('on-tv:lesson-instance');
  const { config } = useConfig();

  const {
    data,
    loading,
    error,
    subscribeToMore,
  } = useLessonInstanceQuery(userId, lessonInstanceId, partyId);

  const workout = useMemo(() => (
    data.lessonInstance?.workouts?.edges?.find(({ node }) => node.owner?.id === userId)
  ), [data, userId]);

  const lessonId = data.lessonInstance?.lesson.id || 0;

  useEffect(() => {
    if (!subscribeToMore || !partyId || workout || loading || error) {
      return;
    }

    subscribeToMore({
      document: WORKOUT_CREATED,
      variables: { partyId },
      updateQuery: (
        prev, {
          subscriptionData,
        } : { subscriptionData: { data: LessonInstanceWorkoutCreatedSubscription } },
      ): OnTvPartyQuery => {
        if (!subscriptionData.data || !prev.partyById) {
          return prev;
        }

        return {
          ...prev,
          partyById: {
            ...prev.partyById,
            lessonInstance: {
              ...prev.partyById?.lessonInstance,
              workouts: {
                __typename: 'WorkoutConnection',
                edges: [{
                  __typename: 'WorkoutEdge',
                  node: { ...subscriptionData.data.workoutCreated.workout },
                }],
              },
            },
          },
        };
      },
    });
  }, [subscribeToMore, partyId, workout, loading, error]);

  useSubscription(WORKOUT_UPDATED, {
    variables: { workoutId: workout?.node.id },
    skip: !partyId || !workout?.node.id,
    onSubscriptionData: (
      { subscriptionData }: { subscriptionData: { data?: LessonInstanceWorkoutUpdatedSubscription } },
    ) => {
      if (!subscriptionData.data || !workout?.node.id) {
        return;
      }

      const workoutState = subscriptionData.data.workoutUpdated.workout.state;
      const isChromecast = config.APP_TYPE === 'chromecast';

      if (workoutState === WorkoutState.CANCELLED && !isChromecast) {
        dispatch(removeStoredWorkout(userId));
        history.goBack();
        return;
      }

      if (workoutState === WorkoutState.CANCELLED && isChromecast) {
        dispatch(removeStoredWorkout(userId));
        redirect({ route: routes.CAST_SPLASH, params: { id: lessonId }, replaceStack: true });
      }

      if (workoutState === WorkoutState.COMPLETED || WorkoutState.QUIT) {
        dispatch(removeStoredWorkout(userId));
        const route = isChromecast ? routes.CAST_SUMMARY : routes.WORKOUT_SUMMARY;
        redirect({ route, params: { id: workout.node.id }, replaceStack: true });
      }
    },
  });

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !userId || !data.lessonInstance) {
    return <ErrorOverlay error={error || !userId} onDismiss="back" />;
  }

  const { lessonInstance, partyLessonMediaId, trackerConnected } = data;
  const lessonMediaId = partyLessonMediaId || workout?.node?.lessonMedia?.id;

  if (!lessonMediaId) {
    logger.error('No lessonMediaId');
    return <ErrorOverlay error onDismiss="back" />;
  }

  return (
    <LessonInstance
      userId={userId}
      lessonInstance={lessonInstance}
      lessonMediaId={lessonMediaId}
      partyId={partyId}
      studioType={lessonInstance.lesson.studio.id}
      userProfileImage={data.user?.profileImageUrl}
      trackerConnected={trackerConnected}
      // @ts-ignore (heartRate min and max are numbers not strings)
      userHeartRateRange={data.user?.heartRate}
    />
  );
};

const LessonInstancePage = ({ match: { params: { lessonInstanceId, partyId } } }: Props) => {
  const userId = useAppState((state) => state.auth.userId);

  if (!lessonInstanceId && !partyId) {
    return <p>No id screen/redirect</p>;
  }

  if (!userId) {
    return <p>No user redirect?</p>;
  }

  return <LessonInstanceLoader lessonInstanceId={lessonInstanceId} userId={userId} partyId={partyId} />;
};

LessonInstancePage.menu = false;

export default LessonInstancePage;

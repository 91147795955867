/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useConfig from 'app/on-tv/config-provider';

import { rem } from 'ui/helpers';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';
import { BulletedList, ListItem } from 'ui/components/atoms/bulleted-list';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import QRCode from 'ui/components/atoms/qr-code';
import PurchaseCard from 'ui/components/molecules/purchase-card';

export type Props = {
  showFullscreen?: boolean,
  navigateButton: ReactNode,
};

const Wrapper = styled.div<{ image: string }>`
  display: flex;
  flex-direction: row;
  padding: ${rem(120)} ${rem(150)} ${rem(50)};
  height: 100vh;
  width: 100vh;
  flex: 1 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url(${({ image }) => image});
    z-index: -1;
  }
`;

const UpsellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoHolder = styled.div`
  margin-bottom: ${rem(50)};
`;

const Headline = styled(Typography)`
  width: ${rem(1100)};
  margin-bottom: ${rem(7)};
`;

const InfoList = styled(BulletedList)`
  margin-left: ${rem(30)};
  list-style: url('https://images-bucket.prod.fiit-static.net/app/uploads/2022/06/06111109/Tick-26px.svg');
`;

const StyledListItem = styled(ListItem)`
  padding-left: ${rem(5)};
`;

const FreeTrialLink = styled.span`
  margin-left: ${rem(30)};
  margin-top: ${rem(120)};
  align-self: center;
`;

const StyledLink = styled(Typography)`
  margin: 0 0.25rem;
`;

const PaddedMessage = styled(Typography)`
  margin-bottom: ${rem(30)};
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(120)};
  width: ${rem(200)};
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
`;

const QRWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(20)};
`;

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0.2rem;
  border-radius: ${rem(10)};
  width: ${rem(180)};
  height: ${rem(180)};
  min-width: ${rem(180)};
  min-height: ${rem(180)};
  margin: 0 0.5rem 0 ${rem(50)};
`;

const StyledQRCode = styled(QRCode)`
  padding-bottom: 0.2rem;
`;

const ScanText = styled(Typography)`
  max-width: ${rem(275)};
  margin-top: ${rem(50)};
`;

const UnlimitedUpsell = () => {
  const { config } = useConfig();
  const history = useHistory();

  const isWeb = config.APP_TYPE === 'web';
  const skus = [
    {
      name: 'Unlimited Yearly',
      price: {
        prefix: '£',
        numeric: 119.99,
        suffix: '',
      },
      cycle: 12,
      headerText: 'Best Value',
      roundDp: 0,
      description: 'Billed yearly at £119.99',
      headerHighlight: true,
    },
    {
      name: 'Unlimited Monthly',
      price: {
        prefix: '£',
        numeric: 19.99,
        suffix: '',
      },
      cycle: 1,
      description: 'Billed monthly.',
    },
    {
      name: 'Only Plans',
      price: {
        prefix: '£',
        numeric: 7.99,
        suffix: '',
      },
      cycle: 1,
      description: 'Billed monthly.',
      headerText: 'Current',
    },
  ];

  const planCards = skus.map((sku, i) => (
    <PurchaseCard
      dataTest={`purchase-card-${sku.name}`}
      key={i}
      name={sku.name}
      price={sku.price}
      cycle={sku.cycle}
      headerText={sku.headerText}
      headerHighlight={sku.headerHighlight}
      smallVMargin
      focusDisabled
      roundDp={sku.roundDp}
      description={sku.description}
    />
  )) || [];

  return (
    <Wrapper image="https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/13151206/BG-Blur.png">
      <UpsellWrapper data-test="premium-upsell-content">
        <LogoHolder><Logo height="60px" /></LogoHolder>
        <Headline variant="paragon">Unlock Unlimited</Headline>
        <PaddedMessage color="beckersKnop">Take your performance to the next level</PaddedMessage>
        <InfoList color="beckersKnop">
          <StyledListItem>30+ Training Plans</StyledListItem>
          <StyledListItem>DJ mixed music &amp; Spotify connection (App only)</StyledListItem>
          <StyledListItem>Apple &amp; Google Health integration</StyledListItem>
          <StyledListItem>Community challenges, group classes (App only)</StyledListItem>
          <StyledListItem>Integration with 30+ fitness trackers, treadmills &amp; bikes</StyledListItem>
          <StyledListItem>Performance metrics &amp; analysis (heart rate, kCal, rep counting, RPM, speed)</StyledListItem>
          <StyledListItem>Game modes (incl. you vs. you &amp; live global leaderboards)</StyledListItem>
          <StyledListItem>Exclusive content, perks &amp; members events</StyledListItem>
        </InfoList>
        <Nav>
          <StyledButton
            onClick={() => history.goBack()}
            iconOnly
            label="Back"
            labelPosition="right"
            icon={<ChevronLeft />}
            autofocus
          />
          {isWeb ? (
            <FreeTrialLink>
              <Typography color="beckersKnop">To upgrade visit</Typography>
              <StyledLink variant="pica">getfiit.tv/upgrade</StyledLink>
            </FreeTrialLink>
          ) : (
            <QRWrapper>
              <QRContainer>
                <StyledQRCode url="https://getfiit.tv/upgrade" />
              </QRContainer>
              <ScanText variant="body-copy" weight="bold" as="div">Scan this QR code to upgrade</ScanText>
            </QRWrapper>
          )}

        </Nav>
      </UpsellWrapper>
      <PlanCardWrapper>
        {planCards}
      </PlanCardWrapper>
    </Wrapper>
  );
};

export default UnlimitedUpsell;

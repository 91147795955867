import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import Rail from 'ui/components/molecules/rail';
import { SpatialNavParent } from 'utils/spatial-nav';
import CompletedTrainingPlanHero from 'ui/components/molecules/completed-plan-week-hero';

import { Image } from 'app/on-tv/types/graphql';
import useRoutes, { url } from 'utils/use-routes';
import { LessonOrder } from 'types/graphql';
import BrowseCarousel, { HeroCardContent } from 'ui/components/molecules/scrolling-browse/carousel';
import { usePageScroll } from 'utils/use-page-scroll';

const Wrapper = styled.div`
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing.xl};
`;

const StyledRail = styled(Rail)`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

type Props = {
  activeTrainingPlanLessons: ReactNode[],
  recommendedLessons: ReactNode[],
  studioRail: ReactNode[],
  browseCollectionsRail: ReactNode[],
  trendingRail: ReactNode[],
  newReleasesRail: ReactNode[],
  showCompletedPlanWeekHero?: boolean,
  activePlanWeekEnd?: string,
  activePlanWeek?: number | null,
  activePlanDuration?: number,
  activePlanName?: string,
  activePlanImage?: Pick<Image, 'url' | 'hexColour'> | null,
  trainingPlans: ReactNode[],
  heroCardContent: HeroCardContent,
};

const ScrollingBrowse = ({
  activeTrainingPlanLessons,
  recommendedLessons,
  studioRail,
  browseCollectionsRail,
  trendingRail,
  newReleasesRail,
  showCompletedPlanWeekHero = false,
  activePlanWeekEnd,
  activePlanWeek,
  activePlanDuration,
  activePlanName,
  activePlanImage,
  trainingPlans,
  heroCardContent,
}: Props) => {
  const { routes, push } = useRoutes();
  const pageScroll = usePageScroll();
  const showActivePlanLessonRail = activeTrainingPlanLessons.length > 0 && !showCompletedPlanWeekHero;

  const onFocus = useCallback((element: HTMLElement | null) => {
    if (element) {
      // element.offsetTop - 20 to account for top margin
      pageScroll(element.offsetTop - 20);
    }
  }, [pageScroll]);

  return (
    <SpatialNavParent layout="vertical">
      <Wrapper>
        <BrowseCarousel
          autofocus
          heroCardContent={heroCardContent}
          onFocus={onFocus}
        />
        {showActivePlanLessonRail && (
          <StyledRail
            title="This week in your plan"
            key="training_plan"
            viewMoreAction={() => push({ route: routes.ACTIVE_TRAINING_PLAN })}
            viewMoreLabel="View plan"
            onFocus={onFocus}
          >
            { activeTrainingPlanLessons }
          </StyledRail>
        )}
        {showCompletedPlanWeekHero &&
          activePlanWeekEnd &&
          activePlanWeek &&
          activePlanDuration &&
          activePlanName && (
            <CompletedTrainingPlanHero
              completeBefore={activePlanWeekEnd}
              currentWeek={activePlanWeek}
              weeksInPlan={activePlanDuration}
              name={activePlanName}
              backgroundImage={activePlanImage}
              autofocus
              to={url({ route: routes.ACTIVE_TRAINING_PLAN })}
              onFocus={onFocus}
            />
        )}
        {newReleasesRail && newReleasesRail.length > 0 && (
          <StyledRail
            onFocus={onFocus}
            title="New releases"
            key="newest"
            viewMoreAction={() => push({ route: routes.LESSONS, params: { lessonOrder: LessonOrder.PUBLISHED_AT } })}
            viewMoreLabel="View more"
          >
            { newReleasesRail}
          </StyledRail>
        )}
        {trainingPlans && !(activeTrainingPlanLessons.length > 0 || showCompletedPlanWeekHero) && (
          <StyledRail
            onFocus={onFocus}
            title="Start a plan"
            key="training-plans"
            viewMoreAction={() => push({ route: routes.TRAINING_PLANS })}
          >
            {trainingPlans}
          </StyledRail>
        )}
        {studioRail?.length > 0 && (
          <StyledRail
            onFocus={onFocus}
            title="Studios"
            key="studios"
          >
            { studioRail}
          </StyledRail>
        )}
        <StyledRail
          title="Collections"
          key="collections"
          onFocus={onFocus}
        >
          {browseCollectionsRail}
        </StyledRail>
        {(trendingRail && trendingRail.length > 0) && (
          <StyledRail
            title="Most popular"
            key="trending"
            viewMoreAction={() => (
              push({ route: routes.LESSONS, params: { lessonOrder: LessonOrder.WORKOUTS_COMPLETED } })
            )}
            viewMoreLabel="View more"
            onFocus={onFocus}
          >
            { trendingRail}
          </StyledRail>
        )}
        {(recommendedLessons && recommendedLessons.length > 0) && (
          <StyledRail
            title="Recommended classes"
            key="recommended"
            viewMoreAction={() => (
              push({ route: routes.LESSONS, params: { lessonOrder: LessonOrder.RECOMMENDED } })
            )}
            viewMoreLabel="View more"
            onFocus={onFocus}
          >
            { recommendedLessons}
          </StyledRail>
        )}
      </Wrapper>
    </SpatialNavParent>
  );
};

export default ScrollingBrowse;

/* eslint-disable max-len */
type SimulateWorkoutStatsEventInput = {
  bpm?: number,
  ar?: number,
  iar?: number,
  tr?: number,
  itr?: number,
  kc?: number,
  pt?: number,
  bkc?: number,
  brp?: number,
  bts?: number,
  tkc?: number,
  tsp?: number,
  tts?: number,
};

const setupWebSocketMocks = (wsProcessData: (data: string) => void, userId: number) => {
  (global as any).__test_ws_client = {
    simulateLobbyEntryCreatedEvent: () => wsProcessData(JSON.stringify(
      {
        id: '2',
        payload: {
          data: {
            lobbyEntryCreated: {
              lobbyEntry: {
                id: 'lbe_YF4uPjRVNyc',
                user: { id: userId, handle: 'haaaaaaag', __typename: 'User' },
                trackerConnected: false,
                equipmentConnected: false,
                leftAt: null,
                __typename: 'LobbyEntry',
              },
              __typename: 'LobbyEntryCreatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateTrackerConnectedEvent: () => wsProcessData(JSON.stringify(
      {
        id: '1',
        payload: {
          data: {
            lobbyEntryUpdated: {
              lobbyEntry: {
                id: 'lbe_YF4uPjRVNyc',
                user: { id: userId, handle: 'haaaaaaag', __typename: 'User' },
                trackerConnected: true,
                equipmentConnected: false,
                leftAt: null,
                __typename: 'LobbyEntry',
              },
              __typename: 'LobbyEntryUpdatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateEquipmentConnectedEvent: () => wsProcessData(JSON.stringify(
      {
        id: '1',
        payload: {
          data: {
            lobbyEntryUpdated: {
              lobbyEntry: {
                id: 'lbe_YF4uPjRVNyc',
                user: { id: userId, handle: 'haaaaaaag', __typename: 'User' },
                trackerConnected: true,
                equipmentConnected: true,
                leftAt: null,
                __typename: 'LobbyEntry',
              },
              __typename: 'LobbyEntryUpdatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateLobbyLeftEvent: () => wsProcessData(JSON.stringify(
      {
        id: '1',
        payload: {
          data: {
            lobbyEntryUpdated: {
              lobbyEntry: {
                id: 'lbe_YF4uPjRVNyc',
                user: { id: userId, handle: 'haaaaaaag', __typename: 'User' },
                trackerConnected: true,
                equipmentConnected: true,
                leftAt: '2021-03-05T16:03:50.968Z',
                __typename: 'LobbyEntry',
              },
              __typename: 'LobbyEntryUpdatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateWorkoutCreatedEvent: () => wsProcessData(JSON.stringify(
      {
        id: '4',
        payload: {
          data: {
            workoutCreated: {
              workout: {
                id: 200,
                owner: { id: userId, __typename: 'User' },
                lessonMedia: { id: 'lme_eDK4fK1gX4d', __typename: 'LessonMedia' },
                __typename: 'Workout',
              },
              __typename: 'WorkoutCreatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateWorkoutEndedEvent: () => wsProcessData(JSON.stringify(
      {
        id: '5',
        payload: {
          data: {
            workoutUpdated: {
              workout: { id: 200, state: 'CANCELLED', __typename: 'Workout' },
              __typename: 'WorkoutUpdatedEvent',
            },
          },
        },
        type: 'data',
      },
    )),
    simulateWorkoutStatsEvent: (input: SimulateWorkoutStatsEventInput) => wsProcessData(JSON.stringify(
      {
        id: '3',
        payload: {
          data: {
            workoutStatsEvent: {
              ...input,
              __typename: 'workoutStatsEvent',
            },
          },
        },
        type: 'data',
      },
    )),
  };
};

export default setupWebSocketMocks;

import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAppState } from 'state';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useLogger from 'app/hooks/use-logger';
import useRoutes, { QueryParams, url } from 'utils/use-routes';
import useConfig from 'app/on-tv/config-provider';
import { parse } from 'query-string';

import {
  Lessons as LESSONS,
  LessonsNoAuth as LESSONS_NO_AUTH,
} from 'app/on-tv/pages/lessons/lessons.gql';
import {
  LessonsQuery,
  LessonsQueryVariables,
  LessonsNoAuthQuery,
  LessonsNoAuthQueryVariables,
  LessonOrder,
  LessonDurationRange,
  LessonStatus,
} from 'app/on-tv/types/graphql';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';

import ClassCard from 'ui/components/molecules/class-card';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import ClassGrid from 'ui/components/layouts/class-grid-page';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import FilterBar, { AllDurations, useDurationFilter, DurationRanges } from 'ui/components/molecules/filter-bar';
import transformLessonData from 'app/on-tv/utils/transform-lesson-data';

const Title = styled(Typography)`
  display: block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

type LessonOrders = LessonOrder.WORKOUTS_COMPLETED | LessonOrder.PUBLISHED_AT | LessonOrder.RECOMMENDED;

const getContent = (orderBy: LessonOrder, fromFilters: boolean) => {
  switch (orderBy) {
    case LessonOrder.WORKOUTS_COMPLETED:
      return {
        pageTitle: 'Popular classes',
        description: 'Top-rated workouts, as voted by the Fiit community. Try the classes everybody loves',
      };
    case LessonOrder.PUBLISHED_AT:
      return {
        pageTitle: 'New releases',
        description: 'Straight from the Fiit studio, be the first to take our newest workouts. New releases weekly.',
      };
    case LessonOrder.RECOMMENDED:
    default:
      return {
        pageTitle: fromFilters
          ? 'Finding your perfect workout'
          : 'Recommended Classes',
        description: fromFilters
          ? 'Still not right? Adjust filters and try again.'
          : 'Based on your fitness goal and level',
      };
  }
};

type PageContentProps = {
  orderBy: LessonOrders,
  userId: number,
  filters: { [key: string]: string | number | Array<string | number> | null },
  fromFilters: boolean,
};

const LessonsPageContent = ({ orderBy, userId, filters, fromFilters } : PageContentProps) => {
  const [initialDuration] = useState(filters.durationRange as DurationRanges || AllDurations.ALL);
  const { pageTitle, description } = getContent(orderBy, fromFilters);
  const { routes } = useRoutes();
  const { config } = useConfig();
  const logger = useLogger('on-tv:lessons-page:content');

  useDismissEvent();

  const { durationRange, labelFromLessonDuration, onFilter } = useDurationFilter(initialDuration);

  const { data, loading, error } = useQuery<LessonsQuery, LessonsQueryVariables>(
    LESSONS, {
      variables: {
        ...lessonsQueryVariables,
        lessonConditions: {
          ...lessonsQueryVariables.lessonConditions,
          ...filters,
        },
        userId,
        ...(orderBy === LessonOrder.RECOMMENDED)
          ? { firstLessonsLength: config.MAX_LESSONS_IN_GRID }
          : { lastLessonsLength: config.MAX_LESSONS_IN_GRID },
        orderBy,
      },
      onError: (e) => logger.error('LessonsGridPage graphQL error', { error: e }),
    },
  );

  const lessons = useMemo(() => transformLessonData(data?.allLessons?.edges), [data]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  return (
    <ClassGrid
      pageTitle={<Title variant="body-copy" weight="bold">{pageTitle}</Title>}
      description={description}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({
            duration,
            route: routes.LESSONS,
            query: filters as QueryParams,
            params: { lessonOrder: orderBy },
          })}
          autofocus={lessons.length === 0}
          resetScroll
        />
      )}
      selectedFilter={durationRange}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            autofocus={index === 0}
            favourited={lesson.favourited}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

type LessonPageProps = {
  location: {
    search: string
  }
  match: {
    params: {
      lessonOrder: string,
    },
  }
};

const getOrderBy = (param: string): LessonOrder => {
  const paramAsLessonOrder = LessonOrder[param as keyof typeof LessonOrder];
  if (paramAsLessonOrder) {
    return paramAsLessonOrder;
  }

  switch (param) {
    case 'newest':
      return LessonOrder.PUBLISHED_AT;
    case 'popular':
      return LessonOrder.WORKOUTS_COMPLETED;
    default:
      return LessonOrder.RECOMMENDED;
  }
};

const LessonsAuthPage = ({ location: { search }, match: { params: { lessonOrder } } } : LessonPageProps) => {
  const userId = useAppState((state) => state.auth.userId);
  const logger = useLogger('on-tv:lessons-page');
  const parsedQueryParams = parse(search, { arrayFormat: 'comma', parseNumbers: true });
  const orderBy = getOrderBy(lessonOrder);

  if (!userId) {
    logger.error('LessonsGrid page no userId');
    return <ErrorOverlay error onDismiss="back" />;
  }

  if (orderBy !== LessonOrder.PUBLISHED_AT &&
      orderBy !== LessonOrder.WORKOUTS_COMPLETED &&
      orderBy !== LessonOrder.RECOMMENDED
  ) {
    logger.error('Unaccepted orderBy value', { orderBy });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const { durationRange, fromFilters, ...otherFilters } = parsedQueryParams;

  const filters = {
    ...(durationRange === AllDurations.ALL ? null : { durationRange }),
    ...otherFilters,
  };

  return (
    <LessonsPageContent
      filters={filters}
      orderBy={orderBy}
      userId={userId}
      fromFilters={!!fromFilters}
    />
  );
};

type PageContentNoAuthProps = {
  orderBy: LessonOrders,
  filters: { [key: string]: string | number | Array<string | number> | null },
  fromFilters: boolean,
};

const LessonsNoAuthPageContent = ({ orderBy, filters, fromFilters } : PageContentNoAuthProps) => {
  const [initialDuration] = useState(filters.durationRange as DurationRanges || AllDurations.ALL);
  const { pageTitle, description } = getContent(orderBy, fromFilters);
  const { routes } = useRoutes();
  const { config } = useConfig();
  const logger = useLogger('on-tv:lessons-page:content');

  useDismissEvent();

  const { durationRange, labelFromLessonDuration, onFilter } = useDurationFilter(initialDuration);

  const { data, loading, error } = useQuery<LessonsNoAuthQuery, LessonsNoAuthQueryVariables>(
    LESSONS_NO_AUTH, {
      variables: {
        lessonConditions: {
          ...filters,
        },
        lastLessonsLength: config.MAX_LESSONS_IN_GRID,
        orderBy,
      },
      onError: (e) => logger.error('LessonsGridPage graphQL error', { error: e }),
    },
  );

  const lessons = useMemo(() => transformLessonData(data?.allLessons?.edges), [data]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  return (
    <ClassGrid
      pageTitle={<Title variant="body-copy" weight="bold">{pageTitle}</Title>}
      description={description}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({
            duration,
            route: routes.LESSONS,
            query: filters as QueryParams,
            params: { lessonOrder: orderBy },
          })}
          autofocus={lessons.length === 0}
          resetScroll
        />
      )}
      selectedFilter={durationRange}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked
            completed={false}
            kettlebells={lesson.kettlebells}
            dumbbells={lesson.dumbbells}
            autofocus={index === 0}
            favourited={false}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

const LessonsNoAuthPage = ({ location: { search }, match: { params: { lessonOrder } } } : LessonPageProps) => {
  const logger = useLogger('on-tv:lessons-page');
  const parsedQueryParams = parse(search, { arrayFormat: 'comma', parseNumbers: true });
  const orderBy = getOrderBy(lessonOrder);

  if (orderBy !== LessonOrder.PUBLISHED_AT &&
      orderBy !== LessonOrder.WORKOUTS_COMPLETED
  ) {
    logger.error('Unaccepted orderBy value', { orderBy });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const { durationRange, fromFilters, ...otherFilters } = parsedQueryParams;

  const filters = {
    ...(durationRange === AllDurations.ALL ? null : { durationRange }),
    ...otherFilters,
  };

  return (
    <LessonsNoAuthPageContent
      filters={filters}
      orderBy={orderBy}
      fromFilters={!!fromFilters}
    />
  );
};

const LessonsPage = (props: LessonPageProps) => {
  const userId = useAppState((state) => state.auth.userId);

  return userId ? <LessonsAuthPage {...props} /> : <LessonsNoAuthPage {...props} />;
};

LessonsPage.menu = true;
export default LessonsPage;

import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useAppState } from 'state';
import useConfig from 'app/on-tv/config-provider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ActiveTrainingPlan as ACTIVE_TRAINING_PLAN,
} from 'src/app/on-tv/pages/active-training-plan/active-training-plan.gql';
import {
  CancelTrainingPlan as CANCEL_TRAINING_PLAN,
} from 'src/app/on-tv/pages/active-training-plan/cancel-training-plan.gql';
import {
  ActiveTrainingPlanQuery,
  ActiveTrainingPlanQueryVariables,
  CancelTrainingPlanMutation,
  CancelTrainingPlanMutationVariables,
  LessonDifficulty,
  LessonCardFragmentFragment,
  WorkoutState,
  MetricsSummaryFragmentFragment,
  TrainingPlan,
  LessonStatus,
} from 'app/on-tv/types/graphql';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';
import useRoutes, { url } from 'utils/use-routes';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import ActivePlan from 'ui/components/layouts/active-training-plan';
import ClassCard from 'ui/components/molecules/class-card';

import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useLogger from 'app/hooks/use-logger';
import { trainerNames } from 'ui/components/utils/trainer-names';
import { analytics } from 'utils/analytics';

// Skip showing an error page if the plan has already been cancelled and we try to cancel again
const errorToSkip = 'User does not have a cancellable training plan or has been cancelled';

type PageProps = {
  startedAt: string,
  currentWeek: number,
  level: LessonDifficulty,
  trainingPlanId: number,
  trainingPlan: Pick<TrainingPlan, 'id'|'name'|'durationWeeks'|'description'|'tagline'|'equipment'|'images'>,
  durationWeeks: number,
  planClassesTaken: number,
  selectedWeek?: number | null,
  activeTrainingPlanWeek: {
    periodEnd: string,
    periodStart: string,
    weekNumber: number,
    schedule: Array<{
      id: number,
      completeBefore: string,
      workout: any,
      lesson: LessonCardFragmentFragment,
    }>,
  },
  toggleWeek: Dispatch<SetStateAction<number | null>>,
  activeWeekDescription?: string | null,
  metrics?: MetricsSummaryFragmentFragment | null,
  noOfClasses: number,
};

const Page = ({
  startedAt,
  currentWeek,
  trainingPlan,
  level,
  trainingPlanId,
  durationWeeks,
  toggleWeek,
  activeTrainingPlanWeek,
  selectedWeek,
  planClassesTaken,
  activeWeekDescription,
  metrics,
  noOfClasses,
}: PageProps) => {
  const { routes, redirect } = useRoutes();
  const logger = useLogger('on-tv:active-training-plans-page:page');

  const [
    cancelTrainingPlan,
    { error },
  ] = useMutation<CancelTrainingPlanMutation, CancelTrainingPlanMutationVariables>(
    CANCEL_TRAINING_PLAN,
    {
      variables: {
        id: trainingPlanId,
      },
      onError: ({ message }) => {
        if (message.includes(errorToSkip)) {
          return redirect({ route: routes.TRAINING_PLANS });
        }

        return logger.error('Error cancelling training plan', { error });
      },
      onCompleted: () => {
        analytics.track('TrainingPlanCancelled');
      },
    },
  );

  if (error && !error?.message.includes(errorToSkip)) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const classesLeft = activeTrainingPlanWeek.schedule.reduce(
    (acc, { workout }) => acc + (workout?.state === WorkoutState.COMPLETED ? 0 : 1), 0,
  );

  return (
    <ActivePlan
      timeInSeconds={metrics?.time?.sum}
      cancelTrainingPlan={cancelTrainingPlan}
      name={trainingPlan.name}
      level={level}
      durationWeeks={durationWeeks}
      selectedWeek={selectedWeek || currentWeek}
      currentWeek={currentWeek}
      startedAt={startedAt}
      weekStartedAt={activeTrainingPlanWeek.periodStart}
      weekEndsAt={activeTrainingPlanWeek.periodEnd}
      backgroundImage={trainingPlan.images?.cover}
      toggleWeek={toggleWeek}
      classesLeft={classesLeft}
      planClassesTaken={planClassesTaken}
      description={activeWeekDescription}
      noOfClasses={noOfClasses}
      lessons={activeTrainingPlanWeek.schedule.map(({ lesson, workout, id }, i: number) => (
        <ClassCard
          key={i}
          backgroundImage={lesson.mainImage?.url}
          size="large"
          duration={lesson.durationRange}
          trainer={trainerNames(lesson.trainers)}
          name={lesson.name}
          to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id }, query: { scheduleId: id } })}
          completedInTrainingPlan={workout?.state === WorkoutState.COMPLETED}
          locked={!lesson.permissionsWithReasons.start.value}
          kettlebells={!!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL')}
          dumbbells={!!lesson.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS')}
          inTesting={lesson.status === LessonStatus.TESTING}
        />
      ))}
    />
  );
};

const ActiveTrainingPlanPage = () => {
  const userId = useAppState((state) => state.auth.userId);
  const [selectedWeek, setSelectedWeek] = useState<number | null>(null);
  const { routes, redirect } = useRoutes();
  const { config } = useConfig();
  const logger = useLogger('on-tv:active-training-plans-page');

  useDismissEvent();

  const { loading, error, data } = useQuery<ActiveTrainingPlanQuery, ActiveTrainingPlanQueryVariables>(
    ACTIVE_TRAINING_PLAN,
    {
      variables: {
        ...lessonsQueryVariables,
        userId,
        noUserId: !userId,
      },
      pollInterval: config.ACTIVE_TRAINING_PLAN_POLLING_INTERVAL * 1000,
      onError: (e) => logger.error('TrainingPlans graphQL error', { error: e }),
    },
  );

  useEffect(() => {
    if (data && !data.user?.activeTrainingPlan) {
      redirect({ route: routes.TRAINING_PLANS });
    }
  }, [data, redirect, routes.TRAINING_PLANS]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.user?.activeTrainingPlan) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const { startedAt, trainingPlanTemplate, currentWeek, byWeek, id, workoutSummary } = data.user.activeTrainingPlan;
  const { trainingPlan, level, byWeek: trainingPlanTemplateByWeek } = trainingPlanTemplate;

  const activeTrainingPlanWeek =
    byWeek.find((week) => (selectedWeek ? week.weekNumber === selectedWeek : week.weekNumber === currentWeek));

  const planClassesTaken = byWeek.reduce((totalClasses, { schedule }) => {
    const workoutsCompletedByWeek = schedule.reduce((weekClasses, { workout }) => (
      workout && workout.state === WorkoutState.COMPLETED ? weekClasses + 1 : weekClasses
    ), 0);
    return totalClasses + workoutsCompletedByWeek;
  }, 0);

  const noOfClasses = byWeek.reduce((acc: number, { schedule }) => acc + schedule.length, 0);

  if (!activeTrainingPlanWeek || !currentWeek) {
    logger.error('No activeTrainingPlanWeek/currentWeek', { activeTrainingPlanWeek, currentWeek });
    return <ErrorOverlay error onDismiss="back" />;
  }

  const activeWeekDescription = trainingPlanTemplateByWeek[currentWeek - 1]?.overview;

  return (
    <Page
      startedAt={startedAt}
      currentWeek={currentWeek}
      durationWeeks={byWeek.length}
      trainingPlan={trainingPlan}
      level={level}
      trainingPlanId={id}
      selectedWeek={selectedWeek}
      toggleWeek={setSelectedWeek}
      planClassesTaken={planClassesTaken}
      activeTrainingPlanWeek={activeTrainingPlanWeek}
      activeWeekDescription={activeWeekDescription}
      metrics={workoutSummary?.summary}
      noOfClasses={noOfClasses}
    />
  );
};

ActiveTrainingPlanPage.menu = true;
export default ActiveTrainingPlanPage;

import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function TargetIcon({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <path d="M14 12C14 13.105 13.105 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10C13.105 10 14 10.895 14 12Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0H13V2.04937C17.7245 2.51842 21.4816 6.27546 21.9506 11H24V13H21.9506C21.4816 17.7245 17.7245 21.4816 13 21.9506V24H11V21.9506C6.27546 21.4816 2.51842 17.7245 2.04937 13H0V11H2.04937C2.51842 6.27546 6.27546 2.51842 11 2.04937V0ZM13 19.9378V18H11V19.9378C7.38681 19.4852 4.51479 16.6132 4.06219 13H6V11H4.06219C4.51479 7.38681 7.38681 4.51479 11 4.06219V6H13V4.06219C16.6132 4.51479 19.4852 7.38681 19.9378 11H18V13H19.9378C19.4852 16.6132 16.6132 19.4852 13 19.9378Z" fill="white" />
    </Svg>
  );
}

export default TargetIcon;

import React, { useEffect, useState, useCallback } from 'react';

import DebugBar from 'ui/components/utils/debug-bar';

type Props = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
};

const Debug = ({ videoRef }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleDebugBar = (e: KeyboardEvent) => {
      if (e.code === 'KeyD') {
        setVisible(!visible);
      }
    };

    document.addEventListener('keydown', toggleDebugBar);

    return () => {
      document.removeEventListener('keydown', toggleDebugBar);
    };
  }, [visible]);

  const skipSeconds = useCallback((secondsToSkip: number) => {
    const el = videoRef?.current;

    if (!el) {
      return;
    }

    el.currentTime += Math.min(el.duration, secondsToSkip);
  }, [videoRef]);

  return <DebugBar visible={visible} skipSeconds={skipSeconds} />;
};

export default Debug;

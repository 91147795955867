import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import useLogger from 'app/hooks/use-logger';

import { PaymentProvider, PremiumUpsellQuery, PremiumUpsellQueryVariables } from 'app/on-tv/types/graphql';
import { PremiumUpsell as PREMIUM_UPSELL } from 'app/on-tv/pages/premium-upsell/premium-upsell.gql';

import PremiumUpsell from 'app/on-tv/organisms/premium-upsell';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import SignUpIntroPage from 'ui/components/molecules/sign-up-intro';
import { useQueryParam } from 'app/on-tv/hooks/use-query-param';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import useRoutes from 'utils/use-routes';

type IAPSuccessEvent = {
  detail: {
    amazonUserId: string,
    receiptId: string,
  }
};

type IAPErrorEvent = {
  detail: unknown
};

type AndroidIAPProviderNotSupportedEvent = {
  detail: unknown
};

type AmazonIAPProductDataEvent = {
  detail: {
    paymentProvider: PaymentProvider.AMAZON,
    productData: {
      [key: string]: {
        sku: string,
        productType: string,
        description: string,
        price: string,
        smallIconUrl: string,
        title: string,
        coinsRewardAmount: number,
      }
    };
  }
};

type AmazonIAPProductDataErrorEvent = {
  detail: unknown
};

declare global {
  interface WindowEventMap {
    AmazonIapPaymentSuccess: CustomEvent<IAPSuccessEvent>
    AmazonIapPaymentError: CustomEvent<IAPErrorEvent>
    AndroidIapProviderNotSupported: CustomEvent<AndroidIAPProviderNotSupportedEvent>
    AmazonIapProductData: CustomEvent<AmazonIAPProductDataEvent>
    AmazonIapProductDataError: CustomEvent<AmazonIAPProductDataErrorEvent>
  }
}

const PremiumUpsellPage = () => {
  const { routes, redirect } = useRoutes();
  const arrivedFromSignup = Boolean(useQueryParam().get('fromSignUpRoute'));
  useDismissEvent();
  const logger = useLogger('on-tv:premium-upsell-page');

  const { data, loading } = useQuery<PremiumUpsellQuery, PremiumUpsellQueryVariables>(
    PREMIUM_UPSELL,
    {
      skip: !arrivedFromSignup,
      onError: (error) => logger.error('PremiumUpsellQuery error', { error }),
    },
  );

  // if the user has come from the sign up page but is already premium, redirect to browse
  useEffect(() => {
    if (data && !data.auth.features.promoteUpgrade.value) {
      redirect({ route: routes.BROWSE, replaceStack: true });
    }
  }, [data, redirect, routes.BROWSE]);

  const [showSignUpIntroPage, setShowSignUpIntroPage] = useState(arrivedFromSignup);

  if (loading) {
    return <LoadingScreen />;
  }

  if (showSignUpIntroPage) {
    return <SignUpIntroPage onDismiss={() => setShowSignUpIntroPage(false)} />;
  }

  return (
    <PremiumUpsell />
  );
};

PremiumUpsellPage.menu = true;

export default PremiumUpsellPage;

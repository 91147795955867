import { useState, useCallback } from 'react';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import { Config } from 'config';

import closeApp from 'app/on-tv/utils/close-app';

type UseLeaveFiitModalProps = {
  showLeaveFiitModal: boolean,
  dismissLeaveFiitModal: () => void,
};

export const useLeaveFiitModal = (config: Config): UseLeaveFiitModalProps => {
  const [showLeaveFiitModal, setShowLeaveFiitModal] = useState(false);

  useDismissEvent(useCallback(() => {
    if (showLeaveFiitModal) {
      if (config.APP_TYPE === 'virgin') {
        setShowLeaveFiitModal(false);
      } else {
        closeApp();
      }
    } else {
      setShowLeaveFiitModal(true);
    }
  }, [showLeaveFiitModal, config.APP_TYPE]), true);

  const dismissLeaveFiitModal = useCallback(() => {
    setShowLeaveFiitModal(false);
  }, []);

  return {
    showLeaveFiitModal,
    dismissLeaveFiitModal,
  };
};

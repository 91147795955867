import React from 'react';
import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';
import { SpatialNavParent } from 'utils/spatial-nav';
import {
  ResumeWorkoutModal as RESUME_WORKOUT_MODAL_QUERY,
} from 'app/on-tv/organisms/resume-workout-modal/resume-workout-modal.gql';
import { useQuery } from '@apollo/react-hooks';
import {
  ResumeWorkoutModalQuery,
  ResumeWorkoutModalQueryVariables,
} from 'app/on-tv/types/graphql';
import useRoutes from 'utils/use-routes';
import ClassLength from 'ui/components/atoms/class-length';
import { trainerNames } from 'ui/components/utils/trainer-names';
import { useFinishWorkout } from 'app/on-tv/hooks/use-finish-workout';
import { useAppState } from 'state';

type ModalProps = {
  userId: number,
  storedWorkout: {
    workoutId?: number,
    currentTime?: number,
    duration?: number,
    lessonId: number,
    lessonInstanceId: string,
    partyId?: string,
  },
};

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.1rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  width: 50%;
  background-color: ${({ theme }) => theme.colors.black};
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ClassSection = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: 0.5rem;
`;

const StyledButton = styled(Button)`
  margin: 1rem 1rem 0 0;
  width: 8rem;
`;

const StyledClassLength = styled(ClassLength)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

const ClassName = styled(Typography)`
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
`;

const TrainerNames = styled(Typography)`
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  position: relative;
`;

const ResumeWorkoutModal = ({ userId } : { userId: number }) => {
  const storedWorkout = useAppState((state) => state.locallyStoredWorkout);
  if (!storedWorkout) {
    return null;
  }

  return (
    <Modal userId={userId} storedWorkout={storedWorkout} />
  );
};

const Modal = ({ userId, storedWorkout } : ModalProps) => {
  const { routes, push } = useRoutes();
  const mutationArgs = {
    ...storedWorkout,
    workoutId: storedWorkout.workoutId || 0,
    currentTime: storedWorkout.currentTime,
    duration: storedWorkout.duration,
  };
  const { finishWorkout } = useFinishWorkout(userId, mutationArgs);
  const { lessonId, lessonInstanceId, partyId } = storedWorkout;

  const { loading, error, data } = useQuery<ResumeWorkoutModalQuery, ResumeWorkoutModalQueryVariables>(
    RESUME_WORKOUT_MODAL_QUERY, {
      variables: {
        id: lessonId,
        userId,
      },
    },
  );

  const onOkClick = () => {
    if (partyId) {
      return push({ route: routes.PARTY, params: { partyId } });
    }

    return push({ route: routes.LESSON_INSTANCE, params: { lessonInstanceId } });
  };

  const dismiss = () => {
    finishWorkout();
  };

  if (loading || error || !(data && data.lessonById)) {
    return null;
  }

  const { name, trainers, mainImage, durationRange } = data.lessonById;

  return (
    <FullPageOverlay>
      <SpatialNavParent layout="horizontal">
        <Container>
          <ClassSection>
            <Image src={mainImage?.url} />
            <StyledClassLength duration={durationRange} />
            <ClassName variant="body-copy">{name}</ClassName>
            <TrainerNames variant="body-copy" color="beckersKnop">{trainerNames(trainers)}</TrainerNames>
          </ClassSection>
          <ContentSection>
            <Typography variant="body-copy" weight="bold">Want to finish what you started?</Typography>
            <Typography variant="body-copy">Pick up where you left off.</Typography>
            <ButtonsSection data-test="resume-workout-buttons-holder">
              <StyledButton
                autofocus
                label="Ok"
                onClick={onOkClick}
              />
              <StyledButton
                label="No thanks"
                onClick={dismiss}
              />
            </ButtonsSection>
          </ContentSection>
        </Container>
      </SpatialNavParent>
    </FullPageOverlay>
  );
};

export default ResumeWorkoutModal;

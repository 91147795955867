import React, { Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import ClassLength from 'ui/components/atoms/class-length';
import Typography from 'ui/components/atoms/typography';
import { Image, LessonDurationRange } from 'types/graphql';
import { rem } from 'ui/helpers';
import CardBadge from 'ui/components/atoms/card-badge';
import WatchIcon from 'ui/components/atoms/icons/watch';
import EquipmentIcon from 'ui/components/atoms/icons/equipment';
import ClassesIcon from 'ui/components/atoms/icons/classes';
import { pluralise } from 'ui/components/utils/pluralise';
import { useSpatialTarget } from 'utils/spatial-nav';
import { Link } from 'react-router-dom';
import CardFocus from 'ui/components/atoms/card-focus';
import TestingBadge from 'ui/components/atoms/testing-badge';

export type Props = {
  name?: string,
  duration?: LessonDurationRange,
  backgroundImage?: Pick<Image, 'url' | 'hexColour'> | null,
  trainer?: string,
  onClick?: () => void,
  className?: string,
  title?: string,
  description?: string | null,
  durationWeeks?: number,
  noOfClasses?: number,
  equipment?: string[] | null,
  autofocus?: boolean,
  to?: string,
  setScrollPosition?: Dispatch<SetStateAction<number>>,
  onFocus?: (target: HTMLElement | null) => void,
  inTesting?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  border-radius: ${rem(2)};
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
    border: 1px solid white;
    border-radius: ${rem(2)};
  }
`;

const ContentTop = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
`;

const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
`;

const Title = styled(Typography)`
  padding-bottom: 1rem;
  opacity: 0.5;
`;

const StyledTypography = styled(Typography)<{ transparent?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  ${({ transparent }) => (transparent ? 'opacity: 0.5' : '')};
`;

const ClassLengthStyled = styled(ClassLength)`
  padding: 0;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.hr`
  margin: 0 0.5rem 0 0.5rem;
  height: 0.5rem;
  border: 0.5px solid currentColor;
`;

const CardLink = styled(Link)`
  width: 100%;
  display: flex;
`;

const StyledTestingBadge = styled(TestingBadge)`
  top: 0.5rem;
  right: 0.5rem;
`;

const HeroCard = ({
  name,
  duration,
  backgroundImage,
  trainer,
  className,
  title,
  description,
  durationWeeks,
  noOfClasses,
  equipment,
  autofocus = false,
  to,
  onFocus,
  inTesting,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
    disabled: !to,
  });

  if (focused && onFocus) {
    onFocus(elRef.current);
  }

  const Card = (
    <CardFocus focus={focused} boxSize="large" data-test="hero-card">
      {inTesting && <StyledTestingBadge />}
      <Wrapper
        className={className}
        ref={elRef}
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage.url})`
            : 'none',
          backgroundColor: backgroundImage?.hexColour || '',
        }}
      >
        <ContentTop>
          {title && <Title variant="body-copy">{title}</Title>}
          {name && <Typography color="white" variant="paragon">{name}</Typography>}
          {
            description && (
              <StyledTypography
                variant="body-copy"
                transparent
              >
                {description}
              </StyledTypography>
            )
          }
          {
            trainer && (
              <StyledTypography
                variant="body-copy"
                weight="bold"
              >
                {trainer}
              </StyledTypography>
            )
          }
          {
            (durationWeeks || noOfClasses || equipment) && (
              <ListContainer>
                {durationWeeks && (
                  <>
                    <CardBadge marginRight outline={false}>
                      <WatchIcon />
                    </CardBadge>
                    <Typography variant="pica">{durationWeeks} {pluralise(durationWeeks, 'week', 'weeks')}</Typography>
                  </>
                )}
                {noOfClasses && (
                  <>
                    <Line />
                    <CardBadge marginRight outline={false}>
                      <ClassesIcon />
                    </CardBadge>
                    <Typography variant="pica">{noOfClasses} {pluralise(noOfClasses, 'class', 'classes')}</Typography>
                  </>
                )}
                {equipment && (
                  <>
                    <Line />
                    <CardBadge marginRight fill>
                      <EquipmentIcon />
                    </CardBadge>
                    {equipment && equipment.length > 0 && (
                      <Typography variant="pica">{equipment.join(', ')}</Typography>
                    )}
                  </>
                )}
              </ListContainer>
            )
          }
        </ContentTop>
        <ContentBottom>
          {duration && <ClassLengthStyled showIcon duration={duration} />}
        </ContentBottom>
      </Wrapper>
    </CardFocus>
  );

  if (!to) {
    return Card;
  }

  return (
    <CardLink ref={elRef} to={to} className={className}>
      {Card}
    </CardLink>
  );
};

export default HeroCard;

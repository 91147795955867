import React from 'react';
import styled from 'styled-components';
import useConfig from 'config';
import { rem } from 'ui/helpers';

import FullPageOverlay from 'ui/components/layouts/full-page-overlay';
import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';
import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import Button from 'ui/components/atoms/button';
import LeaveFiitModal, { useLeaveFiitModal } from 'app/on-tv/organisms/leave-fiit-modal';

const Wrapper = styled.div<{ image: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${rem(120)} ${rem(150)} ${rem(50)};
  height: 100vh;
  width: 100vh;
  flex: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url(${({ image }) => image});
    z-index: -1;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LogoHolder = styled.div`
  margin-bottom: ${rem(100)};
`;

const Headline = styled(Typography)`
  width: ${rem(750)};
  margin-bottom: ${rem(40)};
`;

const Tagline = styled(Typography)`
  opacity: 80%;
`;

const StyledButton = styled(Button)`
  margin: ${rem(72)} 0;
  padding-left: ${rem(40)};
  width: ${rem(300)};
`;

type Props = {
  onDismiss: () => void;
};

const SignUpIntro = ({ onDismiss } : Props) => {
  const { config } = useConfig();
  const { showLeaveFiitModal, dismissLeaveFiitModal } = useLeaveFiitModal(config);

  if (showLeaveFiitModal) {
    return <LeaveFiitModal dismiss={dismissLeaveFiitModal} />;
  }

  return (
    <FullPageOverlay centered={false}>
      <Wrapper
        // eslint-disable-next-line max-len
        image="https://images-bucket.prod.fiit-static.net/app/uploads/2021/01/26153618/logged-in-confirmation-bg-scaled.jpg"
      >
        <MainWrapper>
          <LogoHolder><Logo height="60px" /></LogoHolder>
          <Headline variant="paragon">You&apos;re in</Headline>
          <Tagline>Get ready to move</Tagline>
        </MainWrapper>
        <StyledButton
          onClick={onDismiss}
          iconOnly
          label="Let's get started"
          labelPosition="left"
          icon={<ChevronRight />}
          autofocus
        />
      </Wrapper>
    </FullPageOverlay>
  );
};

export default SignUpIntro;

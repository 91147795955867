import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import WatchIcon from 'ui/components/atoms/icons/watch';
import ClassIcon from 'ui/components/atoms/icons/classes';
import { pluralise } from 'ui/components/utils/pluralise';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.beckersKnop};
`;

const MetricContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
`;

const StyledIcon = styled.div`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;

const Divider = styled.hr`
  margin: auto;
  border-right: 1px solid currentColor;
  height: 0.5rem;
`;

const StyledTypography = styled(Typography)`
  margin-top: 0.2rem;
`;

type Props = {
  classesLeft: number,
  endsAt: string,
};

const getDayDifference = (startedAt: Date, endsAt: Date) => {
  const timeDifference = endsAt.getTime() - startedAt.getTime();
  return timeDifference / (1000 * 3600 * 24);
};

const getDaysLeftText = (daysLeft: number): string => {
  if (daysLeft === 0) {
    return 'Ends today';
  }
  return daysLeft > 0 ? `${daysLeft} ${pluralise(daysLeft, 'Day', 'Days')} Left` : 'Week ended';
};

const TrainingPlanMetrics = ({ endsAt, classesLeft } : Props) => {
  const daysLeft = Math.floor(getDayDifference(new Date(), new Date(endsAt)));

  return (
    <Wrapper>
      <MetricContainer>
        <StyledIcon color="beckersKnop" as={ClassIcon} />
        <StyledTypography variant="pica">
          {classesLeft} {pluralise(classesLeft, 'Class', 'Classes')} Left
        </StyledTypography>
      </MetricContainer>
      <Divider />
      <MetricContainer>
        <StyledIcon color="beckersKnop" as={WatchIcon} />
        <StyledTypography variant="pica">{getDaysLeftText(daysLeft)}</StyledTypography>
      </MetricContainer>
    </Wrapper>
  );
};

export default TrainingPlanMetrics;

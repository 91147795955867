import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

import FullPageModal, { Props as FullPageModalProps } from 'ui/components/layouts/full-page-modal';

import ModalAlertContents from 'ui/components/molecules/modal-alert-contents';
import { NumberedList, ListItem } from 'ui/components/atoms/numbered-list';
import Typography from 'ui/components/atoms/typography';
import Plus from 'ui/components/atoms/icons/plus';

import { AcceptAgreement as ACCEPT_AGREEMENT } from 'app/on-tv/pages/lesson/accept-agreement.gql';

import {
  AgreementType,
  AcceptAgreementMutation,
  AcceptAgreementMutationVariables,
} from 'app/on-tv/types/graphql';

export type Agreement = {
  id: number,
  type: AgreementType,
  url: string,
  acceptedByUser: boolean,
};

export type Props = Agreement & {
  userId: number,
  onDismiss: FullPageModalProps['onDismiss'],
};

export const SUPPORTED_AGREEMENTS = [
  'HEALTH_DISCLAIMER',
  'POSTNATAL',
];

const AgreementIntro = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const AgreementContent = ({ type }: { type: AgreementType }) => {
  if (type === 'POSTNATAL') {
    return (
      <>
        <AgreementIntro>
          <Typography>
            Our Postnatal classes have been designed by a team of experts to guide you on your journey in a safe way.
          </Typography>
        </AgreementIntro>
        <NumberedList>
          <ListItem>
            You must obtain professional advice from your doctor before participating and have been specifically cleared
            by your doctor for exercise of this nature at your postnatal medical review no earlier than 6 weeks after
            giving birth.
          </ListItem>
          <ListItem>
            If you feel any discomfort, leaking, dragging or heaviness sensations within the vagina, pain, dizziness or
            nausea, you should discontinue exercise immediately.
          </ListItem>
        </NumberedList>
      </>
    );
  }

  return (
    <NumberedList>
      <ListItem>Complete the warm up and cool down for each class to prevent injury.</ListItem>
      <ListItem>Stay hydrated.</ListItem>
      <ListItem>
        Stop exercising immediately if you feel faint. If you continue to feel dizzy please consult your doctor.
      </ListItem>
      <ListItem>Don’t attempt to do any exercises that are beyond your current ability.</ListItem>
    </NumberedList>
  );
};

const AgreementModal = ({ userId, onDismiss, id, url, type }: Props) => {
  const [acceptAgreement, { loading, error }] = useMutation<AcceptAgreementMutation, AcceptAgreementMutationVariables>(
    ACCEPT_AGREEMENT,
    {
      variables: {
        userId,
        healthAgreement: type === 'HEALTH_DISCLAIMER' ? id : null,
        breathworkAgreement: type === 'BREATHWORK' ? id : null,
        postnatalAgreement: type === 'POSTNATAL' ? id : null,
      },
      update: (cache, { data }) => {
        if (data?.patchUser) {
          cache.writeFragment({
            id: `Agreement:${id}`,
            fragment: gql`
              fragment UpdateAgreement on Agreement {
                __typename
                id
                acceptedByUser(user: {
                  id: $userId
                })
              }
            `,
            data: {
              __typename: 'Agreement',
              acceptedByUser: true,
            },
            variables: {
              userId,
            },
          });
        }
      },
    },
  );

  const title = type === 'POSTNATAL' ? 'Important notice' : 'Exercise safely!';

  return (
    <FullPageModal icon={<Plus />} onDismiss={onDismiss}>
      <ModalAlertContents
        title={title}
        onAcceptClick={acceptAgreement}
        accepting={loading}
        acceptError={error}
        autofocus
      >
        <AgreementContent type={type} />
        <Typography>For more visit </Typography>
        <Typography variant="pica">{ url.replace(/\/$/, '') }</Typography>
      </ModalAlertContents>
    </FullPageModal>
  );
};

export default AgreementModal;

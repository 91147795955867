import React from 'react';

import UnlimitedUpsell from 'app/on-tv/organisms/unlimited-upsell';
import useDismissEvent from 'app/hooks/use-dismiss-event';

const UnlimitedUpsellPage = () => {
  useDismissEvent();
  return (
    <UnlimitedUpsell />
  );
};

UnlimitedUpsellPage.menu = true;

export default UnlimitedUpsellPage;

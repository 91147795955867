import { SpatialStateNode, SpatialStateTree } from 'utils/spatial-nav/types';

// methods of tree traversal - TODO - can they be optimized / reduce the boilerplate

// eslint-disable-next-line
type Propagate = typeof climbLeft | typeof climbRight | typeof climbUp | typeof climbDown;

export const drillForTarget = (
  tree: SpatialStateTree,
  node: SpatialStateNode,
  propagate: Propagate,
): SpatialStateNode | null => {
  const { info, children } = node;

  if (!info) {
    throw new Error('No info found when traversing node node');
  }

  if (info?.el) {
    return node;
  }

  if (!children) {
    throw new Error(`Parent node with no children ${JSON.stringify(info)}`);
  }

  // If the last focused child of this parent is not disabled, focus it
  const lastFocusedChild = tree[children[info.lastFocusedChildIndex]];

  if (lastFocusedChild && lastFocusedChild.info && !lastFocusedChild.info.disabled) {
    return drillForTarget(tree, lastFocusedChild, propagate);
  }

  const firstEnabledChild = children.find((childId) => !tree[childId]?.info?.disabled);

  if (!firstEnabledChild) {
    // No child is found down this leaf - back out from here to the next leaf until we find one
    return propagate(tree, node);
  }

  return drillForTarget(tree, tree[firstEnabledChild], propagate);
};

const getSiblingToLeft = (tree: SpatialStateTree, parent: SpatialStateNode, childIndex: number) => {
  if (!parent.info || !parent?.children?.length) {
    return null;
  }

  const { info: { layout, columns = 1 }, children: siblings } = parent;

  // If it's a horizontal layout, and we're not the first element
  if (layout === 'horizontal') {
    return siblings.slice(0, childIndex).reverse().find((id) => !tree[id]?.info?.disabled) || null;
  }

  // If it's a grid layout and we're not in the left most column
  if (layout === 'grid') {
    // TODO grid with disabled elements not supported
    return (childIndex % columns) > 0 ? siblings[childIndex - 1] : null;
  }

  // if it's a vertical layout - then it's never got siblings to the left
  return null;
};

export const climbLeft = (tree: SpatialStateTree, node: SpatialStateNode): SpatialStateNode | null => {
  const { info } = node;
  if (!info?.parentId) {
    return null;
  }

  const parent = tree[info.parentId];

  const leftChildId = getSiblingToLeft(tree, parent, info.index);
  if (leftChildId) {
    const leftNode = tree[leftChildId];

    return drillForTarget(tree, leftNode, climbLeft);
  }

  return climbLeft(tree, parent);
};

const getSiblingToRight = (tree: SpatialStateTree, parent: SpatialStateNode, childIndex: number) => {
  if (!parent.info) {
    return null;
  }

  const { info: { layout, columns = 1 }, children: siblings } = parent;

  // If it's a horizontal layout, and we're not the last element
  if (layout === 'horizontal') {
    return siblings.slice(childIndex + 1).find((id) => !tree[id]?.info?.disabled) || null;
  }

  // If it's a grid layout, and we're not in the right most column
  if (layout === 'grid') {
    // TODO grid with disabled elements not supported
    return (childIndex % columns) < (columns - 1) ? siblings[childIndex + 1] : null;
  }

  // if it's a vertical layout - then it's never got siblings to the right
  return null;
};

export const climbRight = (tree: SpatialStateTree, node: SpatialStateNode): SpatialStateNode | null => {
  const { info } = node;

  if (!info?.parentId) {
    return null;
  }

  const parent = tree[info.parentId];

  const rightChildId = getSiblingToRight(tree, parent, info.index);
  if (rightChildId) {
    const rightNode = tree[rightChildId];

    return drillForTarget(tree, rightNode, climbRight);
  }

  return climbRight(tree, parent);
};

const getSiblingBelow = (tree: SpatialStateTree, parent: SpatialStateNode, childIndex: number) => {
  if (!parent.info) {
    return null;
  }

  const { info: { layout, columns = 1 }, children: siblings } = parent;

  // If it's a vertical layout, and we're not the last element
  if (layout === 'vertical') {
    return siblings.slice(childIndex + 1).find((id) => !tree[id]?.info?.disabled) || null;
  }

  // If it's a grid layout, and we're not in the last row return the row below
  if (layout === 'grid') {
    // TODO grid with disabled elements not supported
    return childIndex < (siblings.length - columns) ? siblings[childIndex + columns] : null;
  }

  // if it's a horizontal layout - then it's never got siblings below
  return null;
};

export const climbDown = (tree: SpatialStateTree, node: SpatialStateNode): SpatialStateNode | null => {
  const { info } = node;

  if (!info?.parentId) {
    return null;
  }

  const parent = tree[info.parentId];

  const belowChildId = getSiblingBelow(tree, parent, info.index);
  if (belowChildId) {
    const belowNode = tree[belowChildId];

    return drillForTarget(tree, belowNode, climbDown);
  }

  return climbDown(tree, parent);
};

const getSiblingAbove = (tree: SpatialStateTree, parent: SpatialStateNode, childIndex: number) => {
  if (!parent.info) {
    return null;
  }

  const { info: { layout, columns = 1 }, children: siblings } = parent;

  // If it's a vertical layout, and we're not the first element
  if (layout === 'vertical') {
    return siblings.slice(0, childIndex).reverse().find((id) => !tree[id]?.info?.disabled) || null;
  }

  // If it's a grid layout, and we're not in the first row
  if (layout === 'grid') {
    // TODO grid with disabled elements not supported
    return childIndex >= columns ? siblings[childIndex - columns] : null;
  }

  // if it's a horizontal layout - then it's never got siblings above
  return null;
};

export const climbUp = (tree: SpatialStateTree, node: SpatialStateNode): SpatialStateNode | null => {
  const { info } = node;

  if (!info?.parentId) {
    return null;
  }

  const parent = tree[info.parentId];

  const aboveChildId = getSiblingAbove(tree, parent, info.index);
  if (aboveChildId) {
    const aboveNode = tree[aboveChildId];

    return drillForTarget(tree, aboveNode, climbUp);
  }

  return climbUp(tree, parent);
};

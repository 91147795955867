import React, { useMemo, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { useAppState } from 'state';

import useLogger from 'app/hooks/use-logger';

import useConfig from 'app/on-tv/config-provider';

import ClassCard from 'ui/components/molecules/class-card';
import ClassGrid from 'ui/components/layouts/class-grid-page';
import { UserFavouriteLessons as USER_FAVOURITE_LESSONS_QUERY } from 'app/on-tv/pages/favourites/favourite-lessons.gql';
import useRoutes, { url } from 'utils/use-routes';
import FilterBar, { useDurationFilter } from 'ui/components/molecules/filter-bar';
import useDelay from 'utils/use-delay';

import { LessonDurationRange } from 'types/graphql';
import lessonsQueryVariables from 'app/on-tv/utils/lessons-query-variables';

import {
  FavouriteLessonOrder,
  LessonStatus,
  UserFavouriteLessonsQuery,
  UserFavouriteLessonsQueryVariables,
} from 'app/on-tv/types/graphql';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import EmptyState from 'ui/components/molecules/empty-state';
import FavouriteOutline from 'ui/components/atoms/icons/favourite-outline';
import transformLessonData from 'app/on-tv/utils/transform-lesson-data';
import Typography from 'ui/components/atoms/typography';
import { useSetFocus } from 'utils/spatial-nav';

export type Props = RouteComponentProps<{}>;

export type LessonItem = {
  id: number,
  mainImageUrl?: string,
  duration: string,
  trainerName: string,
  name: string,
  locked?: boolean,
  completed: boolean,
  equipment?: boolean,
  favourited?: boolean,
};

const FavouritesPage = () => {
  const userId = useAppState((state) => state.auth.userId);
  const setFocus = useSetFocus();

  const { config } = useConfig();

  const { routes, redirect } = useRoutes();

  const { durationRange, showAllDurations, labelFromLessonDuration, onFilter } = useDurationFilter();

  const logger = useLogger('on-tv:favourites');

  const { loading, error, data } = useQuery<UserFavouriteLessonsQuery, UserFavouriteLessonsQueryVariables>(
    USER_FAVOURITE_LESSONS_QUERY, {
      variables: {
        ...lessonsQueryVariables,
        lessonConditions: showAllDurations
          ? lessonsQueryVariables.lessonConditions
          : {
            ...lessonsQueryVariables.lessonConditions,
            durationRange: [durationRange],
          },
        userId,
        lessonFirst: config.MAX_LESSONS_IN_GRID,
        lessonOrderBy: FavouriteLessonOrder.FAVOURITED_AT,
      },
      pollInterval: config.FAVOURITES_POLLING_INTERVAL * 1000,
      onError: (e) => logger.error('UserFavouriteLessonsQuery error', { error: e }),
    },
  );
  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  useDismissEvent();

  const lessons = useMemo(() => transformLessonData(data?.user?.favouriteLessons?.edges), [data]);

  useEffect(() => {
    if (lessons.length > 0) {
      setFocus('CLASS_GRID_CONTENT');
    }
  }, [lessons, setFocus]);

  const emptyStateOnAction = useCallback(() => redirect({ route: routes.BROWSE }), [redirect, routes]);

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if ((data?.user?.favouriteLessons.totalCount === 0 && showAllDurations) || !userId) {
    return (
      <EmptyState
        icon={<FavouriteOutline />}
        heading="No favourite classes yet"
        subheading="Tap the heart on your favourite workouts in the phone app to find them easily here."
        buttonLabel="Find Classes"
        onAction={emptyStateOnAction}
      />
    );
  }

  return (
    <ClassGrid
      pageTitle={<Typography variant="double-pica">Favourites</Typography>}
      filterBar={(
        <FilterBar
          selected={durationRange}
          enums={LessonDurationRange}
          labelFromEnum={labelFromLessonDuration}
          onSelect={(duration) => onFilter({ duration, route: routes.FAVOURITES })}
          autofocus={lessons.length === 0}
          resetScroll
        />
      )}
      selectedFilter={durationRange}
    >
      {
        lessons.map((lesson, index) => (
          <ClassCard
            key={lesson.id}
            backgroundImage={lesson.mainImageUrl}
            size="large"
            duration={lesson.duration}
            trainer={lesson.trainerName}
            name={lesson.name}
            to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
            locked={lesson.locked}
            completed={lesson.completed}
            dumbbells={lesson.dumbbells}
            kettlebells={lesson.kettlebells}
            favourited={lesson.favourited}
            autofocus={index === 0}
            isWithinGrid
            inTesting={lesson.status === LessonStatus.TESTING}
          />
        ))
      }
    </ClassGrid>
  );
};

FavouritesPage.menu = true;

export default FavouritesPage;

import useDismissEvent from 'app/hooks/use-dismiss-event';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import ScrollableText from 'ui/components/atoms/scrollable-text';
import TabBar from 'ui/components/molecules/tab-bar';
import { rem } from 'ui/helpers';
import { SpatialNavParent } from 'utils/spatial-nav';
import PrivacyPolicy from 'app/on-tv/pages/legal/privacy-policy';
import TermsAndConditions from 'app/on-tv/pages/legal/terms-and-conditions';

const Wrapper = styled.div`
  display: flex;
  margin: ${rem(96)} ${rem(164)};
`;

const ContentSection = styled.div`
  width: ${rem(1060)};
  position: relative;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-end;
  width: ${rem(228)};
  margin-right: ${rem(24)};
`;

const StyledTabBar = styled(TabBar)`
  margin-bottom: 1rem;
`;

const StyledScrollableText = styled(ScrollableText)`
  height: 76vh;
`;

const tabNames = [
  { name: 'Terms and conditions', id: 'terms_and_conditions' },
  { name: 'Privacy policy', id: 'privacy_policy' },
];

const getContent = (selectedTabIndex: number) => (
  tabNames[selectedTabIndex].id === 'terms_and_conditions'
    ? <TermsAndConditions />
    : <PrivacyPolicy />
);

const LegalPage = () => {
  const history = useHistory();
  useDismissEvent();

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = useMemo(() => (
    tabNames.map((tab, i) => ({
      ...tab,
      selected: i === selectedTab,
    }))
  ), [selectedTab]);

  return (
    <Wrapper>
      <SpatialNavParent layout="horizontal">
        <ButtonSection>
          <Button
            label="Back"
            labelPosition="right"
            icon={<ChevronLeft />}
            iconOnly
            onClick={history.goBack}
          />
        </ButtonSection>
        <ContentSection>
          <SpatialNavParent layout="vertical" forgetFocus>
            <StyledTabBar
              tabs={tabs}
              autofocus
              onTabFocus={setSelectedTab}
            />
            <StyledScrollableText rightHandScrollbar fadeText>
              {getContent(selectedTab)}
            </StyledScrollableText>
          </SpatialNavParent>
        </ContentSection>
      </SpatialNavParent>
    </Wrapper>
  );
};

LegalPage.menu = false;
export default LegalPage;

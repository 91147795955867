import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
`;

// Generated with `npx @svgr/cli icon.svg`
const NthIcon = ({ className = '', color = 'white' }: IconProps) => {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg className={className} x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
      <defs>
        <path
          id="nth_icon_svg__a"
          d="M19.2 13.457c-2.65 0-4.8 2.137-4.8 4.771 0 2.635 2.15 4.772 4.8 4.772 2.651 0 4.8-2.137 4.8-4.772 0-2.634-2.149-4.77-4.8-4.77m-14.4 0c-2.65 0-4.8 2.136-4.8 4.77C0 20.863 2.15 23 4.8 23c2.652 0 4.8-2.137 4.8-4.772 0-2.634-2.148-4.77-4.8-4.77m12-7.687c0 2.635-2.149 4.77-4.8 4.77-2.65 0-4.8-2.135-4.8-4.77S9.35 1 12 1c2.651 0 4.8 2.136 4.8 4.77"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="nth_icon_svg__b" fill={fill}>
          <use xlinkHref="#nth_icon_svg__a" />
        </mask>
        <g fill={fill} mask="url(#nth_icon_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </Svg>
  );
};

export default NthIcon;

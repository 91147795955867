import { ComponentClass } from 'react';

import LoginByPasswordPage from 'app/pages/login-password';
import LoginSplashPage from 'app/on-tv/pages/login-splash';
import PinPage from 'app/on-tv/pages/pin';
import LoginByMagicPage from 'app/on-tv/pages/login-magic';
import AuthenticatePage from 'app/on-tv/pages/authenticate';

import BrowsePage from 'app/on-tv/pages/browse';
import LessonPage from 'app/on-tv/pages/lesson';
import CollectionPage from 'app/on-tv/pages/collection';
import FavouritesPage from 'app/on-tv/pages/favourites';
import TrainerPage from 'app/on-tv/pages/trainer';
import TrainersPage from 'app/on-tv/pages/trainers';
import LessonInstancePage from 'app/on-tv/pages/lesson-instance';
import WorkoutSummaryPage from 'app/on-tv/pages/workout-summary';
import TrainingPlansPage from 'app/on-tv/pages/training-plans';
import TrainingPlanDetailsPage from 'app/on-tv/pages/training-plan-details';
import ActiveTrainingPlanPage from 'app/on-tv/pages/active-training-plan';
import LessonsPage from 'app/on-tv/pages/lessons';
import FilterPage from 'app/on-tv/pages/filter';
import LegalPage from 'app/on-tv/pages/legal';

import ProfilePage from 'app/on-tv/pages/profile';
import PremiumUpsellPage from 'app/on-tv/pages/premium-upsell';
import UnlimitedUpsellPage from 'app/on-tv/pages/unlimited-upsell';
import ConnectDevicePage from 'app/on-tv/pages/connect-device';

import DebugPage from 'app/on-tv/pages/debug';
import ConfigDebugPage from 'app/on-tv/pages/debug/config';
import CastingSplashScreen from 'app/on-tv/pages/casting-splash';
import CastingSummaryScreen from 'app/on-tv/pages/casting-summary';

export type RouteConfig = {
  acceptedPaths: string[],
  component: ((args: any) => (JSX.Element) | null) | ComponentClass<any>;
  exact: boolean,
  // Does it show the side menu?
  menu?: boolean,
  // Is the route accessible without authentication?
  unauthenticated?: Array<boolean | null>,
};

const routes = {
  // Login routes - unauthenticated
  LOGIN: {
    path: 'LOGIN',
    acceptedPaths: ['/', '/login'],
    component: LoginSplashPage,
    exact: true,
    unauthenticated: [true],
    menu: LoginSplashPage.menu,
  },
  LOGIN_BY_PIN: {
    path: 'LOGIN_BY_PIN',
    acceptedPaths: ['/login/pin'],
    component: PinPage,
    exact: true,
    unauthenticated: [true],
    menu: PinPage.menu,
  },
  LOGIN_BY_PASSWORD: {
    path: 'LOGIN_BY_PASSWORD',
    acceptedPaths: ['/login/password'],
    component: LoginByPasswordPage,
    exact: true,
    unauthenticated: [true],
    menu: LoginByPasswordPage.menu,
  },
  LOGIN_BY_MAGIC: {
    path: 'LOGIN_BY_MAGIC',
    acceptedPaths: ['/login/magic'],
    component: LoginByMagicPage,
    exact: true,
    unauthenticated: [true],
    menu: LoginByMagicPage.menu,
  },
  SIGNUP: {
    path: 'SIGNUP',
    acceptedPaths: ['/signup'],
    component: PinPage,
    exact: true,
    unauthenticated: [true],
    menu: PinPage.menu,
  },
  AUTHENTICATE: {
    path: 'AUTHENTICATE',
    acceptedPaths: ['/authenticate'],
    component: AuthenticatePage,
    exact: true,
    unauthenticated: [true],
    menu: AuthenticatePage.menu,
  },

  // Profile
  PROFILE: {
    path: 'PROFILE',
    acceptedPaths: ['/profile'],
    component: ProfilePage,
    exact: true,
    unauthenticated: [false],
    menu: ProfilePage.menu,
  },

  // T&Cs and privacy policy
  LEGAL: {
    path: 'LEGAL',
    acceptedPaths: ['/privacy', '/terms-and-conditions'],
    component: LegalPage,
    exact: true,
    unauthenticated: [false],
    menu: LegalPage.menu,
  },

  // Premium Upsell
  PREMIUM_UPSELL: {
    path: 'PREMIUM_UPSELL',
    acceptedPaths: ['/premium', '/pricing/iap'],
    component: PremiumUpsellPage,
    exact: true,
    unauthenticated: [false],
    menu: PremiumUpsellPage.menu,
  },

  // Unlimited Upsell
  UNLIMITED_UPSELL: {
    path: 'UNLIMITED_UPSELL',
    acceptedPaths: ['/unlimited'],
    component: UnlimitedUpsellPage,
    exact: true,
    unauthenticated: [false],
    menu: UnlimitedUpsellPage.menu,
  },

  // Browse + catalogue pages
  BROWSE: {
    path: 'BROWSE',
    acceptedPaths: [
      '/browse',
      '/browse/collections',
      '/studios',
      '/classes',
      '/index.html', /* samsung special */
    ],
    component: BrowsePage,
    exact: true,
    unauthenticated: [false, true],
    menu: BrowsePage.menu,
  },
  LESSON_DETAILS: {
    path: 'LESSON_DETAILS',
    acceptedPaths: ['/classes/:id'],
    component: LessonPage,
    exact: true,
    unauthenticated: [false, true],
    menu: LessonPage.menu,
  },
  LESSONS: {
    path: 'LESSONS',
    acceptedPaths: ['/lessons/:lessonOrder', '/browse/:lessonOrder'],
    component: LessonsPage,
    exact: true,
    unauthenticated: [false, true],
    menu: LessonsPage.menu,
  },
  FILTER: {
    path: 'FILTER',
    acceptedPaths: ['/filter'],
    component: FilterPage,
    exact: true,
    unauthenticated: [false, true],
    menu: FilterPage.menu,
  },
  COLLECTION: {
    path: 'COLLECTION',
    acceptedPaths: ['/collections/:slug', '/browse/collections/:slug', '/studios/:slug'],
    component: CollectionPage,
    exact: true,
    unauthenticated: [true, false],
    menu: CollectionPage.menu,
  },
  FAVOURITES: {
    path: 'FAVOURITES',
    acceptedPaths: ['/favourites'],
    component: FavouritesPage,
    exact: true,
    unauthenticated: [false],
    menu: FavouritesPage.menu,
  },
  TRAINER: {
    path: 'TRAINER',
    acceptedPaths: ['/trainers/:slug'],
    component: TrainerPage,
    exact: true,
    unauthenticated: [false, true],
    menu: TrainerPage.menu,
  },
  TRAINERS: {
    path: 'TRAINERS',
    acceptedPaths: ['/trainers'],
    component: TrainersPage,
    exact: true,
    unauthenticated: [false, true],
    menu: TrainersPage.menu,
  },
  TRAINING_PLANS: {
    path: 'TRAINING_PLANS',
    acceptedPaths: ['/training-plans'],
    component: TrainingPlansPage,
    exact: true,
    unauthenticated: [false, true],
    menu: TrainingPlansPage.menu,
  },
  TRAINING_PLAN: {
    path: 'TRAINING_PLAN',
    acceptedPaths: ['/training-plans/:slug', '/training-plans/:slug/:level'],
    component: TrainingPlanDetailsPage,
    exact: true,
    unauthenticated: [false, true],
    menu: TrainingPlanDetailsPage.menu,
  },
  ACTIVE_TRAINING_PLAN: {
    path: 'ACTIVE_TRAINING_PLAN',
    acceptedPaths: ['/active-training-plan'],
    component: ActiveTrainingPlanPage,
    exact: true,
    unauthenticated: [false],
    menu: ActiveTrainingPlanPage.menu,
  },
  CONNECT_DEVICE: {
    path: 'CONNECT_DEVICE',
    acceptedPaths: ['/connect-device/:partyId'],
    component: ConnectDevicePage,
    exact: true,
    unauthenticated: [false],
    menu: ConnectDevicePage.menu,
  },
  WORKOUT_SUMMARY: {
    path: 'WORKOUT_SUMMARY',
    acceptedPaths: ['/workout-summary/:id'],
    component: WorkoutSummaryPage,
    exact: true,
    unauthenticated: [false],
    menu: WorkoutSummaryPage.menu,
  },

  // Workout page (currently 2 routes to support party and non party workouts)
  PARTY: {
    path: 'PARTY',
    acceptedPaths: ['/party/:partyId'],
    component: LessonInstancePage,
    exact: true,
    unauthenticated: [false],
    menu: LessonInstancePage.menu,
  },
  LESSON_INSTANCE: {
    path: 'LESSON_INSTANCE',
    acceptedPaths: ['/lesson-instance/:lessonInstanceId'],
    component: LessonInstancePage,
    exact: true,
    unauthenticated: [false],
    menu: LessonInstancePage.menu,
  },

  // Dev debug page
  DEBUG: {
    path: 'DEBUG',
    acceptedPaths: ['/debug'],
    component: DebugPage,
    exact: true,
    unauthenticated: [false],
    menu: DebugPage.menu,
  },

  // Changing config
  DEBUG_CONFIG: {
    path: 'DEBUG_CONFIG',
    acceptedPaths: ['/debug/config'],
    component: ConfigDebugPage,
    exact: true,
    unauthenticated: [false],
    menu: ConfigDebugPage.menu,
  },

  CAST_SPLASH: {
    path: 'CAST_SPLASH',
    acceptedPaths: ['/cast/classes/:id', '/cast'],
    component: CastingSplashScreen,
    exact: true,
    unauthenticated: [null],
    menu: false,
  },

  CAST_SUMMARY: {
    path: 'CAST_SUMMARY',
    acceptedPaths: ['/cast/summary/:id'],
    component: CastingSummaryScreen,
    exact: true,
    unauthenticated: [null],
    menu: false,
  },
};

export default routes;

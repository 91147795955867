import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import useLogger from 'app/hooks/use-logger';

import useConfig from 'app/on-tv/config-provider';

import ClassCard from 'ui/components/molecules/class-card';
import TrainersGrid from 'ui/components/layouts/trainer-grid-page';
import { AllTrainers as ALL_TRAINERS_QUERY } from 'app/on-tv/pages/trainers/trainers.gql';
import useRoutes, { url } from 'utils/use-routes';
import useDelay from 'utils/use-delay';

import {
  AllTrainersQuery,
  AllTrainersQueryVariables,
} from 'app/on-tv/types/graphql';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useDismissEvent from 'app/hooks/use-dismiss-event';

const TrainersPage = () => {
  const { config } = useConfig();

  const { routes } = useRoutes();

  const logger = useLogger('on-tv:trainers');

  const { loading, error, data } = useQuery<AllTrainersQuery, AllTrainersQueryVariables>(ALL_TRAINERS_QUERY, {
    variables: {
      trainersCount: 100,
    },
    onError: (e) => logger.error('AllTrainersQuery error', { error: e }),
  });
  const delayed = useDelay(config.BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS);

  useDismissEvent();

  const trainers = useMemo(() => data?.allTrainers?.edges?.map(({ node }, i) => (
    <ClassCard
      key={i}
      backgroundImage={node.coverImage?.url}
      size="trainer"
      name={node.firstname}
      focusedName={`${node.firstname}\n${node.lastname}`}
      to={url({ route: routes.TRAINER, params: { slug: node.slug } })}
      autofocus={i === 0}
      isWithinGrid
    />
  )), [data, routes]) || [];

  if (loading || delayed) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  if (!data?.allTrainers) {
    logger.error('An error occured loading trainers');
    return <ErrorOverlay error onDismiss="back" />;
  }

  return (
    <TrainersGrid
      pageTitle="Meet the trainers"
      subtitle="World-class instructors that keep you coming back for more."
      gridTitle="Trainers"
      columns={3}
    >
      {trainers}
    </TrainersGrid>
  );
};

TrainersPage.menu = true;

export default TrainersPage;

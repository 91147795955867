import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function SvgEquipment({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];

  return (
    <Svg viewBox="-3 0 30 24" className={className}>
      <path
        d="M18.1 5c3.8 0 6.4 3.1 5.7 6.9-.3 1.6-1.1 3.1-2.3 4.2l1 1.3-2.2 1.6-1.1-1.4c-1 .5-2.2.7-3.4.7-3.7 0-6.5-3.1-5.8-6.9.8-3.7 4.2-6.4 8.1-6.4zM11 5l-.5 2.9H5L4.6 10h4.9L9 12.8H4.1l-.4 2.3h5.5L8.7 18H0L2.4 5H11zm6.7 2.9c-2.2 0-4.1 1.5-4.5 3.8-.4 2.3.9 3.8 3 3.8.4 0 .8-.1 1.2-.1l-1.6-2 2.3-1.7 1.7 2.1c.5-.6.9-1.3 1-2.1.5-2.3-.9-3.8-3.1-3.8z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgEquipment;

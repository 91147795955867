import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

type Props = {
  name: string;
  value?: ReactNode;
  icon: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: ${rem(260)};
  height: ${rem(120)};
`;

const MetricValue = styled(Typography)`
  padding-top: ${rem(10)};
`;

const MetricName = styled(Typography)`
  color: ${({ theme }) => theme.colors.beckersKnop};
`;

const PostClassMetric = ({ name, value, icon }: Props) => (
  <Container>
    { icon }
    <MetricValue variant="double-pica">{ value || '-' }</MetricValue>
    <MetricName variant="pica">{ name }</MetricName>
  </Container>
);

export default PostClassMetric;

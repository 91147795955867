import React from 'react';
import styled from 'styled-components';
import useConfig from 'app/on-tv/config-provider';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

const Wrapper = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;

const Title = styled(Typography)`
  margin-bottom: 1.5rem;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InlineText = styled.div<{ pushRight?: boolean }>`
  position: absolute;
  ${({ pushRight }) => (pushRight ? 'right: 4rem' : 'left: 4rem')};

  & :first-child {
    margin-right: 0.5rem;
  }
`;

const StepImage = styled.img`
  width: ${rem(390)};
  height: ${rem(390)};
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22%;
  text-align: center;
`;

const StepBlock = styled.div`
  padding: 0.3rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.fordDarkCharcoal};
  width: ${rem(140)};
  align-self: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

const BackButton = styled(Button)`
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: auto;
`;

const Url = styled(Typography)`
  margin-left: 0.5rem;
`;

const getSteps = (appType: string, isConnectedKit: boolean) => [
  {
    stepNumber: 1,
    image: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/30102330/step-1.png',
    description: `Wear a compatible fitness tracker* ${isConnectedKit && 'and have a compatible item of equipment'}`,
  },
  {
    stepNumber: 2,
    image: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/30102325/step-2.png',
    description: 'Download the Fiit app on your mobile device',
  },
  {
    stepNumber: 3,
    image: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/30102327/step-3.png',
    description: `Log in using the same details as the Fiit ${appType === 'web' ? 'web' : 'TV'} app`,
  },
  {
    stepNumber: 4,
    image: 'https://images-bucket.prod.fiit-static.net/app/uploads/2020/11/30102328/step-4.png',
    description: `Follow the steps to connect your tracker ${isConnectedKit ? 'and equipment' : ''}`,
  },
];

type Props = {
  onBackClick: () => void;
  isConnectedKit: boolean,
};

const ConnectDeviceGuide = ({ onBackClick, isConnectedKit } : Props) => {
  const { config } = useConfig();

  return (
    <Wrapper>
      <Title variant="double-pica">How to see your live stats on screen</Title>
      <StepsContainer>
        {getSteps(config.APP_TYPE, isConnectedKit).map(({ image, description, stepNumber }) => (
          <Step key={`step:${stepNumber}`}>
            <StepImage src={`${image}?content-type=png`} />
            <StepBlock>
              <Typography weight="medium">Step {stepNumber}</Typography>
            </StepBlock>
            <Typography weight="medium" color="beckersKnop">{description}</Typography>
          </Step>
        ))}
      </StepsContainer>
      <BackButton
        label="Back"
        icon={<ChevronLeft />}
        labelPosition="right"
        iconOnly
        onClick={onBackClick}
        autofocus
      />
      <Footer>
        <InlineText>
          <Typography color="beckersKnop">
            * For a list of compatible fitness trackers visit
          </Typography>
          <Url variant="pica">
            fiit.tv/connected-fitness
          </Url>
        </InlineText>
        <InlineText pushRight>
          <Typography color="beckersKnop">
            For help go to
          </Typography>
          <Url variant="pica">
            HELP.FIIT.TV
          </Url>
        </InlineText>
      </Footer>
    </Wrapper>
  );
};

export default ConnectDeviceGuide;

import React from 'react';

import Casting from 'ui/components/layouts/casting';
import Typography from 'ui/components/atoms/typography';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import useLogger from 'app/hooks/use-logger';
import { useQuery } from '@apollo/react-hooks';
import { CastingSummaryWorkoutQuery, CastingSummaryWorkoutQueryVariables } from 'app/on-tv/types/graphql';
import { CastingSummaryWorkout as WORKOUT_QUERY } from 'app/on-tv/pages/casting-summary/workout-info.gql';
import { RouteComponentProps } from 'react-router-dom';

const CastingSummaryScreen = (props: RouteComponentProps<{ id: string }>) => {
  const id = parseInt(props.match.params.id, 10);
  const logger = useLogger('casting-summary-page');

  const { loading, error, data } = useQuery<CastingSummaryWorkoutQuery, CastingSummaryWorkoutQueryVariables>(
    WORKOUT_QUERY, {
      skip: !id,
      variables: { id },
      onError: (e) => logger.error('Casting Summary graphQL error', { error: e }),
    },
  );

  if (!id || loading) {
    return <LoadingScreen />;
  }

  const lesson = data?.workoutById?.lesson;

  if (error || !lesson) {
    return (
      <ErrorOverlay
        error={error || new Error('Lesson not found.')}
        showBackButton={false}
        message="Something went wrong, please try casting again."
      />
    );
  }

  return (
    <Casting backgroundUrl={lesson.mainImage?.url} footerText="The casting will stop after you leave the class summary">
      <Typography variant="paragon">
        Great work
      </Typography>
    </Casting>
  );
};

export default CastingSummaryScreen;

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';

import useLogger from 'app/hooks/use-logger';
import useRoutes from 'utils/use-routes';
import useCastMessageListener from 'app/on-tv/hooks/use-cast-message-listener';
import {
  CastingLessonMediaQuery,
  CastingLessonMediaQueryVariables,
  CreatePartyMutation,
  CreatePartyMutationVariables,
  LobbyJoinedSubscription,
  PatchLessonInstanceMutation,
  PatchLessonInstanceMutationVariables,
  Party,
} from 'app/on-tv/types/graphql';

import { CreateParty as CREATE_PARTY_MUTATION } from 'app/on-tv/pages/lesson/create-party.gql';
import { useAppState } from 'state';
import { CastingLessonMedia as LESSON_QUERY } from 'app/on-tv/pages/casting-splash/lesson-info.gql';
import { LobbyJoined as LOBBY_JOINED_SUB } from 'app/on-tv/pages/connect-device/subscriptions.gql';
import { PatchLessonInstance as PATCH_LESSON_INSTANCE } from 'app/on-tv/pages/connect-device/mutations.gql';
import { LessonInstanceLobbyStatus, LessonInstancePlayState } from 'types/graphql';
import useSendChromecastMessage from 'app/on-tv/utils/send-chromecast-message';

export type PartyHandlerProps = {
  userId: number,
  lessonId: number,
};

type PartyStarterProps = {
  userId: number,
  lessonMediaId: string,
};

type PartyWrapperProps = {
  partyId: string,
  lessonInstanceId: string,
};

const PartyWrapper = ({ partyId, lessonInstanceId }: PartyWrapperProps) => {
  const { routes, redirect } = useRoutes();
  const logger = useLogger('casting-splash-page');
  const { sendChromecastMessage } = useSendChromecastMessage();

  logger.info('Party Wrapper triggered');

  const [
    patchLessonInstance,
  ] = useMutation<PatchLessonInstanceMutation, PatchLessonInstanceMutationVariables>(PATCH_LESSON_INSTANCE, {
    onError: (e) => {
      logger.error('PatchLessonInstanceMutation error', { error: e });
      throw e;
    },
    onCompleted: () => {
      logger.info('Patched lesson instance');
    },
  });

  const { error } = useSubscription(LOBBY_JOINED_SUB, {
    variables: { partyId },
    onSubscriptionData: async (
      { subscriptionData }: { subscriptionData: { data?: LobbyJoinedSubscription } },
    ) => {
      logger.info('Received subscription data', { ...subscriptionData });
      if (!subscriptionData.data) {
        return;
      }

      logger.info('Patching lesson instance');

      await patchLessonInstance({
        variables: {
          id: lessonInstanceId,
          input: {
            playState: LessonInstancePlayState.PLAYING,
            lobbyStatus: LessonInstanceLobbyStatus.CLOSED,
          },
        },
      });

      logger.info('Redirecting to party page');

      redirect({ route: routes.PARTY, params: { partyId } });
    },
  });

  useEffect(() => {
    if (!error) {
      sendChromecastMessage({
        type: 'JOIN_PARTY',
        data: {
          partyId,
          lessonInstanceId,
        },
      });
    }
  }, [error, sendChromecastMessage, partyId, lessonInstanceId]);

  return <></>;
};

const PartyStarter = ({ userId, lessonMediaId }: PartyStarterProps) => {
  const logger = useLogger('casting-splash-page');
  const [party, setParty] = useState<Party | null>(null);
  const hostInstallationId = useAppState((state) => state?.installation?.installationId);

  const [createParty] = useMutation<CreatePartyMutation, CreatePartyMutationVariables>(CREATE_PARTY_MUTATION, {
    variables: {
      input: {
        lessonMediaId,
        hostUserId: userId,
        hostInstallationId,
      },
    },
    onCompleted: ({ createParty: { party: { id } } }) => {
      logger.log('party created with id', id);
    },
    onError: (e) => {
      logger.error('CreatePartyMutation error', e);
      throw e;
    },
  });

  useCastMessageListener('START_PARTY', async () => {
    logger.log('starting party');
    const { data } = await createParty();
    logger.info('created party', { ...data });

    setParty(data?.createParty.party as Party);
  });

  return party ? <PartyWrapper partyId={party.id} lessonInstanceId={party.lessonInstance.id} /> : <></>;
};

const PartyHandler = ({ userId, lessonId }: PartyHandlerProps) => {
  const logger = useLogger('casting-splash-page');

  // Pull lesson with lesson media now we're authed.
  const { data, error } = useQuery<CastingLessonMediaQuery, CastingLessonMediaQueryVariables>(LESSON_QUERY, {
    skip: !lessonId,
    variables: {
      id: lessonId,
    },
    onError: (e) => logger.error('Casting Lesson Media graphQL error', { error: e }),
  });

  const lesson = data && data?.lessonById;

  // Once we have the lesson, start the party.
  if (lesson && lesson.defaultMediaOption) {
    return <PartyStarter userId={userId} lessonMediaId={lesson.defaultMediaOption.id} />;
  }

  if (error) {
    throw error;
  }

  return <></>;
};

export default PartyHandler;
